export const JOB51JOBTITLE = [
  { code: '0100', value: '后端开发', pinyin: 'houduankaifa'
  },
  { code: '0106', value: '高级软件工程师', pinyin: 'gaojiruanjiangongchengshi'
  },
  { code: '0107', value: '软件工程师', pinyin: 'ruanjiangongchengshi'
  },
  { code: '0117', value: 'ERP技术开发', pinyin: 'ERPjishukaifa'
  },
  { code: '0120', value: 'PHP开发工程师', pinyin: 'PHPkaifagongchengshi'
  },
  { code: '0121', value: 'Java开发工程师', pinyin: 'Javakaifagongchengshi'
  },
  { code: '0156', value: 'C开发工程师', pinyin: 'Ckaifagongchengshi'
  },
  { code: '0157', value: 'C++开发工程师', pinyin: 'C++kaifagongchengshi'
  },
  { code: '0123', value: '系统分析员', pinyin: 'xitongfenxiyuan'
  },
  { code: '0124', value: 'Python开发工程师', pinyin: 'Pythonkaifagongchengshi'
  },
  { code: '0126', value: '.NET开发工程师', pinyin: '.NETkaifagongchengshi'
  },
  { code: '0128', value: '区块链开发', pinyin: 'qukuailiankaifa'
  },
  { code: '0129', value: 'Hadoop工程师', pinyin: 'Hadoopgongchengshi'
  },
  { code: '0130', value: '大数据开发工程师', pinyin: 'dashujukaifagongchengshi'
  },
  { code: '0131', value: '爬虫开发工程师', pinyin: 'pachongkaifagongchengshi'
  },
  { code: '0132', value: '脚本开发工程师', pinyin: 'jiaobenkaifagongchengshi'
  },
  { code: '0133', value: '多媒体开发工程师', pinyin: 'duomeitikaifagongchengshi'
  },
  { code: '0142', value: '其他', pinyin: 'qita'
  },
  { code: '0143', value: '系统架构设计师', pinyin: 'xitongjiagoushejishi'
  },
  { code: '0149', value: '技术文员/助理', pinyin: 'jishuwenyuan/zhuli'
  },
  { code: '0150', value: '技术文档工程师', pinyin: 'jishuwendanggongchengshi'
  },
  { code: '0151', value: 'Ruby开发工程师', pinyin: 'Rubykaifagongchengshi'
  },
  { code: '0152', value: 'Go开发工程师', pinyin: 'Gokaifagongchengshi'
  },
  { code: '0153', value: 'C#开发工程师', pinyin: 'C#kaifagongchengshi'
  },
  { code: '0154', value: '全栈工程师', pinyin: 'quanzhangongchengshi'
  },
  { code: '0155', value: 'GIS工程师', pinyin: 'GISgongchengshi'
  },
  { code: '0200', value: '销售管理', pinyin: 'xiaoshouguanli'
  },
  { code: '0201', value: '销售总监', pinyin: 'xiaoshouzongjian'
  },
  { code: '0202', value: '销售经理', pinyin: 'xiaoshoujingli'
  },
  { code: '0203', value: '销售主管', pinyin: 'xiaoshouzhuguan'
  },
  { code: '0207', value: '渠道/分销经理', pinyin: 'qudao/fenxiaojingli'
  },
  { code: '0208', value: '客户经理/主管', pinyin: 'kehujingli/zhuguan'
  },
  { code: '0220', value: '渠道/分销主管', pinyin: 'qudao/fenxiaozhuguan'
  },
  { code: '0226', value: '区域销售经理', pinyin: 'quyuxiaoshoujingli'
  },
  { code: '0230', value: '区域销售总监', pinyin: 'quyuxiaoshouzongjian'
  },
  { code: '0231', value: '其他', pinyin: 'qita'
  },
  { code: '0232', value: '业务拓展主管/经理', pinyin: 'yewutuozhanzhuguan/jingli'
  },
  { code: '0233', value: '渠道/分销总监', pinyin: 'qudao/fenxiaozongjian'
  },
  { code: '0234', value: '团购经理/主管', pinyin: 'tuangoujingli/zhuguan'
  },
  { code: '0235', value: '大客户管理', pinyin: 'dakehuguanli'
  },
  { code: '0236', value: '区域销售主管', pinyin: 'quyuxiaoshouzhuguan'
  },
  { code: '0237', value: '城市经理', pinyin: 'chengshijingli'
  },
  { code: '0300', value: '市场/营销', pinyin: 'shichang/yingxiao'
  },
  { code: '0301', value: '市场/营销/拓展总监', pinyin: 'shichang/yingxiao/tuozhanzongjian'
  },
  { code: '0302', value: '市场/营销/拓展经理', pinyin: 'shichang/yingxiao/tuozhanjingli'
  },
  { code: '0303', value: '市场/营销/拓展主管', pinyin: 'shichang/yingxiao/tuozhanzhuguan'
  },
  { code: '0304', value: '市场/营销/拓展专员', pinyin: 'shichang/yingxiao/tuozhanzhuanyuan'
  },
  { code: '0305', value: '市场助理', pinyin: 'shichangzhuli'
  },
  { code: '0306', value: '产品/品牌经理', pinyin: 'chanpin/pinpaijingli'
  },
  { code: '0307', value: '产品/品牌主管', pinyin: 'chanpin/pinpaizhuguan'
  },
  { code: '0308', value: '市场通路经理/主管', pinyin: 'shichangtonglujingli/zhuguan'
  },
  { code: '0310', value: '促销经理', pinyin: 'cuxiaojingli'
  },
  { code: '0324', value: '市场分析/调研人员', pinyin: 'shichangfenxi/diaoyanrenyuan'
  },
  { code: '0329', value: '其他', pinyin: 'qita'
  },
  { code: '0330', value: '产品/品牌专员', pinyin: 'chanpin/pinpaizhuanyuan'
  },
  { code: '0335', value: '市场通路专员', pinyin: 'shichangtongluzhuanyuan'
  },
  { code: '0336', value: '市场企划经理/主管', pinyin: 'shichangqihuajingli/zhuguan'
  },
  { code: '0337', value: '市场企划专员', pinyin: 'shichangqihuazhuanyuan'
  },
  { code: '0338', value: '选址拓展/新店开发', pinyin: 'xuanzhituozhan/xindiankaifa'
  },
  { code: '0340', value: '互联网营销师', pinyin: 'hulianwangyingxiaoshi'
  },
  { code: '0400', value: '财务/审计/税务', pinyin: 'caiwu/shenji/shuiwu'
  },
  { code: '0401', value: '财务总监', pinyin: 'caiwuzongjian'
  },
  { code: '0402', value: '财务经理', pinyin: 'caiwujingli'
  },
  { code: '0403', value: '财务主管/总账主管', pinyin: 'caiwuzhuguan/zongzhangzhuguan'
  },
  { code: '0404', value: '会计经理/会计主管', pinyin: 'huijijingli/huijizhuguan'
  },
  { code: '0405', value: '会计', pinyin: 'huiji'
  },
  { code: '0406', value: '财务分析经理/主管', pinyin: 'caiwufenxijingli/zhuguan'
  },
  { code: '0407', value: '财务分析员', pinyin: 'caiwufenxiyuan'
  },
  { code: '0408', value: '成本经理/成本主管', pinyin: 'chengbenjingli/chengbenzhuguan'
  },
  { code: '0409', value: '成本管理员', pinyin: 'chengbenguanliyuan'
  },
  { code: '0410', value: '审计经理/主管', pinyin: 'shenjijingli/zhuguan'
  },
  { code: '0411', value: '税务经理/税务主管', pinyin: 'shuiwujingli/shuiwuzhuguan'
  },
  { code: '0412', value: '税务专员/助理', pinyin: 'shuiwuzhuanyuan/zhuli'
  },
  { code: '0414', value: '出纳员', pinyin: 'chunayuan'
  },
  { code: '0419', value: '审计专员/助理', pinyin: 'shenjizhuanyuan/zhuli'
  },
  { code: '0422', value: '财务助理/财务文员', pinyin: 'caiwuzhuli/caiwuwenyuan'
  },
  { code: '0443', value: '其他', pinyin: 'qita'
  },
  { code: '0444', value: '首席财务官 CFO', pinyin: 'shouxicaiwuguan CFO'
  },
  { code: '0445', value: '财务顾问', pinyin: 'caiwuguwen'
  },
  { code: '0446', value: '统计员', pinyin: 'tongjiyuan'
  },
  { code: '0448', value: '固定资产会计', pinyin: 'gudingzichanhuiji'
  },
  { code: '0449', value: '资金经理/主管', pinyin: 'zijinjingli/zhuguan'
  },
  { code: '0450', value: '资金专员', pinyin: 'zijinzhuanyuan'
  },
  { code: '0457', value: '会计助理', pinyin: 'huijizhuli'
  },
  { code: '0458', value: '财务专员', pinyin: 'caiwuzhuanyuan'
  },
  { code: '0500', value: '工程/机械/能源', pinyin: 'gongcheng/jixie/nengyuan'
  },
  { code: '0510', value: '技术研发经理/主管', pinyin: 'jishuyanfajingli/zhuguan'
  },
  { code: '0511', value: '技术研发工程师', pinyin: 'jishuyanfagongchengshi'
  },
  { code: '0512', value: '实验室负责人/工程师', pinyin: 'shiyanshifuzeren/gongchengshi'
  },
  { code: '0513', value: '工程/设备经理', pinyin: 'gongcheng/shebeijingli'
  },
  { code: '0514', value: '工程/设备主管', pinyin: 'gongcheng/shebeizhuguan'
  },
  { code: '0515', value: '工程/设备工程师', pinyin: 'gongcheng/shebeigongchengshi'
  },
  { code: '0523', value: '工程/机械绘图员', pinyin: 'gongcheng/jixiehuituyuan'
  },
  { code: '0537', value: '维修工程师', pinyin: 'weixiugongchengshi'
  },
  { code: '0539', value: '机械工程师', pinyin: 'jixiegongchengshi'
  },
  { code: '0544', value: '机电工程师', pinyin: 'jidiangongchengshi'
  },
  { code: '0547', value: '产品工艺/制程工程师', pinyin: 'chanpingongyi/zhichenggongchengshi'
  },
  { code: '0548', value: '模具工程师', pinyin: 'mojugongchengshi'
  },
  { code: '0559', value: '产品规划工程师', pinyin: 'chanpinguihuagongchengshi'
  },
  { code: '0560', value: '工业工程师', pinyin: 'gongyegongchengshi'
  },
  { code: '0561', value: '结构工程师', pinyin: 'jiegougongchengshi'
  },
  { code: '0562', value: '铸造/锻造工程师/技师', pinyin: 'zhuzao/duanzaogongchengshi/jishi'
  },
  { code: '0563', value: '注塑工程师/技师', pinyin: 'zhusugongchengshi/jishi'
  },
  { code: '0564', value: '焊接工程师/技师', pinyin: 'hanjiegongchengshi/jishi'
  },
  { code: '0565', value: '夹具工程师/技师', pinyin: 'jiajugongchengshi/jishi'
  },
  { code: '0566', value: 'CNC工程师', pinyin: 'CNCgongchengshi'
  },
  { code: '0567', value: '冲压工程师/技师', pinyin: 'chongyagongchengshi/jishi'
  },
  { code: '0568', value: '锅炉工程师/技师', pinyin: 'guolugongchengshi/jishi'
  },
  { code: '0569', value: '电力工程师/技术员', pinyin: 'dianligongchengshi/jishuyuan'
  },
  { code: '0570', value: '光源与照明工程', pinyin: 'guangyuanyuzhaominggongcheng'
  },
  { code: '0571', value: '汽车/摩托车工程师', pinyin: 'qiche/motuochegongchengshi'
  },
  { code: '0572', value: '船舶工程师', pinyin: 'chuanbogongchengshi'
  },
  { code: '0573', value: '飞行器设计与制造', pinyin: 'feixingqishejiyuzhizao'
  },
  { code: '0574', value: '其他', pinyin: 'qita'
  },
  { code: '0575', value: '轨道交通工程师/技术员', pinyin: 'guidaojiaotonggongchengshi/jishuyuan'
  },
  { code: '0576', value: '飞机维修机械师', pinyin: 'feijiweixiujixieshi'
  },
  { code: '0577', value: '水利/水电工程师', pinyin: 'shuili/shuidiangongchengshi'
  },
  { code: '0578', value: '石油天然气技术人员', pinyin: 'shiyoutianranqijishurenyuan'
  },
  { code: '0579', value: '矿产勘探/地质勘测工程师', pinyin: 'kuangchankantan/dizhikancegongchengshi'
  },
  { code: '0580', value: '维修经理/主管', pinyin: 'weixiujingli/zhuguan'
  },
  { code: '0581', value: '装配工程师/技师', pinyin: 'zhuangpeigongchengshi/jishi'
  },
  { code: '0582', value: '材料工程师', pinyin: 'cailiaogongchengshi'
  },
  { code: '0583', value: '光伏系统工程师', pinyin: 'guangfuxitonggongchengshi'
  },
  { code: '0584', value: '项目管理', pinyin: 'xiangmuguanli'
  },
  { code: '0585', value: '空调/热能工程师', pinyin: 'kongdiao/renenggongchengshi'
  },
  { code: '0586', value: '机械设计', pinyin: 'jixiesheji'
  },
  { code: '0587', value: '模具设计', pinyin: 'mojusheji'
  },
  { code: '0600', value: '人力资源', pinyin: 'renliziyuan'
  },
  { code: '0601', value: '人事总监', pinyin: 'renshizongjian'
  },
  { code: '0602', value: '人事经理', pinyin: 'renshijingli'
  },
  { code: '0603', value: '人事主管', pinyin: 'renshizhuguan'
  },
  { code: '0604', value: '人事专员', pinyin: 'renshizhuanyuan'
  },
  { code: '0605', value: '人事助理', pinyin: 'renshizhuli'
  },
  { code: '0606', value: '招聘经理/主管', pinyin: 'zhaopinjingli/zhuguan'
  },
  { code: '0607', value: '薪资福利经理/主管', pinyin: 'xinzifulijingli/zhuguan'
  },
  { code: '0608', value: '薪资福利专员/助理', pinyin: 'xinzifulizhuanyuan/zhuli'
  },
  { code: '0609', value: '培训经理/主管', pinyin: 'peixunjingli/zhuguan'
  },
  { code: '0610', value: '培训专员/助理/培训师', pinyin: 'peixunzhuanyuan/zhuli/peixunshi'
  },
  { code: '0611', value: 'HRBP', pinyin: 'HRBP'
  },
  { code: '0625', value: '其他', pinyin: 'qita'
  },
  { code: '0626', value: '招聘专员/助理', pinyin: 'zhaopinzhuanyuan/zhuli'
  },
  { code: '0627', value: '绩效考核经理/主管', pinyin: 'jixiaokaohejingli/zhuguan'
  },
  { code: '0628', value: '绩效考核专员/助理', pinyin: 'jixiaokaohezhuanyuan/zhuli'
  },
  { code: '0629', value: '企业文化/员工关系/工会管理', pinyin: 'qiyewenhua/yuangongguanxi/gonghuiguanli'
  },
  { code: '0630', value: '人力资源信息系统专员', pinyin: 'renliziyuanxinxixitongzhuanyuan'
  },
  { code: '0635', value: '劳务派遣专员', pinyin: 'laowupaiqianzhuanyuan'
  },
  { code: '0700', value: '高级管理', pinyin: 'gaojiguanli'
  },
  { code: '0701', value: '首席执行官CEO/总裁/总经理', pinyin: 'shouxizhixingguanCEO/zongcai/zongjingli'
  },
  { code: '0702', value: '副总经理/副总裁', pinyin: 'fuzongjingli/fuzongcai'
  },
  { code: '0703', value: '总裁助理/总经理助理', pinyin: 'zongcaizhuli/zongjinglizhuli'
  },
  { code: '0704', value: '合伙人', pinyin: 'hehuoren'
  },
  { code: '0705', value: '总监/部门经理', pinyin: 'zongjian/bumenjingli'
  },
  { code: '0706', value: '其他', pinyin: 'qita'
  },
  { code: '0707', value: '首席运营官COO', pinyin: 'shouxiyunyingguanCOO'
  },
  { code: '0708', value: '办事处首席代表', pinyin: 'banshichushouxidaibiao'
  },
  { code: '0709', value: '办事处/分公司/分支机构经理', pinyin: 'banshichu/fengongsi/fenzhijigoujingli'
  },
  { code: '0710', value: '策略发展总监', pinyin: 'celuefazhanzongjian'
  },
  { code: '0711', value: '企业秘书/董事会秘书', pinyin: 'qiyemishu/dongshihuimishu'
  },
  { code: '0712', value: '投资者关系', pinyin: 'touzizheguanxi'
  },
  { code: '0800', value: '物流/仓储', pinyin: 'wuliu/cangchu'
  },
  { code: '0801', value: '物流经理', pinyin: 'wuliujingli'
  },
  { code: '0802', value: '物流主管', pinyin: 'wuliuzhuguan'
  },
  { code: '0803', value: '物料经理', pinyin: 'wuliaojingli'
  },
  { code: '0804', value: '物料主管/专员', pinyin: 'wuliaozhuguan/zhuanyuan'
  },
  { code: '0808', value: '仓库经理/主管', pinyin: 'cangkujingli/zhuguan'
  },
  { code: '0809', value: '仓库管理员', pinyin: 'cangkuguanliyuan'
  },
  { code: '0810', value: '运输经理/主管', pinyin: 'yunshujingli/zhuguan'
  },
  { code: '0811', value: '报关与报检', pinyin: 'baoguanyubaojian'
  },
  { code: '0812', value: '单证员', pinyin: 'danzhengyuan'
  },
  { code: '0813', value: '快递员', pinyin: 'kuaidiyuan'
  },
  { code: '0814', value: '物流专员/助理', pinyin: 'wuliuzhuanyuan/zhuli'
  },
  { code: '0815', value: '船务/空运陆运操作', pinyin: 'chuanwu/kongyunluyuncaozuo'
  },
  { code: '0823', value: '仓储理货员', pinyin: 'cangchulihuoyuan'
  },
  { code: '0824', value: '其他', pinyin: 'qita'
  },
  { code: '0825', value: '供应链经理', pinyin: 'gongyinglianjingli'
  },
  { code: '0826', value: '供应链主管/专员', pinyin: 'gongyinglianzhuguan/zhuanyuan'
  },
  { code: '0827', value: '物流总监', pinyin: 'wuliuzongjian'
  },
  { code: '0828', value: '供应链总监', pinyin: 'gongyinglianzongjian'
  },
  { code: '0829', value: '货运代理', pinyin: 'huoyundaili'
  },
  { code: '0830', value: '集装箱业务', pinyin: 'jizhuangxiangyewu'
  },
  { code: '0831', value: '调度员', pinyin: 'diaoduyuan'
  },
  { code: '0832', value: '海关事务管理', pinyin: 'haiguanshiwuguanli'
  },
  { code: '0833', value: '项目经理/主管', pinyin: 'xiangmujingli/zhuguan'
  },
  { code: '0834', value: '订单处理员', pinyin: 'dingdanchuliyuan'
  },
  { code: '0835', value: '安检员', pinyin: 'anjianyuan'
  },
  { code: '0836', value: '搬运工', pinyin: 'banyungong'
  },
  { code: '0837', value: '物流销售', pinyin: 'wuliuxiaoshou'
  },
  { code: '0838', value: '分拣员', pinyin: 'fenjianyuan'
  },
  { code: '0839', value: '装卸工', pinyin: 'zhuangxiegong'
  },
  { code: '0840', value: '仓库文员', pinyin: 'cangkuwenyuan'
  },
  { code: '0900', value: '工业/艺术设计', pinyin: 'gongye/yishusheji'
  },
  { code: '0919', value: '工业设计/产品设计', pinyin: 'gongyesheji/chanpinsheji'
  },
  { code: '0920', value: '工艺品/珠宝设计鉴定', pinyin: 'gongyipin/zhubaoshejijianding'
  },
  { code: '0921', value: '其他', pinyin: 'qita'
  },
  { code: '0925', value: '展览/展示/店面设计', pinyin: 'zhanlan/zhanshi/dianmiansheji'
  },
  { code: '0927', value: '包装设计', pinyin: 'baozhuangsheji'
  },
  { code: '0928', value: '家具设计/家居设计', pinyin: 'jiajusheji/jiajusheji'
  },
  { code: '0929', value: '玩具设计', pinyin: 'wanjusheji'
  },
  { code: '0934', value: '照明设计', pinyin: 'zhaomingsheji'
  },
  { code: '0936', value: '陈列设计', pinyin: 'chenliesheji'
  },
  { code: '0940', value: '家具设计', pinyin: 'jiajusheji'
  },
  { code: '0941', value: '家居设计', pinyin: 'jiajusheji'
  },
  { code: '1000', value: '科研', pinyin: 'keyan'
  },
  { code: '1001', value: '科研人员', pinyin: 'keyanrenyuan'
  },
  { code: '1002', value: '科研管理人员', pinyin: 'keyanguanlirenyuan'
  },
  { code: '1100', value: '律师/法务/合规', pinyin: 'lvshi/fawu/hegui'
  },
  { code: '1101', value: '律师/法律顾问', pinyin: 'lvshi/falvguwen'
  },
  { code: '1102', value: '法务主管/专员', pinyin: 'fawuzhuguan/zhuanyuan'
  },
  { code: '1103', value: '律师助理', pinyin: 'lvshizhuli'
  },
  { code: '1105', value: '其他', pinyin: 'qita'
  },
  { code: '1106', value: '法务经理', pinyin: 'fawujingli'
  },
  { code: '1107', value: '法务助理', pinyin: 'fawuzhuli'
  },
  { code: '1108', value: '知识产权/专利/商标', pinyin: 'zhishichanquan/zhuanli/shangbiao'
  },
  { code: '1109', value: '合规经理', pinyin: 'heguijingli'
  },
  { code: '1110', value: '合规主管/专员', pinyin: 'heguizhuguan/zhuanyuan'
  },
  { code: '1111', value: '法务总监', pinyin: 'fawuzongjian'
  },
  { code: '1112', value: '企业贸易合规师', pinyin: 'qiyemaoyiheguishi'
  },
  { code: '1200', value: '教师', pinyin: 'jiaoshi'
  },
  { code: '1201', value: '中学教师', pinyin: 'zhongxuejiaoshi'
  },
  { code: '1204', value: '讲师/助教', pinyin: 'jiangshi/zhujiao'
  },
  { code: '1205', value: '家教', pinyin: 'jiajiao'
  },
  { code: '1206', value: '其他', pinyin: 'qita'
  },
  { code: '1207', value: '幼教', pinyin: 'youjiao'
  },
  { code: '1208', value: '大学教授', pinyin: 'daxuejiaoshou'
  },
  { code: '1209', value: '小学教师', pinyin: 'xiaoxuejiaoshi'
  },
  { code: '1210', value: '兼职教师', pinyin: 'jianzhijiaoshi'
  },
  { code: '1211', value: '职业技术教师', pinyin: 'zhiyejishujiaoshi'
  },
  { code: '1214', value: '音乐/美术教师', pinyin: 'yinle/meishujiaoshi'
  },
  { code: '1215', value: '其他外语老师', pinyin: 'qitawaiyulaoshi'
  },
  { code: '1216', value: '体育教师', pinyin: 'tiyujiaoshi'
  },
  { code: '1218', value: '在线辅导老师', pinyin: 'zaixianfudaolaoshi'
  },
  { code: '1219', value: '英语老师', pinyin: 'yingyulaoshi'
  },
  { code: '1220', value: '数学老师', pinyin: 'shuxuelaoshi'
  },
  { code: '1221', value: '语文老师', pinyin: 'yuwenlaoshi'
  },
  { code: '1222', value: '物理老师', pinyin: 'wulilaoshi'
  },
  { code: '1223', value: '化学老师', pinyin: 'huaxuelaoshi'
  },
  { code: '1224', value: '日语老师', pinyin: 'riyulaoshi'
  },
  { code: '1225', value: '早教老师', pinyin: 'zaojiaolaoshi'
  },
  { code: '1226', value: '音乐老师', pinyin: 'yinlelaoshi'
  },
  { code: '1228', value: '美术老师', pinyin: 'meishulaoshi'
  },
  { code: '1231', value: '初中教师', pinyin: 'chuzhongjiaoshi'
  },
  { code: '1232', value: '高中教师', pinyin: 'gaozhongjiaoshi'
  },
  { code: '1233', value: '钢琴老师', pinyin: 'gangqinlaoshi'
  },
  { code: '1300', value: '医院/医疗/护理', pinyin: 'yiyuan/yiliao/huli'
  },
  { code: '1301', value: '内科医生', pinyin: 'neikeyisheng'
  },
  { code: '1302', value: '医院管理人员', pinyin: 'yiyuanguanlirenyuan'
  },
  { code: '1304', value: '药库主任/药剂师', pinyin: 'yaokuzhuren/yaojishi'
  },
  { code: '1305', value: '护士/护理人员', pinyin: 'hushi/hulirenyuan'
  },
  { code: '1308', value: '麻醉医生', pinyin: 'mazuiyisheng'
  },
  { code: '1309', value: '心理医生', pinyin: 'xinliyisheng'
  },
  { code: '1310', value: '医学检验', pinyin: 'yixuejianyan'
  },
  { code: '1311', value: '其他', pinyin: 'qita'
  },
  { code: '1313', value: '针灸/推拿', pinyin: 'zhenjiu/tuina'
  },
  { code: '1314', value: '营养师', pinyin: 'yingyangshi'
  },
  { code: '1315', value: '兽医', pinyin: 'shouyi'
  },
  { code: '1317', value: '外科医生', pinyin: 'waikeyisheng'
  },
  { code: '1318', value: '专科医生', pinyin: 'zhuankeyisheng'
  },
  { code: '1319', value: '牙科医生', pinyin: 'yakeyisheng'
  },
  { code: '1320', value: '美容整形师', pinyin: 'meirongzhengxingshi'
  },
  { code: '1321', value: '理疗师', pinyin: 'liliaoshi'
  },
  { code: '1322', value: '中医科医生', pinyin: 'zhongyikeyisheng'
  },
  { code: '1323', value: '公共卫生/疾病控制', pinyin: 'gonggongweisheng/jibingkongzhi'
  },
  { code: '1324', value: '护理主任/护士长', pinyin: 'hulizhuren/hushichang'
  },
  { code: '1325', value: '儿科医生', pinyin: 'erkeyisheng'
  },
  { code: '1326', value: '验光师', pinyin: 'yanguangshi'
  },
  { code: '1327', value: '超声影像/放射科医师', pinyin: 'chaoshengyingxiang/fangshekeyishi'
  },
  { code: '1328', value: '综合门诊/全科医生', pinyin: 'zonghemenzhen/quankeyisheng'
  },
  { code: '1329', value: '医美咨询', pinyin: 'yimeizixun'
  },
  { code: '1330', value: '健康管理师', pinyin: 'jiankangguanlishi'
  },
  { code: '1331', value: '核酸检测员', pinyin: 'hesuanjianceyuan'
  },
  { code: '1332', value: '防疫员', pinyin: 'fangyiyuan'
  },
  { code: '1333', value: '消毒员', pinyin: 'xiaoduyuan'
  },
  { code: '1335', value: '心理咨询师', pinyin: 'xinlizixunshi'
  },
  { code: '1336', value: '导医', pinyin: 'daoyi'
  },
  { code: '1337', value: '妇产科医生', pinyin: 'fuchankeyisheng'
  },
  { code: '1339', value: '眼科医生', pinyin: 'yankeyisheng'
  },
  { code: '1340', value: '医学顾问', pinyin: 'yixueguwen'
  },
  { code: '1400', value: '咨询/顾问', pinyin: 'zixun/guwen'
  },
  { code: '1401', value: '专业顾问', pinyin: 'zhuanyeguwen'
  },
  { code: '1402', value: '咨询总监', pinyin: 'zixunzongjian'
  },
  { code: '1403', value: '咨询经理', pinyin: 'zixunjingli'
  },
  { code: '1404', value: '咨询员', pinyin: 'zixunyuan'
  },
  { code: '1405', value: '其他', pinyin: 'qita'
  },
  { code: '1406', value: '专业培训师', pinyin: 'zhuanyepeixunshi'
  },
  { code: '1407', value: '情报信息分析人员', pinyin: 'qingbaoxinxifenxirenyuan'
  },
  { code: '1408', value: '猎头/人才中介', pinyin: 'lietou/rencaizhongjie'
  },
  { code: '1409', value: '调研员', pinyin: 'diaoyanyuan'
  },
  { code: '1410', value: '管理咨询师', pinyin: 'guanlizixunshi'
  },
  { code: '1411', value: '行业研究员', pinyin: 'xingyeyanjiuyuan'
  },
  { code: '1500', value: '政府/非盈利机构', pinyin: 'zhengfu/feiyinglijigou'
  },
  { code: '1501', value: '公务员', pinyin: 'gongwuyuan'
  },
  { code: '1502', value: '志愿者/社会工作者', pinyin: 'zhiyuanzhe/shehuigongzuozhe'
  },
  { code: '1503', value: '城市管理网格员', pinyin: 'chengshiguanliwanggeyuan'
  },
  { code: '1600', value: '在校学生', pinyin: 'zaixiaoxuesheng'
  },
  { code: '1601', value: '中专/职校生', pinyin: 'zhongzhuan/zhixiaosheng'
  },
  { code: '1602', value: '大学/大专应届毕业生', pinyin: 'daxue/dazhuanyingjiebiyesheng'
  },
  { code: '1604', value: '其他', pinyin: 'qita'
  },
  { code: '1605', value: '研究生', pinyin: 'yanjiusheng'
  },
  { code: '1700', value: '储备干部/培训生/实习生', pinyin: 'chubeiganbu/peixunsheng/shixisheng'
  },
  { code: '1701', value: '培训生', pinyin: 'peixunsheng'
  },
  { code: '1702', value: '储备干部', pinyin: 'chubeiganbu'
  },
  { code: '1703', value: '实习生', pinyin: 'shixisheng'
  },
  { code: '1800', value: '交通运输服务', pinyin: 'jiaotongyunshufuwu'
  },
  { code: '1801', value: '乘务员', pinyin: 'chengwuyuan'
  },
  { code: '1810', value: '商务司机', pinyin: 'shangwusiji'
  },
  { code: '1822', value: '飞机机长/副机长', pinyin: 'feijijichang/fujichang'
  },
  { code: '1823', value: '空乘人员', pinyin: 'kongchengrenyuan'
  },
  { code: '1824', value: '地勤人员', pinyin: 'diqinrenyuan'
  },
  { code: '1825', value: '列车/地铁车长', pinyin: 'lieche/ditiechechang'
  },
  { code: '1826', value: '列车/地铁司机', pinyin: 'lieche/ditiesiji'
  },
  { code: '1827', value: '船长/副船长', pinyin: 'chuanchang/fuchuanchang'
  },
  { code: '1828', value: '船员', pinyin: 'chuanyuan'
  },
  { code: '1829', value: '其他', pinyin: 'qita'
  },
  { code: '1830', value: '客运司机', pinyin: 'keyunsiji'
  },
  { code: '1831', value: '货运司机', pinyin: 'huoyunsiji'
  },
  { code: '1832', value: '出租车司机', pinyin: 'chuzuchesiji'
  },
  { code: '1833', value: '班车司机', pinyin: 'banchesiji'
  },
  { code: '1835', value: '特种车司机', pinyin: 'tezhongchesiji'
  },
  { code: '1839', value: '驾校教练', pinyin: 'jiaxiaojiaolian'
  },
  { code: '1840', value: '代驾', pinyin: 'daijia'
  },
  { code: '1842', value: '站务人员', pinyin: 'zhanwurenyuan'
  },
  { code: '1900', value: '其他', pinyin: 'qita'
  },
  { code: '1901', value: '其他', pinyin: 'qita'
  },
  { code: '2000', value: '翻译', pinyin: 'fanyi'
  },
  { code: '2001', value: '英语翻译', pinyin: 'yingyufanyi'
  },
  { code: '2002', value: '日语翻译', pinyin: 'riyufanyi'
  },
  { code: '2003', value: '德语翻译', pinyin: 'deyufanyi'
  },
  { code: '2004', value: '法语翻译', pinyin: 'fayufanyi'
  },
  { code: '2005', value: '俄语翻译', pinyin: 'eyufanyi'
  },
  { code: '2006', value: '西班牙语翻译', pinyin: 'xibanyayufanyi'
  },
  { code: '2007', value: '韩语/朝鲜语翻译', pinyin: 'hanyu/chaoxianyufanyi'
  },
  { code: '2008', value: '其他语种翻译', pinyin: 'qitayuzhongfanyi'
  },
  { code: '2009', value: '阿拉伯语翻译', pinyin: 'alaboyufanyi'
  },
  { code: '2010', value: '意大利语翻译', pinyin: 'yidaliyufanyi'
  },
  { code: '2011', value: '葡萄牙语翻译', pinyin: 'putaoyayufanyi'
  },
  { code: '2012', value: '泰语翻译', pinyin: 'taiyufanyi'
  },
  { code: '2013', value: '中国方言翻译', pinyin: 'zhongguofangyanfanyi'
  },
  { code: '2100', value: '建筑工程与装潢', pinyin: 'jianzhugongchengyuzhuangzuo'
  },
  { code: '2101', value: '建筑工程师', pinyin: 'jianzhugongchengshi'
  },
  { code: '2102', value: '结构/土木/土建工程师', pinyin: 'jiegou/tumu/tujiangongchengshi'
  },
  { code: '2103', value: '建筑机电工程师', pinyin: 'jianzhujidiangongchengshi'
  },
  { code: '2104', value: '给排水/暖通工程', pinyin: 'geipaishui/nuantonggongcheng'
  },
  { code: '2105', value: '工程造价师/预结算经理', pinyin: 'gongchengzaojiashi/yujiesuanjingli'
  },
  { code: '2106', value: '建筑工程管理/项目经理', pinyin: 'jianzhugongchengguanli/xiangmujingli'
  },
  { code: '2107', value: '工程监理', pinyin: 'gongchengjianli'
  },
  { code: '2111', value: '建筑安装施工员', pinyin: 'jianzhuanzhuangshigongyuan'
  },
  { code: '2112', value: '消防安全', pinyin: 'xiaofanganquan'
  },
  { code: '2116', value: '其他', pinyin: 'qita'
  },
  { code: '2118', value: '公路/桥梁/港口/隧道工程', pinyin: 'gonglu/qiaoliang/gangkou/suidaogongcheng'
  },
  { code: '2119', value: '岩土工程', pinyin: 'yantugongcheng'
  },
  { code: '2120', value: '测绘/测量', pinyin: 'cehui/celiang'
  },
  { code: '2121', value: '建筑工程验收', pinyin: 'jianzhugongchengyanshou'
  },
  { code: '2122', value: '幕墙工程师', pinyin: 'muqianggongchengshi'
  },
  { code: '2123', value: '高级建筑工程师/总工', pinyin: 'gaojijianzhugongchengshi/zonggong'
  },
  { code: '2124', value: '预结算员', pinyin: 'yujiesuanyuan'
  },
  { code: '2125', value: '楼宇自动化', pinyin: 'louyuzidonghua'
  },
  { code: '2126', value: '智能大厦/综合布线/安防/弱电', pinyin: 'zhinengdaxia/zonghebuxian/anfang/ruodian'
  },
  { code: '2127', value: '开发报建', pinyin: 'kaifabaojian'
  },
  { code: '2128', value: '合同管理', pinyin: 'hetongguanli'
  },
  { code: '2129', value: '安全员', pinyin: 'anquanyuan'
  },
  { code: '2130', value: '资料员', pinyin: 'ziliaoyuan'
  },
  { code: '2132', value: '市政工程师', pinyin: 'shizhenggongchengshi'
  },
  { code: '2133', value: '建筑项目助理', pinyin: 'jianzhuxiangmuzhuli'
  },
  { code: '2134', value: '砌筑工', pinyin: 'qizhugong'
  },
  { code: '2135', value: '瓦工', pinyin: 'wagong'
  },
  { code: '2136', value: '混凝土工', pinyin: 'hunningtugong'
  },
  { code: '2137', value: '浇注工', pinyin: 'jiaozhugong'
  },
  { code: '2138', value: '钢筋工', pinyin: 'gangjingong'
  },
  { code: '2139', value: '木工', pinyin: 'mugong'
  },
  { code: '2140', value: '油漆工', pinyin: 'youqigong'
  },
  { code: '2141', value: '电梯工', pinyin: 'diantigong'
  },
  { code: '2142', value: '抹灰工', pinyin: 'mohuigong'
  },
  { code: '2143', value: '施工开料工', pinyin: 'shigongkailiaogong'
  },
  { code: '2144', value: '管道/暖通', pinyin: 'guandao/nuantong'
  },
  { code: '2145', value: '工长', pinyin: 'gongchang'
  },
  { code: '2146', value: '精装修工程师', pinyin: 'jingzhuangxiugongchengshi'
  },
  { code: '2147', value: '房修工程师', pinyin: 'fangxiugongchengshi'
  },
  { code: '2149', value: '材料员', pinyin: 'cailiaoyuan'
  },
  { code: '2200', value: '银行', pinyin: 'yinxing'
  },
  { code: '2207', value: '行长/副行长', pinyin: 'xingchang/fuxingchang'
  },
  { code: '2208', value: '资产评估/分析', pinyin: 'zichanpinggu/fenxi'
  },
  { code: '2209', value: '风险控制', pinyin: 'fengxiankongzhi'
  },
  { code: '2210', value: '进出口/信用证结算', pinyin: 'jinchukou/xinyongzhengjiesuan'
  },
  { code: '2211', value: '清算人员', pinyin: 'qingsuanrenyuan'
  },
  { code: '2212', value: '外汇交易', pinyin: 'waihuijiaoyi'
  },
  { code: '2213', value: '高级客户经理/客户经理', pinyin: 'gaojikehujingli/kehujingli'
  },
  { code: '2214', value: '客户主管/专员', pinyin: 'kehuzhuguan/zhuanyuan'
  },
  { code: '2215', value: '信贷管理', pinyin: 'xindaiguanli'
  },
  { code: '2216', value: '银行柜员', pinyin: 'yinxingguiyuan'
  },
  { code: '2221', value: '其他', pinyin: 'qita'
  },
  { code: '2222', value: '信用卡销售', pinyin: 'xinyongkaxiaoshou'
  },
  { code: '2223', value: '个人业务部门经理/主管', pinyin: 'gerenyewubumenjingli/zhuguan'
  },
  { code: '2224', value: '个人业务客户经理', pinyin: 'gerenyewukehujingli'
  },
  { code: '2225', value: '公司业务部门经理/主管', pinyin: 'gongsiyewubumenjingli/zhuguan'
  },
  { code: '2226', value: '公司业务客户经理', pinyin: 'gongsiyewukehujingli'
  },
  { code: '2227', value: '综合业务经理/主管', pinyin: 'zongheyewujingli/zhuguan'
  },
  { code: '2228', value: '综合业务专员', pinyin: 'zongheyewuzhuanyuan'
  },
  { code: '2229', value: '信审核查', pinyin: 'xinshenhecha'
  },
  { code: '2230', value: '营业部大堂经理', pinyin: 'yingyebudatangjingli'
  },
  { code: '2231', value: '银行客户总监', pinyin: 'yinxingkehuzongjian'
  },
  { code: '2232', value: '呼叫中心客服', pinyin: 'hujiaozhongxinkefu'
  },
  { code: '2233', value: '理财经理', pinyin: 'licaijingli'
  },
  { code: '2234', value: '小微信贷专员', pinyin: 'xiaoweixindaizhuanyuan'
  },
  { code: '2300', value: '行政/后勤', pinyin: 'xingzheng/houqin'
  },
  { code: '2301', value: '行政总监', pinyin: 'xingzhengzongjian'
  },
  { code: '2302', value: '行政经理/主管/办公室主任', pinyin: 'xingzhengjingli/zhuguan/bangongshizhuren'
  },
  { code: '2303', value: '行政专员/助理', pinyin: 'xingzhengzhuanyuan/zhuli'
  },
  { code: '2304', value: '经理助理/秘书', pinyin: 'jinglizhuli/mishu'
  },
  { code: '2305', value: '前台接待/总机/接待生', pinyin: 'qiantaijiedai/zongji/jiedaisheng'
  },
  { code: '2306', value: '后勤', pinyin: 'houqin'
  },
  { code: '2307', value: '图书管理员/资料管理员', pinyin: 'tushuguanliyuan/ziliaoguanliyuan'
  },
  { code: '2308', value: '电脑操作员/打字员', pinyin: 'diannaocaozuoyuan/daziyuan'
  },
  { code: '2309', value: '其他', pinyin: 'qita'
  },
  { code: '2310', value: '党工团干事', pinyin: 'danggongtuanganshi'
  },
  { code: '2311', value: '文员', pinyin: 'wenyuan'
  },
  { code: '2312', value: '档案管理员', pinyin: 'danganguanliyuan'
  },
  { code: '2600', value: '技术管理', pinyin: 'jishuguanli'
  },
  { code: '2601', value: '首席技术执行官CTO/首席信息官CIO', pinyin: 'shouxijishuzhixingguanCTO/shouxixinxiguanCIO'
  },
  { code: '2602', value: '技术总监/经理', pinyin: 'jishuzongjian/jingli'
  },
  { code: '2605', value: '项目总监', pinyin: 'xiangmuzongjian'
  },
  { code: '2606', value: '项目经理', pinyin: 'xiangmujingli'
  },
  { code: '2607', value: '项目主管', pinyin: 'xiangmuzhuguan'
  },
  { code: '2608', value: '项目执行/协调人员', pinyin: 'xiangmuzhixing/xiediaorenyuan'
  },
  { code: '2609', value: '其他', pinyin: 'qita'
  },
  { code: '2610', value: '项目助理', pinyin: 'xiangmuzhuli'
  },
  { code: '2611', value: '首席技术执行官CTO', pinyin: 'shouxijishuzhixingguanCTO'
  },
  { code: '2612', value: '首席信息官CIO', pinyin: 'shouxixinxiguanCIO'
  },
  { code: '2700', value: '测试', pinyin: 'ceshi'
  },
  { code: '2704', value: '标准化工程师', pinyin: 'biaozhunhuagongchengshi'
  },
  { code: '2705', value: '测试经理', pinyin: 'ceshijingli'
  },
  { code: '2706', value: '系统测试', pinyin: 'xitongceshi'
  },
  { code: '2707', value: '软件测试工程师', pinyin: 'ruanjianceshigongchengshi'
  },
  { code: '2711', value: '其他', pinyin: 'qita'
  },
  { code: '2718', value: '功能测试', pinyin: 'gongnengceshi'
  },
  { code: '2719', value: '性能测试', pinyin: 'xingnengceshi'
  },
  { code: '2720', value: '自动化测试', pinyin: 'zidonghuaceshi'
  },
  { code: '2721', value: '移动端测试', pinyin: 'yidongduanceshi'
  },
  { code: '2722', value: '测试开发', pinyin: 'ceshikaifa'
  },
  { code: '2723', value: '测试主管', pinyin: 'ceshizhuguan'
  },
  { code: '2724', value: '安全测试', pinyin: 'anquanceshi'
  },
  { code: '2725', value: '测试工程师', pinyin: 'ceshigongchengshi'
  },
  { code: '2726', value: '测试总监', pinyin: 'ceshizongjian'
  },
  { code: '2800', value: '通信技术开发及应用', pinyin: 'tongxinjishukaifajiyingyong'
  },
  { code: '2801', value: '通信技术工程师', pinyin: 'tongxinjishugongchengshi'
  },
  { code: '2802', value: '有线传输工程师', pinyin: 'youxianchuanshugongchengshi'
  },
  { code: '2803', value: '无线通信工程师', pinyin: 'wuxiantongxingongchengshi'
  },
  { code: '2804', value: '电信交换工程师', pinyin: 'dianxinjiaohuangongchengshi'
  },
  { code: '2805', value: '数据通信工程师', pinyin: 'shujutongxingongchengshi'
  },
  { code: '2807', value: '通信网络工程师', pinyin: 'tongxinwangluogongchengshi'
  },
  { code: '2808', value: '通信电源工程师', pinyin: 'tongxindianyuangongchengshi'
  },
  { code: '2809', value: '其他', pinyin: 'qita'
  },
  { code: '2812', value: '通信文档工程师', pinyin: 'tongxinwendanggongchengshi'
  },
  { code: '2813', value: '通信产品经理/产品工程师', pinyin: 'tongxinchanpinjingli/chanpingongchengshi'
  },
  { code: '2814', value: '光通信工程师', pinyin: 'guangtongxingongchengshi'
  },
  { code: '2815', value: '射频工程师', pinyin: 'shepingongchengshi'
  },
  { code: '2816', value: '通信测试工程师', pinyin: 'tongxinceshigongchengshi'
  },
  { code: '2817', value: '通信销售工程师', pinyin: 'tongxinxiaoshougongchengshi'
  },
  { code: '2818', value: '基站工程师', pinyin: 'jizhangongchengshi'
  },
  { code: '2819', value: '核心网工程师', pinyin: 'hexinwanggongchengshi'
  },
  { code: '2820', value: '通信设备工程师', pinyin: 'tongxinshebeigongchengshi'
  },
  { code: '2821', value: '通信项目管理', pinyin: 'tongxinxiangmuguanli'
  },
  { code: '2900', value: '电子/电器/仪器仪表', pinyin: 'dianzi/dianqi/yiqiyibiao'
  },
  { code: '2903', value: '电子工程师/技术员', pinyin: 'dianzigongchengshi/jishuyuan'
  },
  { code: '2904', value: '电气工程师/技术员', pinyin: 'dianqigongchengshi/jishuyuan'
  },
  { code: '2905', value: '电路工程师/技术员(模拟/数字)', pinyin: 'dianlugongchengshi/jishuyuan(moni/shuzi)'
  },
  { code: '2906', value: '电声/音响工程师/技术员', pinyin: 'diansheng/yinxianggongchengshi/jishuyuan'
  },
  { code: '2908', value: '自动控制工程师/技术员', pinyin: 'zidongkongzhigongchengshi/jishuyuan'
  },
  { code: '2909', value: '电子软件开发(ARM/MCU...)', pinyin: 'dianziruanjiankaifa(ARM/MCU...)'
  },
  { code: '2910', value: '嵌入式软件开发(Linux/单片机/PLC/DSP…)', pinyin: 'qianrushiruanjiankaifa(Linux/danpianji/PLC/DSP…)'
  },
  { code: '2911', value: '电池/电源开发', pinyin: 'dianchi/dianyuankaifa'
  },
  { code: '2913', value: '家用电器/数码产品研发', pinyin: 'jiayongdianqi/shumachanpinyanfa'
  },
  { code: '2914', value: '仪器/仪表/计量分析师', pinyin: 'yiqi/yibiao/jiliangfenxishi'
  },
  { code: '2916', value: '其他', pinyin: 'qita'
  },
  { code: '2917', value: '电子技术研发工程师', pinyin: 'dianzijishuyanfagongchengshi'
  },
  { code: '2918', value: '激光/光电子技术', pinyin: 'jiguang/guangdianzijishu'
  },
  { code: '2919', value: '嵌入式硬件开发(主板机…)', pinyin: 'qianrushiyingjiankaifa(zhubanji…)'
  },
  { code: '2920', value: '电子/电器维修工程师/技师', pinyin: 'dianzi/dianqiweixiugongchengshi/jishi'
  },
  { code: '2921', value: '变压器与磁电工程师', pinyin: 'bianyaqiyucidiangongchengshi'
  },
  { code: '2925', value: '安防系统工程师', pinyin: 'anfangxitonggongchengshi'
  },
  { code: '2951', value: '电子工艺工程师', pinyin: 'dianzigongyigongchengshi'
  },
  { code: '2952', value: '电子销售工程师', pinyin: 'dianzixiaoshougongchengshi'
  },
  { code: '2953', value: '电子文档工程师', pinyin: 'dianziwendanggongchengshi'
  },
  { code: '2954', value: '电子产品经理/产品工程师', pinyin: 'dianzichanpinjingli/chanpingongchengshi'
  },
  { code: '2955', value: '硬件工程师', pinyin: 'yingjiangongchengshi'
  },
  { code: '2956', value: '高级硬件工程师', pinyin: 'gaojiyingjiangongchengshi'
  },
  { code: '2957', value: '硬件测试工程师', pinyin: 'yingjianceshigongchengshi'
  },
  { code: '2958', value: '计量工程师', pinyin: 'jilianggongchengshi'
  },
  { code: '2959', value: '电子设备工程师', pinyin: 'dianzishebeigongchengshi'
  },
  { code: '2962', value: '电子元器件工程师', pinyin: 'dianziyuanqijiangongchengshi'
  },
  { code: '2963', value: '机器人调试工程师', pinyin: 'jiqirendiaoshigongchengshi'
  },
  { code: '2964', value: 'PCB工程师', pinyin: 'PCBgongchengshi'
  },
  { code: '2965', value: 'SMT工程师', pinyin: 'SMTgongchengshi'
  },
  { code: '2966', value: 'PLC工程师', pinyin: 'PLCgongchengshi'
  },
  { code: '2969', value: '服务机器人应用技术员', pinyin: 'fuwujiqirenyingyongjishuyuan'
  },
  { code: '2970', value: '智能硬件装调员', pinyin: 'zhinengyingjianzhuangdiaoyuan'
  },
  { code: '2971', value: '工业视觉系统运维员', pinyin: 'gongyeshijuexitongyunweiyuan'
  },
  { code: '3000', value: '销售人员', pinyin: 'xiaoshourenyuan'
  },
  { code: '3001', value: '销售代表', pinyin: 'xiaoshoudaibiao'
  },
  { code: '3002', value: '渠道/分销专员', pinyin: 'qudao/fenxiaozhuanyuan'
  },
  { code: '3003', value: '客户代表', pinyin: 'kehudaibiao'
  },
  { code: '3004', value: '销售工程师', pinyin: 'xiaoshougongchengshi'
  },
  { code: '3005', value: '电话销售', pinyin: 'dianhuaxiaoshou'
  },
  { code: '3006', value: '经销商', pinyin: 'jingxiaoshang'
  },
  { code: '3007', value: '其他', pinyin: 'qita'
  },
  { code: '3008', value: '团购业务员', pinyin: 'tuangouyewuyuan'
  },
  { code: '3009', value: '大客户销售', pinyin: 'dakehuxiaoshou'
  },
  { code: '3010', value: '网络销售/在线销售', pinyin: 'wangluoxiaoshou/zaixianxiaoshou'
  },
  { code: '3011', value: '会籍顾问', pinyin: 'huijiguwen'
  },
  { code: '3012', value: '销售助理', pinyin: 'xiaoshouzhuli'
  },
  { code: '3013', value: '直播销售', pinyin: 'zhiboxiaoshou'
  },
  { code: '3014', value: '区域销售代表', pinyin: 'quyuxiaoshoudaibiao'
  },
  { code: '3015', value: '海外销售', pinyin: 'haiwaixiaoshou'
  },
  { code: '3016', value: '门店销售', pinyin: 'mendianxiaoshou'
  },
  { code: '3017', value: '地推专员', pinyin: 'dituizhuanyuan'
  },
  { code: '3100', value: '销售行政及商务', pinyin: 'xiaoshouxingzhengjishangwu'
  },
  { code: '3101', value: '销售行政经理/主管', pinyin: 'xiaoshouxingzhengjingli/zhuguan'
  },
  { code: '3102', value: '销售行政专员', pinyin: 'xiaoshouxingzhengzhuanyuan'
  },
  { code: '3103', value: '商务经理', pinyin: 'shangwujingli'
  },
  { code: '3104', value: '商务主管/专员', pinyin: 'shangwuzhuguan/zhuanyuan'
  },
  { code: '3105', value: '商务助理', pinyin: 'shangwuzhuli'
  },
  { code: '3106', value: '销售行政助理', pinyin: 'xiaoshouxingzhengzhuli'
  },
  { code: '3107', value: '其他', pinyin: 'qita'
  },
  { code: '3108', value: '业务分析经理/主管', pinyin: 'yewufenxijingli/zhuguan'
  },
  { code: '3109', value: '业务分析专员/助理', pinyin: 'yewufenxizhuanyuan/zhuli'
  },
  { code: '3200', value: '客服及支持', pinyin: 'kefujizhichi'
  },
  { code: '3201', value: '客服总监', pinyin: 'kefuzongjian'
  },
  { code: '3202', value: '客服经理', pinyin: 'kefujingli'
  },
  { code: '3203', value: '客服主管', pinyin: 'kefuzhuguan'
  },
  { code: '3204', value: '客服专员/助理', pinyin: 'kefuzhuanyuan/zhuli'
  },
  { code: '3205', value: '售前/售后技术支持经理', pinyin: 'shouqian/shouhoujishuzhichijingli'
  },
  { code: '3206', value: '售前/售后技术支持主管', pinyin: 'shouqian/shouhoujishuzhichizhuguan'
  },
  { code: '3207', value: '售前/售后技术支持工程师', pinyin: 'shouqian/shouhoujishuzhichigongchengshi'
  },
  { code: '3208', value: '咨询热线/呼叫中心服务人员', pinyin: 'zixunrexian/hujiaozhongxinfuwurenyuan'
  },
  { code: '3209', value: '其他', pinyin: 'qita'
  },
  { code: '3210', value: '客户关系经理/主管', pinyin: 'kehuguanxijingli/zhuguan'
  },
  { code: '3211', value: '投诉专员', pinyin: 'tousuzhuanyuan'
  },
  { code: '3212', value: 'VIP专员', pinyin: 'VIPzhuanyuan'
  },
  { code: '3213', value: '网络/在线客服', pinyin: 'wangluo/zaixiankefu'
  },
  { code: '3300', value: '金融/证券/期货/投资', pinyin: 'jinrong/zhengquan/qihuo/touzi'
  },
  { code: '3301', value: '证券/期货/外汇经纪人', pinyin: 'zhengquan/qihuo/waihuijingjiren'
  },
  { code: '3302', value: '证券分析师', pinyin: 'zhengquanfenxishi'
  },
  { code: '3303', value: '股票/期货操盘手', pinyin: 'gupiao/qihuocaopanshou'
  },
  { code: '3304', value: '金融/经济研究员', pinyin: 'jinrong/jingjiyanjiuyuan'
  },
  { code: '3305', value: '投资/基金项目经理', pinyin: 'touzi/jijinxiangmujingli'
  },
  { code: '3306', value: '投资/理财顾问', pinyin: 'touzi/licaiguwen'
  },
  { code: '3307', value: '投资银行业务', pinyin: 'touziyinxingyewu'
  },
  { code: '3308', value: '融资经理/融资主管', pinyin: 'rongzijingli/rongzizhuguan'
  },
  { code: '3309', value: '融资专员', pinyin: 'rongzizhuanyuan'
  },
  { code: '3310', value: '拍卖/担保/典当业务', pinyin: 'paimai/danbao/diandangyewu'
  },
  { code: '3311', value: '其他', pinyin: 'qita'
  },
  { code: '3312', value: '金融产品经理', pinyin: 'jinrongchanpinjingli'
  },
  { code: '3313', value: '投资银行财务分析', pinyin: 'touziyinxingcaiwufenxi'
  },
  { code: '3314', value: '风险管理/控制', pinyin: 'fengxianguanli/kongzhi'
  },
  { code: '3315', value: '金融产品销售', pinyin: 'jinrongchanpinxiaoshou'
  },
  { code: '3316', value: '量化研究', pinyin: 'lianghuayanjiu'
  },
  { code: '3317', value: '机构业务销售', pinyin: 'jigouyewuxiaoshou'
  },
  { code: '3318', value: '营业部总经理/副总经理', pinyin: 'yingyebuzongjingli/fuzongjingli'
  },
  { code: '3319', value: '期货分析师', pinyin: 'qihuofenxishi'
  },
  { code: '3320', value: '证券交易员', pinyin: 'zhengquanjiaoyiyuan'
  },
  { code: '3321', value: '公司金融顾问', pinyin: 'gongsijinrongguwen'
  },
  { code: '3322', value: '投资总监', pinyin: 'touzizongjian'
  },
  { code: '3323', value: '投资经理', pinyin: 'touzijingli'
  },
  { code: '3324', value: '资产管理', pinyin: 'zichanguanli'
  },
  { code: '3325', value: '投资顾问', pinyin: 'touziguwen'
  },
  { code: '3326', value: '理财顾问', pinyin: 'licaiguwen'
  },
  { code: '3340', value: '催收', pinyin: 'cuishou'
  },
  { code: '3341', value: '基金经理', pinyin: 'jijinjingli'
  },
  { code: '3400', value: '保险', pinyin: 'baoxian'
  },
  { code: '3401', value: '保险精算师', pinyin: 'baoxianjingsuanshi'
  },
  { code: '3402', value: '保险产品开发/项目策划', pinyin: 'baoxianchanpinkaifa/xiangmucehua'
  },
  { code: '3403', value: '保险业务经理/主管', pinyin: 'baoxianyewujingli/zhuguan'
  },
  { code: '3404', value: '保险经纪人/保险代理', pinyin: 'baoxianjingjiren/baoxiandaili'
  },
  { code: '3405', value: '理财顾问/财务规划师', pinyin: 'licaiguwen/caiwuguihuashi'
  },
  { code: '3406', value: '储备经理人', pinyin: 'chubeijingliren'
  },
  { code: '3407', value: '保险核保', pinyin: 'baoxianhebao'
  },
  { code: '3408', value: '保险理赔', pinyin: 'baoxianlipei'
  },
  { code: '3409', value: '保险客户服务/续期管理', pinyin: 'baoxiankehufuwu/xuqiguanli'
  },
  { code: '3410', value: '保险培训师', pinyin: 'baoxianpeixunshi'
  },
  { code: '3411', value: '保险内勤', pinyin: 'baoxianneiqin'
  },
  { code: '3412', value: '其他', pinyin: 'qita'
  },
  { code: '3413', value: '契约管理', pinyin: 'qiyueguanli'
  },
  { code: '3414', value: '保险电销', pinyin: 'baoxiandianxiao'
  },
  { code: '3415', value: '保险业务推动/督导', pinyin: 'baoxianyewutuidong/dudao'
  },
  { code: '3500', value: '生产/营运', pinyin: 'shengchan/yingyun'
  },
  { code: '3501', value: '工厂经理/厂长', pinyin: 'gongchangjingli/changchang'
  },
  { code: '3502', value: '总工程师/副总工程师', pinyin: 'zonggongchengshi/fuzonggongchengshi'
  },
  { code: '3503', value: '项目经理/主管', pinyin: 'xiangmujingli/zhuguan'
  },
  { code: '3504', value: '项目工程师', pinyin: 'xiangmugongchengshi'
  },
  { code: '3505', value: '营运经理', pinyin: 'yingyunjingli'
  },
  { code: '3506', value: '营运主管', pinyin: 'yingyunzhuguan'
  },
  { code: '3507', value: '生产经理/车间主任', pinyin: 'shengchanjingli/chejianzhuren'
  },
  { code: '3508', value: '生产计划/物料管理(PMC)', pinyin: 'shengchanjihua/wuliaoguanli(PMC)'
  },
  { code: '3509', value: '生产主管', pinyin: 'shengchanzhuguan'
  },
  { code: '3510', value: '化验员', pinyin: 'huayanyuan'
  },
  { code: '3511', value: '其他', pinyin: 'qita'
  },
  { code: '3512', value: '生产文员', pinyin: 'shengchanwenyuan'
  },
  { code: '3513', value: '项目总监', pinyin: 'xiangmuzongjian'
  },
  { code: '3514', value: '生产总监', pinyin: 'shengchanzongjian'
  },
  { code: '3515', value: '生产领班/组长', pinyin: 'shengchanlingban/zuchang'
  },
  { code: '3516', value: '设备主管', pinyin: 'shebeizhuguan'
  },
  { code: '3517', value: '厂务', pinyin: 'changwu'
  },
  { code: '3518', value: '生产跟单', pinyin: 'shengchangendan'
  },
  { code: '3600', value: '质量安全', pinyin: 'zhilianganquan'
  },
  { code: '3601', value: '质量管理/测试经理(QA/QC经理)', pinyin: 'zhiliangguanli/ceshijingli(QA/QCjingli)'
  },
  { code: '3602', value: '质量管理/测试主管(QA/QC主管)', pinyin: 'zhiliangguanli/ceshizhuguan(QA/QCzhuguan)'
  },
  { code: '3603', value: '质量管理/测试工程师(QA/QC工程师)', pinyin: 'zhiliangguanli/ceshigongchengshi(QA/QCgongchengshi)'
  },
  { code: '3604', value: '质检员/测试员(QC)', pinyin: 'zhijianyuan/ceshiyuan(QC)'
  },
  { code: '3605', value: '可靠度工程师', pinyin: 'kekaodugongchengshi'
  },
  { code: '3606', value: '故障分析工程师', pinyin: 'guzhangfenxigongchengshi'
  },
  { code: '3607', value: '认证工程师', pinyin: 'renzhenggongchengshi'
  },
  { code: '3608', value: '体系工程师', pinyin: 'tixigongchengshi'
  },
  { code: '3609', value: '环境/健康/安全经理/主管（EHS）', pinyin: 'huanjing/jiankang/anquanjingli/zhuguan（EHS）'
  },
  { code: '3610', value: '环境/健康/安全工程师（EHS）', pinyin: 'huanjing/jiankang/anquangongchengshi（EHS）'
  },
  { code: '3611', value: '供应商管理', pinyin: 'gongyingshangguanli'
  },
  { code: '3612', value: '采购材料、设备质量管理', pinyin: 'caigoucailiao、shebeizhiliangguanli'
  },
  { code: '3613', value: '其他', pinyin: 'qita'
  },
  { code: '3614', value: '安全员', pinyin: 'anquanyuan'
  },
  { code: '3615', value: '审核员', pinyin: 'shenheyuan'
  },
  { code: '3700', value: '技工普工', pinyin: 'jigongpugong'
  },
  { code: '3701', value: '技工', pinyin: 'jigong'
  },
  { code: '3703', value: '焊工', pinyin: 'hangong'
  },
  { code: '3706', value: '电工', pinyin: 'diangong'
  },
  { code: '3707', value: '叉车司机/铲车司机', pinyin: 'chachesiji/chanchesiji'
  },
  { code: '3708', value: '空调工', pinyin: 'kongdiaogong'
  },
  { code: '3709', value: '水工', pinyin: 'shuigong'
  },
  { code: '3710', value: '普工/操作工', pinyin: 'pugong/caozuogong'
  },
  { code: '3713', value: '其他', pinyin: 'qita'
  },
  { code: '3715', value: '组装工', pinyin: 'zuzhuanggong'
  },
  { code: '3716', value: '包装工', pinyin: 'baozhuanggong'
  },
  { code: '3717', value: '氩弧焊工', pinyin: 'zuohuhangong'
  },
  { code: '3718', value: '电力线路工', pinyin: 'dianlixianlugong'
  },
  { code: '3719', value: '旋压工', pinyin: 'xuanyagong'
  },
  { code: '3720', value: '仪表工', pinyin: 'yibiaogong'
  },
  { code: '3721', value: '电镀工', pinyin: 'diandugong'
  },
  { code: '3722', value: '喷塑工', pinyin: 'pensugong'
  },
  { code: '3723', value: '木工', pinyin: 'mugong'
  },
  { code: '3724', value: '漆工', pinyin: 'qigong'
  },
  { code: '3725', value: '电梯工', pinyin: 'diantigong'
  },
  { code: '3726', value: '锅炉工', pinyin: 'guolugong'
  },
  { code: '3727', value: '学徒工', pinyin: 'xuetugong'
  },
  { code: '3728', value: '吊车司机', pinyin: 'diaochesiji'
  },
  { code: '3729', value: '挖掘机司机', pinyin: 'wajuejisiji'
  },
  { code: '3730', value: '3D打印操作员', pinyin: '3Ddayincaozuoyuan'
  },
  { code: '3800', value: '服装/纺织/皮革', pinyin: 'fuzhuang/fangzhi/pige'
  },
  { code: '3801', value: '服装/纺织设计', pinyin: 'fuzhuang/fangzhisheji'
  },
  { code: '3802', value: '面料辅料开发', pinyin: 'mianliaofuliaokaifa'
  },
  { code: '3803', value: '面料辅料采购', pinyin: 'mianliaofuliaocaigou'
  },
  { code: '3804', value: '服装/纺织/皮革跟单', pinyin: 'fuzhuang/fangzhi/pigegendan'
  },
  { code: '3805', value: '服装纺织质检员(QA/QC)', pinyin: 'fuzhuangfangzhizhijianyuan(QA/QC)'
  },
  { code: '3806', value: '板房/楦头/底格出格师', pinyin: 'banfang/zuotou/digechugeshi'
  },
  { code: '3807', value: '打样/制版', pinyin: 'dayang/zhiban'
  },
  { code: '3808', value: '纸样师/车板工', pinyin: 'zhiyangshi/chebangong'
  },
  { code: '3809', value: '裁床', pinyin: 'caichuang'
  },
  { code: '3810', value: '其他', pinyin: 'qita'
  },
  { code: '3811', value: '电脑放码员', pinyin: 'diannaofangmayuan'
  },
  { code: '3812', value: '服装/纺织设计总监', pinyin: 'fuzhuang/fangzhishejizongjian'
  },
  { code: '3813', value: '服装/纺织/皮革工艺师', pinyin: 'fuzhuang/fangzhi/pigegongyishi'
  },
  { code: '3814', value: '服装领班', pinyin: 'fuzhuanglingban'
  },
  { code: '3815', value: '裁剪工', pinyin: 'caijiangong'
  },
  { code: '3816', value: '缝纫工', pinyin: 'fengrengong'
  },
  { code: '3817', value: '手缝工', pinyin: 'shoufenggong'
  },
  { code: '3818', value: '烫工', pinyin: 'tanggong'
  },
  { code: '3819', value: '样衣工', pinyin: 'yangyigong'
  },
  { code: '3820', value: '纺织工', pinyin: 'fangzhigong'
  },
  { code: '3821', value: '针织工', pinyin: 'zhenzhigong'
  },
  { code: '3822', value: '配色工', pinyin: 'peisegong'
  },
  { code: '3823', value: '印染工', pinyin: 'yinrangong'
  },
  { code: '3824', value: '漂染工', pinyin: 'piaorangong'
  },
  { code: '3825', value: '挡车工', pinyin: 'dangchegong'
  },
  { code: '3826', value: '整经工', pinyin: 'zhengjinggong'
  },
  { code: '3827', value: '细纱工', pinyin: 'xishagong'
  },
  { code: '3828', value: '浆纱工', pinyin: 'jiangshagong'
  },
  { code: '3900', value: '采购', pinyin: 'caigou'
  },
  { code: '3901', value: '采购总监', pinyin: 'caigouzongjian'
  },
  { code: '3902', value: '采购经理', pinyin: 'caigoujingli'
  },
  { code: '3903', value: '采购主管', pinyin: 'caigouzhuguan'
  },
  { code: '3904', value: '采购员', pinyin: 'caigouyuan'
  },
  { code: '3905', value: '采购助理', pinyin: 'caigouzhuli'
  },
  { code: '3907', value: '其他', pinyin: 'qita'
  },
  { code: '3908', value: '买手', pinyin: 'maishou'
  },
  { code: '3909', value: '供应商开发', pinyin: 'gongyingshangkaifa'
  },
  { code: '4000', value: '贸易', pinyin: 'maoyi'
  },
  { code: '4001', value: '贸易/外贸经理/主管', pinyin: 'maoyi/waimaojingli/zhuguan'
  },
  { code: '4002', value: '贸易/外贸专员/助理', pinyin: 'maoyi/waimaozhuanyuan/zhuli'
  },
  { code: '4003', value: '国内贸易人员', pinyin: 'guoneimaoyirenyuan'
  },
  { code: '4004', value: '业务跟单经理', pinyin: 'yewugendanjingli'
  },
  { code: '4005', value: '高级业务跟单', pinyin: 'gaojiyewugendan'
  },
  { code: '4006', value: '业务跟单', pinyin: 'yewugendan'
  },
  { code: '4007', value: '助理业务跟单', pinyin: 'zhuliyewugendan'
  },
  { code: '4008', value: '其他', pinyin: 'qita'
  },
  { code: '4009', value: '外贸销售', pinyin: 'waimaoxiaoshou'
  },
  { code: '4100', value: '生物/制药/医疗器械', pinyin: 'shengwu/zhiyao/yiliaoqixie'
  },
  { code: '4101', value: '生物工程/生物制药', pinyin: 'shengwugongcheng/shengwuzhiyao'
  },
  { code: '4102', value: '医药销售人员', pinyin: 'yiyaoxiaoshourenyuan'
  },
  { code: '4103', value: '医药技术研发管理人员', pinyin: 'yiyaojishuyanfaguanlirenyuan'
  },
  { code: '4104', value: '医药技术研发人员', pinyin: 'yiyaojishuyanfarenyuan'
  },
  { code: '4105', value: '临床研究员', pinyin: 'linchuangyanjiuyuan'
  },
  { code: '4106', value: '临床协调员', pinyin: 'linchuangxiediaoyuan'
  },
  { code: '4107', value: '药品注册', pinyin: 'yaopinzhuce'
  },
  { code: '4108', value: '药品生产/质量管理', pinyin: 'yaopinshengchan/zhiliangguanli'
  },
  { code: '4109', value: '药品市场推广经理', pinyin: 'yaopinshichangtuiguangjingli'
  },
  { code: '4110', value: '药品市场推广主管/专员', pinyin: 'yaopinshichangtuiguangzhuguan/zhuanyuan'
  },
  { code: '4111', value: '医药销售经理/主管', pinyin: 'yiyaoxiaoshoujingli/zhuguan'
  },
  { code: '4112', value: '医药代表', pinyin: 'yiyaodaibiao'
  },
  { code: '4113', value: '医疗器械市场推广', pinyin: 'yiliaoqixieshichangtuiguang'
  },
  { code: '4114', value: '医疗器械销售代表', pinyin: 'yiliaoqixiexiaoshoudaibiao'
  },
  { code: '4115', value: '其他', pinyin: 'qita'
  },
  { code: '4116', value: '化学分析测试员', pinyin: 'huaxuefenxiceshiyuan'
  },
  { code: '4117', value: '医疗器械注册', pinyin: 'yiliaoqixiezhuce'
  },
  { code: '4118', value: '医疗器械生产/质量管理', pinyin: 'yiliaoqixieshengchan/zhiliangguanli'
  },
  { code: '4119', value: '医疗器械维修人员', pinyin: 'yiliaoqixieweixiurenyuan'
  },
  { code: '4120', value: '医药招商', pinyin: 'yiyaozhaoshang'
  },
  { code: '4121', value: '政府事务管理', pinyin: 'zhengfushiwuguanli'
  },
  { code: '4122', value: '招投标管理', pinyin: 'zhaotoubiaoguanli'
  },
  { code: '4123', value: '临床数据分析员', pinyin: 'linchuangshujufenxiyuan'
  },
  { code: '4124', value: '医疗器械研发', pinyin: 'yiliaoqixieyanfa'
  },
  { code: '4125', value: '医疗器械销售经理/主管', pinyin: 'yiliaoqixiexiaoshoujingli/zhuguan'
  },
  { code: '4126', value: '医药学术推广', pinyin: 'yiyaoxueshutuiguang'
  },
  { code: '4127', value: '临床监查员', pinyin: 'linchuangjianchayuan'
  },
  { code: '4128', value: '试剂研发经理', pinyin: 'shijiyanfajingli'
  },
  { code: '4129', value: '试剂研发工程师', pinyin: 'shijiyanfagongchengshi'
  },
  { code: '4130', value: '制剂研究员', pinyin: 'zhijiyanjiuyuan'
  },
  { code: '4131', value: '药物分析研究员', pinyin: 'yaowufenxiyanjiuyuan'
  },
  { code: '4132', value: '药理研究员', pinyin: 'yaoliyanjiuyuan'
  },
  { code: '4133', value: '病理研究员', pinyin: 'bingliyanjiuyuan'
  },
  { code: '4134', value: '药物合成/有机合成研究员', pinyin: 'yaowuhecheng/youjihechengyanjiuyuan'
  },
  { code: '4135', value: '细胞培养技术员', pinyin: 'xibaopeiyangjishuyuan'
  },
  { code: '4136', value: '药物警戒经理', pinyin: 'yaowujingjiejingli'
  },
  { code: '4137', value: '药物警戒专员', pinyin: 'yaowujingjiezhuanyuan'
  },
  { code: '4138', value: '动物实验技术员', pinyin: 'dongwushiyanjishuyuan'
  },
  { code: '4200', value: '广告', pinyin: 'guanggao'
  },
  { code: '4201', value: '广告客户总监/副总监', pinyin: 'guanggaokehuzongjian/fuzongjian'
  },
  { code: '4202', value: '广告客户经理', pinyin: 'guanggaokehujingli'
  },
  { code: '4203', value: '广告客户主管/专员', pinyin: 'guanggaokehuzhuguan/zhuanyuan'
  },
  { code: '4204', value: '广告创意/设计经理', pinyin: 'guanggaochuangyi/shejijingli'
  },
  { code: '4205', value: '广告创意总监', pinyin: 'guanggaochuangyizongjian'
  },
  { code: '4206', value: '广告创意/设计主管/专员', pinyin: 'guanggaochuangyi/shejizhuguan/zhuanyuan'
  },
  { code: '4207', value: '文案/策划', pinyin: 'wenan/cehua'
  },
  { code: '4208', value: '企业/业务发展经理', pinyin: 'qiye/yewufazhanjingli'
  },
  { code: '4209', value: '企业策划人员', pinyin: 'qiyecehuarenyuan'
  },
  { code: '4210', value: '其他', pinyin: 'qita'
  },
  { code: '4211', value: '美术指导', pinyin: 'meishuzhidao'
  },
  { code: '4212', value: '广告制作执行', pinyin: 'guanggaozhizuozhixing'
  },
  { code: '4213', value: '广告销售', pinyin: 'guanggaoxiaoshou'
  },
  { code: '4300', value: '公关/媒介', pinyin: 'gongguan/meijie'
  },
  { code: '4301', value: '公关经理', pinyin: 'gongguanjingli'
  },
  { code: '4302', value: '公关主管', pinyin: 'gongguanzhuguan'
  },
  { code: '4303', value: '公关专员', pinyin: 'gongguanzhuanyuan'
  },
  { code: '4304', value: '会务/会展经理', pinyin: 'huiwu/huizhanjingli'
  },
  { code: '4305', value: '会务/会展主管', pinyin: 'huiwu/huizhanzhuguan'
  },
  { code: '4306', value: '会务/会展专员', pinyin: 'huiwu/huizhanzhuanyuan'
  },
  { code: '4307', value: '媒介经理', pinyin: 'meijiejingli'
  },
  { code: '4308', value: '媒介主管', pinyin: 'meijiezhuguan'
  },
  { code: '4309', value: '媒介专员', pinyin: 'meijiezhuanyuan'
  },
  { code: '4310', value: '公关/媒介助理', pinyin: 'gongguan/meijiezhuli'
  },
  { code: '4311', value: '其他', pinyin: 'qita'
  },
  { code: '4312', value: '媒介销售', pinyin: 'meijiexiaoshou'
  },
  { code: '4313', value: '活动策划', pinyin: 'huodongcehua'
  },
  { code: '4314', value: '活动执行', pinyin: 'huodongzhixing'
  },
  { code: '4315', value: '公关总监', pinyin: 'gongguanzongjian'
  },
  { code: '4400', value: '影视/媒体', pinyin: 'yingshi/meiti'
  },
  { code: '4401', value: '影视策划/制作人员', pinyin: 'yingshicehua/zhizuorenyuan'
  },
  { code: '4402', value: '导演/编导', pinyin: 'daoyan/biandao'
  },
  { code: '4403', value: '艺术/设计总监', pinyin: 'yishu/shejizongjian'
  },
  { code: '4404', value: '经纪人/星探', pinyin: 'jingjiren/xingtan'
  },
  { code: '4405', value: '主播/主持人', pinyin: 'zhubo/zhuchiren'
  },
  { code: '4406', value: '摄影师/摄像师', pinyin: 'sheyingshi/shexiangshi'
  },
  { code: '4407', value: '音效师', pinyin: 'yinxiaoshi'
  },
  { code: '4408', value: '配音员', pinyin: 'peiyinyuan'
  },
  { code: '4410', value: '其他', pinyin: 'qita'
  },
  { code: '4411', value: '后期制作', pinyin: 'houqizhizuo'
  },
  { code: '4412', value: '放映经理/主管', pinyin: 'fangyingjingli/zhuguan'
  },
  { code: '4413', value: '放映员', pinyin: 'fangyingyuan'
  },
  { code: '4414', value: '艺术指导/舞台美术设计', pinyin: 'yishuzhidao/wutaimeishusheji'
  },
  { code: '4415', value: '灯光师', pinyin: 'dengguangshi'
  },
  { code: '4416', value: '视频剪辑', pinyin: 'shipinjianji'
  },
  { code: '4417', value: '编剧', pinyin: 'bianju'
  },
  { code: '4418', value: '制片人', pinyin: 'zhipianren'
  },
  { code: '4500', value: '编辑出版', pinyin: 'bianjichuban'
  },
  { code: '4501', value: '总编/副总编', pinyin: 'zongbian/fuzongbian'
  },
  { code: '4502', value: '编辑', pinyin: 'bianji'
  },
  { code: '4503', value: '记者', pinyin: 'jizhe'
  },
  { code: '4504', value: '美术编辑', pinyin: 'meishubianji'
  },
  { code: '4505', value: '排版设计', pinyin: 'paibansheji'
  },
  { code: '4507', value: '出版/发行', pinyin: 'chuban/faxing'
  },
  { code: '4508', value: '其他', pinyin: 'qita'
  },
  { code: '4516', value: '电话采编', pinyin: 'dianhuacaibian'
  },
  { code: '4517', value: '作家/撰稿人', pinyin: 'zuojia/zhuangaoren'
  },
  { code: '4600', value: '房地产开发', pinyin: 'fangdichankaifa'
  },
  { code: '4601', value: '房地产项目/策划经理', pinyin: 'fangdichanxiangmu/cehuajingli'
  },
  { code: '4602', value: '房地产项目/策划主管/专员', pinyin: 'fangdichanxiangmu/cehuazhuguan/zhuanyuan'
  },
  { code: '4603', value: '房产项目配套工程师', pinyin: 'fangchanxiangmupeitaogongchengshi'
  },
  { code: '4604', value: '房地产投资管理', pinyin: 'fangdichantouziguanli'
  },
  { code: '4607', value: '其他', pinyin: 'qita'
  },
  { code: '4608', value: '房地产项目招投标', pinyin: 'fangdichanxiangmuzhaotoubiao'
  },
  { code: '4610', value: '房地产投资分析', pinyin: 'fangdichantouzifenxi'
  },
  { code: '4611', value: '房地产资产管理', pinyin: 'fangdichanzichanguanli'
  },
  { code: '4612', value: '监察人员', pinyin: 'jiancharenyuan'
  },
  { code: '4700', value: '物业管理', pinyin: 'wuyeguanli'
  },
  { code: '4701', value: '高级物业顾问/物业顾问', pinyin: 'gaojiwuyeguwen/wuyeguwen'
  },
  { code: '4702', value: '物业管理经理', pinyin: 'wuyeguanlijingli'
  },
  { code: '4703', value: '物业管理专员/助理', pinyin: 'wuyeguanlizhuanyuan/zhuli'
  },
  { code: '4704', value: '招商/租赁/租售', pinyin: 'zhaoshang/zulin/zushou'
  },
  { code: '4705', value: '物业设施管理人员', pinyin: 'wuyesheshiguanlirenyuan'
  },
  { code: '4706', value: '物业维修员', pinyin: 'wuyeweixiuyuan'
  },
  { code: '4707', value: '其他', pinyin: 'qita'
  },
  { code: '4708', value: '物业机电工程师', pinyin: 'wuyejidiangongchengshi'
  },
  { code: '4709', value: '停车管理员', pinyin: 'tingcheguanliyuan'
  },
  { code: '4710', value: '保安经理', pinyin: 'baoanjingli'
  },
  { code: '4711', value: '保安人员', pinyin: 'baoanrenyuan'
  },
  { code: '4712', value: '保洁', pinyin: 'baojie'
  },
  { code: '4713', value: '绿化工', pinyin: 'lvhuagong'
  },
  { code: '4714', value: '物业管理主管', pinyin: 'wuyeguanlizhuguan'
  },
  { code: '4715', value: '物业机电维修工', pinyin: 'wuyejidianweixiugong'
  },
  { code: '4716', value: '前介工程师', pinyin: 'qianjiegongchengshi'
  },
  { code: '4717', value: '长租公寓管家/养老专员', pinyin: 'changzugongyuguanjia/yanglaozhuanyuan'
  },
  { code: '4719', value: '写字楼运营', pinyin: 'xiezilouyunying'
  },
  { code: '4800', value: '餐饮服务', pinyin: 'canyinfuwu'
  },
  { code: '4801', value: '餐饮店长/经理', pinyin: 'canyindianchang/jingli'
  },
  { code: '4802', value: '餐厅领班', pinyin: 'cantinglingban'
  },
  { code: '4803', value: '餐饮服务员', pinyin: 'canyinfuwuyuan'
  },
  { code: '4804', value: '礼仪/迎宾', pinyin: 'liyi/yingbin'
  },
  { code: '4806', value: '行政主厨/厨师长', pinyin: 'xingzhengzhuchu/chushichang'
  },
  { code: '4807', value: '中餐厨师', pinyin: 'zhongcanchushi'
  },
  { code: '4808', value: '调酒师/侍酒师/吧台员', pinyin: 'diaojiushi/shijiushi/bataiyuan'
  },
  { code: '4809', value: '茶艺师', pinyin: 'chayishi'
  },
  { code: '4810', value: '其他', pinyin: 'qita'
  },
  { code: '4811', value: '传菜主管', pinyin: 'chuancaizhuguan'
  },
  { code: '4812', value: '厨师助理/学徒', pinyin: 'chushizhuli/xuetu'
  },
  { code: '4813', value: '配菜/打荷', pinyin: 'peicai/dahe'
  },
  { code: '4814', value: '洗碗工', pinyin: 'xiwangong'
  },
  { code: '4815', value: '送餐员', pinyin: 'songcanyuan'
  },
  { code: '4816', value: '咖啡师', pinyin: 'kafeishi'
  },
  { code: '4817', value: '杂工', pinyin: 'zagong'
  },
  { code: '4818', value: '餐饮收银员', pinyin: 'canyinshouyinyuan'
  },
  { code: '4819', value: '餐饮大堂经理', pinyin: 'canyindatangjingli'
  },
  { code: '4820', value: '西餐厨师', pinyin: 'xicanchushi'
  },
  { code: '4821', value: '日式厨师', pinyin: 'rishichushi'
  },
  { code: '4822', value: '面点师', pinyin: 'miandianshi'
  },
  { code: '4823', value: '西点师', pinyin: 'xidianshi'
  },
  { code: '4824', value: '餐饮预订员', pinyin: 'canyinyudingyuan'
  },
  { code: '4825', value: '传菜员', pinyin: 'chuancaiyuan'
  },
  { code: '4826', value: '调饮师', pinyin: 'diaoyinshi'
  },
  { code: '4827', value: '食品安全管理师', pinyin: 'shipinanquanguanlishi'
  },
  { code: '4900', value: '酒店旅游', pinyin: 'jiudianlvyou'
  },
  { code: '4901', value: '酒店/宾馆经理', pinyin: 'jiudian/binguanjingli'
  },
  { code: '4902', value: '酒店/宾馆销售', pinyin: 'jiudian/binguanxiaoshou'
  },
  { code: '4903', value: '酒店大堂经理', pinyin: 'jiudiandatangjingli'
  },
  { code: '4904', value: '楼面经理', pinyin: 'loumianjingli'
  },
  { code: '4905', value: '酒店前台', pinyin: 'jiudianqiantai'
  },
  { code: '4906', value: '客房服务员/楼面服务员', pinyin: 'kefangfuwuyuan/loumianfuwuyuan'
  },
  { code: '4907', value: '行李员', pinyin: 'xingliyuan'
  },
  { code: '4908', value: '清洁服务人员', pinyin: 'qingjiefuwurenyuan'
  },
  { code: '4909', value: '导游/旅行顾问', pinyin: 'daoyou/lvxingguwen'
  },
  { code: '4910', value: '票务', pinyin: 'piaowu'
  },
  { code: '4911', value: '其他', pinyin: 'qita'
  },
  { code: '4912', value: '宴会管理', pinyin: 'yanhuiguanli'
  },
  { code: '4913', value: '机场代表', pinyin: 'jichangdaibiao'
  },
  { code: '4914', value: '管家部经理/主管', pinyin: 'guanjiabujingli/zhuguan'
  },
  { code: '4915', value: '宾客服务经理', pinyin: 'binkefuwujingli'
  },
  { code: '4916', value: '预订主管', pinyin: 'yudingzhuguan'
  },
  { code: '4917', value: '预订员', pinyin: 'yudingyuan'
  },
  { code: '4918', value: '健身房服务', pinyin: 'jianshenfangfuwu'
  },
  { code: '4919', value: '旅游产品销售', pinyin: 'lvyouchanpinxiaoshou'
  },
  { code: '4920', value: '行程管理/计调', pinyin: 'xingchengguanli/jidiao'
  },
  { code: '4921', value: '签证专员', pinyin: 'qianzhengzhuanyuan'
  },
  { code: '4922', value: '研学项目主管/经理', pinyin: 'yanxuexiangmuzhuguan/jingli'
  },
  { code: '4923', value: '旅游策划师', pinyin: 'lvyoucehuashi'
  },
  { code: '5000', value: '美容保健', pinyin: 'meirongbaojian'
  },
  { code: '5001', value: '美容顾问', pinyin: 'meirongguwen'
  },
  { code: '5002', value: '美容助理', pinyin: 'meirongzhuli'
  },
  { code: '5003', value: '瘦身顾问', pinyin: 'shoushenguwen'
  },
  { code: '5004', value: '发型师', pinyin: 'faxingshi'
  },
  { code: '5005', value: '发型助理/学徒', pinyin: 'faxingzhuli/xuetu'
  },
  { code: '5006', value: '美甲师', pinyin: 'meijiashi'
  },
  { code: '5007', value: '按摩', pinyin: 'anmo'
  },
  { code: '5010', value: '宠物护理/美容', pinyin: 'chongwuhuli/meirong'
  },
  { code: '5011', value: '其他', pinyin: 'qita'
  },
  { code: '5013', value: '彩妆培训师', pinyin: 'caizhuangpeixunshi'
  },
  { code: '5014', value: '专柜彩妆顾问(BA)', pinyin: 'zhuanguicaizhuangguwen(BA)'
  },
  { code: '5016', value: '美容培训师/导师', pinyin: 'meirongpeixunshi/daoshi'
  },
  { code: '5017', value: '美体师', pinyin: 'meitishi'
  },
  { code: '5018', value: '美容店长', pinyin: 'meirongdianchang'
  },
  { code: '5019', value: '美容师', pinyin: 'meirongshi'
  },
  { code: '5020', value: '化妆师', pinyin: 'huazhuangshi'
  },
  { code: '5021', value: '造型师', pinyin: 'zaoxingshi'
  },
  { code: '5022', value: '美发店长', pinyin: 'meifadianchang'
  },
  { code: '5023', value: 'SPA 技师', pinyin: 'SPA jishi'
  },
  { code: '5024', value: '足疗', pinyin: 'zuliao'
  },
  { code: '5100', value: '百货零售', pinyin: 'baihuolingshou'
  },
  { code: '5101', value: '门店经理/店长', pinyin: 'mendianjingli/dianchang'
  },
  { code: '5102', value: '店员/营业员', pinyin: 'dianyuan/yingyeyuan'
  },
  { code: '5103', value: '收银主管', pinyin: 'shouyinzhuguan'
  },
  { code: '5104', value: '陈列员', pinyin: 'chenlieyuan'
  },
  { code: '5105', value: '促销员/导购员', pinyin: 'cuxiaoyuan/daogouyuan'
  },
  { code: '5106', value: '兼职店员', pinyin: 'jianzhidianyuan'
  },
  { code: '5107', value: '其他', pinyin: 'qita'
  },
  { code: '5108', value: '防损员/内保', pinyin: 'fangsunyuan/neibao'
  },
  { code: '5109', value: '西点师/面包糕点加工', pinyin: 'xidianshi/mianbaogaodianjiagong'
  },
  { code: '5110', value: '生鲜食品加工/处理', pinyin: 'shengxianshipinjiagong/chuli'
  },
  { code: '5111', value: '熟食加工', pinyin: 'shushijiagong'
  },
  { code: '5112', value: '品类管理', pinyin: 'pinleiguanli'
  },
  { code: '5113', value: '安防主管', pinyin: 'anfangzhuguan'
  },
  { code: '5114', value: '品牌/连锁招商管理', pinyin: 'pinpai/liansuozhaoshangguanli'
  },
  { code: '5115', value: '奢侈品业务', pinyin: 'shechipinyewu'
  },
  { code: '5116', value: '珠宝销售顾问', pinyin: 'zhubaoxiaoshouguwen'
  },
  { code: '5117', value: '促销主管/督导/巡店', pinyin: 'cuxiaozhuguan/dudao/xundian'
  },
  { code: '5119', value: '收银员', pinyin: 'shouyinyuan'
  },
  { code: '5120', value: '收货员', pinyin: 'shouhuoyuan'
  },
  { code: '5121', value: '理货员', pinyin: 'lihuoyuan'
  },
  { code: '5122', value: '店长助理', pinyin: 'dianchangzhuli'
  },
  { code: '5123', value: '导购管理', pinyin: 'daogouguanli'
  },
  { code: '5124', value: '陈列管理', pinyin: 'chenlieguanli'
  },
  { code: '5130', value: '商品管理', pinyin: 'shangpinguanli'
  },
  { code: '5200', value: '家政保洁', pinyin: 'jiazhengbaojie'
  },
  { code: '5202', value: '保镖', pinyin: 'baozuo'
  },
  { code: '5203', value: '寻呼员/话务员', pinyin: 'xunhuyuan/huawuyuan'
  },
  { code: '5205', value: '清洁工', pinyin: 'qingjiegong'
  },
  { code: '5206', value: '家政服务/保姆', pinyin: 'jiazhengfuwu/baomu'
  },
  { code: '5207', value: '其他', pinyin: 'qita'
  },
  { code: '5209', value: '月嫂', pinyin: 'yuesao'
  },
  { code: '5210', value: '育婴师/保育员', pinyin: 'yuyingshi/baoyuyuan'
  },
  { code: '5211', value: '护工', pinyin: 'hugong'
  },
  { code: '5212', value: '钟点工', pinyin: 'zhongdiangong'
  },
  { code: '5213', value: '洗衣工', pinyin: 'xiyigong'
  },
  { code: '5214', value: '送水工', pinyin: 'songshuigong'
  },
  { code: '5215', value: '空调维修', pinyin: 'kongdiaoweixiu'
  },
  { code: '5216', value: '家电维修', pinyin: 'jiadianweixiu'
  },
  { code: '5300', value: '兼职', pinyin: 'jianzhi'
  },
  { code: '5301', value: '兼职', pinyin: 'jianzhi'
  },
  { code: '5400', value: '汽车制造', pinyin: 'qichezhizao'
  },
  { code: '5404', value: '汽车质量工程师', pinyin: 'qichezhilianggongchengshi'
  },
  { code: '5406', value: '汽车装配工艺工程师', pinyin: 'qichezhuangpeigongyigongchengshi'
  },
  { code: '5411', value: '其他', pinyin: 'qita'
  },
  { code: '5421', value: '供应商质量工程师', pinyin: 'gongyingshangzhilianggongchengshi'
  },
  { code: '5422', value: '前期质量工程师', pinyin: 'qianqizhilianggongchengshi'
  },
  { code: '5423', value: '过程质量工程师', pinyin: 'guochengzhilianggongchengshi'
  },
  { code: '5424', value: '客户质量工程师', pinyin: 'kehuzhilianggongchengshi'
  },
  { code: '5425', value: '总装工程师', pinyin: 'zongzhuanggongchengshi'
  },
  { code: '5426', value: '焊接工艺工程师', pinyin: 'hanjiegongyigongchengshi'
  },
  { code: '5427', value: '冲压工艺工程师', pinyin: 'chongyagongyigongchengshi'
  },
  { code: '5428', value: '涂装工艺工程师', pinyin: 'tuzhuanggongyigongchengshi'
  },
  { code: '5500', value: '化工', pinyin: 'huagong'
  },
  { code: '5501', value: '化工技术应用/化工工程师', pinyin: 'huagongjishuyingyong/huagonggongchengshi'
  },
  { code: '5502', value: '化工实验室研究员/技术员', pinyin: 'huagongshiyanshiyanjiuyuan/jishuyuan'
  },
  { code: '5503', value: '涂料研发工程师', pinyin: 'tuliaoyanfagongchengshi'
  },
  { code: '5504', value: '配色技术员', pinyin: 'peisejishuyuan'
  },
  { code: '5505', value: '塑料工程师', pinyin: 'suliaogongchengshi'
  },
  { code: '5506', value: '化妆品研发', pinyin: 'huazhuangpinyanfa'
  },
  { code: '5507', value: '食品/饮料研发', pinyin: 'shipin/yinliaoyanfa'
  },
  { code: '5508', value: '其他', pinyin: 'qita'
  },
  { code: '5509', value: '造纸研发', pinyin: 'zaozhiyanfa'
  },
  { code: '5510', value: '酒体设计师', pinyin: 'jiutishejishi'
  },
  { code: '5511', value: '有机合成研究员', pinyin: 'youjihechengyanjiuyuan'
  },
  { code: '5600', value: '环保', pinyin: 'huanbao'
  },
  { code: '5601', value: '环保工程师', pinyin: 'huanbaogongchengshi'
  },
  { code: '5602', value: '水处理工程师', pinyin: 'shuichuligongchengshi'
  },
  { code: '5603', value: '其他', pinyin: 'qita'
  },
  { code: '5604', value: '环境影响评价工程师', pinyin: 'huanjingyingxiangpingjiagongchengshi'
  },
  { code: '5605', value: '环保检测', pinyin: 'huanbaojiance'
  },
  { code: '5606', value: '水质检测员', pinyin: 'shuizhijianceyuan'
  },
  { code: '5607', value: '固废工程师', pinyin: 'gufeigongchengshi'
  },
  { code: '5608', value: '废气处理工程师', pinyin: 'feiqichuligongchengshi'
  },
  { code: '5609', value: '生态治理/规划', pinyin: 'shengtaizhili/guihua'
  },
  { code: '5610', value: '碳排放管理员', pinyin: 'tanpaifangguanliyuan'
  },
  { code: '5700', value: '培训', pinyin: 'peixun'
  },
  { code: '5701', value: '培训督导', pinyin: 'peixundudao'
  },
  { code: '5702', value: '培训讲师', pinyin: 'peixunjiangshi'
  },
  { code: '5703', value: '培训策划', pinyin: 'peixuncehua'
  },
  { code: '5704', value: '培训助理', pinyin: 'peixunzhuli'
  },
  { code: '5705', value: '其他', pinyin: 'qita'
  },
  { code: '5707', value: '培训产品开发', pinyin: 'peixunchanpinkaifa'
  },
  { code: '5800', value: '农/林/牧/渔', pinyin: 'nong/lin/mu/yu'
  },
  { code: '5801', value: '养殖部主管', pinyin: 'yangzhibuzhuguan'
  },
  { code: '5802', value: '场长(农/林/牧/渔业)', pinyin: 'changchang(nong/lin/mu/yuye)'
  },
  { code: '5803', value: '农艺师', pinyin: 'nongyishi'
  },
  { code: '5804', value: '畜牧师', pinyin: 'xumushi'
  },
  { code: '5805', value: '饲养员', pinyin: 'siyangyuan'
  },
  { code: '5806', value: '动物营养/饲料研发', pinyin: 'dongwuyingyang/siliaoyanfa'
  },
  { code: '5807', value: '其他', pinyin: 'qita'
  },
  { code: '5808', value: '农业技术员', pinyin: 'nongyejishuyuan'
  },
  { code: '5810', value: '驯兽师/助理驯兽师', pinyin: 'xunshoushi/zhulixunshoushi'
  },
  { code: '5900', value: '汽车销售与服务', pinyin: 'qichexiaoshouyufuwu'
  },
  { code: '5901', value: '4S店经理/维修站经理', pinyin: '4Sdianjingli/weixiuzhanjingli'
  },
  { code: '5902', value: '售后服务/客户服务', pinyin: 'shouhoufuwu/kehufuwu'
  },
  { code: '5903', value: '汽车销售/经纪人', pinyin: 'qichexiaoshou/jingjiren'
  },
  { code: '5904', value: '二手车评估师', pinyin: 'ershouchepinggushi'
  },
  { code: '5905', value: '汽车检验/检测', pinyin: 'qichejianyan/jiance'
  },
  { code: '5906', value: '汽车装饰美容', pinyin: 'qichezhuangshimeirong'
  },
  { code: '5907', value: '汽车修理工', pinyin: 'qichexiuligong'
  },
  { code: '5908', value: '洗车工', pinyin: 'xichegong'
  },
  { code: '5910', value: '加油站工作员', pinyin: 'jiayouzhangongzuoyuan'
  },
  { code: '5911', value: '其他', pinyin: 'qita'
  },
  { code: '5912', value: '汽车电工', pinyin: 'qichediangong'
  },
  { code: '5913', value: '汽车钣金', pinyin: 'qichezuojin'
  },
  { code: '5914', value: '汽车喷漆', pinyin: 'qichepenqi'
  },
  { code: '5915', value: '车险定损/理赔', pinyin: 'chexiandingsun/lipei'
  },
  { code: '5916', value: '汽车金融销售', pinyin: 'qichejinrongxiaoshou'
  },
  { code: '5917', value: '汽车金融专员', pinyin: 'qichejinrongzhuanyuan'
  },
  { code: '5918', value: '汽车金融经理', pinyin: 'qichejinrongjingli'
  },
  { code: '5919', value: '二手车经纪人', pinyin: 'ershouchejingjiren'
  },
  { code: '5920', value: '汽车救援员', pinyin: 'qichejiuyuanyuan'
  },
  { code: '5921', value: '汽车服务顾问', pinyin: 'qichefuwuguwen'
  },
  { code: '6000', value: '房地产销售与中介', pinyin: 'fangdichanxiaoshouyuzhongjie'
  },
  { code: '6001', value: '房地产中介/置业顾问', pinyin: 'fangdichanzhongjie/zhiyeguwen'
  },
  { code: '6002', value: '房地产评估', pinyin: 'fangdichanpinggu'
  },
  { code: '6004', value: '房地产店长/经理', pinyin: 'fangdichandianchang/jingli'
  },
  { code: '6006', value: '房地产客服', pinyin: 'fangdichankefu'
  },
  { code: '6007', value: '房地产内勤', pinyin: 'fangdichanneiqin'
  },
  { code: '6008', value: '其他', pinyin: 'qita'
  },
  { code: '6009', value: '房地产销售经理/主管', pinyin: 'fangdichanxiaoshoujingli/zhuguan'
  },
  { code: '6010', value: '房地产销售', pinyin: 'fangdichanxiaoshou'
  },
  { code: '6100', value: '电子商务', pinyin: 'dianzishangwu'
  },
  { code: '6101', value: '网店店长', pinyin: 'wangdiandianchang'
  },
  { code: '6102', value: '电商运营', pinyin: 'dianshangyunying'
  },
  { code: '6103', value: '网店店铺管理员', pinyin: 'wangdiandianpuguanliyuan'
  },
  { code: '6104', value: '网店客服', pinyin: 'wangdiankefu'
  },
  { code: '6105', value: '店铺推广', pinyin: 'dianputuiguang'
  },
  { code: '6107', value: '网店模特', pinyin: 'wangdianmote'
  },
  { code: '6108', value: '其他', pinyin: 'qita'
  },
  { code: '6109', value: '电商专员', pinyin: 'dianshangzhuanyuan'
  },
  { code: '6110', value: '电商经理/电商主管', pinyin: 'dianshangjingli/dianshangzhuguan'
  },
  { code: '6111', value: '电商总监', pinyin: 'dianshangzongjian'
  },
  { code: '6112', value: '跨境电商运营', pinyin: 'kuajingdianshangyunying'
  },
  { code: '6200', value: '机械机床', pinyin: 'jixiejichuang'
  },
  { code: '6201', value: '数控操机', pinyin: 'shukongcaoji'
  },
  { code: '6202', value: '数控编程', pinyin: 'shukongbiancheng'
  },
  { code: '6203', value: '机修工', pinyin: 'jixiugong'
  },
  { code: '6204', value: '折弯工', pinyin: 'zhewangong'
  },
  { code: '6205', value: '车工', pinyin: 'chegong'
  },
  { code: '6206', value: '磨工', pinyin: 'mogong'
  },
  { code: '6207', value: '铣工', pinyin: 'xigong'
  },
  { code: '6208', value: '冲压工', pinyin: 'chongyagong'
  },
  { code: '6209', value: '刨工', pinyin: 'paogong'
  },
  { code: '6210', value: '钳工', pinyin: 'qiangong'
  },
  { code: '6211', value: '钻工', pinyin: 'zuangong'
  },
  { code: '6212', value: '镗工', pinyin: 'zuogong'
  },
  { code: '6213', value: '铆工', pinyin: 'maogong'
  },
  { code: '6214', value: '钣金工', pinyin: 'zuojingong'
  },
  { code: '6215', value: '抛光工', pinyin: 'paoguanggong'
  },
  { code: '6216', value: '切割技工', pinyin: 'qiegejigong'
  },
  { code: '6217', value: '模具工', pinyin: 'mojugong'
  },
  { code: '6218', value: '炼胶工', pinyin: 'lianjiaogong'
  },
  { code: '6219', value: '硫化工', pinyin: 'liuhuagong'
  },
  { code: '6220', value: '吹膜工', pinyin: 'chuimogong'
  },
  { code: '6221', value: '注塑工', pinyin: 'zhusugong'
  },
  { code: '6222', value: '其他', pinyin: 'qita'
  },
  { code: '6300', value: '印刷包装', pinyin: 'yinshuabaozhuang'
  },
  { code: '6301', value: '印刷工', pinyin: 'yinshuagong'
  },
  { code: '6302', value: '校对/录入', pinyin: 'xiaodui/luru'
  },
  { code: '6304', value: '调色员', pinyin: 'diaoseyuan'
  },
  { code: '6305', value: '烫金工', pinyin: 'tangjingong'
  },
  { code: '6306', value: '晒版员', pinyin: 'shaibanyuan'
  },
  { code: '6307', value: '印刷排版/制版', pinyin: 'yinshuapaiban/zhiban'
  },
  { code: '6308', value: '装订工', pinyin: 'zhuangdinggong'
  },
  { code: '6309', value: '印刷机械机长', pinyin: 'yinshuajixiejichang'
  },
  { code: '6310', value: '数码直印/菲林输出', pinyin: 'shumazhiyin/feilinshuchu'
  },
  { code: '6311', value: '调墨技师', pinyin: 'diaomojishi'
  },
  { code: '6312', value: '电分操作员', pinyin: 'dianfencaozuoyuan'
  },
  { code: '6313', value: '打稿机操作员', pinyin: 'dagaojicaozuoyuan'
  },
  { code: '6314', value: '切纸机操作工', pinyin: 'qiezhijicaozuogong'
  },
  { code: '6315', value: '裱胶工', pinyin: 'zuojiaogong'
  },
  { code: '6316', value: '压痕工', pinyin: 'yahengong'
  },
  { code: '6317', value: '复卷工', pinyin: 'fujuangong'
  },
  { code: '6318', value: '其他', pinyin: 'qita'
  },
  { code: '6400', value: '运动健身', pinyin: 'yundongjianshen'
  },
  { code: '6401', value: '健身顾问/教练', pinyin: 'jianshenguwen/jiaolian'
  },
  { code: '6402', value: '瑜伽老师', pinyin: 'zuozuolaoshi'
  },
  { code: '6403', value: '舞蹈老师', pinyin: 'wudaolaoshi'
  },
  { code: '6404', value: '游泳教练', pinyin: 'youyongjiaolian'
  },
  { code: '6405', value: '救生员', pinyin: 'jiushengyuan'
  },
  { code: '6406', value: '高尔夫教练', pinyin: 'gaoerfujiaolian'
  },
  { code: '6407', value: '体育运动教练', pinyin: 'tiyuyundongjiaolian'
  },
  { code: '6408', value: '其他', pinyin: 'qita'
  },
  { code: '6500', value: '休闲娱乐', pinyin: 'xiuxianyule'
  },
  { code: '6501', value: '司仪', pinyin: 'siyi'
  },
  { code: '6502', value: '婚礼/庆典策划服务', pinyin: 'hunli/qingdiancehuafuwu'
  },
  { code: '6503', value: 'DJ', pinyin: 'DJ'
  },
  { code: '6504', value: '驻唱/歌手', pinyin: 'zhuchang/geshou'
  },
  { code: '6505', value: '舞蹈演员', pinyin: 'wudaoyanyuan'
  },
  { code: '6506', value: '模特', pinyin: 'mote'
  },
  { code: '6507', value: '演员/群众演员', pinyin: 'yanyuan/qunzhongyanyuan'
  },
  { code: '6508', value: '其他', pinyin: 'qita'
  },
  { code: '6509', value: '娱乐领班', pinyin: 'yulelingban'
  },
  { code: '6510', value: '娱乐服务员', pinyin: 'yulefuwuyuan'
  },
  { code: '6511', value: '前台迎宾', pinyin: 'qiantaiyingbin'
  },
  { code: '6512', value: '网络主播', pinyin: 'wangluozhubo'
  },
  { code: '6513', value: '主播助理', pinyin: 'zhubozhuli'
  },
  { code: '6514', value: '带货主播', pinyin: 'daihuozhubo'
  },
  { code: '6600', value: '产品', pinyin: 'chanpin'
  },
  { code: '6601', value: '产品专员', pinyin: 'chanpinzhuanyuan'
  },
  { code: '6602', value: '产品经理/主管', pinyin: 'chanpinjingli/zhuguan'
  },
  { code: '6603', value: '产品助理', pinyin: 'chanpinzhuli'
  },
  { code: '6604', value: '产品总监', pinyin: 'chanpinzongjian'
  },
  { code: '6605', value: '互联网产品经理', pinyin: 'hulianwangchanpinjingli'
  },
  { code: '6606', value: '移动产品经理', pinyin: 'yidongchanpinjingli'
  },
  { code: '6607', value: '用户产品经理', pinyin: 'yonghuchanpinjingli'
  },
  { code: '6608', value: '电商产品经理', pinyin: 'dianshangchanpinjingli'
  },
  { code: '6609', value: '需求工程师', pinyin: 'xuqiugongchengshi'
  },
  { code: '6610', value: '其他', pinyin: 'qita'
  },
  { code: '6700', value: '半导体/芯片', pinyin: 'bandaoti/xinpian'
  },
  { code: '6701', value: '集成电路IC设计/应用工程师', pinyin: 'jichengdianluICsheji/yingyonggongchengshi'
  },
  { code: '6702', value: 'IC验证工程师', pinyin: 'ICyanzhenggongchengshi'
  },
  { code: '6707', value: '半导体技术', pinyin: 'bandaotijishu'
  },
  { code: '6712', value: 'FAE 现场应用工程师', pinyin: 'FAE xianchangyingyonggongchengshi'
  },
  { code: '6722', value: '版图设计工程师', pinyin: 'bantushejigongchengshi'
  },
  { code: '6723', value: '半导体工艺工程师', pinyin: 'bandaotigongyigongchengshi'
  },
  { code: '6727', value: '芯片架构工程师', pinyin: 'xinpianjiagougongchengshi'
  },
  { code: '6728', value: 'FPGA开发工程师', pinyin: 'FPGAkaifagongchengshi'
  },
  { code: '6729', value: 'MEMS工程师', pinyin: 'MEMSgongchengshi'
  },
  { code: '6730', value: '射频芯片设计', pinyin: 'shepinxinpiansheji'
  },
  { code: '6731', value: '模拟芯片工程师', pinyin: 'monixinpiangongchengshi'
  },
  { code: '6732', value: '模拟版图工程师', pinyin: 'monibantugongchengshi'
  },
  { code: '6733', value: '数字前端工程师', pinyin: 'shuziqianduangongchengshi'
  },
  { code: '6734', value: 'FPGA原型验证工程师', pinyin: 'FPGAyuanxingyanzhenggongchengshi'
  },
  { code: '6735', value: 'EDA工程师', pinyin: 'EDAgongchengshi'
  },
  { code: '6736', value: '可测性设计工程师(DFT)', pinyin: 'kecexingshejigongchengshi(DFT)'
  },
  { code: '6737', value: '数字后端工程师', pinyin: 'shuzihouduangongchengshi'
  },
  { code: '6738', value: '芯片测试工程师', pinyin: 'xinpianceshigongchengshi'
  },
  { code: '6739', value: '半导体设备工程师', pinyin: 'bandaotishebeigongchengshi'
  },
  { code: '6740', value: '工艺整合工程师(PIE)', pinyin: 'gongyizhenghegongchengshi(PIE)'
  },
  { code: '6741', value: '失效分析工程师(FA)', pinyin: 'shixiaofenxigongchengshi(FA)'
  },
  { code: '6744', value: '封装研发工程师', pinyin: 'fengzhuangyanfagongchengshi'
  },
  { code: '6746', value: '芯片销售工程师', pinyin: 'xinpianxiaoshougongchengshi'
  },
  { code: '6747', value: '半导体文档工程师', pinyin: 'bandaotiwendanggongchengshi'
  },
  { code: '6748', value: '半导体产品经理/产品工程师', pinyin: 'bandaotichanpinjingli/chanpingongchengshi'
  },
  { code: '6749', value: '其他', pinyin: 'qita'
  },
  { code: '6750', value: '半导体测试工程师', pinyin: 'bandaoticeshigongchengshi'
  },
  { code: '6760', value: '封装工程师', pinyin: 'fengzhuanggongchengshi'
  },
  { code: '6761', value: '半导体器件工程师', pinyin: 'bandaotiqijiangongchengshi'
  },
  { code: '6900', value: '建筑规划与设计', pinyin: 'jianzhuguihuayusheji'
  },
  { code: '6901', value: '室内设计', pinyin: 'shineisheji'
  },
  { code: '6902', value: '软装设计', pinyin: 'ruanzhuangsheji'
  },
  { code: '6903', value: '精装设计', pinyin: 'jingzhuangsheji'
  },
  { code: '6904', value: '规划与设计', pinyin: 'guihuayusheji'
  },
  { code: '6905', value: '建筑设计师', pinyin: 'jianzhushejishi'
  },
  { code: '6906', value: '钢结构设计', pinyin: 'gangjiegousheji'
  },
  { code: '6907', value: '幕墙设计', pinyin: 'muqiangsheji'
  },
  { code: '6908', value: '建筑结构设计', pinyin: 'jianzhujiegousheji'
  },
  { code: '6909', value: '建筑制图/模型/渲染', pinyin: 'jianzhuzhitu/moxing/zuoran'
  },
  { code: '6910', value: '建筑机电设计', pinyin: 'jianzhujidiansheji'
  },
  { code: '6911', value: '暖通设计', pinyin: 'nuantongsheji'
  },
  { code: '6912', value: '给排水设计', pinyin: 'geipaishuisheji'
  },
  { code: '6913', value: '城市规划设计', pinyin: 'chengshiguihuasheji'
  },
  { code: '6914', value: '园艺/园林/景观设计', pinyin: 'yuanyi/yuanlin/jingguansheji'
  },
  { code: '6915', value: '其他', pinyin: 'qita'
  },
  { code: '6916', value: 'BIM工程师', pinyin: 'BIMgongchengshi'
  },
  { code: '6917', value: '室内设计师助理', pinyin: 'shineishejishizhuli'
  },
  { code: '6918', value: '室内设计经理/主管', pinyin: 'shineishejijingli/zhuguan'
  },
  { code: '6919', value: '室内设计总监', pinyin: 'shineishejizongjian'
  },
  { code: '6920', value: '家装顾问', pinyin: 'jiazhuangguwen'
  },
  { code: '7100', value: '汽车研发设计', pinyin: 'qicheyanfasheji'
  },
  { code: '7101', value: '汽车项目管理', pinyin: 'qichexiangmuguanli'
  },
  { code: '7102', value: '汽车设计工程师', pinyin: 'qicheshejigongchengshi'
  },
  { code: '7103', value: '车身/造型设计', pinyin: 'cheshen/zaoxingsheji'
  },
  { code: '7104', value: '汽车结构工程师', pinyin: 'qichejiegougongchengshi'
  },
  { code: '7105', value: '内外饰工程师', pinyin: 'neiwaishigongchengshi'
  },
  { code: '7106', value: '汽车电子工程师', pinyin: 'qichedianzigongchengshi'
  },
  { code: '7107', value: '电气/电器工程师', pinyin: 'dianqi/dianqigongchengshi'
  },
  { code: '7108', value: '附件系统工程师', pinyin: 'fujianxitonggongchengshi'
  },
  { code: '7109', value: '动力总成工程师', pinyin: 'donglizongchenggongchengshi'
  },
  { code: '7110', value: '发动机工程师', pinyin: 'fadongjigongchengshi'
  },
  { code: '7111', value: '底盘工程师', pinyin: 'dipangongchengshi'
  },
  { code: '7112', value: '汽车安全性能工程师', pinyin: 'qicheanquanxingnenggongchengshi'
  },
  { code: '7113', value: '汽车试验工程师', pinyin: 'qicheshiyangongchengshi'
  },
  { code: '7114', value: '新能源电池工程师', pinyin: 'xinnengyuandianchigongchengshi'
  },
  { code: '7115', value: '新能源电控工程师', pinyin: 'xinnengyuandiankonggongchengshi'
  },
  { code: '7116', value: '新能源电机工程师', pinyin: 'xinnengyuandianjigongchengshi'
  },
  { code: '7117', value: '汽车标定工程师', pinyin: 'qichebiaodinggongchengshi'
  },
  { code: '7118', value: '发动机匹配工程师', pinyin: 'fadongjipipeigongchengshi'
  },
  { code: '7119', value: '车联网工程师', pinyin: 'chelianwanggongchengshi'
  },
  { code: '7120', value: '智能驾驶工程师', pinyin: 'zhinengjiashigongchengshi'
  },
  { code: '7121', value: '研发总监/部长/专家', pinyin: 'yanfazongjian/buchang/zhuanjia'
  },
  { code: '7122', value: '其他', pinyin: 'qita'
  },
  { code: '7124', value: '智能驾驶测试工程师', pinyin: 'zhinengjiashiceshigongchengshi'
  },
  { code: '7200', value: '前端开发', pinyin: 'qianduankaifa'
  },
  { code: '7201', value: 'Web前端开发', pinyin: 'Webqianduankaifa'
  },
  { code: '7202', value: 'HTML5开发工程师', pinyin: 'HTML5kaifagongchengshi'
  },
  { code: '7203', value: '其他', pinyin: 'qita'
  },
  { code: '7300', value: '人工智能', pinyin: 'rengongzhineng'
  },
  { code: '7301', value: '机器学习工程师', pinyin: 'jiqixuexigongchengshi'
  },
  { code: '7302', value: '深度学习工程师', pinyin: 'shenduxuexigongchengshi'
  },
  { code: '7303', value: '图像算法工程师', pinyin: 'tuxiangsuanfagongchengshi'
  },
  { code: '7304', value: '图像处理工程师', pinyin: 'tuxiangchuligongchengshi'
  },
  { code: '7305', value: '图像识别工程师', pinyin: 'tuxiangshibiegongchengshi'
  },
  { code: '7306', value: '语音识别工程师', pinyin: 'yuyinshibiegongchengshi'
  },
  { code: '7307', value: '机器视觉工程师', pinyin: 'jiqishijuegongchengshi'
  },
  { code: '7308', value: '自然语言处理(NLP)', pinyin: 'ziranyuyanchuli(NLP)'
  },
  { code: '7309', value: '算法工程师', pinyin: 'suanfagongchengshi'
  },
  { code: '7310', value: '推荐算法工程师', pinyin: 'tuijiansuanfagongchengshi'
  },
  { code: '7311', value: '搜索算法工程师', pinyin: 'sousuosuanfagongchengshi'
  },
  { code: '7312', value: '其他', pinyin: 'qita'
  },
  { code: '7400', value: '视觉/交互设计', pinyin: 'shijue/jiaohusheji'
  },
  { code: '7401', value: '网页设计师', pinyin: 'wangyeshejishi'
  },
  { code: '7402', value: '交互设计师', pinyin: 'jiaohushejishi'
  },
  { code: '7403', value: '视觉设计师', pinyin: 'shijueshejishi'
  },
  { code: '7404', value: '用户体验（UE/UX）设计师', pinyin: 'yonghutiyan（UE/UX）shejishi'
  },
  { code: '7405', value: '网站架构设计师', pinyin: 'wangzhanjiagoushejishi'
  },
  { code: '7406', value: 'Flash设计师', pinyin: 'Flashshejishi'
  },
  { code: '7407', value: '特效设计师', pinyin: 'texiaoshejishi'
  },
  { code: '7408', value: '音效设计师', pinyin: 'yinxiaoshejishi'
  },
  { code: '7409', value: '计算机辅助设计工程师', pinyin: 'jisuanjifuzhushejigongchengshi'
  },
  { code: '7410', value: '仿真应用工程师', pinyin: 'fangzhenyingyonggongchengshi'
  },
  { code: '7411', value: '其他', pinyin: 'qita'
  },
  { code: '7412', value: 'UI设计师', pinyin: 'UIshejishi'
  },
  { code: '7413', value: '平面设计师', pinyin: 'pingmianshejishi'
  },
  { code: '7416', value: '多媒体设计', pinyin: 'duomeitisheji'
  },
  { code: '7417', value: '绘画', pinyin: 'huihua'
  },
  { code: '7418', value: '原画师', pinyin: 'yuanhuashi'
  },
  { code: '7419', value: '美工/电商设计师', pinyin: 'meigong/dianshangshejishi'
  },
  { code: '7420', value: '平面设计总监', pinyin: 'pingmianshejizongjian'
  },
  { code: '7421', value: '平面设计经理/主管', pinyin: 'pingmianshejijingli/zhuguan'
  },
  { code: '7422', value: '动画/3D设计', pinyin: 'donghua/3Dsheji'
  },
  { code: '7500', value: '数据', pinyin: 'shuju'
  },
  { code: '7501', value: '数据分析师', pinyin: 'shujufenxishi'
  },
  { code: '7502', value: '数据分析经理/主管', pinyin: 'shujufenxijingli/zhuguan'
  },
  { code: '7503', value: 'ETL开发工程师', pinyin: 'ETLkaifagongchengshi'
  },
  { code: '7504', value: 'BI工程师', pinyin: 'BIgongchengshi'
  },
  { code: '7505', value: '数据仓库工程师', pinyin: 'shujucangkugongchengshi'
  },
  { code: '7506', value: '数据采集工程师', pinyin: 'shujucaijigongchengshi'
  },
  { code: '7507', value: '数据建模工程师', pinyin: 'shujujianmogongchengshi'
  },
  { code: '7508', value: '数据治理工程师', pinyin: 'shujuzhiligongchengshi'
  },
  { code: '7509', value: '其他', pinyin: 'qita'
  },
  { code: '7510', value: '电子数据取证分析师', pinyin: 'dianzishujuquzhengfenxishi'
  },
  { code: '7511', value: '密码技术应用员', pinyin: 'mimajishuyingyongyuan'
  },
  { code: '7512', value: '数据标注师', pinyin: 'shujubiaozhushi'
  },
  { code: '7700', value: '移动开发', pinyin: 'yidongkaifa'
  },
  { code: '7701', value: 'Android开发工程师', pinyin: 'Androidkaifagongchengshi'
  },
  { code: '7702', value: 'iOS开发工程师', pinyin: 'iOSkaifagongchengshi'
  },
  { code: '7703', value: '移动开发工程师', pinyin: 'yidongkaifagongchengshi'
  },
  { code: '7704', value: '其他', pinyin: 'qita'
  },
  { code: '7705', value: '小程序开发工程师', pinyin: 'xiaochengxukaifagongchengshi'
  },
  { code: '7800', value: '游戏', pinyin: 'youxi'
  },
  { code: '7801', value: '游戏策划师', pinyin: 'youxicehuashi'
  },
  { code: '7802', value: '游戏系统策划', pinyin: 'youxixitongcehua'
  },
  { code: '7803', value: '游戏数值策划', pinyin: 'youxishuzhicehua'
  },
  { code: '7804', value: '游戏关卡策划', pinyin: 'youxiguankacehua'
  },
  { code: '7805', value: '游戏文案策划/剧情策划', pinyin: 'youxiwenancehua/juqingcehua'
  },
  { code: '7806', value: '游戏界面设计师', pinyin: 'youxijiemianshejishi'
  },
  { code: '7807', value: '游戏原画师', pinyin: 'youxiyuanhuashi'
  },
  { code: '7808', value: '游戏动画师', pinyin: 'youxidonghuashi'
  },
  { code: '7809', value: '游戏开发工程师', pinyin: 'youxikaifagongchengshi'
  },
  { code: '7810', value: 'Cocos2d-x开发工程师', pinyin: 'Cocos2d-xkaifagongchengshi'
  },
  { code: '7811', value: 'Unity3d开发工程师', pinyin: 'Unity3dkaifagongchengshi'
  },
  { code: '7812', value: '游戏客户端开发工程师', pinyin: 'youxikehuduankaifagongchengshi'
  },
  { code: '7813', value: '游戏服务端开发工程师', pinyin: 'youxifuwuduankaifagongchengshi'
  },
  { code: '7814', value: '游戏运营', pinyin: 'youxiyunying'
  },
  { code: '7815', value: '电子竞技运营', pinyin: 'dianzijingjiyunying'
  },
  { code: '7816', value: '其他', pinyin: 'qita'
  },
  { code: '7817', value: '游戏特效设计师', pinyin: 'youxitexiaoshejishi'
  },
  { code: '7818', value: '游戏动作设计师', pinyin: 'youxidongzuoshejishi'
  },
  { code: '7819', value: '游戏场景设计师', pinyin: 'youxichangjingshejishi'
  },
  { code: '7820', value: '游戏角色设计师', pinyin: 'youxijiaoseshejishi'
  },
  { code: '7821', value: '游戏测试', pinyin: 'youxiceshi'
  },
  { code: '7822', value: 'UE4特效师', pinyin: 'UE4texiaoshi'
  },
  { code: '7823', value: 'UE4开发工程师', pinyin: 'UE4kaifagongchengshi'
  },
  { code: '7900', value: '运维/技术支持', pinyin: 'yunwei/jishuzhichi'
  },
  { code: '7901', value: '运维工程师', pinyin: 'yunweigongchengshi'
  },
  { code: '7902', value: '系统工程师', pinyin: 'xitonggongchengshi'
  },
  { code: '7903', value: '数据库工程师(DBA)', pinyin: 'shujukugongchengshi(DBA)'
  },
  { code: '7904', value: '系统集成工程师', pinyin: 'xitongjichenggongchengshi'
  },
  { code: '7905', value: 'ERP实施顾问', pinyin: 'ERPshishiguwen'
  },
  { code: '7906', value: '网络安全工程师', pinyin: 'wangluoanquangongchengshi'
  },
  { code: '7907', value: '网站维护工程师', pinyin: 'wangzhanweihugongchengshi'
  },
  { code: '7908', value: '技术支持/维护经理', pinyin: 'jishuzhichi/weihujingli'
  },
  { code: '7909', value: '技术支持/维护工程师', pinyin: 'jishuzhichi/weihugongchengshi'
  },
  { code: '7910', value: '配置管理工程师', pinyin: 'peizhiguanligongchengshi'
  },
  { code: '7912', value: 'IT经理/IT主管', pinyin: 'ITjingli/ITzhuguan'
  },
  { code: '7913', value: '网络工程师(IT工程师)', pinyin: 'wangluogongchengshi(ITgongchengshi)'
  },
  { code: '7914', value: '网络管理(Helpdesk)', pinyin: 'wangluoguanli(Helpdesk)'
  },
  { code: '7915', value: '运维开发', pinyin: 'yunweikaifa'
  },
  { code: '7916', value: '网络维修', pinyin: 'wangluoweixiu'
  },
  { code: '7917', value: '手机维修', pinyin: 'shoujiweixiu'
  },
  { code: '7918', value: '电脑维修', pinyin: 'diannaoweixiu'
  },
  { code: '7919', value: '其他', pinyin: 'qita'
  },
  { code: '7920', value: '自动化运维工程师', pinyin: 'zidonghuayunweigongchengshi'
  },
  { code: '8000', value: '运营', pinyin: 'yunying'
  },
  { code: '8003', value: '网站运营经理/主管', pinyin: 'wangzhanyunyingjingli/zhuguan'
  },
  { code: '8006', value: '网站策划', pinyin: 'wangzhancehua'
  },
  { code: '8007', value: '网站编辑', pinyin: 'wangzhanbianji'
  },
  { code: '8010', value: '新媒体运营', pinyin: 'xinmeitiyunying'
  },
  { code: '8011', value: '其他', pinyin: 'qita'
  },
  { code: '8016', value: '网站运营专员', pinyin: 'wangzhanyunyingzhuanyuan'
  },
  { code: '8024', value: 'SEO/SEM', pinyin: 'SEO/SEM'
  },
  { code: '8030', value: '网站运营总监', pinyin: 'wangzhanyunyingzongjian'
  },
  { code: '8032', value: '网络推广总监', pinyin: 'wangluotuiguangzongjian'
  },
  { code: '8033', value: '网络推广经理/主管', pinyin: 'wangluotuiguangjingli/zhuguan'
  },
  { code: '8034', value: '网络推广专员', pinyin: 'wangluotuiguangzhuanyuan'
  },
  { code: '8041', value: '用户运营', pinyin: 'yonghuyunying'
  },
  { code: '8042', value: '活动运营', pinyin: 'huodongyunying'
  },
  { code: '8043', value: '内容运营', pinyin: 'neirongyunying'
  },
  { code: '8044', value: '数据运营', pinyin: 'shujuyunying'
  },
  { code: '8045', value: '线下运营', pinyin: 'xianxiayunying'
  },
  { code: '8046', value: '信息流优化师', pinyin: 'xinxiliuyouhuashi'
  },
  { code: '8047', value: '运营专员', pinyin: 'yunyingzhuanyuan'
  },
  { code: '8048', value: '运营主管', pinyin: 'yunyingzhuguan'
  },
  { code: '8049', value: '运营经理', pinyin: 'yunyingjingli'
  },
  { code: '8050', value: '运营总监', pinyin: 'yunyingzongjian'
  },
  { code: '8051', value: '品类运营', pinyin: 'pinleiyunying'
  },
  { code: '8052', value: '内容审核', pinyin: 'neirongshenhe'
  },
  { code: '8053', value: '运营助理', pinyin: 'yunyingzhuli'
  },
  { code: '8054', value: '微博运营', pinyin: 'weiboyunying'
  },
  { code: '8055', value: '微信运营', pinyin: 'weixinyunying'
  },
  { code: '8057', value: '产品运营', pinyin: 'chanpinyunying'
  },
  { code: '8058', value: '社区/社群运营', pinyin: 'shequ/shequnyunying'
  },
  { code: '8059', value: '直播运营', pinyin: 'zhiboyunying'
  },
  { code: '8100', value: '教育咨询', pinyin: 'jiaoyuzixun'
  },
  { code: '8101', value: '课程顾问', pinyin: 'kechengguwen'
  },
  { code: '8102', value: '招生老师', pinyin: 'zhaoshenglaoshi'
  },
  { code: '8103', value: '学习规划师', pinyin: 'xuexiguihuashi'
  },
  { code: '8104', value: '留学顾问', pinyin: 'liuxueguwen'
  },
  { code: '8105', value: '其他', pinyin: 'qita'
  },
  { code: '8200', value: '教育管理', pinyin: 'jiaoyuguanli'
  },
  { code: '8201', value: '校长', pinyin: 'xiaochang'
  },
  { code: '8202', value: '班主任/辅导员', pinyin: 'banzhuren/fudaoyuan'
  },
  { code: '8203', value: '院校教务管理人员', pinyin: 'yuanxiaojiaowuguanlirenyuan'
  },
  { code: '8204', value: '园长', pinyin: 'yuanchang'
  },
  { code: '8205', value: '教研组长/主管', pinyin: 'jiaoyanzuchang/zhuguan'
  },
  { code: '8206', value: '教研员', pinyin: 'jiaoyanyuan'
  },
  { code: '8207', value: '教师培训/师训', pinyin: 'jiaoshipeixun/shixun'
  },
  { code: '8208', value: '其他', pinyin: 'qita'
  }
];