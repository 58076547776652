<!--
 * @Author: liushuang
 * @Date: 2023-02-10 18:01:59
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\components\search\indust.vue
-->
<template>
  <a-tree-select
    v-model:value="industSel"
    treeNodeFilterProp="title"
    style="width: 100%"
    tree-checkable
    placeholder="请选择"
    show-search
    labelInValue
    :filterTreeNode="filterTreeNode"
    :dropdownMatchSelectWidth=false
    :showArrow="true"
    :treeData="treeData"
    @change="searchBtn"
    @click="setNodeBtn"
    @search="searchValue"
  >
    <template #title="{ title }">
      <template v-if="title.toLowerCase().indexOf(selVal.toLowerCase()) > -1 && selVal.length > 0">
        <span v-html="validater.strIndexOf(selVal, title)"></span>
      </template>
      <span v-else>{{ title }}</span>
    </template>
    <template #suffixIcon><svg t="1675939229923" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2927" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="12"><path d="M761.923087 702.208894c2.19403 1.535821 4.241792 3.217911 6.143285 5.192539l243.391091 243.317956A43.149262 43.149262 0 0 1 981.033577 1024a42.78359 42.78359 0 0 1-30.350752-12.505973L707.364868 768.029802a41.686575 41.686575 0 0 1-5.119404-6.143285 429.664261 429.664261 0 1 1 59.677623-59.750757z m-332.176182 71.305984a343.731408 343.731408 0 1 0 0-687.462817 343.731408 343.731408 0 0 0 0 687.462817z" fill="#A2A5B4" p-id="2928"></path></svg></template>
  </a-tree-select>
</template>
<script>
import { defineComponent, reactive, toRefs, onMounted,ref, nextTick } from 'vue';
import { sortBy } from 'lodash-es';
import { validater } from "@/utils/validater.js"
import $ from 'jquery'
import {
  industrySelectAllApi
} from '@/api/comm'
import { message } from 'ant-design-vue';

export default defineComponent({
  props: {
    oldIndstry: Array,
    selecNum: Number,
  },
  emits: ['searchIndustTypehandle'],
  setup(props, {emit}) {
    const data = reactive({
      treeData: [],
      selVal: ''
    });
    const industSel= ref([])
    onMounted(() => {
      if(props.oldIndstry != undefined && props.oldIndstry.length > 0 && props.oldIndstry[0] != '') {
        industSel.value = props.oldIndstry;
      }
      getList()
    })

    // industSel.value = props.oldIndstry
    //行业列表
    const getList = ()=>{
      industrySelectAllApi().then(res=>{
        if(res.success){
          setTreeNode(sortBy([...res.data],[function(o) { return o.id; }]))
        } else {
          message.warning(res.message)
        }
      })
    }
    //设置城市数据为树状结构
    const setTreeNode = (arr) => {
      var result = [];   
			const itemMap = {};   
			for (var item of arr) {
        itemMap[item.id] = { ...item, children: [] ,
          title: item.name,
          value: item.id,
          checkable: item.hasNextLevel ? false : true
        }
        const id = item.id;
        const parentId = item.parentId;
        var treeItem = itemMap[id];
        treeItem = itemMap[id]
        if (parentId == 0) {
          result.push(treeItem);
          result = sortBy([...result],[function(o) { return o.sort; }]);
        } else {
          itemMap[parentId].children.push(treeItem)
          itemMap[parentId].children = sortBy([...itemMap[parentId].children],[function(o) { return o.sort; }]);
        }
			}
			data.treeData = result;
      getAllIds(data.treeData, industSel.value)
    }
    //设置按钮
    const setNodeBtn = () => {
      nextTick(() => {
        $(".btn-tree-button").remove()
        if ($(".btn-tree-button").length == 0) {
          var div = $("<div class='btn-tree-button'><a type='primary' class='mainBtn' @click='handleButtonClick'>确 定</a></div>")
          $(".ant-select-tree-list").append(div);
        }
        $(".ant-select-tree-list").on("click", ".mainBtn", () => {
          $(".ant-select-selection-search-input").blur();
          data.selVal = '';
        })
      })
    }
    //输入的搜索内容
    const searchValue = (value) => {
      data.selVal = value;
      setNodeBtn();
    }
    //递归遍历数组
    const getAllIds = (tree,value)=>{
      for (const i in tree) {
        tree[i].disabled = value.length ?!(value.findIndex(info =>  tree[i].value == info.value) > -1):false
        if (tree[i].children) {
          getAllIds(tree[i].children,value);
        }
      }
    }
    //选中值发生改变
    const searchBtn = (value) => {
      if( value.length <= props.selecNum ){
        emit('searchIndustTypehandle', value)
        getAllIds(data.treeData,[])
      }
      if(value.length == props.selecNum){
        getAllIds(data.treeData,value)
      }
    }
   return {
      ...toRefs(data),
      getList,
      searchBtn,
      industSel,
      setNodeBtn,
      validater,
      searchValue
    };
  },
  
})
</script>
<style scoped>
::v-deep .ant-select-tree .ant-select-tree-treenode {
  margin-right: 12px;
}
</style>
