// 录入职位/客户/简历 最大/最小化功能
import { useRoute} from 'vue-router';
import { getCurrentInstance } from 'vue';
import { cloneDeep,isEqual} from 'lodash-es'
export default function getAdd(data) {
  const route = useRoute();
  const $bus = getCurrentInstance().appContext.config.globalProperties.$bus
  var isMax= window.location.pathname ==`/${data.path}`
  var newData = isMax?route.query:data.addPar
  var key;
  if(newData.id && newData.id != undefined && newData.id != 'undefined'){
    key = newData.id
    data.title = `编辑${data.title}`
  }else{
    key = newData.newID
    data.title = `录入${data.title}`
  }
  //展开页和从最小化过来从缓存拿数据 并且拷贝原始对象后期最大化/最小化操作时对比数据是否发生变化在存本地
  let list = localStorage.getItem(data.path)?JSON.parse(localStorage.getItem(data.path)):[]
  var index = list.findIndex(item =>{ return item[key]})
  var oldData ={}
  if(!isMax){
    // todo
    data.addPar.fromMin && index > -1?data.ruleForm = list[index][key]:''
    oldData = cloneDeep(data.ruleForm)
  }else{
    index > -1?data.ruleForm = list[index][key]:''
    data.isAdd = 1
  }
  

  //弹窗最小化操作
	const minOperation = ()=>{
    if(!isEqual(oldData,data.ruleForm)){//数据发生变化时 更新本地缓存
      if(index >-1){ list.splice(index,1)}
      list.push({[`${key}`]:data.ruleForm})
      localStorage.setItem(data.path,JSON.stringify(list))
    }
    
    // 最小化数组
    let obj = {
      title:data.title,
      id:data.addPar.id,
      path:data.path,
      newID:data.addPar.newID,
    }
    $bus.emit('addMin',obj)
    
    closeMoadl()
  };
  //弹窗最大化操作
  const maxOperation = ()=>{
    if(!isEqual(oldData,data.ruleForm)){//数据发生变化时 更新本地缓存
      if(index >-1){ list.splice(index,1)}
      list.push({[`${key}`]:data.ruleForm})
      localStorage.setItem(data.path,JSON.stringify(list))
    }
    closeMoadl()
    let path;
    if(data.addPar.customerId){
      path = `${data.path}?customerId=${data.addPar.customerId}`
    }else if(data.addPar.id){
      path = `${data.path}?id=${data.addPar.id}`
    }else{
      path = `${data.path}?newID=${data.addPar.newID}`
    }
    // const url = router.resolve({ path:path });
    // 打开新窗口
    window.open(path);
  };
  //关闭弹窗
  const closeMoadl=()=>{
    $bus.emit("changeAdd",{key:data.path,val:false});  // 发布事件
  }
  //关闭当前窗口
  const closeMax=()=>{
    window.opener=null;
    window.open('','_self');
    window.close();
  }
  
  return {
    minOperation,
    maxOperation,
    closeMoadl,
    isMax,
    newData,
    closeMax,
  }
}