/*
 * @Author: liushuang
 * @Date: 2023-03-13 17:06:04
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\api\position.js
 */
import { get, post } from "../utils/httprequest"; // 导入模块
//职位列表获取根据状态统计项目数
export const listStatusTotalApi = () => get("/project/list-status-total");
//职位-列表
export const projectListApi = (params) => post("/project/list", params);
//职位-详情
export const projectDetailApi = (projectId) => get("/project/" + projectId);
//职位-详情头部信息
export const conciseInfoApi = (projectId) => get("/project/concise-info/" + projectId);
//职位-录入
export const projectSaveApi = (params) => post("/project/save", params);
//职位-更新职位（单项）
export const projectUpdataeItemApi = (params) => post("/project/updateItem", params);
//职位-更新职位（整体）
export const projectUpdataeApi = (params) => post("/project/update", params);
//修改职位状态
export const projectUpdataStatusApi = (params) => post("/project/updateStatus", params);
//职位附件-列表
export const attachmentListApi = (projectId) => get("/project/attachment/list/" + projectId);
//职位附件-新增
export const projectAttachmentSaveApi = (projectId, params) => post("/project/attachment/save/" + projectId, params);
//职位附件-删除
export const projectAttachmentDelApi = (attachmentId) => post("/project/attachment/delete/" + attachmentId);

//备注-列表
export const projectRemarkListApi = (params) => post("/project/remark/list", params);
//备注-编辑
export const projectRemarkUpdateApi = (params) => post("/project/remark/updateRemark",params);
//备注-保存
export const projectRemarkSaveApi = (params) => post("/project/remark/save", params);
//备注-删除
export const projectRemarkDelApi = (params) => post("/project/remark/deleteRemark" ,params);
//备注-详情
export const projectRemarkDetailApi = (remarkId) => get("/project/remark/detail/" + remarkId);

//候选人列表-详情
export const projectProcessListApi = (params) => post("/process/projectProcessList", params);

//获取职位列表 已审核通过并且项目状态为进展中的职位
export const projectBoxListApi = (params) => get("/project/box/list", params);

//用户下拉框-职位-参与者
export const userProjectApi = (params) => get("/user/dropdown-list/project", params);


//职位详情-候选人-统计
export const projectProCountApi = (params) => post("/process/projectProcessListCount", params);
export const gaodeApi = (params) => get("/project/getCoordinates", params);

//职位-当前用户所属集团所拥有的猎头公司下拉框
export const huntCompanyDropdownListApi = () => get("/hunterCompany/dropdown-list");
