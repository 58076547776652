export const ZHIPINJOBTITLE = [
  {
    "code": 310000,
    "name": "高级管理",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 150400,
        "name": "高级管理职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150407,
            "name": "总裁/总经理/CEO",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150408,
            "name": "副总裁/副总经理/VP",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150409,
            "name": "分公司/代表处负责人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150410,
            "name": "区域负责人(辖多个分公司)",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150411,
            "name": "总助/CEO助理/董事长助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150412,
            "name": "合伙人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150413,
            "name": "联合创始人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150414,
            "name": "董事会秘书",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 100000,
    "name": "技术",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 100100,
        "name": "后端开发",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100101,
            "name": "Java",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100102,
            "name": "C++",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100103,
            "name": "PHP",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100105,
            "name": "C",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100106,
            "name": "C#",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100107,
            "name": ".NET",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100108,
            "name": "Hadoop",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100109,
            "name": "Python",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100110,
            "name": "Delphi",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100111,
            "name": "VB",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100112,
            "name": "Perl",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100113,
            "name": "Ruby",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100114,
            "name": "Node.js",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100116,
            "name": "Golang",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100119,
            "name": "Erlang",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100121,
            "name": "语音/视频/图形开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100122,
            "name": "数据采集",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100123,
            "name": "全栈工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100124,
            "name": "GIS工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100200,
        "name": "移动开发",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100201,
            "name": "HTML5",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100202,
            "name": "Android",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100203,
            "name": "iOS",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100205,
            "name": "移动web前端",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100206,
            "name": "Flash开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100208,
            "name": "JavaScript",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100209,
            "name": "U3D",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100210,
            "name": "COCOS2DX",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100211,
            "name": "UE4",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100300,
        "name": "测试",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100301,
            "name": "测试工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100302,
            "name": "自动化测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100303,
            "name": "功能测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100304,
            "name": "性能测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100305,
            "name": "测试开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100306,
            "name": "移动端测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100307,
            "name": "游戏测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100308,
            "name": "硬件测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100309,
            "name": "软件测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100310,
            "name": "渗透测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100400,
        "name": "运维/技术支持",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100401,
            "name": "运维工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100402,
            "name": "运维开发工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100403,
            "name": "网络工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100404,
            "name": "系统工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100405,
            "name": "IT技术支持",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100406,
            "name": "系统管理员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100407,
            "name": "网络安全",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100408,
            "name": "系统安全",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100409,
            "name": "DBA",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100500,
        "name": "数据",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100506,
            "name": "ETL工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100507,
            "name": "数据仓库",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100508,
            "name": "数据开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100509,
            "name": "数据挖掘",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100511,
            "name": "数据分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100512,
            "name": "数据架构师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100600,
        "name": "项目管理",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100601,
            "name": "项目经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100603,
            "name": "项目助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100604,
            "name": "项目专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100605,
            "name": "实施顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100606,
            "name": "实施工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100607,
            "name": "需求分析工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100817,
            "name": "硬件项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100800,
        "name": "硬件开发",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100801,
            "name": "硬件工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100802,
            "name": "嵌入式",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100803,
            "name": "自动化",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100804,
            "name": "单片机",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100805,
            "name": "电路设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100806,
            "name": "驱动开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100807,
            "name": "系统集成",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100808,
            "name": "FPGA开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100809,
            "name": "DSP开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100810,
            "name": "ARM开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100811,
            "name": "PCB工艺",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100816,
            "name": "射频工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100900,
        "name": "前端开发",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100901,
            "name": "web前端",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100902,
            "name": "JavaScript",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100903,
            "name": "Flash开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100904,
            "name": "HTML5",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 101000,
        "name": "通信",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 101001,
            "name": "通信技术工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101002,
            "name": "通信研发工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101003,
            "name": "数据通信工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101004,
            "name": "移动通信工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101005,
            "name": "电信网络工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101006,
            "name": "电信交换工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101007,
            "name": "有线传输工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101008,
            "name": "无线/射频通信工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101009,
            "name": "通信电源工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101010,
            "name": "通信标准化工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101011,
            "name": "通信项目专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101012,
            "name": "通信项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101013,
            "name": "核心网工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101014,
            "name": "通信测试工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101015,
            "name": "通信设备工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101016,
            "name": "光通信工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101017,
            "name": "光传输工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101018,
            "name": "光网络工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 101400,
        "name": "电子/半导体",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 101402,
            "name": "电气工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101404,
            "name": "电气设计工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101401,
            "name": "电子工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101405,
            "name": "集成电路IC设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101403,
            "name": "FAE",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101406,
            "name": "IC验证工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 100700,
        "name": "高端技术职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100701,
            "name": "技术经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100702,
            "name": "技术总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100703,
            "name": "测试经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100704,
            "name": "架构师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100705,
            "name": "CTO",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100706,
            "name": "运维总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100707,
            "name": "技术合伙人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 101300,
        "name": "人工智能",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100104,
            "name": "数据挖掘",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100115,
            "name": "搜索算法",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100117,
            "name": "自然语言处理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100118,
            "name": "推荐算法",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100120,
            "name": "算法工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101308,
            "name": "智能驾驶系统工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101309,
            "name": "反欺诈/风控算法",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101301,
            "name": "机器学习",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101302,
            "name": "深度学习",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101305,
            "name": "语音识别",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101306,
            "name": "图像识别",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101307,
            "name": "算法研究员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 101200,
        "name": "销售技术支持",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 101201,
            "name": "售前技术支持",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 101202,
            "name": "售后技术支持",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 101100,
        "name": "其他技术职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 101101,
            "name": "其他技术职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 110000,
    "name": "产品",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 110100,
        "name": "产品经理",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 110101,
            "name": "产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110102,
            "name": "网页产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110103,
            "name": "移动产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110104,
            "name": "产品助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110105,
            "name": "数据产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110106,
            "name": "电商产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110107,
            "name": "游戏策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110108,
            "name": "产品专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110109,
            "name": "硬件产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 110300,
        "name": "高端产品职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 110302,
            "name": "产品总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110303,
            "name": "游戏制作人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 110304,
            "name": "产品VP",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 110400,
        "name": "其他产品职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 110401,
            "name": "其他产品职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 120000,
    "name": "设计",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 120100,
        "name": "视觉/交互设计",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 120101,
            "name": "视觉设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120102,
            "name": "网页设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120103,
            "name": "Flash设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120104,
            "name": "APP设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120105,
            "name": "UI设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120106,
            "name": "平面设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120107,
            "name": "3D设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120108,
            "name": "广告设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120109,
            "name": "多媒体设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120110,
            "name": "原画师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120116,
            "name": "CAD设计/制图",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120117,
            "name": "美工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120118,
            "name": "包装设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120119,
            "name": "设计师助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120120,
            "name": "动画设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120121,
            "name": "插画师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120122,
            "name": "漫画师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120123,
            "name": "人像修图师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120201,
            "name": "交互设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 120200,
        "name": "游戏设计",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 120111,
            "name": "游戏特效",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120112,
            "name": "游戏界面设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120113,
            "name": "游戏场景",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120114,
            "name": "游戏角色",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120115,
            "name": "游戏动作",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120303,
            "name": "游戏数值策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 120300,
        "name": "用户研究",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 120301,
            "name": "数据分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120302,
            "name": "用户研究员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120304,
            "name": "UX设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120407,
            "name": "用户研究经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120408,
            "name": "用户研究总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 120400,
        "name": "高端设计职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 120401,
            "name": "设计经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120402,
            "name": "设计总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120404,
            "name": "视觉设计总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 120600,
        "name": "非视觉设计",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 120611,
            "name": "展览/展示设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120612,
            "name": "照明设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120601,
            "name": "服装/纺织设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120602,
            "name": "工业设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120603,
            "name": "橱柜设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120604,
            "name": "家具设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120605,
            "name": "家居设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120606,
            "name": "珠宝设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120607,
            "name": "室内设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 120608,
            "name": "陈列设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 120500,
        "name": "其他设计职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 120501,
            "name": "其他设计职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 130000,
    "name": "运营",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 130100,
        "name": "运营",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 130101,
            "name": "用户运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130102,
            "name": "产品运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130103,
            "name": "数据运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130104,
            "name": "内容运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130105,
            "name": "活动运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130106,
            "name": "商家运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130107,
            "name": "品类运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130108,
            "name": "游戏运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130110,
            "name": "网站运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130111,
            "name": "新媒体运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130112,
            "name": "社区运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130113,
            "name": "微信运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130114,
            "name": "微博运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130115,
            "name": "策略运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130116,
            "name": "线下拓展运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130117,
            "name": "电商运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130118,
            "name": "运营助理/专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130120,
            "name": "内容审核",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130121,
            "name": "数据标注",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130122,
            "name": "直播运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130123,
            "name": "车辆运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130124,
            "name": "跨境电商运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130125,
            "name": "网店店长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 130200,
        "name": "编辑",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 130201,
            "name": "副主编",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130202,
            "name": "内容编辑",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130203,
            "name": "文案策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130204,
            "name": "网站编辑",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130206,
            "name": "采编",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210101,
            "name": "医学编辑",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 130300,
        "name": "客服",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 130301,
            "name": "售前客服",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130302,
            "name": "售后客服",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130303,
            "name": "网络客服",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130304,
            "name": "客服经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130305,
            "name": "客服专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130306,
            "name": "客服主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130308,
            "name": "电话客服",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130309,
            "name": "咨询热线/呼叫中心客服",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 130400,
        "name": "高端运营职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 130401,
            "name": "主编",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130402,
            "name": "运营总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130403,
            "name": "COO",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130404,
            "name": "客服总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130405,
            "name": "运营经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 130500,
        "name": "其他运营职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 130501,
            "name": "其他运营职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 140000,
    "name": "市场",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 140800,
        "name": "政府事务",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140112,
            "name": "政府关系",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140801,
            "name": "政策研究",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140802,
            "name": "企业党建",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 140100,
        "name": "市场/营销",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 130109,
            "name": "网络推广",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140101,
            "name": "市场营销",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140102,
            "name": "市场策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140103,
            "name": "市场顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140104,
            "name": "市场推广",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140105,
            "name": "SEO",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140106,
            "name": "SEM",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140107,
            "name": "商务渠道",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140108,
            "name": "商业数据分析",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140109,
            "name": "活动策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140110,
            "name": "网络营销",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140111,
            "name": "海外市场",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140113,
            "name": "APP推广",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140114,
            "name": "选址开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140115,
            "name": "游戏推广",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140315,
            "name": "营销主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 140200,
        "name": "公关媒介",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140201,
            "name": "媒介经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140202,
            "name": "广告协调",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140203,
            "name": "品牌公关",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140204,
            "name": "媒介专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140205,
            "name": "活动策划执行",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140206,
            "name": "媒介策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 140500,
        "name": "会务会展",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140502,
            "name": "会议活动策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140503,
            "name": "会议活动执行",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140505,
            "name": "会展活动策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140506,
            "name": "会展活动执行",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 140600,
        "name": "广告",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140612,
            "name": "广告/会展项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140601,
            "name": "广告创意设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140602,
            "name": "美术指导",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140603,
            "name": "广告设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140604,
            "name": "策划经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140605,
            "name": "广告文案",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140607,
            "name": "广告制作",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140608,
            "name": "媒介投放",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140609,
            "name": "媒介合作",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140611,
            "name": "广告审核",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 140400,
        "name": "高端市场职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140401,
            "name": "市场总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140404,
            "name": "CMO",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140405,
            "name": "公关总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140406,
            "name": "媒介总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140407,
            "name": "创意总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 140700,
        "name": "其他市场职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140701,
            "name": "其他市场职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 150000,
    "name": "人事/财务/行政",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 150100,
        "name": "人力资源",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150102,
            "name": "招聘",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150103,
            "name": "HRBP",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150104,
            "name": "人力资源专员/助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150105,
            "name": "培训",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150106,
            "name": "薪资福利",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150107,
            "name": "绩效考核",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150403,
            "name": "人力资源经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150406,
            "name": "人力资源VP/CHO",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150108,
            "name": "人力资源总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150109,
            "name": "员工关系",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150110,
            "name": "组织发展",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 150200,
        "name": "行政",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150201,
            "name": "行政专员/助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150202,
            "name": "前台",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150205,
            "name": "经理助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150207,
            "name": "后勤",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150401,
            "name": "行政经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150209,
            "name": "行政总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 150300,
        "name": "财务",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150301,
            "name": "会计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150302,
            "name": "出纳",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150303,
            "name": "财务顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150304,
            "name": "结算",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150305,
            "name": "税务",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150306,
            "name": "审计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150307,
            "name": "风控",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150310,
            "name": "成本",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150311,
            "name": "总账会计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150402,
            "name": "财务经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150404,
            "name": "CFO",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150308,
            "name": "财务总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 150500,
        "name": "法务",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150203,
            "name": "法务专员/助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150502,
            "name": "律师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150504,
            "name": "法律顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150506,
            "name": "法务经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 150507,
            "name": "法务总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 150600,
        "name": "其他职能职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150601,
            "name": "其他职能职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 160000,
    "name": "销售",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 160300,
        "name": "销售行政/商务",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 160301,
            "name": "商务专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 160302,
            "name": "商务经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140309,
            "name": "销售助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140403,
            "name": "商务总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 130119,
            "name": "销售运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160400,
        "name": "房地产销售/招商",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 160401,
            "name": "置业顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 160403,
            "name": "地产中介",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220403,
            "name": "物业招商管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220505,
            "name": "房地产销售总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160500,
        "name": "服务业销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 160501,
            "name": "服装销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210406,
            "name": "彩妆顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210414,
            "name": "美容顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210610,
            "name": "会籍顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290312,
            "name": "珠宝销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 280103,
            "name": "旅游顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210602,
            "name": "瘦身顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160600,
        "name": "汽车销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 230201,
            "name": "汽车销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230202,
            "name": "汽车配件销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160700,
        "name": "广告/会展销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140313,
            "name": "广告销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140501,
            "name": "会议活动销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140504,
            "name": "会展活动销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140610,
            "name": "媒介顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160800,
        "name": "金融销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180801,
            "name": "证券经纪人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180401,
            "name": "信用卡销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180701,
            "name": "保险顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180506,
            "name": "理财顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160900,
        "name": "外贸销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 250201,
            "name": "外贸经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 250203,
            "name": "外贸业务员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 140300,
        "name": "销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140301,
            "name": "销售专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140303,
            "name": "客户代表",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140304,
            "name": "大客户代表",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140305,
            "name": "BD经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140307,
            "name": "渠道销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140308,
            "name": "代理商销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140310,
            "name": "电话销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140311,
            "name": "销售顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140314,
            "name": "网络销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140316,
            "name": "销售工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140317,
            "name": "客户经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 190600,
        "name": "课程销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190601,
            "name": "课程顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190602,
            "name": "招生顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190603,
            "name": "留学顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210500,
        "name": "医疗销售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210506,
            "name": "医疗器械销售",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210502,
            "name": "医药代表",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210504,
            "name": "健康顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210505,
            "name": "医美咨询",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160100,
        "name": "销售管理",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140302,
            "name": "销售经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 140402,
            "name": "销售总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 160101,
            "name": "区域总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 160102,
            "name": "城市经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 160103,
            "name": "销售VP",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 160104,
            "name": "团队经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 160200,
        "name": "其他销售职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 160201,
            "name": "其他销售职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 170000,
    "name": "传媒",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 170100,
        "name": "采编/写作/出版",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 170109,
            "name": "排版设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170101,
            "name": "记者",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170102,
            "name": "编辑",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170103,
            "name": "采编",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170104,
            "name": "撰稿人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170105,
            "name": "出版发行",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170106,
            "name": "校对录入",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170107,
            "name": "总编",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170108,
            "name": "自媒体",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 170300,
        "name": "公关媒介",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 170301,
            "name": "媒介经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170302,
            "name": "媒介专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170303,
            "name": "广告协调",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170304,
            "name": "品牌公关",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170305,
            "name": "活动策划执行",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170306,
            "name": "媒介策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 170200,
        "name": "广告",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 170212,
            "name": "广告/会展项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170201,
            "name": "广告创意设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170202,
            "name": "美术指导",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170203,
            "name": "广告设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170204,
            "name": "策划经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170205,
            "name": "广告文案",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170207,
            "name": "广告制作",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170208,
            "name": "媒介投放",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170209,
            "name": "媒介合作",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170211,
            "name": "广告审核",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 170600,
        "name": "影视媒体",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 170625,
            "name": "带货主播",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170617,
            "name": "艺人助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170620,
            "name": "主持人/DJ",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170621,
            "name": "主播助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170622,
            "name": "灯光师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170623,
            "name": "剪辑师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170624,
            "name": "影视特效",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170601,
            "name": "导演/编导",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170602,
            "name": "摄影/摄像",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170603,
            "name": "视频编辑",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170604,
            "name": "音频编辑",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170605,
            "name": "经纪人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170606,
            "name": "后期制作",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170608,
            "name": "影视发行",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170609,
            "name": "影视策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170610,
            "name": "主播",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170611,
            "name": "演员/配音/模特",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170612,
            "name": "化妆/造型/服装",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170613,
            "name": "放映员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170614,
            "name": "录音/音效",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170615,
            "name": "制片人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 170616,
            "name": "编剧",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 170500,
        "name": "其他传媒职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 170501,
            "name": "其他传媒职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 180000,
    "name": "金融",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 180100,
        "name": "投融资",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180101,
            "name": "投资经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180103,
            "name": "行业研究",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180104,
            "name": "资产管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180112,
            "name": "投资总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180113,
            "name": "投资VP",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180114,
            "name": "投资合伙人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180115,
            "name": "融资",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180116,
            "name": "并购",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180117,
            "name": "投后管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180118,
            "name": "投资助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180111,
            "name": "其他投融资职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180119,
            "name": "投资顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 180200,
        "name": "中后台",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180201,
            "name": "风控",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180202,
            "name": "法务",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180203,
            "name": "资信评估",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180204,
            "name": "合规稽查",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 180300,
        "name": "税务审计",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180301,
            "name": "审计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180302,
            "name": "法务",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180303,
            "name": "会计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180304,
            "name": "清算",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 180400,
        "name": "银行",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180102,
            "name": "分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180402,
            "name": "柜员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180403,
            "name": "商务渠道",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180404,
            "name": "大堂经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180405,
            "name": "客户经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180406,
            "name": "信贷管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180107,
            "name": "风控",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 180500,
        "name": "互联网金融",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180501,
            "name": "金融产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180502,
            "name": "风控",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180503,
            "name": "催收员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180504,
            "name": "分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180505,
            "name": "投资经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180109,
            "name": "审计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180110,
            "name": "清算",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 180700,
        "name": "保险",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180702,
            "name": "保险精算师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180703,
            "name": "保险理赔",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 180800,
        "name": "证券/基金/期货",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180803,
            "name": "买方分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180804,
            "name": "股票/期货操盘手",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180805,
            "name": "基金经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180806,
            "name": "投资银行业务",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180802,
            "name": "卖方分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 180106,
            "name": "证券交易员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 180600,
        "name": "其他金融职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 180601,
            "name": "其他金融职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 190000,
    "name": "教育培训",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 190100,
        "name": "教育产品研发",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190101,
            "name": "课程设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190102,
            "name": "课程编辑",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190104,
            "name": "培训研究",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190105,
            "name": "培训师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190107,
            "name": "培训策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190106,
            "name": "其他教育产品研发职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 190200,
        "name": "教育行政",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190205,
            "name": "园长/副园长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190201,
            "name": "校长/副校长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190202,
            "name": "教务管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190203,
            "name": "教学管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190204,
            "name": "班主任/辅导员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 190300,
        "name": "教师",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190301,
            "name": "教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190314,
            "name": "日语教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190315,
            "name": "其他外语教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190316,
            "name": "语文教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190317,
            "name": "数学教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190318,
            "name": "物理教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190319,
            "name": "化学教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190320,
            "name": "生物教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190321,
            "name": "家教",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190322,
            "name": "托管老师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190323,
            "name": "早教老师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190302,
            "name": "助教",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190303,
            "name": "高中教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190304,
            "name": "初中教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190305,
            "name": "小学教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190306,
            "name": "幼教",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190307,
            "name": "理科教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190308,
            "name": "文科教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190309,
            "name": "英语教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190310,
            "name": "音乐教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190311,
            "name": "美术教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190312,
            "name": "体育教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190313,
            "name": "就业老师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 190400,
        "name": "IT培训",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190401,
            "name": "JAVA培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190402,
            "name": "Android培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190403,
            "name": "iOS培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190404,
            "name": "PHP培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190405,
            "name": ".NET培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190406,
            "name": "C++培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190407,
            "name": "Unity 3D培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190408,
            "name": "Web前端培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190409,
            "name": "软件测试培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190410,
            "name": "动漫培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190411,
            "name": "UI设计培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 190500,
        "name": "职业培训",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190501,
            "name": "财会培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190502,
            "name": "HR培训讲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190503,
            "name": "培训师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190504,
            "name": "拓展培训",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 190700,
        "name": "特长培训",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190708,
            "name": "武术教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190709,
            "name": "轮滑教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190710,
            "name": "表演教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190711,
            "name": "机器人教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190712,
            "name": "书法教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190713,
            "name": "钢琴教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190714,
            "name": "吉他教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190715,
            "name": "古筝教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190716,
            "name": "播音主持教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190717,
            "name": "乐高教师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190701,
            "name": "舞蹈老师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190702,
            "name": "瑜伽老师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190704,
            "name": "游泳教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190705,
            "name": "健身教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190706,
            "name": "篮球/羽毛球教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 190707,
            "name": "跆拳道教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 190800,
        "name": "其他教育培训职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 190801,
            "name": "其他教育培训职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 210000,
    "name": "医疗健康",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 211000,
        "name": "临床试验",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210118,
            "name": "临床研究",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210119,
            "name": "临床协调",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210120,
            "name": "临床数据分析",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 211001,
            "name": "临床项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210501,
            "name": "医学总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210300,
        "name": "医生/医技",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210103,
            "name": "医生",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210308,
            "name": "幼儿园保健医",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210112,
            "name": "医生助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210113,
            "name": "医学影像/放射科医师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210114,
            "name": "B超医生",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210306,
            "name": "内科医生",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210307,
            "name": "全科医生",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210302,
            "name": "中医",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210303,
            "name": "心理医生",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210104,
            "name": "药剂师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210304,
            "name": "牙科医生",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210305,
            "name": "康复治疗师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210109,
            "name": "验光师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210111,
            "name": "检验科医师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210107,
            "name": "其他医生职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210200,
        "name": "护士/护理",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210202,
            "name": "护士长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210201,
            "name": "护士",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210503,
            "name": "导医",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210400,
        "name": "健康整形",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210401,
            "name": "营养师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210402,
            "name": "整形师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210403,
            "name": "理疗师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210404,
            "name": "针灸推拿",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210100,
        "name": "生物制药",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210115,
            "name": "生物制药",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210116,
            "name": "药品注册",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210117,
            "name": "药品生产",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210123,
            "name": "医药项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210108,
            "name": "医药研发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210900,
        "name": "医疗器械",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210121,
            "name": "医疗器械注册",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210122,
            "name": "医疗器械生产/质量管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210105,
            "name": "医疗器械研发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210800,
        "name": "药店",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210801,
            "name": "药店店长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210802,
            "name": "执业药师/驻店药师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210803,
            "name": "药店店员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210700,
        "name": "其他医疗健康职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210701,
            "name": "其他医疗健康职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 250000,
    "name": "采购/贸易",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 250100,
        "name": "采购",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 140312,
            "name": "商品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 250108,
            "name": "供应商质量工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 250101,
            "name": "采购总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 250102,
            "name": "采购经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 250103,
            "name": "采购专员/助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 250104,
            "name": "买手",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 250105,
            "name": "采购工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 250200,
        "name": "进出口贸易",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 250204,
            "name": "贸易跟单",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240114,
            "name": "报关/报检员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240117,
            "name": "单证员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 250300,
        "name": "其他采购/贸易职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 250301,
            "name": "其他采购/贸易类职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 240000,
    "name": "供应链/物流",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 240100,
        "name": "物流",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 240101,
            "name": "供应链专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240102,
            "name": "供应链经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240302,
            "name": "集装箱管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240103,
            "name": "物流专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240104,
            "name": "物流经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240105,
            "name": "物流运营",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240106,
            "name": "物流跟单",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240108,
            "name": "调度员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240109,
            "name": "物流/仓储项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240111,
            "name": "货运代理专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240112,
            "name": "货运代理经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240113,
            "name": "水/空/陆运操作",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240116,
            "name": "核销员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 240200,
        "name": "仓储",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 240201,
            "name": "仓库经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240204,
            "name": "仓库管理员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240205,
            "name": "仓库文员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240206,
            "name": "配/理/拣/发货",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 240300,
        "name": "交通/运输",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150208,
            "name": "商务司机",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240305,
            "name": "网约车司机",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240306,
            "name": "代驾司机",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240307,
            "name": "驾校教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240301,
            "name": "货运司机",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240303,
            "name": "配送员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240304,
            "name": "快递员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240110,
            "name": "运输经理/主管",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 240400,
        "name": "高端供应链职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 240401,
            "name": "供应链总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 240402,
            "name": "物流总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 240500,
        "name": "其他供应链职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 240501,
            "name": "其他供应链职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 220000,
    "name": "房地产/建筑",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 220100,
        "name": "房地产规划开发",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 220101,
            "name": "房地产策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220102,
            "name": "地产项目管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220103,
            "name": "地产招投标",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220302,
            "name": "房产评估师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 220200,
        "name": "设计装修与市政建设",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 220202,
            "name": "建筑工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220203,
            "name": "建筑设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220204,
            "name": "土木/土建/结构工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220205,
            "name": "室内设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220206,
            "name": "园林/景观设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220207,
            "name": "城市规划设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220208,
            "name": "工程监理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220209,
            "name": "工程造价",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220210,
            "name": "工程预算",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220211,
            "name": "资料员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220212,
            "name": "建筑施工现场管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220213,
            "name": "弱电工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220214,
            "name": "给排水工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220215,
            "name": "暖通工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220216,
            "name": "幕墙工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220217,
            "name": "软装设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220218,
            "name": "施工员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220219,
            "name": "测绘/测量",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220220,
            "name": "材料员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220221,
            "name": "BIM工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220222,
            "name": "装修项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220223,
            "name": "建筑机电工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220224,
            "name": "消防工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220225,
            "name": "施工安全员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 220400,
        "name": "物业管理",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 220401,
            "name": "物业经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220404,
            "name": "物业维修",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220405,
            "name": "绿化工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220406,
            "name": "物业管理员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 220500,
        "name": "高端房地产职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 220501,
            "name": "地产项目总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220502,
            "name": "地产策划总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 220503,
            "name": "地产招投标总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 220600,
        "name": "其他房地产职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 220601,
            "name": "其他房地产职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 400000,
    "name": "农/林/牧/渔",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 400100,
        "name": "农业/林业",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 400101,
            "name": "农业/林业技术员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 400200,
        "name": "畜牧/渔业",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 400201,
            "name": "饲养员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 400202,
            "name": "禽畜/水产养殖技术员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 400203,
            "name": "畜牧兽医",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 260000,
    "name": "咨询/翻译/法律",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 260100,
        "name": "咨询/调研",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 260111,
            "name": "知识产权/专利/商标代理人",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260112,
            "name": "心理咨询师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260113,
            "name": "婚恋咨询师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260101,
            "name": "企业管理咨询",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260401,
            "name": "咨询总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260102,
            "name": "数据分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260402,
            "name": "咨询经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260103,
            "name": "财务咨询顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260104,
            "name": "IT咨询顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260105,
            "name": "人力资源咨询顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260106,
            "name": "咨询项目管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260107,
            "name": "战略咨询",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260108,
            "name": "猎头顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260109,
            "name": "市场调研",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260110,
            "name": "其他咨询顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 260200,
        "name": "律师",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 150503,
            "name": "专利律师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260203,
            "name": "知识产权律师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260204,
            "name": "律师助理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260201,
            "name": "事务所律师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260202,
            "name": "法务",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 260300,
        "name": "翻译",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 260301,
            "name": "英语翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260302,
            "name": "日语翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260303,
            "name": "韩语/朝鲜语翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260304,
            "name": "法语翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260305,
            "name": "德语翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260306,
            "name": "俄语翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260307,
            "name": "西班牙语翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 260308,
            "name": "其他语种翻译",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 260500,
        "name": "其他咨询类职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 260501,
            "name": "其他咨询/翻译类职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 280000,
    "name": "旅游",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 280100,
        "name": "旅游服务",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 280101,
            "name": "计调",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 280102,
            "name": "签证专员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 280104,
            "name": "导游",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 280105,
            "name": "预定票务",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 280106,
            "name": "讲解员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 280200,
        "name": "旅游产品开发/策划",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 280201,
            "name": "旅游产品经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 280202,
            "name": "旅游策划师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 280300,
        "name": "其他旅游职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 280301,
            "name": "其他旅游职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 290000,
    "name": "服务业",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 290200,
        "name": "餐饮",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 290208,
            "name": "后厨",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290209,
            "name": "配菜打荷",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290210,
            "name": "茶艺师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290211,
            "name": "西点师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290212,
            "name": "餐饮学徒",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290213,
            "name": "面点师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290214,
            "name": "行政总厨",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290215,
            "name": "厨师长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290216,
            "name": "传菜员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290217,
            "name": "洗碗工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290218,
            "name": "凉菜厨师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290219,
            "name": "中餐厨师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290220,
            "name": "西餐厨师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290221,
            "name": "日料厨师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290222,
            "name": "烧烤师傅",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290201,
            "name": "收银",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290202,
            "name": "服务员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290203,
            "name": "厨师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290204,
            "name": "咖啡师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290205,
            "name": "送餐员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290206,
            "name": "餐饮店长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290207,
            "name": "领班",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 290100,
        "name": "酒店",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 290107,
            "name": "礼仪/迎宾/接待",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290115,
            "name": "前厅经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290116,
            "name": "客房经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290101,
            "name": "收银",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290102,
            "name": "酒店前台",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290103,
            "name": "客房服务员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290104,
            "name": "酒店经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 290300,
        "name": "零售",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 290305,
            "name": "督导/巡店",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290306,
            "name": "陈列员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290307,
            "name": "理货员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290308,
            "name": "防损员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290309,
            "name": "卖场经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290311,
            "name": "促销员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290301,
            "name": "收银",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290302,
            "name": "导购",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290303,
            "name": "店员/营业员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290304,
            "name": "门店店长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 290800,
        "name": "美容保健",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210407,
            "name": "纹绣师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210408,
            "name": "美体师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210409,
            "name": "美发学徒",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210410,
            "name": "美容店长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210411,
            "name": "足疗师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210412,
            "name": "按摩师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210413,
            "name": "美睫师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210607,
            "name": "发型师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210608,
            "name": "美甲师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210609,
            "name": "化妆师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290801,
            "name": "养发师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210405,
            "name": "美容师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 210600,
        "name": "运动健身",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 210613,
            "name": "救生员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210601,
            "name": "瑜伽老师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210603,
            "name": "游泳教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210604,
            "name": "美体教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210605,
            "name": "舞蹈老师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 210606,
            "name": "健身教练",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 290700,
        "name": "婚礼/花艺",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 290701,
            "name": "花艺师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290702,
            "name": "婚礼策划",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 290600,
        "name": "宠物服务",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 290601,
            "name": "宠物美容",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290602,
            "name": "宠物医生",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 290500,
        "name": "安保/家政/维修",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 290105,
            "name": "保安",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290106,
            "name": "保洁",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290108,
            "name": "保姆",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290109,
            "name": "月嫂",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290110,
            "name": "育婴师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290111,
            "name": "护工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290112,
            "name": "地铁安检",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290113,
            "name": "手机维修",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290114,
            "name": "家电维修",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290117,
            "name": "保安经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290118,
            "name": "产后康复师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290119,
            "name": "钟点工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290120,
            "name": "押运员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290121,
            "name": "消防中控员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 290122,
            "name": "保洁经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 290400,
        "name": "其他服务业职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 290401,
            "name": "其他服务业职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 300000,
    "name": "生产制造",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 300100,
        "name": "生产营运",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 300101,
            "name": "厂长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300102,
            "name": "生产总监",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300103,
            "name": "车间主任",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300104,
            "name": "生产组长/拉长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300105,
            "name": "生产员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300106,
            "name": "生产设备管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300107,
            "name": "生产计划管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300108,
            "name": "生产跟单",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 300200,
        "name": "质量安全",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 300208,
            "name": "质检员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300201,
            "name": "质量管理/测试",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300202,
            "name": "可靠度工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300203,
            "name": "故障分析师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300204,
            "name": "认证工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300205,
            "name": "体系工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300206,
            "name": "审核员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300207,
            "name": "生产安全员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230109,
            "name": "汽车质量工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 300800,
        "name": "新能源",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 300801,
            "name": "电池工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300802,
            "name": "电机工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300803,
            "name": "线束设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300804,
            "name": "充电桩设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 230100,
        "name": "汽车制造",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 230101,
            "name": "汽车设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230102,
            "name": "车身/造型设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230103,
            "name": "底盘工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230105,
            "name": "动力系统工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230106,
            "name": "汽车电子工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230107,
            "name": "汽车零部件设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230108,
            "name": "汽车项目管理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230110,
            "name": "内外饰设计工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230210,
            "name": "总装工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 230200,
        "name": "汽车服务",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 230203,
            "name": "汽车服务顾问",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230204,
            "name": "汽车维修",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230205,
            "name": "汽车美容",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230206,
            "name": "汽车定损理赔",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230207,
            "name": "二手车评估师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230208,
            "name": "4S店店长/维修站长",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 23,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 230209,
            "name": "汽车改装工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 300300,
        "name": "机械设计/制造",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 100813,
            "name": "热设计工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 100815,
            "name": "精益工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300301,
            "name": "机械工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300302,
            "name": "机械设计师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300303,
            "name": "机械设备工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300304,
            "name": "机械维修/保养",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300305,
            "name": "机械制图",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300306,
            "name": "机械结构工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300307,
            "name": "工业工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300308,
            "name": "工艺/制程工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300309,
            "name": "材料工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300310,
            "name": "机电工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300311,
            "name": "CNC/数控",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300312,
            "name": "冲压工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300313,
            "name": "夹具工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300314,
            "name": "模具工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300315,
            "name": "焊接工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300316,
            "name": "注塑工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300317,
            "name": "铸造/锻造工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 300400,
        "name": "化工",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 300407,
            "name": "化工项目经理",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300401,
            "name": "化工工程师",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300402,
            "name": "实验室技术员",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300403,
            "name": "化学分析",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300404,
            "name": "涂料研发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300405,
            "name": "化妆品研发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300406,
            "name": "食品/饮料研发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 300500,
        "name": "服装/纺织/皮革",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 300501,
            "name": "服装/纺织设计",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300507,
            "name": "面料辅料开发",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300509,
            "name": "打样/制版",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300510,
            "name": "服装/纺织/皮革跟单",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 11,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 300600,
        "name": "技工/普工",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 300601,
            "name": "普工/操作工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300634,
            "name": "挖掘机司机",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300602,
            "name": "叉车工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300603,
            "name": "铲车司机",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300604,
            "name": "焊工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300605,
            "name": "氩弧焊工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300606,
            "name": "电工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300608,
            "name": "木工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300609,
            "name": "油漆工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300610,
            "name": "车工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300611,
            "name": "磨工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300612,
            "name": "铣工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300613,
            "name": "钳工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300614,
            "name": "钻工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300615,
            "name": "铆工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300616,
            "name": "钣金工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300617,
            "name": "抛光工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300618,
            "name": "机修工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300619,
            "name": "折弯工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300620,
            "name": "电镀工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300621,
            "name": "喷塑工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300622,
            "name": "注塑工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300623,
            "name": "组装工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300624,
            "name": "包装工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300625,
            "name": "空调工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300626,
            "name": "电梯工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300627,
            "name": "锅炉工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300628,
            "name": "学徒工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300629,
            "name": "缝纫工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300630,
            "name": "搬运工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 3,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300631,
            "name": "切割工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300632,
            "name": "样衣工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          },
          {
            "code": 300633,
            "name": "模具工",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 22,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      },
      {
        "code": 300700,
        "name": "其他生产制造职位",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 300701,
            "name": "其他生产制造职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  },
  {
    "code": 200000,
    "name": "其他",
    "tip": null,
    "subLevelModelList": [
      {
        "code": 200100,
        "name": "其他职位类别",
        "tip": null,
        "subLevelModelList": [
          {
            "code": 200101,
            "name": "其他职位",
            "tip": null,
            "subLevelModelList": null,
            "firstChar": null,
            "pinyin": null,
            "rank": 0,
            "mark": 21,
            "positionType": 1,
            "cityType": 0,
            "capital": 0
          }
        ],
        "firstChar": null,
        "pinyin": null,
        "rank": 0,
        "mark": 0,
        "positionType": 0,
        "cityType": 0,
        "capital": 0
      }
    ],
    "firstChar": null,
    "pinyin": null,
    "rank": 0,
    "mark": 0,
    "positionType": 0,
    "cityType": 0,
    "capital": 0
  }
]