/*
 * @Author: liushuang
 * @Date: 2023-02-24 13:25:35
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web-enterprise\src\api\comm.js
 */
// import { get,post,detele } from "../utils/httprequest"; // 导入模块
import {get, post} from "../utils/httprequest";
import {message} from "ant-design-vue"; // 导入模块
//登录
export const loginApi = (params) => post("/user/login", params);
//退出登录
export const loginOutApi = () => post("/user/logout");
//获取登录用户信息
export const getUserInfoApi = () => get("/user/get-user");
//获取全世界地区数据
// export const getCitylist = (params) => get("/areaInfo/detail", params);
export const getCitylistApi = (params) => get("/area/list", params);
//获取职位类别数据
// export const getProfessionVategoryApi = (params) => get("/professionCategory-settings/select", params);
export const getProfessionVategoryApi = () => get("/professionCategory-settings/selectAll");
//获取行业数据
export const getIndustrySettingApi = (params) => get("/industry-settings/select", params);
//oss直传-获取签名
export const getOssPolicy = (params, params1) => get("/oss/policy/" + params, params1);
//获取文件上传的policy-用于查看pdf
export const toolPdfViewApi = () => get("/tool/pdf/view");
//查询用户列表设置
export const columnSettingsApi = (businessType) => get("/column-settings/get/" + businessType);
//保存列表设置-1、职位列表2、客户列表3、简历列表
export const columnSaveSettingApi = (params) => post("/column-settings/save-settings", params);
//加入项目
export const addResumeToProjectApi = (params) => post("/process/addResumeToProject", params);
//字段设置-查询字段设置列表
export const fieldSettingsListApi = (params) => get("/field-settings/list", params);
//字段设置-更新字段设置
export const fieldSettingsUpdateApi = (params) => post("/field-settings/update", params);

//查询搜索条件
export const searchConditionSelectApi = (params) => get("/searchCondition/select", params);
//保存搜索条件
export const searchConditionSaveApi = (params) => post("/searchCondition/save", params);
//删除筛选条件
export const searchConditionDeleteApi = (params) => get("/searchCondition/delete", params);
//公司下拉框
export const selectSubCompanyNameApi = (params) => get("/company/selectBox", params);
//客户联系人下拉框
export const selectCustomerContactsApi = (params) => get("/customerContacts/selectBox", params);
//查询字典数据
export const dicList = (params) => get("/dic/list", params);


//公司管理
//公司列表
export const companyListAPI = (params) => post("/company/pageList", params);
//添加公司
export const addCompanyAPI = (params) => post("/company/add", params);
//修改公司
export const updateCompanyAPI = (params) => post("/company/update", params);
//公司详情
export const companyDetailAPI = (params) => get(`/company/detail`, params);
//修改公司状态
export const updateCompanyStatusAPI = (params) => post("/company/batch/updateStatus", params);

//用户管理
//添加用户
export const addUserAPI = (params) => post("/user/save", params);
//修改用户
export const updateUserAPI = (params) => post("/user/update", params);
//用户列表
export const userListAPI = (params) => post("/user/queryPageInfoByCondition", params);
//查询用户详情
export const userDetailAPI = (userId) => get(`/user/${userId}`);
//查询公司职位下拉框（用户添加时归属公司的公司职位）
export const positionDropdownApi = (params) => get("/position-setting/dropdown-list/user", params);
//查询公司职级下拉框（用户添加时归属公司的公司职级）
export const positionRankDropdownApi = (params) => get("/position-rank-setting/dropdown-list/user", params);
//修改用户状态
export const updateUserStatusAPI = (params) => post("/user/update/account_status", params);
//校验用户手机号是否重复
export const checkPhoneApi = (params) => get("/user/check-phone", params);
//校验用户邮箱是否重复
export const checkMailApi = (params) => get("/user/check-mail", params);
//客户离职交接转移
export const handOverApi = (params) => post("/customer/hand-over", params);
//查询离职人的客户列表
export const getCustomerListApi = (params) => get("/customer/customerListByBelongId", params);

//猎企管理
//添加猎企
export const addHuntAPI = (params) => post("/user/hunterCompany/save", params);
//编辑猎企
export const updateHuntAPI = (params) => post("/user/hunterCompany/update", params);
//猎企详情
export const huntDetailAPI = (id) => get(`/user/hunterCompany/detail/${id}`);
//猎企列表
export const huntListAPI = (params) => post("/company/hunterPageList", params);
//猎企查重手机号
export const isExistUHunterUserByPhoneAPI = (phone, id) => get(`/hunterCompany/isExistUHunterUserByPhone/${phone}/${id}`);
//猎企查重邮箱
export const isExistUHunterUserByMailAPI = (mail, id) => get(`/hunterCompany/isExistUHunterUserByMail/${mail}/${id}`);

//设置
//职类设置列表
export const setList = (type) => get(`/${type}-settings/selectAll`);
//职类重命名
export const setRename = (params, type) => post(`/${type}-settings/rename`, params);
//增加职类
export const setAdd = (params, type) => post(`/${type}-settings/add`, params);
//删除职类
export const setDel = (id, type) => post(`/${type}-settings/delete?id=${id}`);
//拖拽排序-职类
export const setDrag = (id, preId, type) => post(`/${type}-settings/drag?id=${id}&preId=${preId}`);


//参与者-职位下拉框
export const projectDropdownListApi = (params) => get("/position-setting/dropdown-list/project", params);
//所属公司下拉框
export const companySelectBoxApi = (params) => get("/company/selectBox", params);

//用户下拉框-查询条件
//type:'search-condition':列表下拉框,'company':公司责任人,'customer-contract':客户合同,'customer':客户,'staff-handover':员工交接(集团下在职的员工)
export const userSearchApi = (type, params) => get(`/user/dropdown-list/${type}`, params);

//行业数据
export const industrySelectAllApi = (params) => get("/industry-settings/selectAll", params);

//设置公司logo
export const setLogApi = (logoUrl) => post(`/company/set-logo?logoUrl=${logoUrl}`);
//查询公司logo
export const getLogoApi = (params) => get("/company/get-logo", params);

//职位操作权限
export const getProjectPermissionApi = (userId, projectId) => get("/project/getProjectPermission/" + userId + "/" + projectId);

//用户名片信息
export const getUserInfo = (params) => get("/user/businessCardInfo", params);
//所有公司数据统计
export const allCompanyStatistic = (params) => post("/statistic/all-company", params);
//某个公司数据统计
export const oneCompanyStatistic = (params) => post("/statistic/one-company", params);
//分公司详细数据
export const userStatistic = (params) => post("/statistic/user-statistic", params);
//查询职级列表
export const getPositionRankDropdownListApi = (params) => get("/position-rank-setting/dropdown-list", params);

//设置-系统职位名称设置
export const getPositionSettingAllApi = (params) => get("/position-setting/all", params);
//设置-系统职位名称-新增
export const positionSettingAddApi = (params) => post("/position-setting/add", params);
//设置-系统职位名称-删除
export const positionSettingDelApi = (params) => post("/position-setting/delete", params);
//设置-系统职位名称-修改职位可用公司
export const positionSettingUpdateAuthApi = (params) => post("/position-setting/updateAuth", params);
//设置-系统职位名称-修改职位名称
export const positionSettingUpdateNameApi = (params) => post("/position-setting/updatePositionName", params);

//设置-系统职级设置
export const getPositionRankSettingAllApi = (params) => get("/position-rank-setting/all", params);
//设置-系统职级-新增
export const positionRankSettingAddApi = (params) => post("/position-rank-setting/add", params);
//设置-系统职级-删除
export const positionRankSettingDelApi = (params) => post("/position-rank-setting/delete", params);
//设置-系统职级-修改职级可用公司
export const positionRankSettingUpdateAuthApi = (params) => post("/position-rank-setting/updateAuth", params);
//设置-系统职级-修改职级名称
export const positionRankSettingUpdateNameApi = (params) => post("/position-rank-setting/updateName", params);


// 设置-查询角色列表
export const roleListAPI = (params) => post("/role/pageList", params);

// 保存角色可用公司
export const saveRoleAvailableCompany = (params) => post("/role/updateRoleUseAbleCompany", params);

// 获取角色详情
export const acquireRoleDetailAPI = (roleId) => get("/role/detail/" + roleId);

// 批量修改角色状态
export const batchModifyRoleStatusAPI = (param) => post("/role/batchUpdateStatus", param);

// 保存角色权限
export const saveRoleInfoAPI = (param) => post("/role/add", param)

// 编辑角色权限
export const updateRoleInfoAPI = (param) => post("/role/update", param)

// 获取角色下拉框（用户管理-新增用户或编辑用户）
export const acquireRoleSelectAPI = (companyId) => get("/role/selectBox?companyId=" + companyId)


// 修改角色名称
export const updateRoleNameAPI = (param) => post("/role/updateRoleName", param)

// 获取允许的导航
export const acquireValidNavigationsAPI = () => get("/role/getUserMenuAuth")


// 获取允许的导航
export const acquirePermissionIdsAPI = (pageCode) => get("/role/getUserModuleAuth?permissionCode=" + pageCode)

// 获取前程无忧token集合
export const getQianchengTokenListAPI = () => get("/qianchengToken/getQianchengTokenList")
const jumpPageConfig = {
    home: {
        navigationCode: "01",
        path: '/homePage'
    },
    resume: {
        navigationCode: "02",
        path: '/personnels'
    },
    position: {
        navigationCode: "04",
        path: '/positions'
    },
    recommend: {
        navigationCode: "05",
        path: '/recommends'
    },
    setting: {
        navigationCode: "08",
        resumeSetting: {
            code: 'resumeSetting',
            permissionCodes: ['08107'],
            path: '/personnelSet'
        },
        positionSetting: {
            code: 'positionSetting',
            permissionCodes: ['08109'],
            path: "/positionSet"
        },
        systemSetting: {
            code: 'systemSetting',
            permissionCodes: ['08110'],
            path: "/logoSet"
        },
        industrySetting: {
            code: 'industrySetting',
            permissionCodes: ['08111'],
            path: "/set/industry"
        },
        positionCategorySetting: {
            code: 'positionCategorySetting',
            permissionCodes: ['08112'],
            path: "/set/professionCategory"
        },
        user: {
            code: 'user',
            permissionCodes: ['08113', '08114', '08115', '08116'],
            path: "/userList"
        },
        company: {
            code: 'company',
            permissionCodes: ['08123', '08124', '08125'],
            path: "/companyList"
        },
        rolePermission: {
            code: 'role',
            permissionCodes: ['08117', '08118', '08119', '08120', '08121', '08122'],
            path: "/roleList"
        }
    }
}

export const acquireFirstLoginPagePathAPI = async () => {
    let pagePath = '/'
    return new Promise((resolve, reject) => {
        acquireValidNavigationsAPI().then(result => {
            const {success: isSuccess, message: errorMessage, data: codes} = result
            if (isSuccess) {
                const navigationCodes = codes;
                if (navigationCodes.indexOf(jumpPageConfig.home.navigationCode) > -1) {
                    pagePath = jumpPageConfig.home.path
                    resolve(pagePath)
                } else if (navigationCodes.indexOf(jumpPageConfig.recommend.navigationCode) > -1) {
                    pagePath = jumpPageConfig.recommend.path
                    resolve(pagePath)
                } else if (navigationCodes.indexOf(jumpPageConfig.resume.navigationCode) > -1) {
                    pagePath = jumpPageConfig.resume.path
                    resolve(pagePath)
                } else if (navigationCodes.indexOf(jumpPageConfig.position.navigationCode) > -1) {
                    pagePath = jumpPageConfig.position.path
                    resolve(pagePath)
                } else if (navigationCodes.indexOf(jumpPageConfig.setting.navigationCode) > -1) {
                    // alert(1)
                    acquirePermissionIdsAPI('08').then(resp => {
                        const {success: isSuccess, message: errorMessage, data: permissionInfos} = resp
                        const menuCodes = []
                        if (isSuccess) {
                            permissionInfos.forEach(item => {
                                if (jumpPageConfig.setting.resumeSetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.resumeSetting.code)
                                }
                                if (jumpPageConfig.setting.positionSetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.positionSetting.code)
                                }
                                if (jumpPageConfig.setting.systemSetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.systemSetting.code)
                                }
                                if (jumpPageConfig.setting.industrySetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.industrySetting.code)
                                }
                                if (jumpPageConfig.setting.positionCategorySetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.positionCategorySetting.code)
                                }
                                if (jumpPageConfig.setting.user.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.user.code)
                                }
                                if (jumpPageConfig.setting.company.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.company.code)
                                }
                                if (jumpPageConfig.setting.rolePermission.permissionCodes.indexOf(item.permissionCode) > -1) {
                                    menuCodes.push(jumpPageConfig.setting.rolePermission.code)
                                }
                            })
                        } else {
                            message.warning(errorMessage);
                            pagePath = '/'
                        }
                        if (menuCodes.indexOf(jumpPageConfig.setting.resumeSetting.code) > -1) {
                            pagePath = jumpPageConfig.setting.resumeSetting.path;
                        } else if (menuCodes.indexOf(jumpPageConfig.setting.positionSetting.code) > -1) {
                            pagePath = jumpPageConfig.setting.positionSetting.path;
                        } else if (menuCodes.indexOf(jumpPageConfig.setting.systemSetting.code) > -1) {
                            pagePath = jumpPageConfig.setting.systemSetting.path;
                        } else if (menuCodes.indexOf(jumpPageConfig.setting.industrySetting.code) > -1) {
                            pagePath = jumpPageConfig.setting.industrySetting.path;
                        } else if (menuCodes.indexOf(jumpPageConfig.setting.positionCategorySetting.code) > -1) {
                            pagePath = jumpPageConfig.setting.positionCategorySetting.path;
                        } else if (menuCodes.indexOf(jumpPageConfig.setting.user.code) > -1) {
                            pagePath = jumpPageConfig.setting.user.path;
                        } else if (menuCodes.indexOf(jumpPageConfig.setting.company.code) > -1) {
                            pagePath = jumpPageConfig.setting.company.path;
                        } else if (menuCodes.indexOf(jumpPageConfig.setting.rolePermission.code) > -1) {
                            pagePath = jumpPageConfig.setting.rolePermission.path;
                        } else {
                            pagePath = '/'
                        }
                        // alert(pagePath)
                        resolve(pagePath)
                    }).catch(function (error) {
                        reject(error);
                    });
                } else {
                    pagePath = '/'
                    resolve(pagePath)
                }
            } else {
                message.warning(errorMessage);
                pagePath = '/login'
                resolve(pagePath)
            }

        }).catch(function (error) {
            reject(error);
        });
    })
}


export const acquireFirstSettingPagePathAPI = async () => {
    let pagePath = '/'
    return new Promise((resolve, reject) =>{
        acquirePermissionIdsAPI('08').then(resp => {
            const {success: isSuccess, message: errorMessage, data: permissionInfos} = resp
            const menuCodes = []
            if (isSuccess) {
                permissionInfos.forEach(item => {
                    if (jumpPageConfig.setting.resumeSetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.resumeSetting.code)
                    }
                    if (jumpPageConfig.setting.positionSetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.positionSetting.code)
                    }
                    if (jumpPageConfig.setting.systemSetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.systemSetting.code)
                    }
                    if (jumpPageConfig.setting.industrySetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.industrySetting.code)
                    }
                    if (jumpPageConfig.setting.positionCategorySetting.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.positionCategorySetting.code)
                    }
                    if (jumpPageConfig.setting.user.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.user.code)
                    }
                    if (jumpPageConfig.setting.company.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.company.code)
                    }
                    if (jumpPageConfig.setting.rolePermission.permissionCodes.indexOf(item.permissionCode) > -1) {
                        menuCodes.push(jumpPageConfig.setting.rolePermission.code)
                    }
                })
           
                if (menuCodes.indexOf(jumpPageConfig.setting.resumeSetting.code) > -1) {
                    pagePath = jumpPageConfig.setting.resumeSetting.path;
                } else if (menuCodes.indexOf(jumpPageConfig.setting.positionSetting.code) > -1) {
                    pagePath = jumpPageConfig.setting.positionSetting.path;
                } else if (menuCodes.indexOf(jumpPageConfig.setting.systemSetting.code) > -1) {
                    pagePath = jumpPageConfig.setting.systemSetting.path;
                } else if (menuCodes.indexOf(jumpPageConfig.setting.industrySetting.code) > -1) {
                    pagePath = jumpPageConfig.setting.industrySetting.path;
                } else if (menuCodes.indexOf(jumpPageConfig.setting.positionCategorySetting.code) > -1) {
                    pagePath = jumpPageConfig.setting.positionCategorySetting.path;
                } else if (menuCodes.indexOf(jumpPageConfig.setting.user.code) > -1) {
                    pagePath = jumpPageConfig.setting.user.path;
                } else if (menuCodes.indexOf(jumpPageConfig.setting.company.code) > -1) {
                    pagePath = jumpPageConfig.setting.company.path;
                } else if (menuCodes.indexOf(jumpPageConfig.setting.rolePermission.code) > -1) {
                    pagePath = jumpPageConfig.setting.rolePermission.path;
                } else {
                    pagePath = '/'
                }
              } else {//接口失败的情况
                message.warning(errorMessage);
                if (resp.code === 403 || resp.code === 11011 || resp.code === 11012 || resp.code === 11014 || resp.code === 11015) {
                  pagePath = '/login'
                }
            }
            resolve(pagePath)
        }).catch(function (error) {
            reject(error);
        });
    })
}

