/*
 * @Author: liushuang
 * @Date: 2023-02-03 13:52:01
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web-enterprise\src\utils\httprequest.js
 */
import axios from "axios"; // 引入axios模块
import { domain } from '../utils/request'
import { message } from 'ant-design-vue'
import router from '@/router/index'

// 环境的切换
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = domain.Base_M2_URL; //开发环境
} else if (process.env.NODE_ENV == "test") {
  axios.defaults.baseURL = domain.Base_M2_URL; //测试环境
} else if (process.env.NODE_ENV == "uat") {
  axios.defaults.baseURL = domain.Base_M2_URL; //uat环境
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = domain.Base_M2_URL; //正式环境
}

let Config = {
  TIMEOUT: 30000,//设置超时时间为30秒
}
axios.defaults.timeout = Config.TIMEOUT;

axios.interceptors.request.use(config => {
  if (!navigator.onLine) {
    message.warning('未连接到互联网,请检查网络')
  } else {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || { type: 'unknown' }
    let type = (connection && connection.rtt) || 'unknown'
    if (type >= 2200) {
      message.warning('网速较差，请检查网络')
    }
  }
  //请求头中需要带有token【获取用户信息】
  if (localStorage.getItem('TOKEN')) {
    config.headers.Authorization = localStorage.getItem('TOKEN');
  }
  return config
}, error => {
  if (!navigator.onLine) {
    message.warning('未连接到互联网,请检查网络')
  }else{
    message.warning('请求超时，请重试')
  }
  return Promise.reject(error)
})
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      //判断是否登录失效
      if (response.data.code === 403 || response.data.code === 11011 || response.data.code === 11012 || response.data.code === 11014 || response.data.code === 11015 ) {
        message.warning(response.data.message)
        localStorage.removeItem('TOKEN')
        localStorage.removeItem('USER') 
        router.push({
          path: "/login",
        });
      }
      return Promise.resolve(response);
    }
    else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (!navigator.onLine) {
      message.warning('未连接到互联网,请检查网络')
    }
    return Promise.reject(error.response);
  }
);

// get 方法
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(function (response) {
        resolve(response ? response.data : '');
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// post 方法
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(function (response) {
        resolve(response ? response.data : '');
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
// put 方法
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(function (response) {
        resolve(response ? response.data : '');
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
// delete 方法
export function detele(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url,params)
      .then(function (response) {
        resolve(response ? response.data : '');
      })
      .catch(function (error) {
        reject(error);
      });
  });
}