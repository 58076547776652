import { get, post,put,detele } from "../utils/httprequest"; // 导入模块
//简历
//简历解析
export const resumeAnalysisAPI = (params) => get("/resume/analysis", params);
//简历添加
export const addResumeAPI = (params) => post("/resume/save", params);
//简历修改
export const updateResumeAPI = (params) => post("/resume/update", params);
//简历单个修改
export const updateFieldResumeAPI = (params) => post("/resume/updateItem", params);
//简历列表
export const resumeListAPI = (params) => post("/resume/queryPageInfoByCondition", params);
//简历详情
export const resumeDetailAPI = (resumeId) => get(`/resume/${resumeId}`);
//第三方招聘平台简历详情
export const fpResumeDetailAPI = (md5Value) => get(`/resume/queryByResumeId/${md5Value}`);
//查询简历头部基本信息
export const resumeBaseDetailAPI = (resumeId) => get(`/resume/concise-info/${resumeId}`);
//查询原版简历
export const originResumeAPI = (resumeId) => get(`/resume/origin-resume/${resumeId}`);
//获取简历报告
export const resumeReportAPI = (resumeId) => get(`/resume-report/list/${resumeId}`);
//日志列表
export const logListAPI = (params) => post("/resume-log/list", params);
//记录用户浏览简历
export const resumeRecordAPI = (params) => get("/resume/record-view", params);
//简历加入项目列表查询
export const resumeProcessListAPI = (params) => get("/process/resume/processList", params);
//简历左侧菜单
export const resumeLeftMenuAPI = (params) => get("/resume/left-side-menu", params);
//修改简历状态
export const resumeChangeStutusAPI = (params) => put("/resume/change-status", params);
//添加备注
export const resumeRemarkSaveApi = (params) => post("/resume-remark/add", params);
//编辑备注
export const resumeRemarkUpdateApi = (params) => post(`/resume-remark/update`,params);
//删除备注
export const resumeRemarkDelApi = (params) => post("/resume-remark/batch-remove",params);
//备注列表
export const resumeRemarkListApi = (params) => post("/resume-remark/queryPageInfoByCondition", params);
//项目记录列表
export const resumeProcessListApi = (params) => post("/process/resume/detail/list", params);
//删除简历
export const resumeDelApi = (resumeIds) => detele(`/resume/batch-remove?resumeIds=${resumeIds}`);
//查询简历是否被删除
export const resumeCheckValidApi = (params) => get(`/resume/check-valid`,params);

/**录入人才方式 */
//解析简历文本内容
export const resumeAnalysisTextAPI = (params) => post("/resume/analysis-text", params);
//批量解析
export const resumeBatchAnalysisAPI = (params) => post("/resume/batch-analysis", params);
//查询批量解析-单个简历的处理结果
export const resumeBatchUpdateResultApi = (analysisId) => get(`/resume/batch-update/result/${analysisId}`);
//查询批量解析简历-单个解析内容查询（录入失败的）
export const resumeBatchAnalysisContentApi = (analysisId) => get(`/resume/batch-analysis-content/item/${analysisId}`);
//pdf文件二次解析
export const optimisePdfAnalysisApi = (params) => get(`/resume/analysis/optimise-pdf-analysis`, params);