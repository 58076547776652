/*
 * @Author: liushuang
 * @Date: 2023-02-03 13:49:44
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web-enterprise\src\utils\request.js
 */
let domain = {};
let nodeEnv = process.env.NODE_ENV;
//对应的不同接口的URL地址
switch (nodeEnv) { 
  case 'development':  
    domain.Base_M2_URL = 'http://47.94.134.3/ats-enterprise-api'; //开发环境
    break;
  case 'test':
    domain.Base_M2_URL = 'http://47.94.134.3/ats-enterprise-api'; //测试环境
    break;
  case 'uat':
    // domain.Base_M2_URL = 'https://ats.risfond.com/ats-api'; //预发布环境
    break;
  case 'production':
    // domain.Base_M2_URL = 'https://ats-b.risfond.com/ats-enterprise-api'; //正式环境
    domain.Base_M2_URL = 'https://www.rnssats.com/ats-enterprise-api'; //正式环境
    break;
}
module.exports = {
  domain
}