export const ZHILIANJOBTITLE =[
    {
        "code": 200,
        "message": "success",
        "data": [
            {
                "config": {
                    "dictName": "new_job_type",
                    "format": "tree",
                    "sceneName": "multiple_parent"
                },
                "data": [
                    {
                        "value": 1000001,
                        "label": "销售",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000001,
                                "label": "房地产销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200040000,
                                        "label": "房产销售",
                                        "extra": {
                                            "shortPinyin": "fcxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125262",
                                        "parentSerial": "125261"
                                    },
                                    {
                                        "value": 7000800010000,
                                        "label": "房地产销售总监",
                                        "extra": {
                                            "shortPinyin": "fdcxszj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125263",
                                        "parentSerial": "125261"
                                    },
                                    {
                                        "value": 7000700010000,
                                        "label": "物业招商管理",
                                        "extra": {
                                            "shortPinyin": "wyzsgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125264",
                                        "parentSerial": "125261"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125261",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000002,
                                "label": "外贸销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200070000,
                                        "label": "外贸业务员",
                                        "extra": {
                                            "shortPinyin": "wmywy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125266",
                                        "parentSerial": "125265"
                                    },
                                    {
                                        "value": 19000200180000,
                                        "label": "外贸经理",
                                        "extra": {
                                            "shortPinyin": "wmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125267",
                                        "parentSerial": "125265"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125265",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000003,
                                "label": "金融销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 12000100110000,
                                        "label": "保险顾问",
                                        "extra": {
                                            "shortPinyin": "bxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125269",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000100140000,
                                        "label": "车险专员",
                                        "extra": {
                                            "shortPinyin": "cxzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125270",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000100180000,
                                        "label": "续保专员",
                                        "extra": {
                                            "shortPinyin": "xbzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125271",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000300070000,
                                        "label": "理财顾问",
                                        "extra": {
                                            "shortPinyin": "lcgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125272",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000300140000,
                                        "label": "信用卡销售",
                                        "extra": {
                                            "shortPinyin": "xykxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125273",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000200060000,
                                        "label": "基金销售",
                                        "extra": {
                                            "shortPinyin": "jjxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125274",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000200190000,
                                        "label": "证券经纪人",
                                        "extra": {
                                            "shortPinyin": "zqjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125275",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000400010000,
                                        "label": "期货经纪人",
                                        "extra": {
                                            "shortPinyin": "qhjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125276",
                                        "parentSerial": "125268"
                                    },
                                    {
                                        "value": 12000400020000,
                                        "label": "外汇经纪人",
                                        "extra": {
                                            "shortPinyin": "whjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125277",
                                        "parentSerial": "125268"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125268",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000004,
                                "label": "医疗销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200120000,
                                        "label": "医药代表",
                                        "extra": {
                                            "shortPinyin": "yyxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125279",
                                        "parentSerial": "125278"
                                    },
                                    {
                                        "value": 18000500110000,
                                        "label": "医药招商",
                                        "extra": {
                                            "shortPinyin": "yyzs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125280",
                                        "parentSerial": "125278"
                                    },
                                    {
                                        "value": 19000200110000,
                                        "label": "医疗器械销售",
                                        "extra": {
                                            "shortPinyin": "ylqxxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125281",
                                        "parentSerial": "125278"
                                    },
                                    {
                                        "value": 6001000100000,
                                        "label": "医美咨询",
                                        "extra": {
                                            "shortPinyin": "ymzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125282",
                                        "parentSerial": "125278"
                                    },
                                    {
                                        "value": 19000200160000,
                                        "label": "健康顾问",
                                        "extra": {
                                            "shortPinyin": "jkgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125283",
                                        "parentSerial": "125278"
                                    },
                                    {
                                        "value": 18000600030000,
                                        "label": "药店店员",
                                        "extra": {
                                            "shortPinyin": "yddy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125284",
                                        "parentSerial": "125278"
                                    },
                                    {
                                        "value": 18000600020000,
                                        "label": "药店店长",
                                        "extra": {
                                            "shortPinyin": "yddc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125285",
                                        "parentSerial": "125278"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125278",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000005,
                                "label": "汽车销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200150000,
                                        "label": "汽车销售",
                                        "extra": {
                                            "shortPinyin": "qcxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125287",
                                        "parentSerial": "125286"
                                    },
                                    {
                                        "value": 15000900030000,
                                        "label": "汽车金融销售",
                                        "extra": {
                                            "shortPinyin": "qcjrxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "178388",
                                        "parentSerial": "125286"
                                    },
                                    {
                                        "value": 15000900010000,
                                        "label": "汽车配件销售",
                                        "extra": {
                                            "shortPinyin": "qcpjxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125288",
                                        "parentSerial": "125286"
                                    },
                                    {
                                        "value": 15000900020000,
                                        "label": "二手车经纪人",
                                        "extra": {
                                            "shortPinyin": "escjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125289",
                                        "parentSerial": "125286"
                                    },
                                    {
                                        "value": 3000200010000,
                                        "label": "4S店店长",
                                        "extra": {
                                            "shortPinyin": "4sddc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125290",
                                        "parentSerial": "125286"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125286",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000006,
                                "label": "广告/会展销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000100030000,
                                        "label": "广告销售",
                                        "extra": {
                                            "shortPinyin": "ggxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125292",
                                        "parentSerial": "125291"
                                    },
                                    {
                                        "value": 16000300060000,
                                        "label": "媒介顾问",
                                        "extra": {
                                            "shortPinyin": "mjgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125293",
                                        "parentSerial": "125291"
                                    },
                                    {
                                        "value": 16000400050000,
                                        "label": "会展活动销售",
                                        "extra": {
                                            "shortPinyin": "hzhdxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125294",
                                        "parentSerial": "125291"
                                    },
                                    {
                                        "value": 16000400060000,
                                        "label": "会议活动销售",
                                        "extra": {
                                            "shortPinyin": "hyhdxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125295",
                                        "parentSerial": "125291"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125291",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000007,
                                "label": "教培销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200170000,
                                        "label": "课程顾问",
                                        "extra": {
                                            "shortPinyin": "zs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125297",
                                        "parentSerial": "125296"
                                    },
                                    {
                                        "value": 11000500050000,
                                        "label": "留学顾问",
                                        "extra": {
                                            "shortPinyin": "lxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125298",
                                        "parentSerial": "125296"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125296",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000008,
                                "label": "服务业销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001500050000,
                                        "label": "服装销售",
                                        "extra": {
                                            "shortPinyin": "fzxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125300",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6001500060000,
                                        "label": "珠宝销售",
                                        "extra": {
                                            "shortPinyin": "zbxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125301",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6001000140000,
                                        "label": "美容顾问",
                                        "extra": {
                                            "shortPinyin": "mrgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125302",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6001000160000,
                                        "label": "彩妆顾问",
                                        "extra": {
                                            "shortPinyin": "czgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125303",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6001600010000,
                                        "label": "健身顾问",
                                        "extra": {
                                            "shortPinyin": "jsgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125304",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6001000150000,
                                        "label": "会籍顾问",
                                        "extra": {
                                            "shortPinyin": "hjgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125305",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6000900050000,
                                        "label": "旅游顾问",
                                        "extra": {
                                            "shortPinyin": "lygw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125306",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 17000400200000,
                                        "label": "家装顾问",
                                        "extra": {
                                            "shortPinyin": "jzgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125307",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 19000200010000,
                                        "label": "促销员",
                                        "extra": {
                                            "shortPinyin": "cxy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125308",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6001500030000,
                                        "label": "店员/营业员",
                                        "extra": {
                                            "shortPinyin": "dy/yyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125309",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 19000200060000,
                                        "label": "商品销售",
                                        "extra": {
                                            "shortPinyin": "spxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125310",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 6001500040000,
                                        "label": "卖场经理",
                                        "extra": {
                                            "shortPinyin": "mcjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125311",
                                        "parentSerial": "125299"
                                    },
                                    {
                                        "value": 5000400020000,
                                        "label": "门店店长",
                                        "extra": {
                                            "shortPinyin": "mddc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125312",
                                        "parentSerial": "125299"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125299",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000009,
                                "label": "销售工程师",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200090000,
                                        "label": "销售工程师",
                                        "extra": {
                                            "shortPinyin": "xsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125314",
                                        "parentSerial": "125313"
                                    },
                                    {
                                        "value": 9000700100000,
                                        "label": "芯片销售工程师",
                                        "extra": {
                                            "shortPinyin": "xpxsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125315",
                                        "parentSerial": "125313"
                                    },
                                    {
                                        "value": 9000700110000,
                                        "label": "电子元器件销售工程师",
                                        "extra": {
                                            "shortPinyin": "dzyqjxsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "178389",
                                        "parentSerial": "125313"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125313",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000010,
                                "label": "其他销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200100000,
                                        "label": "销售顾问",
                                        "extra": {
                                            "shortPinyin": "xsgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125317",
                                        "parentSerial": "125316"
                                    },
                                    {
                                        "value": 19000200030000,
                                        "label": "电话销售",
                                        "extra": {
                                            "shortPinyin": "dhxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125318",
                                        "parentSerial": "125316"
                                    },
                                    {
                                        "value": 19000200080000,
                                        "label": "网络销售",
                                        "extra": {
                                            "shortPinyin": "wlxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125319",
                                        "parentSerial": "125316"
                                    },
                                    {
                                        "value": 19000200020000,
                                        "label": "大客户代表",
                                        "extra": {
                                            "shortPinyin": "dkhdb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125320",
                                        "parentSerial": "125316"
                                    },
                                    {
                                        "value": 19000100040000,
                                        "label": "渠道销售",
                                        "extra": {
                                            "shortPinyin": "qdxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125321",
                                        "parentSerial": "125316"
                                    },
                                    {
                                        "value": 19000100070000,
                                        "label": "渠道经理",
                                        "extra": {
                                            "shortPinyin": "qdjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125322",
                                        "parentSerial": "125316"
                                    },
                                    {
                                        "value": 19000200190000,
                                        "label": "代理商销售",
                                        "extra": {
                                            "shortPinyin": "dlsxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125323",
                                        "parentSerial": "125316"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125316",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000011,
                                "label": "销售管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000300030000,
                                        "label": "销售团队经理",
                                        "extra": {
                                            "shortPinyin": "xstdjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125325",
                                        "parentSerial": "125324"
                                    },
                                    {
                                        "value": 19000300010000,
                                        "label": "城市经理",
                                        "extra": {
                                            "shortPinyin": "csjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125326",
                                        "parentSerial": "125324"
                                    },
                                    {
                                        "value": 19000300040000,
                                        "label": "销售总监",
                                        "extra": {
                                            "shortPinyin": "xszj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125327",
                                        "parentSerial": "125324"
                                    },
                                    {
                                        "value": 19000300050000,
                                        "label": "区域总监",
                                        "extra": {
                                            "shortPinyin": "qyzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125328",
                                        "parentSerial": "125324"
                                    },
                                    {
                                        "value": 19000300020000,
                                        "label": "销售督导",
                                        "extra": {
                                            "shortPinyin": "xsdd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125329",
                                        "parentSerial": "125324"
                                    },
                                    {
                                        "value": 7000800010000,
                                        "label": "房地产销售总监",
                                        "extra": {
                                            "shortPinyin": "fdcxszj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125330",
                                        "parentSerial": "125324"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125324",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000012,
                                "label": "商务拓展",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000100010000,
                                        "label": "BD经理",
                                        "extra": {
                                            "shortPinyin": "bdjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125332",
                                        "parentSerial": "125331"
                                    },
                                    {
                                        "value": 19000100110000,
                                        "label": "商务渠道",
                                        "extra": {
                                            "shortPinyin": "swqd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125333",
                                        "parentSerial": "125331"
                                    },
                                    {
                                        "value": 19000100080000,
                                        "label": "商务专员",
                                        "extra": {
                                            "shortPinyin": "swzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125334",
                                        "parentSerial": "125331"
                                    },
                                    {
                                        "value": 19000100090000,
                                        "label": "商务经理",
                                        "extra": {
                                            "shortPinyin": "swjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125335",
                                        "parentSerial": "125331"
                                    },
                                    {
                                        "value": 19000100100000,
                                        "label": "商务总监",
                                        "extra": {
                                            "shortPinyin": "swzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125336",
                                        "parentSerial": "125331"
                                    },
                                    {
                                        "value": 19000100060000,
                                        "label": "招商",
                                        "extra": {
                                            "shortPinyin": "zs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125337",
                                        "parentSerial": "125331"
                                    },
                                    {
                                        "value": 19000100020000,
                                        "label": "选址开发",
                                        "extra": {
                                            "shortPinyin": "xzkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125338",
                                        "parentSerial": "125331"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125331",
                                "parentSerial": "125260"
                            },
                            {
                                "value": 2000013,
                                "label": "销售行政",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000400070000,
                                        "label": "销售助理",
                                        "extra": {
                                            "shortPinyin": "xszl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125340",
                                        "parentSerial": "125339"
                                    },
                                    {
                                        "value": 14000400060000,
                                        "label": "销售行政主管",
                                        "extra": {
                                            "shortPinyin": "xsxzzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125341",
                                        "parentSerial": "125339"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125339",
                                "parentSerial": "125260"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125260",
                        "parentSerial": null
                    },
                    {
                        "value": 1000002,
                        "label": "人事/行政/党群",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000014,
                                "label": "人力资源",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000600100000,
                                        "label": "人力资源专员/助理",
                                        "extra": {
                                            "shortPinyin": "rlzyzy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125344",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600090000,
                                        "label": "人力资源经理/主管",
                                        "extra": {
                                            "shortPinyin": "rlzyjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125345",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600110000,
                                        "label": "人力资源总监",
                                        "extra": {
                                            "shortPinyin": "rlzyzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125346",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600200000,
                                        "label": "人力资源VP/CHO",
                                        "extra": {
                                            "shortPinyin": "rlzyvp/cho",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125347",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600010000,
                                        "label": "HRBP",
                                        "extra": {
                                            "shortPinyin": "hrbp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125348",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600160000,
                                        "label": "招聘专员/助理",
                                        "extra": {
                                            "shortPinyin": "zp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125349",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600190000,
                                        "label": "招聘经理/主管",
                                        "extra": {
                                            "shortPinyin": "zpjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125350",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600040000,
                                        "label": "绩效考核专员",
                                        "extra": {
                                            "shortPinyin": "jxkhzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125351",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600030000,
                                        "label": "绩效考核经理",
                                        "extra": {
                                            "shortPinyin": "jxkhjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125352",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600140000,
                                        "label": "薪酬福利",
                                        "extra": {
                                            "shortPinyin": "xcfl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125353",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600060000,
                                        "label": "培训专员/助理",
                                        "extra": {
                                            "shortPinyin": "pxzy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125354",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600050000,
                                        "label": "培训经理/主管",
                                        "extra": {
                                            "shortPinyin": "pxjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125355",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600150000,
                                        "label": "员工关系",
                                        "extra": {
                                            "shortPinyin": "yggx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125356",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600070000,
                                        "label": "企业文化",
                                        "extra": {
                                            "shortPinyin": "qywh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125357",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600130000,
                                        "label": "社保专员",
                                        "extra": {
                                            "shortPinyin": "sbzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125358",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600170000,
                                        "label": "组织发展（OD）",
                                        "extra": {
                                            "shortPinyin": "zzfz（od）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125359",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600120000,
                                        "label": "人事信息系统(HRIS)管理",
                                        "extra": {
                                            "shortPinyin": "rsxxxt(hris)gl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125360",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600180000,
                                        "label": "人事测评",
                                        "extra": {
                                            "shortPinyin": "rscp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125361",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 6001400030000,
                                        "label": "猎头顾问",
                                        "extra": {
                                            "shortPinyin": "ltgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125362",
                                        "parentSerial": "125343"
                                    },
                                    {
                                        "value": 14000600080000,
                                        "label": "人力资源咨询顾问",
                                        "extra": {
                                            "shortPinyin": "rlzyzxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125363",
                                        "parentSerial": "125343"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125343",
                                "parentSerial": "125342"
                            },
                            {
                                "value": 2000015,
                                "label": "行政",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000400030000,
                                        "label": "行政专员/助理",
                                        "extra": {
                                            "shortPinyin": "xzzy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125365",
                                        "parentSerial": "125364"
                                    },
                                    {
                                        "value": 14000400080000,
                                        "label": "行政经理/主管",
                                        "extra": {
                                            "shortPinyin": "xzjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125366",
                                        "parentSerial": "125364"
                                    },
                                    {
                                        "value": 14000400040000,
                                        "label": "行政总监",
                                        "extra": {
                                            "shortPinyin": "xzzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125367",
                                        "parentSerial": "125364"
                                    },
                                    {
                                        "value": 14000700080000,
                                        "label": "前台",
                                        "extra": {
                                            "shortPinyin": "qt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125368",
                                        "parentSerial": "125364"
                                    },
                                    {
                                        "value": 14000400050000,
                                        "label": "后勤",
                                        "extra": {
                                            "shortPinyin": "hq",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125369",
                                        "parentSerial": "125364"
                                    },
                                    {
                                        "value": 14000400060000,
                                        "label": "销售行政主管",
                                        "extra": {
                                            "shortPinyin": "xsxzzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125370",
                                        "parentSerial": "125364"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125364",
                                "parentSerial": "125342"
                            },
                            {
                                "value": 2000016,
                                "label": "文员/助理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000700060000,
                                        "label": "秘书/文员",
                                        "extra": {
                                            "shortPinyin": "ms/wy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125372",
                                        "parentSerial": "125371"
                                    },
                                    {
                                        "value": 3000200190000,
                                        "label": "企业秘书",
                                        "extra": {
                                            "shortPinyin": "qyms/dshms",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125373",
                                        "parentSerial": "125371"
                                    },
                                    {
                                        "value": 2000200040000,
                                        "label": "仓库文员",
                                        "extra": {
                                            "shortPinyin": "ckwy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125374",
                                        "parentSerial": "125371"
                                    },
                                    {
                                        "value": 14000400070000,
                                        "label": "销售助理",
                                        "extra": {
                                            "shortPinyin": "xszl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125375",
                                        "parentSerial": "125371"
                                    },
                                    {
                                        "value": 14000700110000,
                                        "label": "统计员",
                                        "extra": {
                                            "shortPinyin": "tjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125376",
                                        "parentSerial": "125371"
                                    },
                                    {
                                        "value": 14000700040000,
                                        "label": "录入员",
                                        "extra": {
                                            "shortPinyin": "lry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125377",
                                        "parentSerial": "125371"
                                    },
                                    {
                                        "value": 14000700050000,
                                        "label": "订单处理员",
                                        "extra": {
                                            "shortPinyin": "ddcly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125378",
                                        "parentSerial": "125371"
                                    },
                                    {
                                        "value": 14000700010000,
                                        "label": "IT技术文员/助理",
                                        "extra": {
                                            "shortPinyin": "itjswy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125379",
                                        "parentSerial": "125371"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125371",
                                "parentSerial": "125342"
                            },
                            {
                                "value": 2000017,
                                "label": "档案管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000200040000,
                                        "label": "资料管理",
                                        "extra": {
                                            "shortPinyin": "zlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125381",
                                        "parentSerial": "125380"
                                    },
                                    {
                                        "value": 14000200010000,
                                        "label": "档案管理",
                                        "extra": {
                                            "shortPinyin": "dagl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125382",
                                        "parentSerial": "125380"
                                    },
                                    {
                                        "value": 14000200020000,
                                        "label": "合同管理",
                                        "extra": {
                                            "shortPinyin": "htgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125383",
                                        "parentSerial": "125380"
                                    },
                                    {
                                        "value": 14000200030000,
                                        "label": "图书管理",
                                        "extra": {
                                            "shortPinyin": "tsgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125384",
                                        "parentSerial": "125380"
                                    },
                                    {
                                        "value": 14000700130000,
                                        "label": "合约专员",
                                        "extra": {
                                            "shortPinyin": "xmjhhyzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125385",
                                        "parentSerial": "125380"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125380",
                                "parentSerial": "125342"
                            },
                            {
                                "value": 2000018,
                                "label": "党群/工会",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000400010000,
                                        "label": "党务/党群",
                                        "extra": {
                                            "shortPinyin": "dwdq",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125387",
                                        "parentSerial": "125386"
                                    },
                                    {
                                        "value": 14000600020000,
                                        "label": "工会干事",
                                        "extra": {
                                            "shortPinyin": "ghgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125388",
                                        "parentSerial": "125386"
                                    },
                                    {
                                        "value": 14000400090000,
                                        "label": "纪检监察",
                                        "extra": {
                                            "shortPinyin": "jjjc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178390",
                                        "parentSerial": "125386"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125386",
                                "parentSerial": "125342"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125342",
                        "parentSerial": null
                    },
                    {
                        "value": 1000003,
                        "label": "财务/法务",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000019,
                                "label": "财务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000100050000,
                                        "label": "财务助理",
                                        "extra": {
                                            "shortPinyin": "cwzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125391",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100220000,
                                        "label": "财务专员",
                                        "extra": {
                                            "shortPinyin": "cwzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125392",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100040000,
                                        "label": "财务主管",
                                        "extra": {
                                            "shortPinyin": "cwzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125393",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100030000,
                                        "label": "财务经理",
                                        "extra": {
                                            "shortPinyin": "cwjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125394",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100060000,
                                        "label": "财务总监",
                                        "extra": {
                                            "shortPinyin": "cwzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125395",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 3000200030000,
                                        "label": "CFO",
                                        "extra": {
                                            "shortPinyin": "cfo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125396",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100100000,
                                        "label": "出纳",
                                        "extra": {
                                            "shortPinyin": "cn",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125397",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100230000,
                                        "label": "会计",
                                        "extra": {
                                            "shortPinyin": "hj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125398",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100150000,
                                        "label": "会计助理",
                                        "extra": {
                                            "shortPinyin": "hjzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125399",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100140000,
                                        "label": "会计经理",
                                        "extra": {
                                            "shortPinyin": "hjjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125400",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100210000,
                                        "label": "总账会计",
                                        "extra": {
                                            "shortPinyin": "zzhj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125401",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100120000,
                                        "label": "固定资产会计",
                                        "extra": {
                                            "shortPinyin": "gdzchj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125402",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100180000,
                                        "label": "注册会计师",
                                        "extra": {
                                            "shortPinyin": "zchjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125403",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100080000,
                                        "label": "成本会计",
                                        "extra": {
                                            "shortPinyin": "cbhj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125404",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100090000,
                                        "label": "成本经理/主管",
                                        "extra": {
                                            "shortPinyin": "cbjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125405",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100070000,
                                        "label": "成本管理",
                                        "extra": {
                                            "shortPinyin": "cbgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125406",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100160000,
                                        "label": "审计",
                                        "extra": {
                                            "shortPinyin": "sj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125407",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100240000,
                                        "label": "审计经理",
                                        "extra": {
                                            "shortPinyin": "sjjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125408",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100170000,
                                        "label": "税务",
                                        "extra": {
                                            "shortPinyin": "sw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125409",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100190000,
                                        "label": "资产管理",
                                        "extra": {
                                            "shortPinyin": "zc/zjgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125410",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100200000,
                                        "label": "资金管理",
                                        "extra": {
                                            "shortPinyin": "zjzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125411",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100010000,
                                        "label": "财务分析",
                                        "extra": {
                                            "shortPinyin": "cwfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125412",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100020000,
                                        "label": "财务顾问",
                                        "extra": {
                                            "shortPinyin": "cwgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125413",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 6001400020000,
                                        "label": "财务咨询顾问",
                                        "extra": {
                                            "shortPinyin": "cwzxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125414",
                                        "parentSerial": "125390"
                                    },
                                    {
                                        "value": 14000100130000,
                                        "label": "核销员",
                                        "extra": {
                                            "shortPinyin": "hxy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125415",
                                        "parentSerial": "125390"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125390",
                                "parentSerial": "125389"
                            },
                            {
                                "value": 2000020,
                                "label": "合规风控/法务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000500030000,
                                        "label": "法务专员/助理",
                                        "extra": {
                                            "shortPinyin": "gsfw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125417",
                                        "parentSerial": "125416"
                                    },
                                    {
                                        "value": 14000500120000,
                                        "label": "法务经理/主管",
                                        "extra": {
                                            "shortPinyin": "fwjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125418",
                                        "parentSerial": "125416"
                                    },
                                    {
                                        "value": 14000500150000,
                                        "label": "法务总监",
                                        "extra": {
                                            "shortPinyin": "fwzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125419",
                                        "parentSerial": "125416"
                                    },
                                    {
                                        "value": 14000500020000,
                                        "label": "风控",
                                        "extra": {
                                            "shortPinyin": "fk",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125420",
                                        "parentSerial": "125416"
                                    },
                                    {
                                        "value": 14000500040000,
                                        "label": "合规稽查",
                                        "extra": {
                                            "shortPinyin": "hgjc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125421",
                                        "parentSerial": "125416"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125416",
                                "parentSerial": "125389"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125389",
                        "parentSerial": null
                    },
                    {
                        "value": 1000004,
                        "label": "技术",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000021,
                                "label": "后端开发",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000300110000,
                                        "label": "Java",
                                        "extra": {
                                            "shortPinyin": "java",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125424",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300010000,
                                        "label": ".NET",
                                        "extra": {
                                            "shortPinyin": ".net",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125425",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300030000,
                                        "label": "C#",
                                        "extra": {
                                            "shortPinyin": "c#",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125426",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300040000,
                                        "label": "C++",
                                        "extra": {
                                            "shortPinyin": "c++",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125427",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300020000,
                                        "label": "C",
                                        "extra": {
                                            "shortPinyin": "c",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125428",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300150000,
                                        "label": "PHP",
                                        "extra": {
                                            "shortPinyin": "php",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125429",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300160000,
                                        "label": "Python",
                                        "extra": {
                                            "shortPinyin": "python",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125430",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300080000,
                                        "label": "GIS",
                                        "extra": {
                                            "shortPinyin": "gis",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125431",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300090000,
                                        "label": "Golang",
                                        "extra": {
                                            "shortPinyin": "golang",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125432",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300050000,
                                        "label": "Delphi",
                                        "extra": {
                                            "shortPinyin": "delphi",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125433",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300130000,
                                        "label": "Node.js",
                                        "extra": {
                                            "shortPinyin": "node.js",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125434",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300180000,
                                        "label": "VB",
                                        "extra": {
                                            "shortPinyin": "vb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125435",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300060000,
                                        "label": "Erlang",
                                        "extra": {
                                            "shortPinyin": "erlang",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125436",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300170000,
                                        "label": "Ruby",
                                        "extra": {
                                            "shortPinyin": "ruby",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125437",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300140000,
                                        "label": "Perl",
                                        "extra": {
                                            "shortPinyin": "perl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125438",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300120000,
                                        "label": "mano",
                                        "extra": {
                                            "shortPinyin": "mano",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125439",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300290000,
                                        "label": "全栈工程师",
                                        "extra": {
                                            "shortPinyin": "qzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125440",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300220000,
                                        "label": "嵌入式软件开发",
                                        "extra": {
                                            "shortPinyin": "qrsrjkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125441",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300200000,
                                        "label": "脚本开发",
                                        "extra": {
                                            "shortPinyin": "jbkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125442",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300250000,
                                        "label": "需求分析工程师",
                                        "extra": {
                                            "shortPinyin": "xqfxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125443",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300210000,
                                        "label": "配置管理",
                                        "extra": {
                                            "shortPinyin": "pzgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125444",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300240000,
                                        "label": "系统集成",
                                        "extra": {
                                            "shortPinyin": "xtjc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125445",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300280000,
                                        "label": "云计算",
                                        "extra": {
                                            "shortPinyin": "yjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125446",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300270000,
                                        "label": "语音/视频/图形开发",
                                        "extra": {
                                            "shortPinyin": "yy/sp/txkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125447",
                                        "parentSerial": "125423"
                                    },
                                    {
                                        "value": 9000300070000,
                                        "label": "ERP技术/应用",
                                        "extra": {
                                            "shortPinyin": "erpjs/yy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125448",
                                        "parentSerial": "125423"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125423",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000022,
                                "label": "前端开发",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000100030000,
                                        "label": "web前端",
                                        "extra": {
                                            "shortPinyin": "webqd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125450",
                                        "parentSerial": "125449"
                                    },
                                    {
                                        "value": 9000100010000,
                                        "label": "HTML5",
                                        "extra": {
                                            "shortPinyin": "html5",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125451",
                                        "parentSerial": "125449"
                                    },
                                    {
                                        "value": 9000100020000,
                                        "label": "JavaScript",
                                        "extra": {
                                            "shortPinyin": "javascript",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125452",
                                        "parentSerial": "125449"
                                    },
                                    {
                                        "value": 9000100040000,
                                        "label": "前端开发",
                                        "extra": {
                                            "shortPinyin": "qdkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125453",
                                        "parentSerial": "125449"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125449",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000023,
                                "label": "移动研发",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000600010000,
                                        "label": "Android",
                                        "extra": {
                                            "shortPinyin": "android",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125455",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600040000,
                                        "label": "iOS",
                                        "extra": {
                                            "shortPinyin": "ios",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125456",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600050000,
                                        "label": "U3D",
                                        "extra": {
                                            "shortPinyin": "u3d",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125457",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600120000,
                                        "label": "UE4",
                                        "extra": {
                                            "shortPinyin": "ue4",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125458",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600030000,
                                        "label": "COCOS2DX",
                                        "extra": {
                                            "shortPinyin": "cocos2dx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125459",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600090000,
                                        "label": "小游戏开发",
                                        "extra": {
                                            "shortPinyin": "xyx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125460",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600070000,
                                        "label": "微信开发",
                                        "extra": {
                                            "shortPinyin": "wx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125461",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600080000,
                                        "label": "小程序开发",
                                        "extra": {
                                            "shortPinyin": "xcx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125462",
                                        "parentSerial": "125454"
                                    },
                                    {
                                        "value": 9000600110000,
                                        "label": "移动开发",
                                        "extra": {
                                            "shortPinyin": "ydkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125463",
                                        "parentSerial": "125454"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125454",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000024,
                                "label": "数据",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000400100000,
                                        "label": "数据挖掘",
                                        "extra": {
                                            "shortPinyin": "sjwj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125465",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400090000,
                                        "label": "数据开发",
                                        "extra": {
                                            "shortPinyin": "sjkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125466",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400050000,
                                        "label": "数据采集",
                                        "extra": {
                                            "shortPinyin": "sjcj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125467",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400060000,
                                        "label": "数据仓库",
                                        "extra": {
                                            "shortPinyin": "sjck",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125468",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400110000,
                                        "label": "数据库开发",
                                        "extra": {
                                            "shortPinyin": "sjkkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125469",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400080000,
                                        "label": "数据架构师",
                                        "extra": {
                                            "shortPinyin": "sjjgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125470",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400020000,
                                        "label": "ETL工程师",
                                        "extra": {
                                            "shortPinyin": "etlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125471",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400010000,
                                        "label": "BI",
                                        "extra": {
                                            "shortPinyin": "bi",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125472",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400040000,
                                        "label": "爬虫",
                                        "extra": {
                                            "shortPinyin": "pc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125473",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400030000,
                                        "label": "Hadoop",
                                        "extra": {
                                            "shortPinyin": "hadoop",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125474",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 9000400070000,
                                        "label": "数据分析师",
                                        "extra": {
                                            "shortPinyin": "sjfxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125475",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 5000500070000,
                                        "label": "销售数据分析",
                                        "extra": {
                                            "shortPinyin": "xssjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125476",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 5000500040000,
                                        "label": "商业数据分析",
                                        "extra": {
                                            "shortPinyin": "sysjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125477",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 5000500030000,
                                        "label": "金融数据分析",
                                        "extra": {
                                            "shortPinyin": "jrsjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125478",
                                        "parentSerial": "125464"
                                    },
                                    {
                                        "value": 10000100100000,
                                        "label": "临床数据分析",
                                        "extra": {
                                            "shortPinyin": "lcsjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125479",
                                        "parentSerial": "125464"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125464",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000025,
                                "label": "人工智能/算法",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000200010000,
                                        "label": "机器视觉",
                                        "extra": {
                                            "shortPinyin": "jqsjsf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125481",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200080000,
                                        "label": "图像识别",
                                        "extra": {
                                            "shortPinyin": "txsb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125482",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200100000,
                                        "label": "自然语言处理",
                                        "extra": {
                                            "shortPinyin": "zryycl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125483",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200130000,
                                        "label": "语音识别",
                                        "extra": {
                                            "shortPinyin": "yysb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125484",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200170000,
                                        "label": "音视频算法",
                                        "extra": {
                                            "shortPinyin": "yspsf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125485",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200140000,
                                        "label": "机器人算法",
                                        "extra": {
                                            "shortPinyin": "jqrsf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125486",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200160000,
                                        "label": "导航算法",
                                        "extra": {
                                            "shortPinyin": "dhsf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125487",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200020000,
                                        "label": "机器学习",
                                        "extra": {
                                            "shortPinyin": "jqxxsf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125488",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200050000,
                                        "label": "深度学习",
                                        "extra": {
                                            "shortPinyin": "sdxx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125489",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200120000,
                                        "label": "推荐算法",
                                        "extra": {
                                            "shortPinyin": "tjsf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125490",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200110000,
                                        "label": "搜索算法",
                                        "extra": {
                                            "shortPinyin": "sssf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125491",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200150000,
                                        "label": "反欺诈/风控算法",
                                        "extra": {
                                            "shortPinyin": "fqz/fksf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125492",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200070000,
                                        "label": "算法工程师",
                                        "extra": {
                                            "shortPinyin": "sfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125493",
                                        "parentSerial": "125480"
                                    },
                                    {
                                        "value": 9000200180000,
                                        "label": "智能驾驶系统工程师",
                                        "extra": {
                                            "shortPinyin": "znjsxtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125494",
                                        "parentSerial": "125480"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125480",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000026,
                                "label": "IT技术管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000300190000,
                                        "label": "架构师",
                                        "extra": {
                                            "shortPinyin": "jgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125496",
                                        "parentSerial": "125495"
                                    },
                                    {
                                        "value": 9000300260000,
                                        "label": "研发经理",
                                        "extra": {
                                            "shortPinyin": "yfjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125497",
                                        "parentSerial": "125495"
                                    },
                                    {
                                        "value": 9000300100000,
                                        "label": "IT技术/研发总监",
                                        "extra": {
                                            "shortPinyin": "itjs/yfzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125498",
                                        "parentSerial": "125495"
                                    },
                                    {
                                        "value": 3000200070000,
                                        "label": "CTO",
                                        "extra": {
                                            "shortPinyin": "cto",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125499",
                                        "parentSerial": "125495"
                                    },
                                    {
                                        "value": 3000200040000,
                                        "label": "CIO",
                                        "extra": {
                                            "shortPinyin": "cio",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125500",
                                        "parentSerial": "125495"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125495",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000027,
                                "label": "测试",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 20000100060000,
                                        "label": "软件测试",
                                        "extra": {
                                            "shortPinyin": "rjcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125502",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100050000,
                                        "label": "功能测试",
                                        "extra": {
                                            "shortPinyin": "gncs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125503",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100150000,
                                        "label": "自动化测试",
                                        "extra": {
                                            "shortPinyin": "zdhcsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125504",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100030000,
                                        "label": "测试开发",
                                        "extra": {
                                            "shortPinyin": "cskf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125505",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100110000,
                                        "label": "性能测试",
                                        "extra": {
                                            "shortPinyin": "xncs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125506",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100090000,
                                        "label": "系统测试",
                                        "extra": {
                                            "shortPinyin": "xtcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125507",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100120000,
                                        "label": "移动端测试",
                                        "extra": {
                                            "shortPinyin": "yddcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125508",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100100000,
                                        "label": "协议测试",
                                        "extra": {
                                            "shortPinyin": "xycs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125509",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100160000,
                                        "label": "渗透测试",
                                        "extra": {
                                            "shortPinyin": "stcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125510",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100140000,
                                        "label": "游戏测试",
                                        "extra": {
                                            "shortPinyin": "yxcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125511",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100130000,
                                        "label": "硬件测试",
                                        "extra": {
                                            "shortPinyin": "yjcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125512",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100020000,
                                        "label": "通信测试",
                                        "extra": {
                                            "shortPinyin": "txcsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125513",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100070000,
                                        "label": "无人机组装测试",
                                        "extra": {
                                            "shortPinyin": "wrjzzcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125514",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100010000,
                                        "label": "测试工程师",
                                        "extra": {
                                            "shortPinyin": "cs/kkxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125515",
                                        "parentSerial": "125501"
                                    },
                                    {
                                        "value": 20000100170000,
                                        "label": "测试经理",
                                        "extra": {
                                            "shortPinyin": "csjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125516",
                                        "parentSerial": "125501"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125501",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000028,
                                "label": "运维",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 20000200320000,
                                        "label": "运维工程师",
                                        "extra": {
                                            "shortPinyin": "ywgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125518",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200330000,
                                        "label": "运维开发工程师",
                                        "extra": {
                                            "shortPinyin": "ywkfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125519",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200340000,
                                        "label": "运维总监",
                                        "extra": {
                                            "shortPinyin": "ywzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125520",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200260000,
                                        "label": "系统工程师",
                                        "extra": {
                                            "shortPinyin": "xtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125521",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200190000,
                                        "label": "网络工程师",
                                        "extra": {
                                            "shortPinyin": "wlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125522",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200220000,
                                        "label": "网络运维",
                                        "extra": {
                                            "shortPinyin": "wlyw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125523",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200200000,
                                        "label": "网络管理员",
                                        "extra": {
                                            "shortPinyin": "wlgly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125524",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200210000,
                                        "label": "网络信息安全工程师",
                                        "extra": {
                                            "shortPinyin": "wlxxaqgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125525",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200350000,
                                        "label": "系统安全",
                                        "extra": {
                                            "shortPinyin": "xtaq",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125526",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200250000,
                                        "label": "系统管理员",
                                        "extra": {
                                            "shortPinyin": "xtgly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125527",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200230000,
                                        "label": "网优工程师",
                                        "extra": {
                                            "shortPinyin": "wygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125528",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200180000,
                                        "label": "DBA",
                                        "extra": {
                                            "shortPinyin": "dba",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125529",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200040000,
                                        "label": "Helpdesk",
                                        "extra": {
                                            "shortPinyin": "helpdesk",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125530",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200020000,
                                        "label": "ERP实施顾问",
                                        "extra": {
                                            "shortPinyin": "erpssgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125531",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200060000,
                                        "label": "IT技术支持",
                                        "extra": {
                                            "shortPinyin": "itjszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125532",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200070000,
                                        "label": "IT文档工程师",
                                        "extra": {
                                            "shortPinyin": "itwdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125533",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200080000,
                                        "label": "IT质量管理工程师",
                                        "extra": {
                                            "shortPinyin": "itzlglgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125534",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200090000,
                                        "label": "IT质量管理经理/主管",
                                        "extra": {
                                            "shortPinyin": "itzlgljl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125535",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200300000,
                                        "label": "信息技术专员",
                                        "extra": {
                                            "shortPinyin": "xxjszy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125536",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200290000,
                                        "label": "信息技术经理/主管",
                                        "extra": {
                                            "shortPinyin": "xxjsjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125537",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200280000,
                                        "label": "信息技术标准化工程师",
                                        "extra": {
                                            "shortPinyin": "xxjsbzhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125538",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200100000,
                                        "label": "标准化工程师",
                                        "extra": {
                                            "shortPinyin": "bzhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125539",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200270000,
                                        "label": "现场应用工程师（FAE）",
                                        "extra": {
                                            "shortPinyin": "xcyygcs（fae）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125540",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200170000,
                                        "label": "设备维护工程师",
                                        "extra": {
                                            "shortPinyin": "sbwhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125541",
                                        "parentSerial": "125517"
                                    },
                                    {
                                        "value": 20000200150000,
                                        "label": "计算机硬件维护工程师",
                                        "extra": {
                                            "shortPinyin": "jsjyjwhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125542",
                                        "parentSerial": "125517"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125517",
                                "parentSerial": "125422"
                            },
                            {
                                "value": 2000029,
                                "label": "售前/售后/技术支持",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 13000200050000,
                                        "label": "软件售后技术支持",
                                        "extra": {
                                            "shortPinyin": "rjshjszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125544",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200120000,
                                        "label": "硬件售后技术支持",
                                        "extra": {
                                            "shortPinyin": "yjshjszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125545",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200070000,
                                        "label": "售后技术支持",
                                        "extra": {
                                            "shortPinyin": "shjszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125546",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200090000,
                                        "label": "售前顾问",
                                        "extra": {
                                            "shortPinyin": "sqgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125547",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200080000,
                                        "label": "售前技术支持",
                                        "extra": {
                                            "shortPinyin": "sqjszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125548",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200030000,
                                        "label": "解决方案工程师",
                                        "extra": {
                                            "shortPinyin": "jjfagcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125549",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200040000,
                                        "label": "现场技术支持",
                                        "extra": {
                                            "shortPinyin": "khjszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125550",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200020000,
                                        "label": "技术支持工程师",
                                        "extra": {
                                            "shortPinyin": "jszcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125551",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200060000,
                                        "label": "实施顾问",
                                        "extra": {
                                            "shortPinyin": "ssgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125552",
                                        "parentSerial": "125543"
                                    },
                                    {
                                        "value": 13000200130000,
                                        "label": "实施工程师",
                                        "extra": {
                                            "shortPinyin": "ssgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125553",
                                        "parentSerial": "125543"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125543",
                                "parentSerial": "125422"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125422",
                        "parentSerial": null
                    },
                    {
                        "value": 1000005,
                        "label": "电子/通信/半导体",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000030,
                                "label": "半导体/芯片",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000500320000,
                                        "label": "集成电路IC设计",
                                        "extra": {
                                            "shortPinyin": "jcdlicsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125556",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000700030000,
                                        "label": "数字前端工程师",
                                        "extra": {
                                            "shortPinyin": "szqdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125557",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000700040000,
                                        "label": "数字后端工程师",
                                        "extra": {
                                            "shortPinyin": "szhdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125558",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000500340000,
                                        "label": "模拟芯片设计",
                                        "extra": {
                                            "shortPinyin": "mnxpsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125559",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000800010000,
                                        "label": "模拟版图工程师",
                                        "extra": {
                                            "shortPinyin": "mnbtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125560",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000500100000,
                                        "label": "版图设计工程师",
                                        "extra": {
                                            "shortPinyin": "btsjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125561",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000500060000,
                                        "label": "IC验证工程师",
                                        "extra": {
                                            "shortPinyin": "icyzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125562",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000700050000,
                                        "label": "芯片测试工程师",
                                        "extra": {
                                            "shortPinyin": "xpcsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125563",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000800020000,
                                        "label": "工艺整合工程师（PIE）",
                                        "extra": {
                                            "shortPinyin": "gyzhgcs（pie）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125564",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000700090000,
                                        "label": "封装工程师",
                                        "extra": {
                                            "shortPinyin": "fzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125565",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000700070000,
                                        "label": "半导体设备工程师",
                                        "extra": {
                                            "shortPinyin": "bdtsbgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125566",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000700060000,
                                        "label": "半导体工艺工程师",
                                        "extra": {
                                            "shortPinyin": "bdtgygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125567",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000500110000,
                                        "label": "半导体技术",
                                        "extra": {
                                            "shortPinyin": "bdtjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125568",
                                        "parentSerial": "125555"
                                    },
                                    {
                                        "value": 9000700100000,
                                        "label": "芯片销售工程师",
                                        "extra": {
                                            "shortPinyin": "xpxsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125569",
                                        "parentSerial": "125555"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125555",
                                "parentSerial": "125554"
                            },
                            {
                                "value": 2000031,
                                "label": "电子/电路",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 15000300110000,
                                        "label": "电子工程师",
                                        "extra": {
                                            "shortPinyin": "dzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125571",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500210000,
                                        "label": "电子技术研发工程师",
                                        "extra": {
                                            "shortPinyin": "dzjsyfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125572",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500140000,
                                        "label": "电路工程师/技术员",
                                        "extra": {
                                            "shortPinyin": "dlgcs/jsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125573",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500190000,
                                        "label": "电子/电器工艺/制程工程师",
                                        "extra": {
                                            "shortPinyin": "dz/dqgy/zcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125574",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500220000,
                                        "label": "电子元器件工程师",
                                        "extra": {
                                            "shortPinyin": "dzyqjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125575",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500130000,
                                        "label": "电控工程师",
                                        "extra": {
                                            "shortPinyin": "dkgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125576",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500200000,
                                        "label": "电子工程设计",
                                        "extra": {
                                            "shortPinyin": "dzgcsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125577",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000700020000,
                                        "label": "SMT工程师",
                                        "extra": {
                                            "shortPinyin": "smtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125578",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000700010000,
                                        "label": "电磁兼容工程师",
                                        "extra": {
                                            "shortPinyin": "dcjrgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125579",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500430000,
                                        "label": "线路结构设计",
                                        "extra": {
                                            "shortPinyin": "xljgsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125580",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000700080000,
                                        "label": "失效分析工程师（FA）",
                                        "extra": {
                                            "shortPinyin": "sxfxgcs（fa）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125581",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000500310000,
                                        "label": "激光/光电子技术",
                                        "extra": {
                                            "shortPinyin": "jg/gdzjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125582",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 15000300280000,
                                        "label": "激光工程师",
                                        "extra": {
                                            "shortPinyin": "jggcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125583",
                                        "parentSerial": "125570"
                                    },
                                    {
                                        "value": 9000700110000,
                                        "label": "电子元器件销售工程师",
                                        "extra": {
                                            "shortPinyin": "dzyqjxsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178391",
                                        "parentSerial": "125570"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125570",
                                "parentSerial": "125554"
                            },
                            {
                                "value": 2000032,
                                "label": "电气/仪器/自动化",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000500150000,
                                        "label": "电气工程师",
                                        "extra": {
                                            "shortPinyin": "dqgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125585",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 15000300590000,
                                        "label": "自动化工程师",
                                        "extra": {
                                            "shortPinyin": "zdhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125586",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 10000200160000,
                                        "label": "自动化设计工程师",
                                        "extra": {
                                            "shortPinyin": "zdhsjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125587",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 9000500160000,
                                        "label": "电气线路设计",
                                        "extra": {
                                            "shortPinyin": "dqxlsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125588",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 10000200040000,
                                        "label": "电器研发",
                                        "extra": {
                                            "shortPinyin": "dqyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125589",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 20000200120000,
                                        "label": "电子/电器设备工程师",
                                        "extra": {
                                            "shortPinyin": "dz/dqsbgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125590",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 3000300040000,
                                        "label": "电子/电器项目经理",
                                        "extra": {
                                            "shortPinyin": "dz/dqxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125591",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 10000200100000,
                                        "label": "家电/数码产品研发",
                                        "extra": {
                                            "shortPinyin": "jd/smcpyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125592",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 15000300520000,
                                        "label": "仪器/仪表工程师",
                                        "extra": {
                                            "shortPinyin": "yq/yb/jlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125593",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 10000200030000,
                                        "label": "电池工程师",
                                        "extra": {
                                            "shortPinyin": "dcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125594",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 20000100040000,
                                        "label": "电声/音响工程师/技术员",
                                        "extra": {
                                            "shortPinyin": "ds/yxgcs/jsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125595",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 10000200110000,
                                        "label": "空调工程/设计",
                                        "extra": {
                                            "shortPinyin": "kdgc/sj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125596",
                                        "parentSerial": "125584"
                                    },
                                    {
                                        "value": 15000300060000,
                                        "label": "变压器与磁电工程师",
                                        "extra": {
                                            "shortPinyin": "byqycdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125597",
                                        "parentSerial": "125584"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125584",
                                "parentSerial": "125554"
                            },
                            {
                                "value": 2000033,
                                "label": "通信",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000500410000,
                                        "label": "通信技术工程师",
                                        "extra": {
                                            "shortPinyin": "txjsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125599",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500560000,
                                        "label": "通信研发工程师",
                                        "extra": {
                                            "shortPinyin": "txyfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125600",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500510000,
                                        "label": "数据通信工程师",
                                        "extra": {
                                            "shortPinyin": "sjtxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125601",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500440000,
                                        "label": "移动通信工程师",
                                        "extra": {
                                            "shortPinyin": "ydtxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125602",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500500000,
                                        "label": "无线通信工程师",
                                        "extra": {
                                            "shortPinyin": "wxtxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125603",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500420000,
                                        "label": "无线电工程师",
                                        "extra": {
                                            "shortPinyin": "wxdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125604",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500180000,
                                        "label": "电信网络工程师",
                                        "extra": {
                                            "shortPinyin": "dxwlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125605",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500170000,
                                        "label": "电信交换工程师",
                                        "extra": {
                                            "shortPinyin": "dxjhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125606",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500480000,
                                        "label": "有线传输工程师",
                                        "extra": {
                                            "shortPinyin": "yxcsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125607",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500380000,
                                        "label": "室分设计工程师",
                                        "extra": {
                                            "shortPinyin": "sfsjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125608",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500010000,
                                        "label": "5G",
                                        "extra": {
                                            "shortPinyin": "5g",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125609",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500290000,
                                        "label": "核心网工程师",
                                        "extra": {
                                            "shortPinyin": "hxwgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125610",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500270000,
                                        "label": "光通信工程师",
                                        "extra": {
                                            "shortPinyin": "gtxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125611",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500280000,
                                        "label": "光网络工程师",
                                        "extra": {
                                            "shortPinyin": "gwlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125612",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500250000,
                                        "label": "光传输工程师",
                                        "extra": {
                                            "shortPinyin": "gcsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125613",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500260000,
                                        "label": "光伏系统工程师",
                                        "extra": {
                                            "shortPinyin": "gfxtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125614",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500400000,
                                        "label": "通信电源工程师",
                                        "extra": {
                                            "shortPinyin": "txdygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125615",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500390000,
                                        "label": "通信标准化工程师",
                                        "extra": {
                                            "shortPinyin": "txbzhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125616",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500550000,
                                        "label": "通信设备工程师",
                                        "extra": {
                                            "shortPinyin": "txsbgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125617",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 3000300090000,
                                        "label": "通信项目经理",
                                        "extra": {
                                            "shortPinyin": "txxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125618",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 20000100020000,
                                        "label": "通信测试",
                                        "extra": {
                                            "shortPinyin": "txcsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125619",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 9000500530000,
                                        "label": "基站工程师",
                                        "extra": {
                                            "shortPinyin": "jzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125620",
                                        "parentSerial": "125598"
                                    },
                                    {
                                        "value": 15000300570000,
                                        "label": "转播工程师",
                                        "extra": {
                                            "shortPinyin": "zbgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125621",
                                        "parentSerial": "125598"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125598",
                                "parentSerial": "125554"
                            },
                            {
                                "value": 2000034,
                                "label": "硬件",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000500460000,
                                        "label": "硬件工程师",
                                        "extra": {
                                            "shortPinyin": "yjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125623",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500350000,
                                        "label": "嵌入式硬件",
                                        "extra": {
                                            "shortPinyin": "qrsyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125624",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500120000,
                                        "label": "单片机",
                                        "extra": {
                                            "shortPinyin": "dpj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125625",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500070000,
                                        "label": "PCB工程师",
                                        "extra": {
                                            "shortPinyin": "pcbgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125626",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500050000,
                                        "label": "FPGA开发",
                                        "extra": {
                                            "shortPinyin": "fpgakf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125627",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500020000,
                                        "label": "ARM开发",
                                        "extra": {
                                            "shortPinyin": "armkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125628",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500040000,
                                        "label": "DSP开发",
                                        "extra": {
                                            "shortPinyin": "dspkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125629",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500080000,
                                        "label": "PLC工程师",
                                        "extra": {
                                            "shortPinyin": "plcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125630",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500540000,
                                        "label": "驱动开发",
                                        "extra": {
                                            "shortPinyin": "qdkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125631",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500470000,
                                        "label": "硬件交互设计师",
                                        "extra": {
                                            "shortPinyin": "yjjhsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125632",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500360000,
                                        "label": "射频工程师",
                                        "extra": {
                                            "shortPinyin": "spgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125633",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 20000100130000,
                                        "label": "硬件测试",
                                        "extra": {
                                            "shortPinyin": "yjcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125634",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 13000200120000,
                                        "label": "硬件售后技术支持",
                                        "extra": {
                                            "shortPinyin": "yjshjszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125635",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 3000100180000,
                                        "label": "硬件产品经理",
                                        "extra": {
                                            "shortPinyin": "yjcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125636",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 3000300230000,
                                        "label": "硬件项目经理",
                                        "extra": {
                                            "shortPinyin": "yjxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125637",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 15000300160000,
                                        "label": "工业机器人工程师",
                                        "extra": {
                                            "shortPinyin": "gyjqrgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125638",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 15000300640000,
                                        "label": "机器人调试工程师",
                                        "extra": {
                                            "shortPinyin": "jqrtsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "178392",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500520000,
                                        "label": "无人机工程师",
                                        "extra": {
                                            "shortPinyin": "wrjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125639",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500230000,
                                        "label": "仿真应用工程师",
                                        "extra": {
                                            "shortPinyin": "fzyygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125640",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500090000,
                                        "label": "安防系统工程师",
                                        "extra": {
                                            "shortPinyin": "afxtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125641",
                                        "parentSerial": "125622"
                                    },
                                    {
                                        "value": 9000500450000,
                                        "label": "音频/视频工程师",
                                        "extra": {
                                            "shortPinyin": "yp/spgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125642",
                                        "parentSerial": "125622"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125622",
                                "parentSerial": "125554"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125554",
                        "parentSerial": null
                    },
                    {
                        "value": 1000006,
                        "label": "产品",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000035,
                                "label": "互联网产品经理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 3000100110000,
                                        "label": "软件产品经理",
                                        "extra": {
                                            "shortPinyin": "rjcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125647",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100080000,
                                        "label": "电商产品经理",
                                        "extra": {
                                            "shortPinyin": "dscpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125648",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100140000,
                                        "label": "数据产品经理",
                                        "extra": {
                                            "shortPinyin": "sjcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125649",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100170000,
                                        "label": "移动产品经理",
                                        "extra": {
                                            "shortPinyin": "ydcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125652",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100090000,
                                        "label": "供应链产品经理",
                                        "extra": {
                                            "shortPinyin": "gylcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125650",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100010000,
                                        "label": "安全产品经理",
                                        "extra": {
                                            "shortPinyin": "aqcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125651",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100020000,
                                        "label": "策略产品经理",
                                        "extra": {
                                            "shortPinyin": "clcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125653",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100210000,
                                        "label": "AI产品经理",
                                        "extra": {
                                            "shortPinyin": "aicpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486852",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100160000,
                                        "label": "增长产品经理",
                                        "extra": {
                                            "shortPinyin": "zccpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125656",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100150000,
                                        "label": "网页产品经理",
                                        "extra": {
                                            "shortPinyin": "wycpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125654",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100120000,
                                        "label": "商业化产品经理",
                                        "extra": {
                                            "shortPinyin": "syhcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125655",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100040000,
                                        "label": "产品经理",
                                        "extra": {
                                            "shortPinyin": "cpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125645",
                                        "parentSerial": "125644"
                                    },
                                    {
                                        "value": 3000100070000,
                                        "label": "产品总监/VP",
                                        "extra": {
                                            "shortPinyin": "cpzj/vp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125646",
                                        "parentSerial": "125644"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125644",
                                "parentSerial": "125643"
                            },
                            {
                                "value": 2000036,
                                "label": "其他产品经理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 3000100050000,
                                        "label": "产品专员",
                                        "extra": {
                                            "shortPinyin": "cpzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125658",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 14000700020000,
                                        "label": "产品助理",
                                        "extra": {
                                            "shortPinyin": "cpzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125659",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 3000100040000,
                                        "label": "产品经理",
                                        "extra": {
                                            "shortPinyin": "cpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125660",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 3000100070000,
                                        "label": "产品总监/VP",
                                        "extra": {
                                            "shortPinyin": "cpzj/vp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125661",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 17000500140000,
                                        "label": "游戏策划",
                                        "extra": {
                                            "shortPinyin": "yxch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125662",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 3000100190000,
                                        "label": "游戏数值策划",
                                        "extra": {
                                            "shortPinyin": "yxszch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125663",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 3000100200000,
                                        "label": "游戏制作人",
                                        "extra": {
                                            "shortPinyin": "yxzzr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125664",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 12000300110000,
                                        "label": "金融产品经理",
                                        "extra": {
                                            "shortPinyin": "jrcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125665",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 18000500120000,
                                        "label": "医药产品经理",
                                        "extra": {
                                            "shortPinyin": "yycpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125666",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 6000900110000,
                                        "label": "旅游产品经理",
                                        "extra": {
                                            "shortPinyin": "lycpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125667",
                                        "parentSerial": "125657"
                                    },
                                    {
                                        "value": 3000100180000,
                                        "label": "硬件产品经理",
                                        "extra": {
                                            "shortPinyin": "yjcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125668",
                                        "parentSerial": "125657"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125657",
                                "parentSerial": "125643"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125643",
                        "parentSerial": null
                    },
                    {
                        "value": 1000007,
                        "label": "设计",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000037,
                                "label": "视觉/交互设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000500120000,
                                        "label": "平面设计",
                                        "extra": {
                                            "shortPinyin": "pmsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125671",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500100000,
                                        "label": "美工",
                                        "extra": {
                                            "shortPinyin": "mg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125672",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500050000,
                                        "label": "UI设计师",
                                        "extra": {
                                            "shortPinyin": "uisjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125673",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500040000,
                                        "label": "UE设计师",
                                        "extra": {
                                            "shortPinyin": "uesjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125674",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500060000,
                                        "label": "UX设计师",
                                        "extra": {
                                            "shortPinyin": "uxsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125675",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500090000,
                                        "label": "交互设计师",
                                        "extra": {
                                            "shortPinyin": "jhsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125676",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500130000,
                                        "label": "网页设计师",
                                        "extra": {
                                            "shortPinyin": "wysjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125677",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500010000,
                                        "label": "3D设计师",
                                        "extra": {
                                            "shortPinyin": "3dsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125678",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500110000,
                                        "label": "美术设计师（2D/3D）",
                                        "extra": {
                                            "shortPinyin": "mssjs（2d/3d）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125679",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 14000700090000,
                                        "label": "设计师助理",
                                        "extra": {
                                            "shortPinyin": "sjszl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125680",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500190000,
                                        "label": "设计经理/主管",
                                        "extra": {
                                            "shortPinyin": "sjglry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125681",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500200000,
                                        "label": "设计总监",
                                        "extra": {
                                            "shortPinyin": "sjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125682",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500180000,
                                        "label": "主笔设计师",
                                        "extra": {
                                            "shortPinyin": "zbsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125683",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500030000,
                                        "label": "CAD设计/制图",
                                        "extra": {
                                            "shortPinyin": "cadsj/zt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125684",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500170000,
                                        "label": "原画师",
                                        "extra": {
                                            "shortPinyin": "yhs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125685",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500070000,
                                        "label": "插画师",
                                        "extra": {
                                            "shortPinyin": "chs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125686",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500220000,
                                        "label": "手绘师",
                                        "extra": {
                                            "shortPinyin": "shs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125687",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500230000,
                                        "label": "漫画师",
                                        "extra": {
                                            "shortPinyin": "mhs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125688",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500240000,
                                        "label": "人像修图师",
                                        "extra": {
                                            "shortPinyin": "rxxts",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125689",
                                        "parentSerial": "125670"
                                    },
                                    {
                                        "value": 17000500080000,
                                        "label": "计算机辅助设计师",
                                        "extra": {
                                            "shortPinyin": "jsjfzsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125690",
                                        "parentSerial": "125670"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125670",
                                "parentSerial": "125669"
                            },
                            {
                                "value": 2000038,
                                "label": "动画动效设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000200020000,
                                        "label": "动画设计",
                                        "extra": {
                                            "shortPinyin": "dhsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125692",
                                        "parentSerial": "125691"
                                    },
                                    {
                                        "value": 17000200010000,
                                        "label": "Flash设计师",
                                        "extra": {
                                            "shortPinyin": "flashsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125693",
                                        "parentSerial": "125691"
                                    },
                                    {
                                        "value": 17000200050000,
                                        "label": "特效设计",
                                        "extra": {
                                            "shortPinyin": "txsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125694",
                                        "parentSerial": "125691"
                                    },
                                    {
                                        "value": 17000200040000,
                                        "label": "角色模型师",
                                        "extra": {
                                            "shortPinyin": "jsmxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125695",
                                        "parentSerial": "125691"
                                    },
                                    {
                                        "value": 17000200030000,
                                        "label": "动漫手绘",
                                        "extra": {
                                            "shortPinyin": "dmsh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125696",
                                        "parentSerial": "125691"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125691",
                                "parentSerial": "125669"
                            },
                            {
                                "value": 2000039,
                                "label": "建筑/室内设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000300240000,
                                        "label": "建筑设计师",
                                        "extra": {
                                            "shortPinyin": "jzsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125698",
                                        "parentSerial": "125697"
                                    },
                                    {
                                        "value": 17000400120000,
                                        "label": "室内设计",
                                        "extra": {
                                            "shortPinyin": "snsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125699",
                                        "parentSerial": "125697"
                                    },
                                    {
                                        "value": 17000400040000,
                                        "label": "家装设计",
                                        "extra": {
                                            "shortPinyin": "jzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125700",
                                        "parentSerial": "125697"
                                    },
                                    {
                                        "value": 17000400030000,
                                        "label": "家居设计",
                                        "extra": {
                                            "shortPinyin": "jjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125701",
                                        "parentSerial": "125697"
                                    },
                                    {
                                        "value": 17000400100000,
                                        "label": "软装设计",
                                        "extra": {
                                            "shortPinyin": "rzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125702",
                                        "parentSerial": "125697"
                                    },
                                    {
                                        "value": 17000400140000,
                                        "label": "硬装设计",
                                        "extra": {
                                            "shortPinyin": "yzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125703",
                                        "parentSerial": "125697"
                                    },
                                    {
                                        "value": 7000300280000,
                                        "label": "空间设计师",
                                        "extra": {
                                            "shortPinyin": "kjsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125704",
                                        "parentSerial": "125697"
                                    },
                                    {
                                        "value": 17000400190000,
                                        "label": "深化设计",
                                        "extra": {
                                            "shortPinyin": "shsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125705",
                                        "parentSerial": "125697"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125697",
                                "parentSerial": "125669"
                            },
                            {
                                "value": 2000040,
                                "label": "工业设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000300060000,
                                        "label": "工业设计",
                                        "extra": {
                                            "shortPinyin": "gysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125707",
                                        "parentSerial": "125706"
                                    },
                                    {
                                        "value": 17000300080000,
                                        "label": "家具设计",
                                        "extra": {
                                            "shortPinyin": "jjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125708",
                                        "parentSerial": "125706"
                                    },
                                    {
                                        "value": 17000300030000,
                                        "label": "橱柜设计",
                                        "extra": {
                                            "shortPinyin": "cgsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125709",
                                        "parentSerial": "125706"
                                    },
                                    {
                                        "value": 17000300010000,
                                        "label": "包装设计",
                                        "extra": {
                                            "shortPinyin": "bzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125710",
                                        "parentSerial": "125706"
                                    },
                                    {
                                        "value": 17000300070000,
                                        "label": "珠宝设计",
                                        "extra": {
                                            "shortPinyin": "zbsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125711",
                                        "parentSerial": "125706"
                                    },
                                    {
                                        "value": 17000300090000,
                                        "label": "玩具设计",
                                        "extra": {
                                            "shortPinyin": "wjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125712",
                                        "parentSerial": "125706"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125706",
                                "parentSerial": "125669"
                            },
                            {
                                "value": 2000041,
                                "label": "广告设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000500210000,
                                        "label": "广告设计",
                                        "extra": {
                                            "shortPinyin": "ggsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125714",
                                        "parentSerial": "125713"
                                    },
                                    {
                                        "value": 16000500040000,
                                        "label": "广告创意设计",
                                        "extra": {
                                            "shortPinyin": "ggcysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125715",
                                        "parentSerial": "125713"
                                    },
                                    {
                                        "value": 16000100010000,
                                        "label": "广告创意/设计总监",
                                        "extra": {
                                            "shortPinyin": "ggcy/sjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125716",
                                        "parentSerial": "125713"
                                    },
                                    {
                                        "value": 16000500050000,
                                        "label": "广告美术指导",
                                        "extra": {
                                            "shortPinyin": "ggmszd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125717",
                                        "parentSerial": "125713"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125713",
                                "parentSerial": "125669"
                            },
                            {
                                "value": 2000042,
                                "label": "服装/纺织设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000300040000,
                                        "label": "服装/纺织设计",
                                        "extra": {
                                            "shortPinyin": "fz/fzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125719",
                                        "parentSerial": "125718"
                                    },
                                    {
                                        "value": 17000300050000,
                                        "label": "服装/纺织设计总监",
                                        "extra": {
                                            "shortPinyin": "fz/fzsjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125720",
                                        "parentSerial": "125718"
                                    },
                                    {
                                        "value": 17000300020000,
                                        "label": "布艺设计",
                                        "extra": {
                                            "shortPinyin": "bysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125721",
                                        "parentSerial": "125718"
                                    },
                                    {
                                        "value": 17000300100000,
                                        "label": "鞋子设计",
                                        "extra": {
                                            "shortPinyin": "xzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125722",
                                        "parentSerial": "125718"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125718",
                                "parentSerial": "125669"
                            },
                            {
                                "value": 2000043,
                                "label": "其他设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000100010000,
                                        "label": "陈列设计",
                                        "extra": {
                                            "shortPinyin": "clsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125724",
                                        "parentSerial": "125723"
                                    },
                                    {
                                        "value": 17000100030000,
                                        "label": "展览/展示设计",
                                        "extra": {
                                            "shortPinyin": "zl/zssj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125725",
                                        "parentSerial": "125723"
                                    },
                                    {
                                        "value": 17000600040000,
                                        "label": "舞美设计",
                                        "extra": {
                                            "shortPinyin": "wmsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125726",
                                        "parentSerial": "125723"
                                    },
                                    {
                                        "value": 17000600050000,
                                        "label": "舞台艺术指导",
                                        "extra": {
                                            "shortPinyin": "wtyszd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125727",
                                        "parentSerial": "125723"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125723",
                                "parentSerial": "125669"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125669",
                        "parentSerial": null
                    },
                    {
                        "value": 1000008,
                        "label": "游戏",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000044,
                                "label": "游戏策划/制作",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000500140000,
                                        "label": "游戏策划",
                                        "extra": {
                                            "shortPinyin": "yxch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125730",
                                        "parentSerial": "125729"
                                    },
                                    {
                                        "value": 3000100190000,
                                        "label": "游戏数值策划",
                                        "extra": {
                                            "shortPinyin": "yxszch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125731",
                                        "parentSerial": "125729"
                                    },
                                    {
                                        "value": 3000100200000,
                                        "label": "游戏制作人",
                                        "extra": {
                                            "shortPinyin": "yxzzr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125732",
                                        "parentSerial": "125729"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125729",
                                "parentSerial": "125728"
                            },
                            {
                                "value": 2000045,
                                "label": "游戏设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000200060000,
                                        "label": "游戏原画师",
                                        "extra": {
                                            "shortPinyin": "yxyhs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125734",
                                        "parentSerial": "125733"
                                    },
                                    {
                                        "value": 17000500150000,
                                        "label": "游戏界面设计师",
                                        "extra": {
                                            "shortPinyin": "yxjmsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125735",
                                        "parentSerial": "125733"
                                    },
                                    {
                                        "value": 17000500250000,
                                        "label": "游戏动作",
                                        "extra": {
                                            "shortPinyin": "yxdz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125736",
                                        "parentSerial": "125733"
                                    },
                                    {
                                        "value": 17000200040000,
                                        "label": "角色模型师",
                                        "extra": {
                                            "shortPinyin": "jsmxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125737",
                                        "parentSerial": "125733"
                                    },
                                    {
                                        "value": 17000200050000,
                                        "label": "特效设计",
                                        "extra": {
                                            "shortPinyin": "txsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125738",
                                        "parentSerial": "125733"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125733",
                                "parentSerial": "125728"
                            },
                            {
                                "value": 2000046,
                                "label": "游戏开发/测试",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000600050000,
                                        "label": "U3D",
                                        "extra": {
                                            "shortPinyin": "u3d",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125740",
                                        "parentSerial": "125739"
                                    },
                                    {
                                        "value": 9000600120000,
                                        "label": "UE4",
                                        "extra": {
                                            "shortPinyin": "ue4",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125741",
                                        "parentSerial": "125739"
                                    },
                                    {
                                        "value": 9000600030000,
                                        "label": "COCOS2DX",
                                        "extra": {
                                            "shortPinyin": "cocos2dx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125742",
                                        "parentSerial": "125739"
                                    },
                                    {
                                        "value": 17000500160000,
                                        "label": "游戏设计开发",
                                        "extra": {
                                            "shortPinyin": "yxsjkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125743",
                                        "parentSerial": "125739"
                                    },
                                    {
                                        "value": 9000600090000,
                                        "label": "小游戏开发",
                                        "extra": {
                                            "shortPinyin": "xyx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125744",
                                        "parentSerial": "125739"
                                    },
                                    {
                                        "value": 20000100140000,
                                        "label": "游戏测试",
                                        "extra": {
                                            "shortPinyin": "yxcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125745",
                                        "parentSerial": "125739"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125739",
                                "parentSerial": "125728"
                            },
                            {
                                "value": 2000047,
                                "label": "游戏推广/运营",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000500350000,
                                        "label": "游戏推广",
                                        "extra": {
                                            "shortPinyin": "yxtg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125747",
                                        "parentSerial": "125746"
                                    },
                                    {
                                        "value": 5000300160000,
                                        "label": "游戏运营",
                                        "extra": {
                                            "shortPinyin": "yxyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125748",
                                        "parentSerial": "125746"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125746",
                                "parentSerial": "125728"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125728",
                        "parentSerial": null
                    },
                    {
                        "value": 1000009,
                        "label": "运营/客服",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000048,
                                "label": "电商运营",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 5000100020000,
                                        "label": "电商运营",
                                        "extra": {
                                            "shortPinyin": "dsyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125751",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 5000100040000,
                                        "label": "电商专员/助理",
                                        "extra": {
                                            "shortPinyin": "dszy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125752",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 5000100010000,
                                        "label": "电商经理/主管",
                                        "extra": {
                                            "shortPinyin": "dsjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125753",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 5000100070000,
                                        "label": "淘宝/天猫运营",
                                        "extra": {
                                            "shortPinyin": "tb/wxyyzy/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125754",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 5000100050000,
                                        "label": "跨境电商运营",
                                        "extra": {
                                            "shortPinyin": "kjdsyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125755",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 5000100060000,
                                        "label": "品类运营",
                                        "extra": {
                                            "shortPinyin": "plyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125756",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 50001000100000,
                                        "label": "选品师",
                                        "extra": {
                                            "shortPinyin": "xps",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125757",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 5000100080000,
                                        "label": "网店管理员",
                                        "extra": {
                                            "shortPinyin": "wdgly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125758",
                                        "parentSerial": "125750"
                                    },
                                    {
                                        "value": 5000100030000,
                                        "label": "电商站长",
                                        "extra": {
                                            "shortPinyin": "dszc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125759",
                                        "parentSerial": "125750"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125750",
                                "parentSerial": "125749"
                            },
                            {
                                "value": 2000049,
                                "label": "新媒体运营",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 5000200060000,
                                        "label": "新媒体运营",
                                        "extra": {
                                            "shortPinyin": "xmtyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125761",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000200050000,
                                        "label": "新媒体编辑",
                                        "extra": {
                                            "shortPinyin": "xmtbj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125762",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000300190000,
                                        "label": "直播运营",
                                        "extra": {
                                            "shortPinyin": "zbyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125763",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000200080000,
                                        "label": "短视频运营",
                                        "extra": {
                                            "shortPinyin": "dspyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125764",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000200040000,
                                        "label": "微信运营",
                                        "extra": {
                                            "shortPinyin": "wxyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125765",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000200030000,
                                        "label": "微博运营",
                                        "extra": {
                                            "shortPinyin": "wbyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125766",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000200070000,
                                        "label": "自媒体运营",
                                        "extra": {
                                            "shortPinyin": "zmtyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125767",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000300080000,
                                        "label": "社交媒体运营",
                                        "extra": {
                                            "shortPinyin": "sjmtyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125768",
                                        "parentSerial": "125760"
                                    },
                                    {
                                        "value": 5000200020000,
                                        "label": "内容运营",
                                        "extra": {
                                            "shortPinyin": "nryy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125769",
                                        "parentSerial": "125760"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125760",
                                "parentSerial": "125749"
                            },
                            {
                                "value": 2000050,
                                "label": "网络/网站运营",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000500290000,
                                        "label": "网络推广",
                                        "extra": {
                                            "shortPinyin": "wltg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125771",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 5000300110000,
                                        "label": "网络运营专员/助理",
                                        "extra": {
                                            "shortPinyin": "wlyyzy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125772",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 5000300100000,
                                        "label": "网络运营经理/主管",
                                        "extra": {
                                            "shortPinyin": "wlyygl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125773",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 16000500300000,
                                        "label": "网站推广",
                                        "extra": {
                                            "shortPinyin": "wztg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125774",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 5000300120000,
                                        "label": "网站运营",
                                        "extra": {
                                            "shortPinyin": "wzyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125775",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 5000400030000,
                                        "label": "网站运营总监",
                                        "extra": {
                                            "shortPinyin": "wzyyzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125776",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 5000300200000,
                                        "label": "信息流优化师",
                                        "extra": {
                                            "shortPinyin": "xxlyhs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125777",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 16000500020000,
                                        "label": "SEO",
                                        "extra": {
                                            "shortPinyin": "seo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125778",
                                        "parentSerial": "125770"
                                    },
                                    {
                                        "value": 16000500010000,
                                        "label": "SEM",
                                        "extra": {
                                            "shortPinyin": "sem",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125779",
                                        "parentSerial": "125770"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125770",
                                "parentSerial": "125749"
                            },
                            {
                                "value": 2000051,
                                "label": "互联网运营",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 5000300010000,
                                        "label": "策略运营",
                                        "extra": {
                                            "shortPinyin": "clyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125781",
                                        "parentSerial": "125780"
                                    },
                                    {
                                        "value": 5000500050000,
                                        "label": "数据运营",
                                        "extra": {
                                            "shortPinyin": "sjyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125782",
                                        "parentSerial": "125780"
                                    },
                                    {
                                        "value": 5000300150000,
                                        "label": "用户运营",
                                        "extra": {
                                            "shortPinyin": "yhyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125783",
                                        "parentSerial": "125780"
                                    },
                                    {
                                        "value": 5000300070000,
                                        "label": "平台运营",
                                        "extra": {
                                            "shortPinyin": "ptyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125784",
                                        "parentSerial": "125780"
                                    },
                                    {
                                        "value": 5000300090000,
                                        "label": "社区运营",
                                        "extra": {
                                            "shortPinyin": "sqyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125785",
                                        "parentSerial": "125780"
                                    },
                                    {
                                        "value": 5000300210000,
                                        "label": "社群运营",
                                        "extra": {
                                            "shortPinyin": "sqyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125786",
                                        "parentSerial": "125780"
                                    },
                                    {
                                        "value": 5000300020000,
                                        "label": "产品运营",
                                        "extra": {
                                            "shortPinyin": "cpyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125787",
                                        "parentSerial": "125780"
                                    },
                                    {
                                        "value": 5000300030000,
                                        "label": "互联网运营",
                                        "extra": {
                                            "shortPinyin": "hlwyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125788",
                                        "parentSerial": "125780"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125780",
                                "parentSerial": "125749"
                            },
                            {
                                "value": 2000052,
                                "label": "业务运营",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 5000300180000,
                                        "label": "运营助理/专员",
                                        "extra": {
                                            "shortPinyin": "yyzl/zy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125790",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300050000,
                                        "label": "活动运营",
                                        "extra": {
                                            "shortPinyin": "hdyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125791",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300220000,
                                        "label": "商家运营",
                                        "extra": {
                                            "shortPinyin": "sjyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125792",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300040000,
                                        "label": "会员运营",
                                        "extra": {
                                            "shortPinyin": "hyyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125793",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300140000,
                                        "label": "销售运营",
                                        "extra": {
                                            "shortPinyin": "xsyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125794",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300060000,
                                        "label": "楼面管理",
                                        "extra": {
                                            "shortPinyin": "lmgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125795",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300130000,
                                        "label": "线下拓展运营",
                                        "extra": {
                                            "shortPinyin": "xxtzyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125796",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000200010000,
                                        "label": "内容审核",
                                        "extra": {
                                            "shortPinyin": "nrsh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125797",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300230000,
                                        "label": "数据标注",
                                        "extra": {
                                            "shortPinyin": "sjbz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125798",
                                        "parentSerial": "125789"
                                    },
                                    {
                                        "value": 5000300240000,
                                        "label": "AI训练师",
                                        "extra": {
                                            "shortPinyin": "aixls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125799",
                                        "parentSerial": "125789"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125789",
                                "parentSerial": "125749"
                            },
                            {
                                "value": 2000053,
                                "label": "运营管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 5000300170000,
                                        "label": "运营主管",
                                        "extra": {
                                            "shortPinyin": "yyzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125801",
                                        "parentSerial": "125800"
                                    },
                                    {
                                        "value": 5000400050000,
                                        "label": "运营经理",
                                        "extra": {
                                            "shortPinyin": "yygl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125802",
                                        "parentSerial": "125800"
                                    },
                                    {
                                        "value": 5000400060000,
                                        "label": "运营总监",
                                        "extra": {
                                            "shortPinyin": "yyzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125803",
                                        "parentSerial": "125800"
                                    },
                                    {
                                        "value": 3000200060000,
                                        "label": "COO",
                                        "extra": {
                                            "shortPinyin": "coo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125804",
                                        "parentSerial": "125800"
                                    },
                                    {
                                        "value": 5000400010000,
                                        "label": "城市运营",
                                        "extra": {
                                            "shortPinyin": "csyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125805",
                                        "parentSerial": "125800"
                                    },
                                    {
                                        "value": 5000400040000,
                                        "label": "督导巡店",
                                        "extra": {
                                            "shortPinyin": "ddxd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125806",
                                        "parentSerial": "125800"
                                    },
                                    {
                                        "value": 5000400070000,
                                        "label": "站长",
                                        "extra": {
                                            "shortPinyin": "zc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125807",
                                        "parentSerial": "125800"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125800",
                                "parentSerial": "125749"
                            },
                            {
                                "value": 2000054,
                                "label": "数据分析",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 9000400070000,
                                        "label": "数据分析师",
                                        "extra": {
                                            "shortPinyin": "sjfxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125809",
                                        "parentSerial": "125808"
                                    },
                                    {
                                        "value": 5000500070000,
                                        "label": "销售数据分析",
                                        "extra": {
                                            "shortPinyin": "xssjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125810",
                                        "parentSerial": "125808"
                                    },
                                    {
                                        "value": 5000500040000,
                                        "label": "商业数据分析",
                                        "extra": {
                                            "shortPinyin": "sysjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125811",
                                        "parentSerial": "125808"
                                    },
                                    {
                                        "value": 5000500030000,
                                        "label": "金融数据分析",
                                        "extra": {
                                            "shortPinyin": "jrsjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125812",
                                        "parentSerial": "125808"
                                    },
                                    {
                                        "value": 10000100100000,
                                        "label": "临床数据分析",
                                        "extra": {
                                            "shortPinyin": "lcsjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125813",
                                        "parentSerial": "125808"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125808",
                                "parentSerial": "125749"
                            },
                            {
                                "value": 2000055,
                                "label": "客户服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 13000100100000,
                                        "label": "客服专员",
                                        "extra": {
                                            "shortPinyin": "kfzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125815",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100090000,
                                        "label": "客服主管",
                                        "extra": {
                                            "shortPinyin": "kfzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125816",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100080000,
                                        "label": "客服经理",
                                        "extra": {
                                            "shortPinyin": "kfjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125817",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100110000,
                                        "label": "客服总监",
                                        "extra": {
                                            "shortPinyin": "kfzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125818",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100190000,
                                        "label": "网络客服",
                                        "extra": {
                                            "shortPinyin": "wlkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125819",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100020000,
                                        "label": "电话客服",
                                        "extra": {
                                            "shortPinyin": "dhkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125820",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100210000,
                                        "label": "咨询热线/呼叫中心客服",
                                        "extra": {
                                            "shortPinyin": "zxrx/hjzxkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125821",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100120000,
                                        "label": "客户关系/投诉协调",
                                        "extra": {
                                            "shortPinyin": "khgx/tsxd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125822",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100030000,
                                        "label": "售后客服",
                                        "extra": {
                                            "shortPinyin": "dsshfw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125823",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100010000,
                                        "label": "VIP专员",
                                        "extra": {
                                            "shortPinyin": "vipzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125824",
                                        "parentSerial": "125814"
                                    },
                                    {
                                        "value": 13000100130000,
                                        "label": "客户续期管理",
                                        "extra": {
                                            "shortPinyin": "khxqgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125825",
                                        "parentSerial": "125814"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125814",
                                "parentSerial": "125749"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125749",
                        "parentSerial": null
                    },
                    {
                        "value": 1000010,
                        "label": "市场",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000056,
                                "label": "市场营销/推广",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000500250000,
                                        "label": "市场专员",
                                        "extra": {
                                            "shortPinyin": "sczy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125828",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500220000,
                                        "label": "市场主管",
                                        "extra": {
                                            "shortPinyin": "scyxzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125829",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500170000,
                                        "label": "市场经理",
                                        "extra": {
                                            "shortPinyin": "scjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125830",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500260000,
                                        "label": "市场总监",
                                        "extra": {
                                            "shortPinyin": "sczj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125831",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 3000200050000,
                                        "label": "CMO",
                                        "extra": {
                                            "shortPinyin": "cmo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125832",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500190000,
                                        "label": "市场推广",
                                        "extra": {
                                            "shortPinyin": "sctg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125833",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500200000,
                                        "label": "市场营销",
                                        "extra": {
                                            "shortPinyin": "scyx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125834",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500240000,
                                        "label": "市场运营",
                                        "extra": {
                                            "shortPinyin": "scyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125835",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 3000100130000,
                                        "label": "产品市场经理",
                                        "extra": {
                                            "shortPinyin": "sccpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125836",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500180000,
                                        "label": "市场通路专员",
                                        "extra": {
                                            "shortPinyin": "sctlzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125837",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500360000,
                                        "label": "海外市场",
                                        "extra": {
                                            "shortPinyin": "hwsc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125838",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500330000,
                                        "label": "药品市场推广经理/主管",
                                        "extra": {
                                            "shortPinyin": "ypsctgjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125839",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500340000,
                                        "label": "药品市场推广专员/助理",
                                        "extra": {
                                            "shortPinyin": "ypsctgzy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125840",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500290000,
                                        "label": "网络推广",
                                        "extra": {
                                            "shortPinyin": "wltg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125841",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500300000,
                                        "label": "网站推广",
                                        "extra": {
                                            "shortPinyin": "wztg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125842",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500310000,
                                        "label": "微信推广",
                                        "extra": {
                                            "shortPinyin": "wxtg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125843",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500350000,
                                        "label": "游戏推广",
                                        "extra": {
                                            "shortPinyin": "yxtg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125844",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500370000,
                                        "label": "APP推广",
                                        "extra": {
                                            "shortPinyin": "apptg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125845",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500270000,
                                        "label": "数字营销专员",
                                        "extra": {
                                            "shortPinyin": "szyxzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125846",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500380000,
                                        "label": "网络营销",
                                        "extra": {
                                            "shortPinyin": "wlyx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125847",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500020000,
                                        "label": "SEO",
                                        "extra": {
                                            "shortPinyin": "seo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125848",
                                        "parentSerial": "125827"
                                    },
                                    {
                                        "value": 16000500010000,
                                        "label": "SEM",
                                        "extra": {
                                            "shortPinyin": "sem",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125849",
                                        "parentSerial": "125827"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125827",
                                "parentSerial": "125826"
                            },
                            {
                                "value": 2000057,
                                "label": "市场调研",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000600030000,
                                        "label": "市场调研",
                                        "extra": {
                                            "shortPinyin": "scdy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125851",
                                        "parentSerial": "125850"
                                    },
                                    {
                                        "value": 16000600060000,
                                        "label": "舆情分析",
                                        "extra": {
                                            "shortPinyin": "yqfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125852",
                                        "parentSerial": "125850"
                                    },
                                    {
                                        "value": 16000600020000,
                                        "label": "情报信息分析",
                                        "extra": {
                                            "shortPinyin": "qbxxfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125853",
                                        "parentSerial": "125850"
                                    },
                                    {
                                        "value": 16000600010000,
                                        "label": "竞品分析",
                                        "extra": {
                                            "shortPinyin": "jpfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125854",
                                        "parentSerial": "125850"
                                    },
                                    {
                                        "value": 16000600050000,
                                        "label": "用户研究员",
                                        "extra": {
                                            "shortPinyin": "yhyjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125855",
                                        "parentSerial": "125850"
                                    },
                                    {
                                        "value": 16000600070000,
                                        "label": "用户研究经理",
                                        "extra": {
                                            "shortPinyin": "yhyjjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125856",
                                        "parentSerial": "125850"
                                    },
                                    {
                                        "value": 16000600080000,
                                        "label": "用户研究总监",
                                        "extra": {
                                            "shortPinyin": "yhyjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125857",
                                        "parentSerial": "125850"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125850",
                                "parentSerial": "125826"
                            },
                            {
                                "value": 2000058,
                                "label": "策划",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000100080000,
                                        "label": "文案策划",
                                        "extra": {
                                            "shortPinyin": "wach",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125859",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000100040000,
                                        "label": "活动策划",
                                        "extra": {
                                            "shortPinyin": "hdch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125860",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000100050000,
                                        "label": "活动执行",
                                        "extra": {
                                            "shortPinyin": "hdzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125861",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000100060000,
                                        "label": "企划",
                                        "extra": {
                                            "shortPinyin": "qh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125862",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000100090000,
                                        "label": "宣传策划",
                                        "extra": {
                                            "shortPinyin": "xcch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125863",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000100100000,
                                        "label": "营销策划",
                                        "extra": {
                                            "shortPinyin": "yxch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125864",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000500160000,
                                        "label": "市场策划专员",
                                        "extra": {
                                            "shortPinyin": "scchzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125865",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000500150000,
                                        "label": "市场策划经理",
                                        "extra": {
                                            "shortPinyin": "scchjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125866",
                                        "parentSerial": "125858"
                                    },
                                    {
                                        "value": 16000100030000,
                                        "label": "婚礼策划",
                                        "extra": {
                                            "shortPinyin": "hlch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125867",
                                        "parentSerial": "125858"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125858",
                                "parentSerial": "125826"
                            },
                            {
                                "value": 2000059,
                                "label": "广告",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000100020000,
                                        "label": "广告文案",
                                        "extra": {
                                            "shortPinyin": "ggwa",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125869",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 17000500210000,
                                        "label": "广告设计",
                                        "extra": {
                                            "shortPinyin": "ggsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125870",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 16000500040000,
                                        "label": "广告创意设计",
                                        "extra": {
                                            "shortPinyin": "ggcysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125871",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 16000100010000,
                                        "label": "广告创意/设计总监",
                                        "extra": {
                                            "shortPinyin": "ggcy/sjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125872",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 16000500030000,
                                        "label": "创意总监",
                                        "extra": {
                                            "shortPinyin": "cyzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125873",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 16000500080000,
                                        "label": "广告制作",
                                        "extra": {
                                            "shortPinyin": "ggzz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125874",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 16000500070000,
                                        "label": "广告协调",
                                        "extra": {
                                            "shortPinyin": "ggxd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125875",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 16000500050000,
                                        "label": "广告美术指导",
                                        "extra": {
                                            "shortPinyin": "ggmszd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125876",
                                        "parentSerial": "125868"
                                    },
                                    {
                                        "value": 16000500060000,
                                        "label": "广告审核",
                                        "extra": {
                                            "shortPinyin": "ggsh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125877",
                                        "parentSerial": "125868"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125868",
                                "parentSerial": "125826"
                            },
                            {
                                "value": 2000060,
                                "label": "公关媒介",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000300130000,
                                        "label": "政府关系",
                                        "extra": {
                                            "shortPinyin": "zfgx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125879",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000500100000,
                                        "label": "品牌公关",
                                        "extra": {
                                            "shortPinyin": "ppgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125880",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300120000,
                                        "label": "商务公关",
                                        "extra": {
                                            "shortPinyin": "swgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125881",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300040000,
                                        "label": "活动公关",
                                        "extra": {
                                            "shortPinyin": "hdgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125882",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300020000,
                                        "label": "公关专员",
                                        "extra": {
                                            "shortPinyin": "ggzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125883",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300010000,
                                        "label": "公关经理/主管",
                                        "extra": {
                                            "shortPinyin": "ggjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125884",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300030000,
                                        "label": "公关总监",
                                        "extra": {
                                            "shortPinyin": "ggzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125885",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300050000,
                                        "label": "媒介策划",
                                        "extra": {
                                            "shortPinyin": "mjch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125886",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300090000,
                                        "label": "媒介投放",
                                        "extra": {
                                            "shortPinyin": "mjtf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125887",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300080000,
                                        "label": "媒介合作",
                                        "extra": {
                                            "shortPinyin": "mjhz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125888",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300060000,
                                        "label": "媒介顾问",
                                        "extra": {
                                            "shortPinyin": "mjgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125889",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300100000,
                                        "label": "媒介专员",
                                        "extra": {
                                            "shortPinyin": "mjzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125890",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300070000,
                                        "label": "媒介经理",
                                        "extra": {
                                            "shortPinyin": "mjjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125891",
                                        "parentSerial": "125878"
                                    },
                                    {
                                        "value": 16000300110000,
                                        "label": "媒介总监",
                                        "extra": {
                                            "shortPinyin": "mjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125892",
                                        "parentSerial": "125878"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125878",
                                "parentSerial": "125826"
                            },
                            {
                                "value": 2000061,
                                "label": "品牌",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000500090000,
                                        "label": "品牌策划",
                                        "extra": {
                                            "shortPinyin": "ppch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125894",
                                        "parentSerial": "125893"
                                    },
                                    {
                                        "value": 16000500130000,
                                        "label": "品牌专员",
                                        "extra": {
                                            "shortPinyin": "ppzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125895",
                                        "parentSerial": "125893"
                                    },
                                    {
                                        "value": 16000500120000,
                                        "label": "品牌主管",
                                        "extra": {
                                            "shortPinyin": "ppzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125896",
                                        "parentSerial": "125893"
                                    },
                                    {
                                        "value": 16000500110000,
                                        "label": "品牌经理",
                                        "extra": {
                                            "shortPinyin": "ppjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125897",
                                        "parentSerial": "125893"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125893",
                                "parentSerial": "125826"
                            },
                            {
                                "value": 2000062,
                                "label": "会展会务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000400020000,
                                        "label": "会务专员",
                                        "extra": {
                                            "shortPinyin": "hwzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125899",
                                        "parentSerial": "125898"
                                    },
                                    {
                                        "value": 16000400010000,
                                        "label": "会务经理",
                                        "extra": {
                                            "shortPinyin": "hwjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125900",
                                        "parentSerial": "125898"
                                    },
                                    {
                                        "value": 16000100110000,
                                        "label": "展厅策划",
                                        "extra": {
                                            "shortPinyin": "ztch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125901",
                                        "parentSerial": "125898"
                                    },
                                    {
                                        "value": 16000400040000,
                                        "label": "会展活动策划",
                                        "extra": {
                                            "shortPinyin": "hzhdch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125902",
                                        "parentSerial": "125898"
                                    },
                                    {
                                        "value": 16000400030000,
                                        "label": "会议活动执行",
                                        "extra": {
                                            "shortPinyin": "hyhdzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125903",
                                        "parentSerial": "125898"
                                    },
                                    {
                                        "value": 16000400050000,
                                        "label": "会展活动销售",
                                        "extra": {
                                            "shortPinyin": "hzhdxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125904",
                                        "parentSerial": "125898"
                                    },
                                    {
                                        "value": 16000400060000,
                                        "label": "会议活动销售",
                                        "extra": {
                                            "shortPinyin": "hyhdxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125905",
                                        "parentSerial": "125898"
                                    },
                                    {
                                        "value": 3000300060000,
                                        "label": "广告/会展项目经理",
                                        "extra": {
                                            "shortPinyin": "gg/hzxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125906",
                                        "parentSerial": "125898"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125898",
                                "parentSerial": "125826"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125826",
                        "parentSerial": null
                    },
                    {
                        "value": 1000011,
                        "label": "项目管理",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000063,
                                "label": "IT项目管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 3000300020000,
                                        "label": "IT项目执行",
                                        "extra": {
                                            "shortPinyin": "itxmzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125909",
                                        "parentSerial": "125908"
                                    },
                                    {
                                        "value": 3000300010000,
                                        "label": "IT项目经理",
                                        "extra": {
                                            "shortPinyin": "itxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125910",
                                        "parentSerial": "125908"
                                    },
                                    {
                                        "value": 3000300030000,
                                        "label": "IT项目总监",
                                        "extra": {
                                            "shortPinyin": "itxmzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125911",
                                        "parentSerial": "125908"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125908",
                                "parentSerial": "125907"
                            },
                            {
                                "value": 2000064,
                                "label": "其他项目管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 3000300090000,
                                        "label": "通信项目经理",
                                        "extra": {
                                            "shortPinyin": "txxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125913",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300230000,
                                        "label": "硬件项目经理",
                                        "extra": {
                                            "shortPinyin": "yjxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125914",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300040000,
                                        "label": "电子/电器项目经理",
                                        "extra": {
                                            "shortPinyin": "dz/dqxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125915",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 7000200040000,
                                        "label": "地产项目管理",
                                        "extra": {
                                            "shortPinyin": "dcxmgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125916",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 7000700020000,
                                        "label": "地产项目总监",
                                        "extra": {
                                            "shortPinyin": "dcxmzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125917",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300240000,
                                        "label": "装修项目经理",
                                        "extra": {
                                            "shortPinyin": "zxxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125918",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300210000,
                                        "label": "咨询项目经理",
                                        "extra": {
                                            "shortPinyin": "zxxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125919",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300060000,
                                        "label": "广告/会展项目经理",
                                        "extra": {
                                            "shortPinyin": "gg/hzxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125920",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300200000,
                                        "label": "证券/投资项目经理",
                                        "extra": {
                                            "shortPinyin": "zq/tzxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125921",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300100000,
                                        "label": "物流/仓储项目经理",
                                        "extra": {
                                            "shortPinyin": "wl/ccxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125922",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300080000,
                                        "label": "生产项目经理/主管",
                                        "extra": {
                                            "shortPinyin": "scxmjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125923",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300070000,
                                        "label": "汽车项目经理",
                                        "extra": {
                                            "shortPinyin": "qcxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125924",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300180000,
                                        "label": "化工项目经理",
                                        "extra": {
                                            "shortPinyin": "hgxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125925",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300050000,
                                        "label": "服装/纺织/皮革项目经理",
                                        "extra": {
                                            "shortPinyin": "fz/fz/pgxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125926",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300190000,
                                        "label": "医药项目经理",
                                        "extra": {
                                            "shortPinyin": "yyxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125927",
                                        "parentSerial": "125912"
                                    },
                                    {
                                        "value": 3000300220000,
                                        "label": "临床项目经理",
                                        "extra": {
                                            "shortPinyin": "lcxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125928",
                                        "parentSerial": "125912"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125912",
                                "parentSerial": "125907"
                            },
                            {
                                "value": 2000065,
                                "label": "项目管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 3000300130000,
                                        "label": "项目执行",
                                        "extra": {
                                            "shortPinyin": "xmzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125930",
                                        "parentSerial": "125929"
                                    },
                                    {
                                        "value": 3000300150000,
                                        "label": "项目助理",
                                        "extra": {
                                            "shortPinyin": "xmzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125931",
                                        "parentSerial": "125929"
                                    },
                                    {
                                        "value": 3000300160000,
                                        "label": "项目专员",
                                        "extra": {
                                            "shortPinyin": "xmzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125932",
                                        "parentSerial": "125929"
                                    },
                                    {
                                        "value": 3000300120000,
                                        "label": "项目经理/主管",
                                        "extra": {
                                            "shortPinyin": "xmjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125933",
                                        "parentSerial": "125929"
                                    },
                                    {
                                        "value": 3000300170000,
                                        "label": "项目总监",
                                        "extra": {
                                            "shortPinyin": "xmzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125934",
                                        "parentSerial": "125929"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125929",
                                "parentSerial": "125907"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125907",
                        "parentSerial": null
                    },
                    {
                        "value": 1000012,
                        "label": "高级管理",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000066,
                                "label": "高级管理职位",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 3000200030000,
                                        "label": "CFO",
                                        "extra": {
                                            "shortPinyin": "cfo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125937",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200040000,
                                        "label": "CIO",
                                        "extra": {
                                            "shortPinyin": "cio",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125938",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200050000,
                                        "label": "CMO",
                                        "extra": {
                                            "shortPinyin": "cmo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125939",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200060000,
                                        "label": "COO",
                                        "extra": {
                                            "shortPinyin": "coo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125940",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200070000,
                                        "label": "CTO",
                                        "extra": {
                                            "shortPinyin": "cto",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125941",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200140000,
                                        "label": "副总裁/副总经理/VP",
                                        "extra": {
                                            "shortPinyin": "fzc/fzjl/vp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125942",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200020000,
                                        "label": "总裁/总经理/CEO",
                                        "extra": {
                                            "shortPinyin": "zc/zjl/ceo",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125943",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200240000,
                                        "label": "总助/CEO助理/董事长助理",
                                        "extra": {
                                            "shortPinyin": "zz/ceozl/dsczl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125944",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200260000,
                                        "label": "董事会秘书",
                                        "extra": {
                                            "shortPinyin": "dshms",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125945",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200180000,
                                        "label": "投资合伙人",
                                        "extra": {
                                            "shortPinyin": "tzhhr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125946",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200120000,
                                        "label": "联合创始人",
                                        "extra": {
                                            "shortPinyin": "lhcsr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125947",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200090000,
                                        "label": "事业部管理",
                                        "extra": {
                                            "shortPinyin": "sybgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125948",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200130000,
                                        "label": "分公司/代表处负责人",
                                        "extra": {
                                            "shortPinyin": "fgs/dbcfzr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125949",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200080000,
                                        "label": "办事处首席代表",
                                        "extra": {
                                            "shortPinyin": "bscsxdb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125950",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200270000,
                                        "label": "区域负责人（辖多个分公司）",
                                        "extra": {
                                            "shortPinyin": "qyfzr（xdgfgs）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125951",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000100070000,
                                        "label": "产品总监/VP",
                                        "extra": {
                                            "shortPinyin": "cpzj/vp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125952",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 14000600200000,
                                        "label": "人力资源VP/CHO",
                                        "extra": {
                                            "shortPinyin": "rlzyvp/cho",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "125953",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200190000,
                                        "label": "企业秘书",
                                        "extra": {
                                            "shortPinyin": "qyms/dshms",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125954",
                                        "parentSerial": "125936"
                                    },
                                    {
                                        "value": 3000200150000,
                                        "label": "高级管理职位",
                                        "extra": {
                                            "shortPinyin": "gjglzw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125955",
                                        "parentSerial": "125936"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125936",
                                "parentSerial": "125935"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125935",
                        "parentSerial": null
                    },
                    {
                        "value": 1000013,
                        "label": "房地产/建筑",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000067,
                                "label": "房地产规划开发",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000200020000,
                                        "label": "房地产策划",
                                        "extra": {
                                            "shortPinyin": "fdcch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125958",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 7000700030000,
                                        "label": "地产策划总监",
                                        "extra": {
                                            "shortPinyin": "dcchzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125959",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 7000200050000,
                                        "label": "房地产项目开发报建",
                                        "extra": {
                                            "shortPinyin": "fdcxmkfbj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125960",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 7000200040000,
                                        "label": "地产项目管理",
                                        "extra": {
                                            "shortPinyin": "dcxmgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125961",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 7000700020000,
                                        "label": "地产项目总监",
                                        "extra": {
                                            "shortPinyin": "dcxmzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125962",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 2000100020000,
                                        "label": "地产招投标",
                                        "extra": {
                                            "shortPinyin": "dcztb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125963",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 7000800020000,
                                        "label": "地产招投标总监",
                                        "extra": {
                                            "shortPinyin": "dcztbzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125964",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 7000300470000,
                                        "label": "项目申报专员",
                                        "extra": {
                                            "shortPinyin": "xmsbzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125965",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 6001300060000,
                                        "label": "房产评估师",
                                        "extra": {
                                            "shortPinyin": "fcpgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125966",
                                        "parentSerial": "125957"
                                    },
                                    {
                                        "value": 14000100110000,
                                        "label": "房地产资产管理",
                                        "extra": {
                                            "shortPinyin": "fdczcgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125967",
                                        "parentSerial": "125957"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125957",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000001,
                                "label": "房地产销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200040000,
                                        "label": "房产销售",
                                        "extra": {
                                            "shortPinyin": "fcxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125969",
                                        "parentSerial": "125968"
                                    },
                                    {
                                        "value": 7000800010000,
                                        "label": "房地产销售总监",
                                        "extra": {
                                            "shortPinyin": "fdcxszj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125970",
                                        "parentSerial": "125968"
                                    },
                                    {
                                        "value": 7000700010000,
                                        "label": "物业招商管理",
                                        "extra": {
                                            "shortPinyin": "wyzsgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125971",
                                        "parentSerial": "125968"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125968",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000068,
                                "label": "物业管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6000100110000,
                                        "label": "物业经理",
                                        "extra": {
                                            "shortPinyin": "wyjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125973",
                                        "parentSerial": "125972"
                                    },
                                    {
                                        "value": 6000100160000,
                                        "label": "物业工程经理",
                                        "extra": {
                                            "shortPinyin": "wygcjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178393",
                                        "parentSerial": "125972"
                                    },
                                    {
                                        "value": 6000100120000,
                                        "label": "物业管理员",
                                        "extra": {
                                            "shortPinyin": "wygly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125974",
                                        "parentSerial": "125972"
                                    },
                                    {
                                        "value": 6000100130000,
                                        "label": "物业维修",
                                        "extra": {
                                            "shortPinyin": "wywx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125975",
                                        "parentSerial": "125972"
                                    },
                                    {
                                        "value": 7000100030000,
                                        "label": "消防中控员",
                                        "extra": {
                                            "shortPinyin": "xfzky",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125976",
                                        "parentSerial": "125972"
                                    },
                                    {
                                        "value": 7000400210000,
                                        "label": "绿化工",
                                        "extra": {
                                            "shortPinyin": "lhg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125977",
                                        "parentSerial": "125972"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125972",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000069,
                                "label": "建筑/规划设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000300240000,
                                        "label": "建筑设计师",
                                        "extra": {
                                            "shortPinyin": "jzsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125979",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400050000,
                                        "label": "建筑工程师",
                                        "extra": {
                                            "shortPinyin": "jzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125980",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 9000500030000,
                                        "label": "BIM工程师",
                                        "extra": {
                                            "shortPinyin": "bimgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125981",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400060000,
                                        "label": "建筑模型师",
                                        "extra": {
                                            "shortPinyin": "jzmxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125982",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400070000,
                                        "label": "建筑制图",
                                        "extra": {
                                            "shortPinyin": "jzzt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125983",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400110000,
                                        "label": "施工图设计",
                                        "extra": {
                                            "shortPinyin": "sgtsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125984",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 7000300200000,
                                        "label": "绘图员",
                                        "extra": {
                                            "shortPinyin": "hty",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125985",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400130000,
                                        "label": "效果图设计",
                                        "extra": {
                                            "shortPinyin": "xgtsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125986",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400190000,
                                        "label": "深化设计",
                                        "extra": {
                                            "shortPinyin": "shsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125987",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 7000300250000,
                                        "label": "结构工程师",
                                        "extra": {
                                            "shortPinyin": "jggcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125988",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 7000300260000,
                                        "label": "结构设计师",
                                        "extra": {
                                            "shortPinyin": "jgsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125989",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400010000,
                                        "label": "城市规划设计",
                                        "extra": {
                                            "shortPinyin": "csghsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125990",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 7000300570000,
                                        "label": "道路设计",
                                        "extra": {
                                            "shortPinyin": "dlsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178394",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 7000300580000,
                                        "label": "给排水设计",
                                        "extra": {
                                            "shortPinyin": "gpssj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178395",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 7000300100000,
                                        "label": "工程设计工程师",
                                        "extra": {
                                            "shortPinyin": "gcsjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125991",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400090000,
                                        "label": "幕墙设计",
                                        "extra": {
                                            "shortPinyin": "mqsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125992",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400150000,
                                        "label": "园林/景观设计",
                                        "extra": {
                                            "shortPinyin": "yl/jgsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125993",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400020000,
                                        "label": "环境艺术设计",
                                        "extra": {
                                            "shortPinyin": "hjyssj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125994",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400160000,
                                        "label": "照明设计",
                                        "extra": {
                                            "shortPinyin": "zmsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125995",
                                        "parentSerial": "125978"
                                    },
                                    {
                                        "value": 17000400170000,
                                        "label": "智能化设计",
                                        "extra": {
                                            "shortPinyin": "znhsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125996",
                                        "parentSerial": "125978"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125978",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000070,
                                "label": "装饰/装修",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000400120000,
                                        "label": "室内设计",
                                        "extra": {
                                            "shortPinyin": "snsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125998",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 17000400040000,
                                        "label": "家装设计",
                                        "extra": {
                                            "shortPinyin": "jzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "125999",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 17000400030000,
                                        "label": "家居设计",
                                        "extra": {
                                            "shortPinyin": "jjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126000",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 17000400100000,
                                        "label": "软装设计",
                                        "extra": {
                                            "shortPinyin": "rzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126001",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 17000400140000,
                                        "label": "硬装设计",
                                        "extra": {
                                            "shortPinyin": "yzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126002",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 7000300280000,
                                        "label": "空间设计师",
                                        "extra": {
                                            "shortPinyin": "kjsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126003",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 7000300500000,
                                        "label": "装修工程师",
                                        "extra": {
                                            "shortPinyin": "zxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126004",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 3000300240000,
                                        "label": "装修项目经理",
                                        "extra": {
                                            "shortPinyin": "zxxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126005",
                                        "parentSerial": "125997"
                                    },
                                    {
                                        "value": 17000400200000,
                                        "label": "家装顾问",
                                        "extra": {
                                            "shortPinyin": "jzgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126006",
                                        "parentSerial": "125997"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "125997",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000071,
                                "label": "建筑工程",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000300380000,
                                        "label": "市政工程",
                                        "extra": {
                                            "shortPinyin": "szgc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126008",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300020000,
                                        "label": "道路工程师",
                                        "extra": {
                                            "shortPinyin": "dl/ql/sdgcjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126009",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300340000,
                                        "label": "桥梁工程师",
                                        "extra": {
                                            "shortPinyin": "qlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126010",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300430000,
                                        "label": "隧道工程师",
                                        "extra": {
                                            "shortPinyin": "sdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126011",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300490000,
                                        "label": "岩土工程",
                                        "extra": {
                                            "shortPinyin": "ytgc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126012",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300510000,
                                        "label": "爆破工程师",
                                        "extra": {
                                            "shortPinyin": "bpgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126013",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300520000,
                                        "label": "钢结构工程师",
                                        "extra": {
                                            "shortPinyin": "gjggcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126014",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300550000,
                                        "label": "房修工程师",
                                        "extra": {
                                            "shortPinyin": "fxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126015",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300450000,
                                        "label": "土建勘察",
                                        "extra": {
                                            "shortPinyin": "tjkc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126016",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300030000,
                                        "label": "地铁轨道设计",
                                        "extra": {
                                            "shortPinyin": "dtgdsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126017",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300140000,
                                        "label": "轨道交通工程师/技术员",
                                        "extra": {
                                            "shortPinyin": "gdjtgcs/jsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126018",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300420000,
                                        "label": "水利/水电工程师",
                                        "extra": {
                                            "shortPinyin": "sl/sdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126019",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300410000,
                                        "label": "水利/港口工程技术",
                                        "extra": {
                                            "shortPinyin": "sl/gkgcjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126020",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300540000,
                                        "label": "海洋工程",
                                        "extra": {
                                            "shortPinyin": "hygc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126021",
                                        "parentSerial": "126007"
                                    },
                                    {
                                        "value": 7000300530000,
                                        "label": "民航工程设计",
                                        "extra": {
                                            "shortPinyin": "mhgcsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126022",
                                        "parentSerial": "126007"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126007",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000072,
                                "label": "安装工程",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000300320000,
                                        "label": "暖通工程师",
                                        "extra": {
                                            "shortPinyin": "ntgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126024",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300090000,
                                        "label": "给排水工程师",
                                        "extra": {
                                            "shortPinyin": "gpsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126025",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300290000,
                                        "label": "空调工程师",
                                        "extra": {
                                            "shortPinyin": "kdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126026",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300010000,
                                        "label": "安装工程师",
                                        "extra": {
                                            "shortPinyin": "azgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126027",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300400000,
                                        "label": "水电安装工程师",
                                        "extra": {
                                            "shortPinyin": "sdazgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126028",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300330000,
                                        "label": "配电设计",
                                        "extra": {
                                            "shortPinyin": "pdsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126029",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300350000,
                                        "label": "弱电工程师",
                                        "extra": {
                                            "shortPinyin": "rdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126030",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300210000,
                                        "label": "建筑机电工程师",
                                        "extra": {
                                            "shortPinyin": "jzjdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126031",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300060000,
                                        "label": "电力工程师",
                                        "extra": {
                                            "shortPinyin": "dlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126032",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300050000,
                                        "label": "电力电子研发工程师",
                                        "extra": {
                                            "shortPinyin": "dldzyfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126033",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300070000,
                                        "label": "电力系统研发工程师",
                                        "extra": {
                                            "shortPinyin": "dlxtyfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126034",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300300000,
                                        "label": "控制保护研发工程师",
                                        "extra": {
                                            "shortPinyin": "kzbhyfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126035",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000400220000,
                                        "label": "智能大厦/布线",
                                        "extra": {
                                            "shortPinyin": "zndx/bx/rd/af",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126036",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000100130000,
                                        "label": "消防工程师",
                                        "extra": {
                                            "shortPinyin": "xfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126037",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000100010000,
                                        "label": "安防",
                                        "extra": {
                                            "shortPinyin": "af",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126038",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000200060000,
                                        "label": "房地产项目配套工程师",
                                        "extra": {
                                            "shortPinyin": "fdcxmptgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126039",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300220000,
                                        "label": "架线和管道工程技术",
                                        "extra": {
                                            "shortPinyin": "jxhgdgcjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126040",
                                        "parentSerial": "126023"
                                    },
                                    {
                                        "value": 7000300130000,
                                        "label": "光源/照明工程师",
                                        "extra": {
                                            "shortPinyin": "gy/zmgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126041",
                                        "parentSerial": "126023"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126023",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000073,
                                "label": "工程管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000200090000,
                                        "label": "工程监理",
                                        "extra": {
                                            "shortPinyin": "gcjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126043",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200100000,
                                        "label": "工程经理",
                                        "extra": {
                                            "shortPinyin": "gcjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126044",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200130000,
                                        "label": "工程总监",
                                        "extra": {
                                            "shortPinyin": "gczj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126045",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200150000,
                                        "label": "建筑施工现场管理",
                                        "extra": {
                                            "shortPinyin": "jzsgxcgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126046",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000300460000,
                                        "label": "土木/土建工程师",
                                        "extra": {
                                            "shortPinyin": "tm/tj/jggcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126047",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200070000,
                                        "label": "高级建筑工程师/建筑总工",
                                        "extra": {
                                            "shortPinyin": "gjjzgcsjzzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126048",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000300110000,
                                        "label": "资料员",
                                        "extra": {
                                            "shortPinyin": "zly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126049",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200120000,
                                        "label": "工程资料管理",
                                        "extra": {
                                            "shortPinyin": "gczlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126050",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000400010000,
                                        "label": "测量员",
                                        "extra": {
                                            "shortPinyin": "cly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126051",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000300230000,
                                        "label": "测绘员",
                                        "extra": {
                                            "shortPinyin": "chy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126052",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000300310000,
                                        "label": "内业技术员",
                                        "extra": {
                                            "shortPinyin": "nyjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126053",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200170000,
                                        "label": "工程造价",
                                        "extra": {
                                            "shortPinyin": "zjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126054",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200110000,
                                        "label": "工程预算",
                                        "extra": {
                                            "shortPinyin": "gcys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126055",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 7000200010000,
                                        "label": "报价工程师",
                                        "extra": {
                                            "shortPinyin": "bjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126056",
                                        "parentSerial": "126042"
                                    },
                                    {
                                        "value": 14000100090000,
                                        "label": "成本经理/主管",
                                        "extra": {
                                            "shortPinyin": "cbjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126057",
                                        "parentSerial": "126042"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126042",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000074,
                                "label": "工程安全/质检",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000100120000,
                                        "label": "施工安全员",
                                        "extra": {
                                            "shortPinyin": "sgaqy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126059",
                                        "parentSerial": "126058"
                                    },
                                    {
                                        "value": 7000100020000,
                                        "label": "安全管理",
                                        "extra": {
                                            "shortPinyin": "aqgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126060",
                                        "parentSerial": "126058"
                                    },
                                    {
                                        "value": 7000100040000,
                                        "label": "安全主管",
                                        "extra": {
                                            "shortPinyin": "aqzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126061",
                                        "parentSerial": "126058"
                                    },
                                    {
                                        "value": 7000100110000,
                                        "label": "建筑工程安全管理",
                                        "extra": {
                                            "shortPinyin": "jzgcaqgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126062",
                                        "parentSerial": "126058"
                                    },
                                    {
                                        "value": 7000100050000,
                                        "label": "工程质量管理",
                                        "extra": {
                                            "shortPinyin": "gczlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126063",
                                        "parentSerial": "126058"
                                    },
                                    {
                                        "value": 7000100060000,
                                        "label": "化验员质检员",
                                        "extra": {
                                            "shortPinyin": "hyyzjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126064",
                                        "parentSerial": "126058"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126058",
                                "parentSerial": "125956"
                            },
                            {
                                "value": 2000075,
                                "label": "施工员",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000400140000,
                                        "label": "施工员",
                                        "extra": {
                                            "shortPinyin": "sgy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126066",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400130000,
                                        "label": "施工队长",
                                        "extra": {
                                            "shortPinyin": "sgdc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126067",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400070000,
                                        "label": "电梯工",
                                        "extra": {
                                            "shortPinyin": "dtg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126068",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400100000,
                                        "label": "空调工",
                                        "extra": {
                                            "shortPinyin": "kdg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126069",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400160000,
                                        "label": "土建工",
                                        "extra": {
                                            "shortPinyin": "tjg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126070",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400080000,
                                        "label": "钢筋工",
                                        "extra": {
                                            "shortPinyin": "gjg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126071",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000300560000,
                                        "label": "材料员",
                                        "extra": {
                                            "shortPinyin": "cly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126072",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400180000,
                                        "label": "瓦工",
                                        "extra": {
                                            "shortPinyin": "wg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126073",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400240000,
                                        "label": "水工",
                                        "extra": {
                                            "shortPinyin": "sg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126074",
                                        "parentSerial": "126065"
                                    },
                                    {
                                        "value": 7000400250000,
                                        "label": "木工",
                                        "extra": {
                                            "shortPinyin": "mg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126075",
                                        "parentSerial": "126065"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126065",
                                "parentSerial": "125956"
                            }
                        ],
                        "mainPath": null,
                        "serial": "125956",
                        "parentSerial": null
                    },
                    {
                        "value": 1000014,
                        "label": "金融",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000076,
                                "label": "保险",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 12000100110000,
                                        "label": "保险顾问",
                                        "extra": {
                                            "shortPinyin": "bxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126078",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100150000,
                                        "label": "保险理赔",
                                        "extra": {
                                            "shortPinyin": "bxlp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126079",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100140000,
                                        "label": "车险专员",
                                        "extra": {
                                            "shortPinyin": "cxzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126080",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100170000,
                                        "label": "汽车定损理赔",
                                        "extra": {
                                            "shortPinyin": "qcds/cxlp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126081",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100130000,
                                        "label": "车险勘查",
                                        "extra": {
                                            "shortPinyin": "cxkc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126082",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100070000,
                                        "label": "保险培训师",
                                        "extra": {
                                            "shortPinyin": "bxpxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126083",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100180000,
                                        "label": "续保专员",
                                        "extra": {
                                            "shortPinyin": "xbzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126084",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100100000,
                                        "label": "保险项目策划",
                                        "extra": {
                                            "shortPinyin": "bxxmch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126085",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100080000,
                                        "label": "保险契约管理",
                                        "extra": {
                                            "shortPinyin": "bxqygl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126086",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100090000,
                                        "label": "保险受理员",
                                        "extra": {
                                            "shortPinyin": "bxsly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126087",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100120000,
                                        "label": "保险公估师",
                                        "extra": {
                                            "shortPinyin": "bxggs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126088",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100010000,
                                        "label": "保险产品开发",
                                        "extra": {
                                            "shortPinyin": "bxcpkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126089",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100050000,
                                        "label": "保险精算师",
                                        "extra": {
                                            "shortPinyin": "bxjss",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126090",
                                        "parentSerial": "126077"
                                    },
                                    {
                                        "value": 12000100040000,
                                        "label": "保险核安",
                                        "extra": {
                                            "shortPinyin": "bxha",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126091",
                                        "parentSerial": "126077"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126077",
                                "parentSerial": "126076"
                            },
                            {
                                "value": 2000077,
                                "label": "银行/金融服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 12000300050000,
                                        "label": "柜员",
                                        "extra": {
                                            "shortPinyin": "gy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126093",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300060000,
                                        "label": "客户经理",
                                        "extra": {
                                            "shortPinyin": "khjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126094",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300070000,
                                        "label": "理财顾问",
                                        "extra": {
                                            "shortPinyin": "lcgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126095",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300140000,
                                        "label": "信用卡销售",
                                        "extra": {
                                            "shortPinyin": "xykxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126096",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300030000,
                                        "label": "大堂经理",
                                        "extra": {
                                            "shortPinyin": "dtjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126097",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 3000200170000,
                                        "label": "行长/副行长",
                                        "extra": {
                                            "shortPinyin": "xc/fxc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126098",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300110000,
                                        "label": "金融产品经理",
                                        "extra": {
                                            "shortPinyin": "jrcpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126099",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300120000,
                                        "label": "金融风控",
                                        "extra": {
                                            "shortPinyin": "jrfk",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126100",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300130000,
                                        "label": "信贷审核",
                                        "extra": {
                                            "shortPinyin": "xdsh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126101",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300090000,
                                        "label": "信贷管理",
                                        "extra": {
                                            "shortPinyin": "xdgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126102",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300020000,
                                        "label": "催收员",
                                        "extra": {
                                            "shortPinyin": "csy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126103",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300080000,
                                        "label": "清算",
                                        "extra": {
                                            "shortPinyin": "qs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126104",
                                        "parentSerial": "126092"
                                    },
                                    {
                                        "value": 12000300010000,
                                        "label": "保理人",
                                        "extra": {
                                            "shortPinyin": "blr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126105",
                                        "parentSerial": "126092"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126092",
                                "parentSerial": "126076"
                            },
                            {
                                "value": 2000078,
                                "label": "投融资",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 12000200140000,
                                        "label": "投融资",
                                        "extra": {
                                            "shortPinyin": "trz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126107",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 14000700120000,
                                        "label": "投资助理",
                                        "extra": {
                                            "shortPinyin": "tzzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126108",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200150000,
                                        "label": "投资经理",
                                        "extra": {
                                            "shortPinyin": "tzjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126109",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200170000,
                                        "label": "投资总监",
                                        "extra": {
                                            "shortPinyin": "tzzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126110",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200220000,
                                        "label": "投资VP",
                                        "extra": {
                                            "shortPinyin": "tzvp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126111",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 3000200180000,
                                        "label": "投资合伙人",
                                        "extra": {
                                            "shortPinyin": "tzhhr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126112",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200120000,
                                        "label": "投资银行业务",
                                        "extra": {
                                            "shortPinyin": "tzyxyw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126113",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200160000,
                                        "label": "投资者关系",
                                        "extra": {
                                            "shortPinyin": "tzzgx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126114",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200030000,
                                        "label": "股权投资",
                                        "extra": {
                                            "shortPinyin": "gqtz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126115",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200130000,
                                        "label": "投后管理",
                                        "extra": {
                                            "shortPinyin": "thgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126116",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200010000,
                                        "label": "并购",
                                        "extra": {
                                            "shortPinyin": "bg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126117",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200090000,
                                        "label": "融资",
                                        "extra": {
                                            "shortPinyin": "rz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126118",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200100000,
                                        "label": "融资总监",
                                        "extra": {
                                            "shortPinyin": "rzzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126119",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200200000,
                                        "label": "资产评估",
                                        "extra": {
                                            "shortPinyin": "zcpg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126120",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 14000100190000,
                                        "label": "资产管理",
                                        "extra": {
                                            "shortPinyin": "zc/zjgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126121",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200040000,
                                        "label": "行业研究",
                                        "extra": {
                                            "shortPinyin": "xyyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126122",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200210000,
                                        "label": "金融研究",
                                        "extra": {
                                            "shortPinyin": "zqfx/jryj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126123",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 5000500030000,
                                        "label": "金融数据分析",
                                        "extra": {
                                            "shortPinyin": "jrsjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126124",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200110000,
                                        "label": "投行财务分析",
                                        "extra": {
                                            "shortPinyin": "txcwfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126125",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200230000,
                                        "label": "量化研究",
                                        "extra": {
                                            "shortPinyin": "lhyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126126",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200070000,
                                        "label": "交易员",
                                        "extra": {
                                            "shortPinyin": "jyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126127",
                                        "parentSerial": "126106"
                                    },
                                    {
                                        "value": 12000200020000,
                                        "label": "股票/期货操盘手",
                                        "extra": {
                                            "shortPinyin": "gp/qhcps",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126128",
                                        "parentSerial": "126106"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126106",
                                "parentSerial": "126076"
                            },
                            {
                                "value": 2000079,
                                "label": "证券/基金/期货",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 12000200180000,
                                        "label": "证券分析",
                                        "extra": {
                                            "shortPinyin": "zqfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126130",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 12000200190000,
                                        "label": "证券经纪人",
                                        "extra": {
                                            "shortPinyin": "zqjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126131",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 12000400030000,
                                        "label": "证券交易员",
                                        "extra": {
                                            "shortPinyin": "zqjyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126132",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 14000700160000,
                                        "label": "证券事务代表",
                                        "extra": {
                                            "shortPinyin": "zqswdb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126133",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 3000300200000,
                                        "label": "证券/投资项目经理",
                                        "extra": {
                                            "shortPinyin": "zq/tzxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126134",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 12000200060000,
                                        "label": "基金销售",
                                        "extra": {
                                            "shortPinyin": "jjxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126135",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 12000200050000,
                                        "label": "基金经理",
                                        "extra": {
                                            "shortPinyin": "jjjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126136",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 12000400010000,
                                        "label": "期货经纪人",
                                        "extra": {
                                            "shortPinyin": "qhjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126137",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 5000500060000,
                                        "label": "外汇分析师",
                                        "extra": {
                                            "shortPinyin": "whfxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126138",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 12000400020000,
                                        "label": "外汇经纪人",
                                        "extra": {
                                            "shortPinyin": "whjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126139",
                                        "parentSerial": "126129"
                                    },
                                    {
                                        "value": 14000700150000,
                                        "label": "信息披露专员",
                                        "extra": {
                                            "shortPinyin": "xxplzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126140",
                                        "parentSerial": "126129"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126129",
                                "parentSerial": "126076"
                            },
                            {
                                "value": 2000080,
                                "label": "信托/担保/拍卖/典当",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 12000500020000,
                                        "label": "信托业务",
                                        "extra": {
                                            "shortPinyin": "xtyw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126142",
                                        "parentSerial": "126141"
                                    },
                                    {
                                        "value": 12000500010000,
                                        "label": "担保业务",
                                        "extra": {
                                            "shortPinyin": "dbyw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126143",
                                        "parentSerial": "126141"
                                    },
                                    {
                                        "value": 6001300190000,
                                        "label": "典当业务",
                                        "extra": {
                                            "shortPinyin": "ddyw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126144",
                                        "parentSerial": "126141"
                                    },
                                    {
                                        "value": 6001300110000,
                                        "label": "拍卖师",
                                        "extra": {
                                            "shortPinyin": "pms",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126145",
                                        "parentSerial": "126141"
                                    },
                                    {
                                        "value": 6001300180000,
                                        "label": "珠宝/收藏品鉴定",
                                        "extra": {
                                            "shortPinyin": "zb/scpjd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126146",
                                        "parentSerial": "126141"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126141",
                                "parentSerial": "126076"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126076",
                        "parentSerial": null
                    },
                    {
                        "value": 1000015,
                        "label": "采购/贸易",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000081,
                                "label": "采购",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 2000100010000,
                                        "label": "采购专员/助理",
                                        "extra": {
                                            "shortPinyin": "cg/sbgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126149",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100140000,
                                        "label": "采购经理/主管",
                                        "extra": {
                                            "shortPinyin": "cgjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126150",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100130000,
                                        "label": "采购总监",
                                        "extra": {
                                            "shortPinyin": "cgzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126151",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100080000,
                                        "label": "商务采购",
                                        "extra": {
                                            "shortPinyin": "swcg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126152",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100190000,
                                        "label": "采购工程师",
                                        "extra": {
                                            "shortPinyin": "cggcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126153",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100060000,
                                        "label": "买手",
                                        "extra": {
                                            "shortPinyin": "ms",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126154",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100050000,
                                        "label": "供应商/采购质量管理",
                                        "extra": {
                                            "shortPinyin": "gys/cgzlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126155",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100170000,
                                        "label": "供应商开发",
                                        "extra": {
                                            "shortPinyin": "gyskf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126156",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100100000,
                                        "label": "物料专员",
                                        "extra": {
                                            "shortPinyin": "wlzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126157",
                                        "parentSerial": "126148"
                                    },
                                    {
                                        "value": 2000100090000,
                                        "label": "物料经理",
                                        "extra": {
                                            "shortPinyin": "wljl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126158",
                                        "parentSerial": "126148"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126148",
                                "parentSerial": "126147"
                            },
                            {
                                "value": 2000082,
                                "label": "进出口贸易",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200070000,
                                        "label": "外贸业务员",
                                        "extra": {
                                            "shortPinyin": "wmywy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126160",
                                        "parentSerial": "126159"
                                    },
                                    {
                                        "value": 19000200180000,
                                        "label": "外贸经理",
                                        "extra": {
                                            "shortPinyin": "wmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126161",
                                        "parentSerial": "126159"
                                    },
                                    {
                                        "value": 2000100180000,
                                        "label": "贸易跟单",
                                        "extra": {
                                            "shortPinyin": "mygd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126162",
                                        "parentSerial": "126159"
                                    },
                                    {
                                        "value": 14000700030000,
                                        "label": "单证员",
                                        "extra": {
                                            "shortPinyin": "dzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126163",
                                        "parentSerial": "126159"
                                    },
                                    {
                                        "value": 14000300030000,
                                        "label": "关务专员",
                                        "extra": {
                                            "shortPinyin": "gwzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126164",
                                        "parentSerial": "126159"
                                    },
                                    {
                                        "value": 14000300020000,
                                        "label": "关务管理",
                                        "extra": {
                                            "shortPinyin": "gwgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126165",
                                        "parentSerial": "126159"
                                    },
                                    {
                                        "value": 14000300040000,
                                        "label": "报关/报检员",
                                        "extra": {
                                            "shortPinyin": "bg/bjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126166",
                                        "parentSerial": "126159"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126159",
                                "parentSerial": "126147"
                            },
                            {
                                "value": 2000083,
                                "label": "招投标",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 2000100020000,
                                        "label": "地产招投标",
                                        "extra": {
                                            "shortPinyin": "dcztb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126168",
                                        "parentSerial": "126167"
                                    },
                                    {
                                        "value": 7000800020000,
                                        "label": "地产招投标总监",
                                        "extra": {
                                            "shortPinyin": "dcztbzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126169",
                                        "parentSerial": "126167"
                                    },
                                    {
                                        "value": 2000100120000,
                                        "label": "医药项目招投标",
                                        "extra": {
                                            "shortPinyin": "yyxmztb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126170",
                                        "parentSerial": "126167"
                                    },
                                    {
                                        "value": 2000100030000,
                                        "label": "工程招投标",
                                        "extra": {
                                            "shortPinyin": "gcztb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126171",
                                        "parentSerial": "126167"
                                    },
                                    {
                                        "value": 2000100110000,
                                        "label": "项目招投标",
                                        "extra": {
                                            "shortPinyin": "xmztb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126172",
                                        "parentSerial": "126167"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126167",
                                "parentSerial": "126147"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126147",
                        "parentSerial": null
                    },
                    {
                        "value": 1000016,
                        "label": "供应链/物流",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000084,
                                "label": "物流",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 2000500120000,
                                        "label": "物流专员",
                                        "extra": {
                                            "shortPinyin": "wlzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126175",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500110000,
                                        "label": "物流经理",
                                        "extra": {
                                            "shortPinyin": "wljl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126176",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500130000,
                                        "label": "物流总监",
                                        "extra": {
                                            "shortPinyin": "wlzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126177",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500080000,
                                        "label": "物流跟单",
                                        "extra": {
                                            "shortPinyin": "wlgd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126178",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500160000,
                                        "label": "物流运营",
                                        "extra": {
                                            "shortPinyin": "wlyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126179",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500090000,
                                        "label": "物流规划",
                                        "extra": {
                                            "shortPinyin": "wlgh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126180",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500100000,
                                        "label": "物流监管",
                                        "extra": {
                                            "shortPinyin": "wljg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126181",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500070000,
                                        "label": "调度员",
                                        "extra": {
                                            "shortPinyin": "ddy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126182",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500150000,
                                        "label": "运输经理/主管",
                                        "extra": {
                                            "shortPinyin": "ysjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126183",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 3000300100000,
                                        "label": "物流/仓储项目经理",
                                        "extra": {
                                            "shortPinyin": "wl/ccxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126184",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500020000,
                                        "label": "货运代理专员",
                                        "extra": {
                                            "shortPinyin": "hydlzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126185",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500170000,
                                        "label": "货运代理经理",
                                        "extra": {
                                            "shortPinyin": "hydljl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126186",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500060000,
                                        "label": "水/空/陆运操作",
                                        "extra": {
                                            "shortPinyin": "s/k/lycz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126187",
                                        "parentSerial": "126174"
                                    },
                                    {
                                        "value": 2000500040000,
                                        "label": "集装箱管理",
                                        "extra": {
                                            "shortPinyin": "jzxgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126188",
                                        "parentSerial": "126174"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126174",
                                "parentSerial": "126173"
                            },
                            {
                                "value": 2000085,
                                "label": "供应链",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 2000100040000,
                                        "label": "供应链专员",
                                        "extra": {
                                            "shortPinyin": "gylzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126190",
                                        "parentSerial": "126189"
                                    },
                                    {
                                        "value": 2000100160000,
                                        "label": "供应链经理",
                                        "extra": {
                                            "shortPinyin": "gyljl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126191",
                                        "parentSerial": "126189"
                                    },
                                    {
                                        "value": 2000100150000,
                                        "label": "供应链总监",
                                        "extra": {
                                            "shortPinyin": "gylzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126192",
                                        "parentSerial": "126189"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126189",
                                "parentSerial": "126173"
                            },
                            {
                                "value": 2000086,
                                "label": "仓储",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 2000200020000,
                                        "label": "仓库管理员",
                                        "extra": {
                                            "shortPinyin": "ckgly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126194",
                                        "parentSerial": "126193"
                                    },
                                    {
                                        "value": 2000200040000,
                                        "label": "仓库文员",
                                        "extra": {
                                            "shortPinyin": "ckwy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126195",
                                        "parentSerial": "126193"
                                    },
                                    {
                                        "value": 2000200030000,
                                        "label": "仓库经理",
                                        "extra": {
                                            "shortPinyin": "ckjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126196",
                                        "parentSerial": "126193"
                                    },
                                    {
                                        "value": 2000200010000,
                                        "label": "仓储物料管理",
                                        "extra": {
                                            "shortPinyin": "ccwlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126197",
                                        "parentSerial": "126193"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126193",
                                "parentSerial": "126173"
                            },
                            {
                                "value": 2000087,
                                "label": "配送理货",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 2000400020000,
                                        "label": "快递员",
                                        "extra": {
                                            "shortPinyin": "kdy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126199",
                                        "parentSerial": "126198"
                                    },
                                    {
                                        "value": 2000400050000,
                                        "label": "送餐员",
                                        "extra": {
                                            "shortPinyin": "scy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126200",
                                        "parentSerial": "126198"
                                    },
                                    {
                                        "value": 6001500010000,
                                        "label": "理货员",
                                        "extra": {
                                            "shortPinyin": "lhy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126201",
                                        "parentSerial": "126198"
                                    },
                                    {
                                        "value": 2000400010000,
                                        "label": "搬运工",
                                        "extra": {
                                            "shortPinyin": "byg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126202",
                                        "parentSerial": "126198"
                                    },
                                    {
                                        "value": 2000400030000,
                                        "label": "配/理/拣/发货",
                                        "extra": {
                                            "shortPinyin": "p/l/j/fh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126203",
                                        "parentSerial": "126198"
                                    },
                                    {
                                        "value": 2000400040000,
                                        "label": "配送员",
                                        "extra": {
                                            "shortPinyin": "psy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126204",
                                        "parentSerial": "126198"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126198",
                                "parentSerial": "126173"
                            },
                            {
                                "value": 2000088,
                                "label": "驾驶员",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 2000300030000,
                                        "label": "货运司机",
                                        "extra": {
                                            "shortPinyin": "hysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126206",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700080000,
                                        "label": "商务司机",
                                        "extra": {
                                            "shortPinyin": "swsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126207",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700100000,
                                        "label": "网约车司机",
                                        "extra": {
                                            "shortPinyin": "wycsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126208",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700010000,
                                        "label": "出租车驾驶员",
                                        "extra": {
                                            "shortPinyin": "czcjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126209",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700040000,
                                        "label": "代驾司机",
                                        "extra": {
                                            "shortPinyin": "djsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126210",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700030000,
                                        "label": "大巴司机",
                                        "extra": {
                                            "shortPinyin": "dbsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126211",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700090000,
                                        "label": "通勤司机",
                                        "extra": {
                                            "shortPinyin": "tqsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126212",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700110000,
                                        "label": "校车司机",
                                        "extra": {
                                            "shortPinyin": "xcsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126213",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 2000300040000,
                                        "label": "卡车司机",
                                        "extra": {
                                            "shortPinyin": "kcsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126214",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 2000300010000,
                                        "label": "吊车司机",
                                        "extra": {
                                            "shortPinyin": "dcsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126215",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 2000300050000,
                                        "label": "危化品司机",
                                        "extra": {
                                            "shortPinyin": "whpsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126216",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 2000300060000,
                                        "label": "油罐车司机",
                                        "extra": {
                                            "shortPinyin": "ygcsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126217",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700060000,
                                        "label": "机动车司机/驾驶",
                                        "extra": {
                                            "shortPinyin": "jdcsj/js",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126218",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000300020000,
                                        "label": "船员/水手",
                                        "extra": {
                                            "shortPinyin": "cy/ss",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126219",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700020000,
                                        "label": "船舶驾驶/操作",
                                        "extra": {
                                            "shortPinyin": "cbjs/cz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126220",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700050000,
                                        "label": "飞机驾驶/操作",
                                        "extra": {
                                            "shortPinyin": "fjjs/cz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126221",
                                        "parentSerial": "126205"
                                    },
                                    {
                                        "value": 6000700070000,
                                        "label": "列车驾驶/操作",
                                        "extra": {
                                            "shortPinyin": "lcjs/cz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126222",
                                        "parentSerial": "126205"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126205",
                                "parentSerial": "126173"
                            },
                            {
                                "value": 2000089,
                                "label": "乘务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6000100010000,
                                        "label": "地铁安检",
                                        "extra": {
                                            "shortPinyin": "dtaj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126224",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300030000,
                                        "label": "地铁站务员",
                                        "extra": {
                                            "shortPinyin": "dtzwy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126225",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300050000,
                                        "label": "公交/地铁乘务",
                                        "extra": {
                                            "shortPinyin": "gj/dtcw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126226",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300070000,
                                        "label": "机场地勤人员",
                                        "extra": {
                                            "shortPinyin": "jcdqry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126227",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300060000,
                                        "label": "航空乘务",
                                        "extra": {
                                            "shortPinyin": "hkcw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126228",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300040000,
                                        "label": "高铁乘务",
                                        "extra": {
                                            "shortPinyin": "gtcw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126229",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300080000,
                                        "label": "列车乘务",
                                        "extra": {
                                            "shortPinyin": "lccw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126230",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300090000,
                                        "label": "邮轮乘务",
                                        "extra": {
                                            "shortPinyin": "ylcw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126231",
                                        "parentSerial": "126223"
                                    },
                                    {
                                        "value": 6000300010000,
                                        "label": "船舶乘务",
                                        "extra": {
                                            "shortPinyin": "cbcw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126232",
                                        "parentSerial": "126223"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126223",
                                "parentSerial": "126173"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126173",
                        "parentSerial": null
                    },
                    {
                        "value": 1000017,
                        "label": "汽车",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000090,
                                "label": "汽车设计/制造",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 10000200010000,
                                        "label": "车身/造型设计",
                                        "extra": {
                                            "shortPinyin": "cs/zxsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126235",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300320000,
                                        "label": "内外饰设计工程师",
                                        "extra": {
                                            "shortPinyin": "nwssjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126236",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300120000,
                                        "label": "动力系统设计",
                                        "extra": {
                                            "shortPinyin": "dlxtsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126237",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300390000,
                                        "label": "动力系统工程师",
                                        "extra": {
                                            "shortPinyin": "dlxtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126238",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800010000,
                                        "label": "线束设计",
                                        "extra": {
                                            "shortPinyin": "xssj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126239",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300370000,
                                        "label": "底盘工程师",
                                        "extra": {
                                            "shortPinyin": "dpgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126240",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 10000200140000,
                                        "label": "汽车零部件设计",
                                        "extra": {
                                            "shortPinyin": "qclbjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126241",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300380000,
                                        "label": "汽车电子工程师",
                                        "extra": {
                                            "shortPinyin": "qcdzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126242",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300400000,
                                        "label": "汽车结构工程师",
                                        "extra": {
                                            "shortPinyin": "qcjggcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126243",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000400120000,
                                        "label": "汽车质量工程师",
                                        "extra": {
                                            "shortPinyin": "qczlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126244",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300420000,
                                        "label": "汽车装配工艺工程师",
                                        "extra": {
                                            "shortPinyin": "qczpgygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126245",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300410000,
                                        "label": "汽车机械工程师",
                                        "extra": {
                                            "shortPinyin": "qcjxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126246",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300600000,
                                        "label": "总装工程师",
                                        "extra": {
                                            "shortPinyin": "zzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126247",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 3000300070000,
                                        "label": "汽车项目经理",
                                        "extra": {
                                            "shortPinyin": "qcxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126249",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800020000,
                                        "label": "充电桩设计",
                                        "extra": {
                                            "shortPinyin": "cdzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178396",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800030000,
                                        "label": "汽车安全性能工程师",
                                        "extra": {
                                            "shortPinyin": "qcaqxngcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486833",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800050000,
                                        "label": "汽车试验工程师",
                                        "extra": {
                                            "shortPinyin": "qcsygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486834",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800070000,
                                        "label": "汽车标定工程师",
                                        "extra": {
                                            "shortPinyin": "qcbdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486835",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800080000,
                                        "label": "汽车发动机工程师",
                                        "extra": {
                                            "shortPinyin": "qcfdjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486836",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800090000,
                                        "label": "车联网工程师",
                                        "extra": {
                                            "shortPinyin": "clwgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486837",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800110000,
                                        "label": "汽车总布置工程师",
                                        "extra": {
                                            "shortPinyin": "qczbzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486838",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800120000,
                                        "label": "汽车试制工程师",
                                        "extra": {
                                            "shortPinyin": "qcszgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486839",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800130000,
                                        "label": "汽车NVH工程师",
                                        "extra": {
                                            "shortPinyin": "qcnvhgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486840",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800140000,
                                        "label": "汽车CAE/CFD工程师",
                                        "extra": {
                                            "shortPinyin": "qccae/cfdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486841",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800150000,
                                        "label": "变速箱/传动系统工程师",
                                        "extra": {
                                            "shortPinyin": "bsx/cdxtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486842",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800160000,
                                        "label": "悬架/车桥/车架系统工程师",
                                        "extra": {
                                            "shortPinyin": "xj/cq/cjxtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486843",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000800170000,
                                        "label": "转向/制动系统工程师",
                                        "extra": {
                                            "shortPinyin": "zx/zdxtgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486844",
                                        "parentSerial": "126234"
                                    },
                                    {
                                        "value": 15000300360000,
                                        "label": "汽车/摩托车工程师",
                                        "extra": {
                                            "shortPinyin": "qc/mtcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126248",
                                        "parentSerial": "126234"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126234",
                                "parentSerial": "126233"
                            },
                            {
                                "value": 2000148,
                                "label": "新能源汽车",
                                "extra": {
                                    "shortPinyin": "xnyqc",
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 22000100010000,
                                        "label": "新能源电池工程师",
                                        "extra": {
                                            "shortPinyin": "xnydcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486849",
                                        "parentSerial": "486847"
                                    },
                                    {
                                        "value": 22000100020000,
                                        "label": "新能源电控工程师",
                                        "extra": {
                                            "shortPinyin": "xnydkgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486850",
                                        "parentSerial": "486847"
                                    },
                                    {
                                        "value": 22000100030000,
                                        "label": "新能源电机工程师",
                                        "extra": {
                                            "shortPinyin": "xnydjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486851",
                                        "parentSerial": "486847"
                                    },
                                    {
                                        "value": 15000800020000,
                                        "label": "充电桩设计",
                                        "extra": {
                                            "shortPinyin": "cdzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "486848",
                                        "parentSerial": "486847"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "486847",
                                "parentSerial": "126233"
                            },
                            {
                                "value": 2000091,
                                "label": "汽车服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001100090000,
                                        "label": "汽车维修",
                                        "extra": {
                                            "shortPinyin": "qcwx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126251",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100020000,
                                        "label": "汽车保养",
                                        "extra": {
                                            "shortPinyin": "qcby",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126252",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100060000,
                                        "label": "汽车美容",
                                        "extra": {
                                            "shortPinyin": "qcmr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126253",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100070000,
                                        "label": "汽车喷漆",
                                        "extra": {
                                            "shortPinyin": "qcpq",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126254",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100080000,
                                        "label": "汽车贴膜",
                                        "extra": {
                                            "shortPinyin": "qctm",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126255",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100100000,
                                        "label": "汽车装潢",
                                        "extra": {
                                            "shortPinyin": "qcz潢",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126256",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100040000,
                                        "label": "汽车改装",
                                        "extra": {
                                            "shortPinyin": "qcgz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126257",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 15000100290000,
                                        "label": "汽车电工",
                                        "extra": {
                                            "shortPinyin": "qcdg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126258",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100010000,
                                        "label": "抛光工",
                                        "extra": {
                                            "shortPinyin": "pgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126259",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100110000,
                                        "label": "洗车工",
                                        "extra": {
                                            "shortPinyin": "xcg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126260",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001100050000,
                                        "label": "汽车轮胎服务",
                                        "extra": {
                                            "shortPinyin": "qcltfw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126261",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 13000100140000,
                                        "label": "汽车售后服务",
                                        "extra": {
                                            "shortPinyin": "qcshfw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126262",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 12000100170000,
                                        "label": "汽车定损理赔",
                                        "extra": {
                                            "shortPinyin": "qcds/cxlp",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126263",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 12000100130000,
                                        "label": "车险勘查",
                                        "extra": {
                                            "shortPinyin": "cxkc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126264",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6001300050000,
                                        "label": "二手车评估师",
                                        "extra": {
                                            "shortPinyin": "escpgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126265",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 6000500050000,
                                        "label": "加油站工作员",
                                        "extra": {
                                            "shortPinyin": "jyzgzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126266",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 15000900040000,
                                        "label": "汽车钣金工",
                                        "extra": {
                                            "shortPinyin": "qc钣jg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486845",
                                        "parentSerial": "126250"
                                    },
                                    {
                                        "value": 15000900050000,
                                        "label": "汽车救援员",
                                        "extra": {
                                            "shortPinyin": "qcjyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "486846",
                                        "parentSerial": "126250"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126250",
                                "parentSerial": "126233"
                            },
                            {
                                "value": 2000005,
                                "label": "汽车销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200150000,
                                        "label": "汽车销售",
                                        "extra": {
                                            "shortPinyin": "qcxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126268",
                                        "parentSerial": "126267"
                                    },
                                    {
                                        "value": 15000900030000,
                                        "label": "汽车金融销售",
                                        "extra": {
                                            "shortPinyin": "qcjrxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178397",
                                        "parentSerial": "126267"
                                    },
                                    {
                                        "value": 15000900010000,
                                        "label": "汽车配件销售",
                                        "extra": {
                                            "shortPinyin": "qcpjxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126269",
                                        "parentSerial": "126267"
                                    },
                                    {
                                        "value": 15000900020000,
                                        "label": "二手车经纪人",
                                        "extra": {
                                            "shortPinyin": "escjjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126270",
                                        "parentSerial": "126267"
                                    },
                                    {
                                        "value": 3000200010000,
                                        "label": "4S店店长",
                                        "extra": {
                                            "shortPinyin": "4sddc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126271",
                                        "parentSerial": "126267"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126267",
                                "parentSerial": "126233"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126233",
                        "parentSerial": null
                    },
                    {
                        "value": 1000018,
                        "label": "生产制造",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000092,
                                "label": "机械设计/制造",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 10000200070000,
                                        "label": "机械设计",
                                        "extra": {
                                            "shortPinyin": "jxsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126274",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300230000,
                                        "label": "机械工程师",
                                        "extra": {
                                            "shortPinyin": "jxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126275",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300250000,
                                        "label": "机械结构工程师",
                                        "extra": {
                                            "shortPinyin": "jxjggcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126276",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 10000200080000,
                                        "label": "机械研发工程师",
                                        "extra": {
                                            "shortPinyin": "jxyfgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126277",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300240000,
                                        "label": "机械工艺工程师",
                                        "extra": {
                                            "shortPinyin": "jxgygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126278",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300270000,
                                        "label": "机械制图",
                                        "extra": {
                                            "shortPinyin": "jxzt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126279",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 10000200130000,
                                        "label": "模具设计",
                                        "extra": {
                                            "shortPinyin": "mjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126280",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300310000,
                                        "label": "模具工程师",
                                        "extra": {
                                            "shortPinyin": "mjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126281",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300300000,
                                        "label": "零部件设计",
                                        "extra": {
                                            "shortPinyin": "lbjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126282",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300530000,
                                        "label": "工艺/制程工程师",
                                        "extra": {
                                            "shortPinyin": "zcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126283",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300580000,
                                        "label": "装配工程师",
                                        "extra": {
                                            "shortPinyin": "zpgcs/khjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126284",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300210000,
                                        "label": "焊接工程师",
                                        "extra": {
                                            "shortPinyin": "hjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126285",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300550000,
                                        "label": "注塑工程师",
                                        "extra": {
                                            "shortPinyin": "zsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126286",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300290000,
                                        "label": "夹具工程师",
                                        "extra": {
                                            "shortPinyin": "jjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126287",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300560000,
                                        "label": "铸造/锻造工程师",
                                        "extra": {
                                            "shortPinyin": "zz/dzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126288",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300090000,
                                        "label": "冲压工程师",
                                        "extra": {
                                            "shortPinyin": "cygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126289",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300620000,
                                        "label": "涂装工程师",
                                        "extra": {
                                            "shortPinyin": "tzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126290",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300180000,
                                        "label": "工装工程师",
                                        "extra": {
                                            "shortPinyin": "gzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126291",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300340000,
                                        "label": "喷涂技术员",
                                        "extra": {
                                            "shortPinyin": "ptjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126292",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 9000500330000,
                                        "label": "精益工程师",
                                        "extra": {
                                            "shortPinyin": "jygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126293",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300540000,
                                        "label": "制造工程师",
                                        "extra": {
                                            "shortPinyin": "zzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126294",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300010000,
                                        "label": "CNC/数控编程",
                                        "extra": {
                                            "shortPinyin": "cnc/sk",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126295",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 15000300150000,
                                        "label": "工业工程师",
                                        "extra": {
                                            "shortPinyin": "gygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126296",
                                        "parentSerial": "126273"
                                    },
                                    {
                                        "value": 17000300060000,
                                        "label": "工业设计",
                                        "extra": {
                                            "shortPinyin": "gysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126297",
                                        "parentSerial": "126273"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126273",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000093,
                                "label": "其他机械设计/制造",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 15000300260000,
                                        "label": "机械设备工程师",
                                        "extra": {
                                            "shortPinyin": "jxsbgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126299",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 20000200140000,
                                        "label": "机械维修/保养",
                                        "extra": {
                                            "shortPinyin": "jxwx/by",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126300",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300220000,
                                        "label": "机电工程师",
                                        "extra": {
                                            "shortPinyin": "jdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126301",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300610000,
                                        "label": "电机工程师",
                                        "extra": {
                                            "shortPinyin": "djgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126302",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 10000200050000,
                                        "label": "发动机工程师",
                                        "extra": {
                                            "shortPinyin": "fdj/zzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126303",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300070000,
                                        "label": "材料工程师",
                                        "extra": {
                                            "shortPinyin": "clgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126304",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300140000,
                                        "label": "高分子材料工程师",
                                        "extra": {
                                            "shortPinyin": "gfzclgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126305",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300170000,
                                        "label": "材料工艺工程师",
                                        "extra": {
                                            "shortPinyin": "clgygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126306",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300160000,
                                        "label": "工业机器人工程师",
                                        "extra": {
                                            "shortPinyin": "gyjqrgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126307",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300640000,
                                        "label": "机器人调试工程师",
                                        "extra": {
                                            "shortPinyin": "jqrtsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178398",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300460000,
                                        "label": "试验工程师",
                                        "extra": {
                                            "shortPinyin": "sygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126308",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300510000,
                                        "label": "液压工程师",
                                        "extra": {
                                            "shortPinyin": "yygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126309",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300350000,
                                        "label": "气动工程师",
                                        "extra": {
                                            "shortPinyin": "qdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126310",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300040000,
                                        "label": "安全性能工程师",
                                        "extra": {
                                            "shortPinyin": "aqxngcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126311",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300430000,
                                        "label": "热能工程师",
                                        "extra": {
                                            "shortPinyin": "rngcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126312",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300630000,
                                        "label": "热设计工程师",
                                        "extra": {
                                            "shortPinyin": "rsjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126313",
                                        "parentSerial": "126298"
                                    },
                                    {
                                        "value": 15000300200000,
                                        "label": "锅炉工程师/技师",
                                        "extra": {
                                            "shortPinyin": "glgcs/js",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126314",
                                        "parentSerial": "126298"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126298",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000094,
                                "label": "新能源",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 15000300610000,
                                        "label": "电机工程师",
                                        "extra": {
                                            "shortPinyin": "djgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126316",
                                        "parentSerial": "126315"
                                    },
                                    {
                                        "value": 10000200030000,
                                        "label": "电池工程师",
                                        "extra": {
                                            "shortPinyin": "dcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126317",
                                        "parentSerial": "126315"
                                    },
                                    {
                                        "value": 15000800010000,
                                        "label": "线束设计",
                                        "extra": {
                                            "shortPinyin": "xssj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126318",
                                        "parentSerial": "126315"
                                    },
                                    {
                                        "value": 15000800020000,
                                        "label": "充电桩设计",
                                        "extra": {
                                            "shortPinyin": "cdzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "178399",
                                        "parentSerial": "126315"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126315",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000095,
                                "label": "化工/食品",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 10000100020000,
                                        "label": "化工研发",
                                        "extra": {
                                            "shortPinyin": "hgyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126320",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100050000,
                                        "label": "化学分析",
                                        "extra": {
                                            "shortPinyin": "hxfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126321",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100040000,
                                        "label": "化学操作",
                                        "extra": {
                                            "shortPinyin": "hxcz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126322",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100060000,
                                        "label": "化学技术应用",
                                        "extra": {
                                            "shortPinyin": "hxjsyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126323",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100070000,
                                        "label": "化学制剂研发",
                                        "extra": {
                                            "shortPinyin": "hxzjyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126324",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 3000300180000,
                                        "label": "化工项目经理",
                                        "extra": {
                                            "shortPinyin": "hgxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126325",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100030000,
                                        "label": "化学/化工技术总监",
                                        "extra": {
                                            "shortPinyin": "hx/hgjszj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126326",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100010000,
                                        "label": "化学合成",
                                        "extra": {
                                            "shortPinyin": "hxhc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126327",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100080000,
                                        "label": "化验/检验",
                                        "extra": {
                                            "shortPinyin": "hy/jy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126328",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100160000,
                                        "label": "食品/饮料研发",
                                        "extra": {
                                            "shortPinyin": "sp/ylyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126329",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100150000,
                                        "label": "食品/饮料检验",
                                        "extra": {
                                            "shortPinyin": "sp/yljy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126330",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100090000,
                                        "label": "化妆品研发",
                                        "extra": {
                                            "shortPinyin": "hzpyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126331",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100240000,
                                        "label": "油漆/化工涂料研发",
                                        "extra": {
                                            "shortPinyin": "yq/hgtlyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126332",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 15000300490000,
                                        "label": "涂料研发",
                                        "extra": {
                                            "shortPinyin": "tlyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126333",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 15000300480000,
                                        "label": "塑料工程师",
                                        "extra": {
                                            "shortPinyin": "slgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126334",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 15000300500000,
                                        "label": "橡胶工程师",
                                        "extra": {
                                            "shortPinyin": "xjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126335",
                                        "parentSerial": "126319"
                                    },
                                    {
                                        "value": 10000100250000,
                                        "label": "造纸研发",
                                        "extra": {
                                            "shortPinyin": "zzyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126336",
                                        "parentSerial": "126319"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126319",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000096,
                                "label": "服装/纺织/皮革",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000300040000,
                                        "label": "服装/纺织设计",
                                        "extra": {
                                            "shortPinyin": "fz/fzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126338",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 17000300050000,
                                        "label": "服装/纺织设计总监",
                                        "extra": {
                                            "shortPinyin": "fz/fzsjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126339",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 17000300100000,
                                        "label": "鞋子设计",
                                        "extra": {
                                            "shortPinyin": "xzsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126340",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 17000300020000,
                                        "label": "布艺设计",
                                        "extra": {
                                            "shortPinyin": "bysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126341",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000300130000,
                                        "label": "服装/纺织/皮革工艺师",
                                        "extra": {
                                            "shortPinyin": "fz/fz/pggys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126342",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000500020000,
                                        "label": "服装/纺织/皮革跟单",
                                        "extra": {
                                            "shortPinyin": "fz/fz/pggd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126343",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000400050000,
                                        "label": "服装/纺织品/皮革质量管理",
                                        "extra": {
                                            "shortPinyin": "fz/fzp/pgzlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126344",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 3000300050000,
                                        "label": "服装/纺织/皮革项目经理",
                                        "extra": {
                                            "shortPinyin": "fz/fz/pgxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126345",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 2000100070000,
                                        "label": "面料辅料采购",
                                        "extra": {
                                            "shortPinyin": "mlflkf/cg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126346",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000500010000,
                                        "label": "面料辅料开发",
                                        "extra": {
                                            "shortPinyin": "mlflkf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126347",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100120000,
                                        "label": "电脑放码员",
                                        "extra": {
                                            "shortPinyin": "dnfmy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126348",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100140000,
                                        "label": "缝纫工",
                                        "extra": {
                                            "shortPinyin": "frg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126349",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100150000,
                                        "label": "打样/制版",
                                        "extra": {
                                            "shortPinyin": "dy/zb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126350",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100430000,
                                        "label": "样衣工",
                                        "extra": {
                                            "shortPinyin": "yyg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126351",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100090000,
                                        "label": "挡车工",
                                        "extra": {
                                            "shortPinyin": "dcg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126352",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100450000,
                                        "label": "印染工",
                                        "extra": {
                                            "shortPinyin": "yrg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126353",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100500000,
                                        "label": "整经工",
                                        "extra": {
                                            "shortPinyin": "zjg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126354",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100260000,
                                        "label": "漂染工",
                                        "extra": {
                                            "shortPinyin": "prg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126355",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100400000,
                                        "label": "细纱工",
                                        "extra": {
                                            "shortPinyin": "xsg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126356",
                                        "parentSerial": "126337"
                                    },
                                    {
                                        "value": 15000100210000,
                                        "label": "浆纱工",
                                        "extra": {
                                            "shortPinyin": "jsg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126357",
                                        "parentSerial": "126337"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126337",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000097,
                                "label": "印刷包装",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 15000100460000,
                                        "label": "印刷操作",
                                        "extra": {
                                            "shortPinyin": "yscz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126359",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100480000,
                                        "label": "印刷排版",
                                        "extra": {
                                            "shortPinyin": "yspb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126360",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100470000,
                                        "label": "印刷机械机长",
                                        "extra": {
                                            "shortPinyin": "ysjxjc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126361",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100100000,
                                        "label": "电分操作员",
                                        "extra": {
                                            "shortPinyin": "dfczy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126362",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100350000,
                                        "label": "数码直印/菲林输出",
                                        "extra": {
                                            "shortPinyin": "smzy/flsc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126363",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100310000,
                                        "label": "切纸机操作工",
                                        "extra": {
                                            "shortPinyin": "qzjczg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126364",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100370000,
                                        "label": "调墨技师",
                                        "extra": {
                                            "shortPinyin": "dmjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126365",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100320000,
                                        "label": "晒版员",
                                        "extra": {
                                            "shortPinyin": "sby",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126366",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100080000,
                                        "label": "打稿机操作员",
                                        "extra": {
                                            "shortPinyin": "dgjczy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126367",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100030000,
                                        "label": "包装工",
                                        "extra": {
                                            "shortPinyin": "bzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126368",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100530000,
                                        "label": "装订工",
                                        "extra": {
                                            "shortPinyin": "zdg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126369",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100410000,
                                        "label": "压痕工",
                                        "extra": {
                                            "shortPinyin": "yhg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126370",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100360000,
                                        "label": "烫金工",
                                        "extra": {
                                            "shortPinyin": "tjg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126371",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000100170000,
                                        "label": "复卷工",
                                        "extra": {
                                            "shortPinyin": "fjg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126372",
                                        "parentSerial": "126358"
                                    },
                                    {
                                        "value": 15000300050000,
                                        "label": "包装工程师",
                                        "extra": {
                                            "shortPinyin": "bzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126373",
                                        "parentSerial": "126358"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126358",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000098,
                                "label": "飞机/船舶/轨道交通",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 20000200130000,
                                        "label": "飞机维修/保养",
                                        "extra": {
                                            "shortPinyin": "fjwx/by",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126375",
                                        "parentSerial": "126374"
                                    },
                                    {
                                        "value": 10000200060000,
                                        "label": "飞机设计制造",
                                        "extra": {
                                            "shortPinyin": "fjsjzz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126376",
                                        "parentSerial": "126374"
                                    },
                                    {
                                        "value": 20000200110000,
                                        "label": "船舶维修/保养",
                                        "extra": {
                                            "shortPinyin": "cbwx/by",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126377",
                                        "parentSerial": "126374"
                                    },
                                    {
                                        "value": 10000200020000,
                                        "label": "船舶设计制造",
                                        "extra": {
                                            "shortPinyin": "cbsjzz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126378",
                                        "parentSerial": "126374"
                                    },
                                    {
                                        "value": 20000200160000,
                                        "label": "列车维修/保养",
                                        "extra": {
                                            "shortPinyin": "lcwx/by",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126379",
                                        "parentSerial": "126374"
                                    },
                                    {
                                        "value": 10000200120000,
                                        "label": "列车设计制造",
                                        "extra": {
                                            "shortPinyin": "lcsjzz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126380",
                                        "parentSerial": "126374"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126374",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000099,
                                "label": "生产管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000700100000,
                                        "label": "生产文员",
                                        "extra": {
                                            "shortPinyin": "scwy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126382",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200210000,
                                        "label": "生产跟单",
                                        "extra": {
                                            "shortPinyin": "scgd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126383",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200090000,
                                        "label": "生产计划管理",
                                        "extra": {
                                            "shortPinyin": "scjhgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126384",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200110000,
                                        "label": "生产设备管理",
                                        "extra": {
                                            "shortPinyin": "scsbgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126385",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200150000,
                                        "label": "生产运营管理",
                                        "extra": {
                                            "shortPinyin": "scyygl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126386",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200120000,
                                        "label": "生产物料管理（PMC）",
                                        "extra": {
                                            "shortPinyin": "scwlgl（pmc）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126387",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200080000,
                                        "label": "生产产品管理",
                                        "extra": {
                                            "shortPinyin": "sccpgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126388",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200180000,
                                        "label": "生产组长/拉长",
                                        "extra": {
                                            "shortPinyin": "sczc/lc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126389",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200160000,
                                        "label": "生产主管/督导",
                                        "extra": {
                                            "shortPinyin": "sczg/dd/zc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126390",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200100000,
                                        "label": "生产经理",
                                        "extra": {
                                            "shortPinyin": "scjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126391",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 3000300080000,
                                        "label": "生产项目经理/主管",
                                        "extra": {
                                            "shortPinyin": "scxmjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126392",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200010000,
                                        "label": "车间主任",
                                        "extra": {
                                            "shortPinyin": "cjzr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126393",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200170000,
                                        "label": "生产总监",
                                        "extra": {
                                            "shortPinyin": "sczj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126394",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 3000200160000,
                                        "label": "厂长",
                                        "extra": {
                                            "shortPinyin": "cc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126395",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200220000,
                                        "label": "生产安全员",
                                        "extra": {
                                            "shortPinyin": "scaqy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126396",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000300450000,
                                        "label": "生产技术员",
                                        "extra": {
                                            "shortPinyin": "scjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126397",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000300440000,
                                        "label": "生产工程师",
                                        "extra": {
                                            "shortPinyin": "scgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126398",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200140000,
                                        "label": "生产项目工程师",
                                        "extra": {
                                            "shortPinyin": "scxmgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126399",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 3000200250000,
                                        "label": "总工程师/副总工程师",
                                        "extra": {
                                            "shortPinyin": "zgcs/fzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126400",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200200000,
                                        "label": "生产营运主管",
                                        "extra": {
                                            "shortPinyin": "scyyzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126401",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200190000,
                                        "label": "生产营运经理",
                                        "extra": {
                                            "shortPinyin": "scyyjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126402",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200050000,
                                        "label": "机械设备经理",
                                        "extra": {
                                            "shortPinyin": "jxsbjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126403",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200030000,
                                        "label": "工程机械主管",
                                        "extra": {
                                            "shortPinyin": "gcjxzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126404",
                                        "parentSerial": "126381"
                                    },
                                    {
                                        "value": 15000200020000,
                                        "label": "工程机械经理",
                                        "extra": {
                                            "shortPinyin": "gcjxjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126405",
                                        "parentSerial": "126381"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126381",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000100,
                                "label": "生产质量管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 15000400150000,
                                        "label": "质检员",
                                        "extra": {
                                            "shortPinyin": "zjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126407",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400030000,
                                        "label": "QC质检",
                                        "extra": {
                                            "shortPinyin": "qczj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126408",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400020000,
                                        "label": "QA专员",
                                        "extra": {
                                            "shortPinyin": "qazy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126409",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400010000,
                                        "label": "QA工程师",
                                        "extra": {
                                            "shortPinyin": "qagcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126410",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400110000,
                                        "label": "品控/品质主管",
                                        "extra": {
                                            "shortPinyin": "pk/pzzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126411",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400160000,
                                        "label": "质量管理/测试",
                                        "extra": {
                                            "shortPinyin": "zlgl/cs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126412",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400060000,
                                        "label": "化验工程师",
                                        "extra": {
                                            "shortPinyin": "hygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126413",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400090000,
                                        "label": "检测",
                                        "extra": {
                                            "shortPinyin": "jc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126414",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 20000100080000,
                                        "label": "无损检测工程师",
                                        "extra": {
                                            "shortPinyin": "wsjcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126415",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400070000,
                                        "label": "计量工程师",
                                        "extra": {
                                            "shortPinyin": "jlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126416",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400040000,
                                        "label": "采样技术员",
                                        "extra": {
                                            "shortPinyin": "cyjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126417",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400170000,
                                        "label": "质量体系工程师",
                                        "extra": {
                                            "shortPinyin": "zltxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126418",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400180000,
                                        "label": "认证工程师",
                                        "extra": {
                                            "shortPinyin": "rzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126419",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400190000,
                                        "label": "认证审核员",
                                        "extra": {
                                            "shortPinyin": "rzshy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126420",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400100000,
                                        "label": "可靠度工程师",
                                        "extra": {
                                            "shortPinyin": "kkdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126421",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000300190000,
                                        "label": "故障分析师",
                                        "extra": {
                                            "shortPinyin": "gzfxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126422",
                                        "parentSerial": "126406"
                                    },
                                    {
                                        "value": 15000400080000,
                                        "label": "技术文档工程师",
                                        "extra": {
                                            "shortPinyin": "jswdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126423",
                                        "parentSerial": "126406"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126406",
                                "parentSerial": "126272"
                            },
                            {
                                "value": 2000101,
                                "label": "普工/技工",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 15000100270000,
                                        "label": "普工/操作工",
                                        "extra": {
                                            "shortPinyin": "pg/czg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126425",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100190000,
                                        "label": "技工",
                                        "extra": {
                                            "shortPinyin": "jg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126426",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400050000,
                                        "label": "电工",
                                        "extra": {
                                            "shortPinyin": "dg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126427",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100300000,
                                        "label": "钳工",
                                        "extra": {
                                            "shortPinyin": "qg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126428",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100110000,
                                        "label": "焊工",
                                        "extra": {
                                            "shortPinyin": "hg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126429",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100420000,
                                        "label": "氩弧焊工",
                                        "extra": {
                                            "shortPinyin": "yhhg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178671",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400020000,
                                        "label": "叉车工",
                                        "extra": {
                                            "shortPinyin": "ccg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126430",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400030000,
                                        "label": "铲车司机",
                                        "extra": {
                                            "shortPinyin": "ccsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126431",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100540000,
                                        "label": "组装工",
                                        "extra": {
                                            "shortPinyin": "zzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126432",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100070000,
                                        "label": "车工",
                                        "extra": {
                                            "shortPinyin": "cg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126433",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100230000,
                                        "label": "模具工",
                                        "extra": {
                                            "shortPinyin": "mjg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126434",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100180000,
                                        "label": "机电技师",
                                        "extra": {
                                            "shortPinyin": "jdjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126435",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100570000,
                                        "label": "CNC/数控操作",
                                        "extra": {
                                            "shortPinyin": "cnc/skcz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126436",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100280000,
                                        "label": "油漆工",
                                        "extra": {
                                            "shortPinyin": "yqg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126437",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100440000,
                                        "label": "仪表工",
                                        "extra": {
                                            "shortPinyin": "ybg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126438",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100520000,
                                        "label": "注塑工",
                                        "extra": {
                                            "shortPinyin": "zsg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126439",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400090000,
                                        "label": "锅炉工",
                                        "extra": {
                                            "shortPinyin": "glg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126440",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100240000,
                                        "label": "磨工",
                                        "extra": {
                                            "shortPinyin": "mg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126441",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400190000,
                                        "label": "万能工",
                                        "extra": {
                                            "shortPinyin": "wng",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126442",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100390000,
                                        "label": "铣工",
                                        "extra": {
                                            "shortPinyin": "xg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126443",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400060000,
                                        "label": "电力线路工",
                                        "extra": {
                                            "shortPinyin": "dlxlg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126444",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100490000,
                                        "label": "折弯工",
                                        "extra": {
                                            "shortPinyin": "zwg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126445",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100580000,
                                        "label": "钣金工",
                                        "extra": {
                                            "shortPinyin": "bjg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178401",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100220000,
                                        "label": "铆工",
                                        "extra": {
                                            "shortPinyin": "mg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126446",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100550000,
                                        "label": "挖掘机司机",
                                        "extra": {
                                            "shortPinyin": "wjjsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126447",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100020000,
                                        "label": "钻工",
                                        "extra": {
                                            "shortPinyin": "zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126448",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100380000,
                                        "label": "调色员",
                                        "extra": {
                                            "shortPinyin": "dsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126449",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100560000,
                                        "label": "切割工",
                                        "extra": {
                                            "shortPinyin": "qgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126450",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 6001100010000,
                                        "label": "抛光工",
                                        "extra": {
                                            "shortPinyin": "pgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126451",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100050000,
                                        "label": "裁床",
                                        "extra": {
                                            "shortPinyin": "cc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126452",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100250000,
                                        "label": "配色",
                                        "extra": {
                                            "shortPinyin": "ps",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126453",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400040000,
                                        "label": "电镀工",
                                        "extra": {
                                            "shortPinyin": "ddg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126454",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400110000,
                                        "label": "喷塑工",
                                        "extra": {
                                            "shortPinyin": "psg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126455",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100160000,
                                        "label": "浮法操作工(玻璃技术)",
                                        "extra": {
                                            "shortPinyin": "ffczg(bljs)",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126456",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 15000100040000,
                                        "label": "裱胶工",
                                        "extra": {
                                            "shortPinyin": "裱jg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126457",
                                        "parentSerial": "126424"
                                    },
                                    {
                                        "value": 7000400170000,
                                        "label": "拖压工",
                                        "extra": {
                                            "shortPinyin": "tyg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126458",
                                        "parentSerial": "126424"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126424",
                                "parentSerial": "126272"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126272",
                        "parentSerial": null
                    },
                    {
                        "value": 1000019,
                        "label": "能源/环保",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000102,
                                "label": "能源/矿产/地质",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000200160000,
                                        "label": "能源/矿产项目",
                                        "extra": {
                                            "shortPinyin": "ny/kcxm",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126461",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 7000300480000,
                                        "label": "选矿/采矿",
                                        "extra": {
                                            "shortPinyin": "xk/ck",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126462",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 21000300040000,
                                        "label": "冶金工程师",
                                        "extra": {
                                            "shortPinyin": "yjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178402",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 21000300010000,
                                        "label": "节能/能源工程师",
                                        "extra": {
                                            "shortPinyin": "jn/nygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126463",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 7000300370000,
                                        "label": "石油工程师",
                                        "extra": {
                                            "shortPinyin": "sygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126464",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 7000300440000,
                                        "label": "天然气技术人员",
                                        "extra": {
                                            "shortPinyin": "trqjsry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126465",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 7000300060000,
                                        "label": "电力工程师",
                                        "extra": {
                                            "shortPinyin": "dlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "308992",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 7000300150000,
                                        "label": "核力/火力工程师",
                                        "extra": {
                                            "shortPinyin": "hl/hlgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126466",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 21000300030000,
                                        "label": "风电工程师",
                                        "extra": {
                                            "shortPinyin": "fdgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126467",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 7000300040000,
                                        "label": "地质工程师",
                                        "extra": {
                                            "shortPinyin": "dzgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126468",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 7000300270000,
                                        "label": "勘探工程师",
                                        "extra": {
                                            "shortPinyin": "ktgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126469",
                                        "parentSerial": "126460"
                                    },
                                    {
                                        "value": 21000300020000,
                                        "label": "考古技术员",
                                        "extra": {
                                            "shortPinyin": "kgjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126470",
                                        "parentSerial": "126460"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126460",
                                "parentSerial": "126459"
                            },
                            {
                                "value": 2000103,
                                "label": "环境科学/环保",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 7000100070000,
                                        "label": "环境/健康/安全工程师（EHS）",
                                        "extra": {
                                            "shortPinyin": "hj/jk/aqgcs（ehs）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126472",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000100080000,
                                        "label": "环境/健康/安全经理（EHS）",
                                        "extra": {
                                            "shortPinyin": "hj/jk/aqjl（ehs）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126473",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300160000,
                                        "label": "环保工程师",
                                        "extra": {
                                            "shortPinyin": "hbgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126474",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300170000,
                                        "label": "环保技术工程师",
                                        "extra": {
                                            "shortPinyin": "hbjsgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126475",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300190000,
                                        "label": "环评技术员",
                                        "extra": {
                                            "shortPinyin": "hpjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126476",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000100100000,
                                        "label": "环境评价工程师",
                                        "extra": {
                                            "shortPinyin": "hjpjgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126477",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300390000,
                                        "label": "水处理工程师",
                                        "extra": {
                                            "shortPinyin": "sclgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126478",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300080000,
                                        "label": "废气处理工程师",
                                        "extra": {
                                            "shortPinyin": "fqclgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126479",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300120000,
                                        "label": "固废处理工程师",
                                        "extra": {
                                            "shortPinyin": "gfclgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126480",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000100090000,
                                        "label": "环境监测工程师",
                                        "extra": {
                                            "shortPinyin": "hjjcgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126481",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300360000,
                                        "label": "生态治理/规划",
                                        "extra": {
                                            "shortPinyin": "stzl/gh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126482",
                                        "parentSerial": "126471"
                                    },
                                    {
                                        "value": 7000300180000,
                                        "label": "环境管理/园林景区保护",
                                        "extra": {
                                            "shortPinyin": "hjgl/yljqbh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126483",
                                        "parentSerial": "126471"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126471",
                                "parentSerial": "126459"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126459",
                        "parentSerial": null
                    },
                    {
                        "value": 1000020,
                        "label": "农/林/牧/渔",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000104,
                                "label": "农业/林业",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001300100000,
                                        "label": "农艺师",
                                        "extra": {
                                            "shortPinyin": "nys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126486",
                                        "parentSerial": "126485"
                                    },
                                    {
                                        "value": 6001300170000,
                                        "label": "园艺师",
                                        "extra": {
                                            "shortPinyin": "yys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126487",
                                        "parentSerial": "126485"
                                    },
                                    {
                                        "value": 17000100020000,
                                        "label": "花艺师",
                                        "extra": {
                                            "shortPinyin": "hys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126488",
                                        "parentSerial": "126485"
                                    },
                                    {
                                        "value": 10000400070000,
                                        "label": "作物育种",
                                        "extra": {
                                            "shortPinyin": "zwyz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126489",
                                        "parentSerial": "126485"
                                    },
                                    {
                                        "value": 6001300090000,
                                        "label": "林业技术人员",
                                        "extra": {
                                            "shortPinyin": "lyjsry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126490",
                                        "parentSerial": "126485"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126485",
                                "parentSerial": "126484"
                            },
                            {
                                "value": 2000105,
                                "label": "畜牧/渔业",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001300020000,
                                        "label": "畜牧师",
                                        "extra": {
                                            "shortPinyin": "xms",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126492",
                                        "parentSerial": "126491"
                                    },
                                    {
                                        "value": 6001300040000,
                                        "label": "动物育种/养殖",
                                        "extra": {
                                            "shortPinyin": "dwyz/yz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126493",
                                        "parentSerial": "126491"
                                    },
                                    {
                                        "value": 6001300030000,
                                        "label": "动物营养/饲料研发",
                                        "extra": {
                                            "shortPinyin": "dwyy/slyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126494",
                                        "parentSerial": "126491"
                                    },
                                    {
                                        "value": 18000200160000,
                                        "label": "兽医",
                                        "extra": {
                                            "shortPinyin": "sy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126495",
                                        "parentSerial": "126491"
                                    },
                                    {
                                        "value": 21000000010000,
                                        "label": "场长（农/林/牧/渔）",
                                        "extra": {
                                            "shortPinyin": "cc（n/l/m/y）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126496",
                                        "parentSerial": "126491"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126491",
                                "parentSerial": "126484"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126484",
                        "parentSerial": null
                    },
                    {
                        "value": 1000021,
                        "label": "医疗健康",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000106,
                                "label": "生物/医药研发",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 10000100170000,
                                        "label": "试剂研发",
                                        "extra": {
                                            "shortPinyin": "sjyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126499",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100260000,
                                        "label": "制剂研发",
                                        "extra": {
                                            "shortPinyin": "zjyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126500",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100180000,
                                        "label": "药品研发",
                                        "extra": {
                                            "shortPinyin": "ypyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126501",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500160000,
                                        "label": "中药研发",
                                        "extra": {
                                            "shortPinyin": "zyyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "178403",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500060000,
                                        "label": "药物合成",
                                        "extra": {
                                            "shortPinyin": "ywhc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126502",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500070000,
                                        "label": "药理研究",
                                        "extra": {
                                            "shortPinyin": "ylyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126503",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500150000,
                                        "label": "计算机辅助药物设计",
                                        "extra": {
                                            "shortPinyin": "jsjfzywsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126504",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100210000,
                                        "label": "医药技术研发",
                                        "extra": {
                                            "shortPinyin": "yyjsyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126505",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100230000,
                                        "label": "医药研发管理",
                                        "extra": {
                                            "shortPinyin": "yyyfgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126506",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100200000,
                                        "label": "医药化学分析",
                                        "extra": {
                                            "shortPinyin": "yyhxfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126507",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500140000,
                                        "label": "药物质量研究",
                                        "extra": {
                                            "shortPinyin": "ywzlyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126508",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 15000400130000,
                                        "label": "药品生产/质量管理",
                                        "extra": {
                                            "shortPinyin": "ypsc/zlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126509",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100270000,
                                        "label": "制药工程师",
                                        "extra": {
                                            "shortPinyin": "zygcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126510",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500080000,
                                        "label": "细胞培养技术员",
                                        "extra": {
                                            "shortPinyin": "xbpyjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126511",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500090000,
                                        "label": "动物实验技术员",
                                        "extra": {
                                            "shortPinyin": "dwsyjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126512",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100140000,
                                        "label": "生物信息工程师",
                                        "extra": {
                                            "shortPinyin": "swxxgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126513",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 10000100130000,
                                        "label": "生物工程/生物制药",
                                        "extra": {
                                            "shortPinyin": "swgc/swzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126514",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500030000,
                                        "label": "生物技术员",
                                        "extra": {
                                            "shortPinyin": "swjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126515",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500100000,
                                        "label": "医学检验",
                                        "extra": {
                                            "shortPinyin": "yxjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126516",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 18000500120000,
                                        "label": "医药产品经理",
                                        "extra": {
                                            "shortPinyin": "yycpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126517",
                                        "parentSerial": "126498"
                                    },
                                    {
                                        "value": 3000300190000,
                                        "label": "医药项目经理",
                                        "extra": {
                                            "shortPinyin": "yyxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126518",
                                        "parentSerial": "126498"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126498",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000107,
                                "label": "临床研究/试验",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 10000100110000,
                                        "label": "临床协调",
                                        "extra": {
                                            "shortPinyin": "lcxd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126520",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 10000100120000,
                                        "label": "临床研究",
                                        "extra": {
                                            "shortPinyin": "lcyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126521",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 18000500020000,
                                        "label": "临床监查员",
                                        "extra": {
                                            "shortPinyin": "lcjcy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126522",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 10000100100000,
                                        "label": "临床数据分析",
                                        "extra": {
                                            "shortPinyin": "lcsjfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126523",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 3000300220000,
                                        "label": "临床项目经理",
                                        "extra": {
                                            "shortPinyin": "lcxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126524",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 6001400050000,
                                        "label": "药品注册",
                                        "extra": {
                                            "shortPinyin": "ypzc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126525",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 18000500050000,
                                        "label": "药物警戒",
                                        "extra": {
                                            "shortPinyin": "ywjj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126526",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 18000500130000,
                                        "label": "医学总监",
                                        "extra": {
                                            "shortPinyin": "yxzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126527",
                                        "parentSerial": "126519"
                                    },
                                    {
                                        "value": 18000700010000,
                                        "label": "医学经理",
                                        "extra": {
                                            "shortPinyin": "yxjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126528",
                                        "parentSerial": "126519"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126519",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000108,
                                "label": "医药市场/销售",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200120000,
                                        "label": "医药代表",
                                        "extra": {
                                            "shortPinyin": "yyxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126530",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 16000500340000,
                                        "label": "药品市场推广专员/助理",
                                        "extra": {
                                            "shortPinyin": "ypsctgzy/zl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126531",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 16000500330000,
                                        "label": "药品市场推广经理/主管",
                                        "extra": {
                                            "shortPinyin": "ypsctgjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126532",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 18000500110000,
                                        "label": "医药招商",
                                        "extra": {
                                            "shortPinyin": "yyzs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126533",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 2000100120000,
                                        "label": "医药项目招投标",
                                        "extra": {
                                            "shortPinyin": "yyxmztb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126534",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 16000500320000,
                                        "label": "学术推广",
                                        "extra": {
                                            "shortPinyin": "xstg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126535",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 10000100220000,
                                        "label": "医药信息沟通专员",
                                        "extra": {
                                            "shortPinyin": "yyxxgtzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126536",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 10000100190000,
                                        "label": "医学信息专员",
                                        "extra": {
                                            "shortPinyin": "yxxxzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126537",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 18000500010000,
                                        "label": "医学联络员（MSL）",
                                        "extra": {
                                            "shortPinyin": "yxlly（msl）",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126538",
                                        "parentSerial": "126529"
                                    },
                                    {
                                        "value": 18000500040000,
                                        "label": "医学编辑",
                                        "extra": {
                                            "shortPinyin": "yxbj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126539",
                                        "parentSerial": "126529"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126529",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000109,
                                "label": "医疗器械",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200110000,
                                        "label": "医疗器械销售",
                                        "extra": {
                                            "shortPinyin": "ylqxxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126541",
                                        "parentSerial": "126540"
                                    },
                                    {
                                        "value": 10000200150000,
                                        "label": "医疗器械研发",
                                        "extra": {
                                            "shortPinyin": "ylqxyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126542",
                                        "parentSerial": "126540"
                                    },
                                    {
                                        "value": 15000400140000,
                                        "label": "医疗器械生产/质量管理",
                                        "extra": {
                                            "shortPinyin": "ylqxsc/zlgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126543",
                                        "parentSerial": "126540"
                                    },
                                    {
                                        "value": 13000200110000,
                                        "label": "医疗器械售后工程师",
                                        "extra": {
                                            "shortPinyin": "ylqxshgcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126544",
                                        "parentSerial": "126540"
                                    },
                                    {
                                        "value": 20000200310000,
                                        "label": "医疗器械维修/保养",
                                        "extra": {
                                            "shortPinyin": "ylqxwx/by",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126545",
                                        "parentSerial": "126540"
                                    },
                                    {
                                        "value": 6001400060000,
                                        "label": "医疗器械注册",
                                        "extra": {
                                            "shortPinyin": "ylqxzc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126546",
                                        "parentSerial": "126540"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126540",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000110,
                                "label": "医生/药剂师",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 18000200140000,
                                        "label": "内科医生",
                                        "extra": {
                                            "shortPinyin": "nkys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126548",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200170000,
                                        "label": "外科医生",
                                        "extra": {
                                            "shortPinyin": "wkys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126549",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200330000,
                                        "label": "皮肤科医生",
                                        "extra": {
                                            "shortPinyin": "pfkys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126550",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200300000,
                                        "label": "妇产科医生",
                                        "extra": {
                                            "shortPinyin": "fckys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126551",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200040000,
                                        "label": "儿科医生",
                                        "extra": {
                                            "shortPinyin": "ekys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126552",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200320000,
                                        "label": "幼儿园保健医",
                                        "extra": {
                                            "shortPinyin": "yeybjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126553",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200200000,
                                        "label": "口腔科医生",
                                        "extra": {
                                            "shortPinyin": "kqkys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126554",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200210000,
                                        "label": "眼科医生",
                                        "extra": {
                                            "shortPinyin": "ykys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126555",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200220000,
                                        "label": "验光师",
                                        "extra": {
                                            "shortPinyin": "ygs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126556",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200280000,
                                        "label": "中医",
                                        "extra": {
                                            "shortPinyin": "zy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126557",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200240000,
                                        "label": "医学影像医师",
                                        "extra": {
                                            "shortPinyin": "yxyx/fskys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126558",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200050000,
                                        "label": "放射科医师",
                                        "extra": {
                                            "shortPinyin": "fskys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126559",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200010000,
                                        "label": "B超医生",
                                        "extra": {
                                            "shortPinyin": "bcys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126560",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200180000,
                                        "label": "心电图医生",
                                        "extra": {
                                            "shortPinyin": "xdtys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126561",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200070000,
                                        "label": "核磁共振医生",
                                        "extra": {
                                            "shortPinyin": "hcgzys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126562",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200090000,
                                        "label": "检验科医师",
                                        "extra": {
                                            "shortPinyin": "jykys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126563",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200190000,
                                        "label": "心理医生",
                                        "extra": {
                                            "shortPinyin": "xlys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126564",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200310000,
                                        "label": "医生助理",
                                        "extra": {
                                            "shortPinyin": "yszl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126565",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200120000,
                                        "label": "麻醉医生",
                                        "extra": {
                                            "shortPinyin": "mzys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126566",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200020000,
                                        "label": "ICU医生",
                                        "extra": {
                                            "shortPinyin": "icuys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126567",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200150000,
                                        "label": "全科医生",
                                        "extra": {
                                            "shortPinyin": "qkys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126568",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200290000,
                                        "label": "专科医生",
                                        "extra": {
                                            "shortPinyin": "zkys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126569",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200080000,
                                        "label": "急诊医生",
                                        "extra": {
                                            "shortPinyin": "jzys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126570",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200110000,
                                        "label": "临床医生",
                                        "extra": {
                                            "shortPinyin": "lcys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126571",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200030000,
                                        "label": "病理医师",
                                        "extra": {
                                            "shortPinyin": "blys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126572",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200060000,
                                        "label": "辅诊医生",
                                        "extra": {
                                            "shortPinyin": "fzys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126573",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200100000,
                                        "label": "接诊医生",
                                        "extra": {
                                            "shortPinyin": "jzys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126574",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200270000,
                                        "label": "中药师",
                                        "extra": {
                                            "shortPinyin": "zys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126575",
                                        "parentSerial": "126547"
                                    },
                                    {
                                        "value": 18000200230000,
                                        "label": "药剂师",
                                        "extra": {
                                            "shortPinyin": "yjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126576",
                                        "parentSerial": "126547"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126547",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000111,
                                "label": "护士/医助",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 18000100050000,
                                        "label": "护士",
                                        "extra": {
                                            "shortPinyin": "hs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126578",
                                        "parentSerial": "126577"
                                    },
                                    {
                                        "value": 18000100060000,
                                        "label": "护士长",
                                        "extra": {
                                            "shortPinyin": "hsc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126579",
                                        "parentSerial": "126577"
                                    },
                                    {
                                        "value": 18000100030000,
                                        "label": "护工",
                                        "extra": {
                                            "shortPinyin": "hg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126580",
                                        "parentSerial": "126577"
                                    },
                                    {
                                        "value": 18000100010000,
                                        "label": "导医",
                                        "extra": {
                                            "shortPinyin": "dy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126581",
                                        "parentSerial": "126577"
                                    },
                                    {
                                        "value": 18000100070000,
                                        "label": "核酸检测员",
                                        "extra": {
                                            "shortPinyin": "hsjcy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126582",
                                        "parentSerial": "126577"
                                    },
                                    {
                                        "value": 18000100080000,
                                        "label": "患教",
                                        "extra": {
                                            "shortPinyin": "hj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126583",
                                        "parentSerial": "126577"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126577",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000112,
                                "label": "医务管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 18000300010000,
                                        "label": "医疗管理",
                                        "extra": {
                                            "shortPinyin": "ylgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126585",
                                        "parentSerial": "126584"
                                    },
                                    {
                                        "value": 18000300030000,
                                        "label": "医务管理",
                                        "extra": {
                                            "shortPinyin": "ywgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126586",
                                        "parentSerial": "126584"
                                    },
                                    {
                                        "value": 18000300020000,
                                        "label": "医务干事",
                                        "extra": {
                                            "shortPinyin": "ywgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126587",
                                        "parentSerial": "126584"
                                    },
                                    {
                                        "value": 18000300040000,
                                        "label": "医院院长/副院长",
                                        "extra": {
                                            "shortPinyin": "yyyc/fyc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126588",
                                        "parentSerial": "126584"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126584",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000113,
                                "label": "药店",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 18000600030000,
                                        "label": "药店店员",
                                        "extra": {
                                            "shortPinyin": "yddy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126590",
                                        "parentSerial": "126589"
                                    },
                                    {
                                        "value": 18000600020000,
                                        "label": "药店店长",
                                        "extra": {
                                            "shortPinyin": "yddc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126591",
                                        "parentSerial": "126589"
                                    },
                                    {
                                        "value": 18000600010000,
                                        "label": "执业药师/驻店药师",
                                        "extra": {
                                            "shortPinyin": "zyys/zdys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126592",
                                        "parentSerial": "126589"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126589",
                                "parentSerial": "126497"
                            },
                            {
                                "value": 2000114,
                                "label": "健康整形",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200160000,
                                        "label": "健康顾问",
                                        "extra": {
                                            "shortPinyin": "jkgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126594",
                                        "parentSerial": "126593"
                                    },
                                    {
                                        "value": 6001000110000,
                                        "label": "康复治疗师",
                                        "extra": {
                                            "shortPinyin": "kfzls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126595",
                                        "parentSerial": "126593"
                                    },
                                    {
                                        "value": 6001000040000,
                                        "label": "理疗师",
                                        "extra": {
                                            "shortPinyin": "lls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126596",
                                        "parentSerial": "126593"
                                    },
                                    {
                                        "value": 18000200260000,
                                        "label": "针灸推拿",
                                        "extra": {
                                            "shortPinyin": "zjtn",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126597",
                                        "parentSerial": "126593"
                                    },
                                    {
                                        "value": 18000200250000,
                                        "label": "营养师",
                                        "extra": {
                                            "shortPinyin": "yys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126598",
                                        "parentSerial": "126593"
                                    },
                                    {
                                        "value": 6001000120000,
                                        "label": "产后康复师",
                                        "extra": {
                                            "shortPinyin": "chkfs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126599",
                                        "parentSerial": "126593"
                                    },
                                    {
                                        "value": 18000200130000,
                                        "label": "整形师",
                                        "extra": {
                                            "shortPinyin": "zxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126600",
                                        "parentSerial": "126593"
                                    },
                                    {
                                        "value": 6001000100000,
                                        "label": "医美咨询",
                                        "extra": {
                                            "shortPinyin": "ymzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126601",
                                        "parentSerial": "126593"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126593",
                                "parentSerial": "126497"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126497",
                        "parentSerial": null
                    },
                    {
                        "value": 1000022,
                        "label": "教育培训",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000115,
                                "label": "早教/幼教",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 11000800080000,
                                        "label": "幼教",
                                        "extra": {
                                            "shortPinyin": "yj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126604",
                                        "parentSerial": "126603"
                                    },
                                    {
                                        "value": 11000800020000,
                                        "label": "儿童教育老师",
                                        "extra": {
                                            "shortPinyin": "etjyls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126605",
                                        "parentSerial": "126603"
                                    },
                                    {
                                        "value": 11000800010000,
                                        "label": "保育员",
                                        "extra": {
                                            "shortPinyin": "byy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126606",
                                        "parentSerial": "126603"
                                    },
                                    {
                                        "value": 11000800040000,
                                        "label": "亲子老师",
                                        "extra": {
                                            "shortPinyin": "qzls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126607",
                                        "parentSerial": "126603"
                                    },
                                    {
                                        "value": 11000800030000,
                                        "label": "绘本老师",
                                        "extra": {
                                            "shortPinyin": "hbls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126608",
                                        "parentSerial": "126603"
                                    },
                                    {
                                        "value": 11000300130000,
                                        "label": "园长/副园长",
                                        "extra": {
                                            "shortPinyin": "yc/fyc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126609",
                                        "parentSerial": "126603"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126603",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000116,
                                "label": "中小学教育",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 11000700080000,
                                        "label": "英语教师",
                                        "extra": {
                                            "shortPinyin": "yyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126611",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000090000,
                                        "label": "数学教师",
                                        "extra": {
                                            "shortPinyin": "sxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126612",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000100000,
                                        "label": "语文教师",
                                        "extra": {
                                            "shortPinyin": "ywjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126613",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000110000,
                                        "label": "物理教师",
                                        "extra": {
                                            "shortPinyin": "wljs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126614",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000120000,
                                        "label": "化学教师",
                                        "extra": {
                                            "shortPinyin": "hxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126615",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000130000,
                                        "label": "生物教师",
                                        "extra": {
                                            "shortPinyin": "swjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126616",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11000200080000,
                                        "label": "美术教师",
                                        "extra": {
                                            "shortPinyin": "msjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126617",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11000200100000,
                                        "label": "音乐教师",
                                        "extra": {
                                            "shortPinyin": "yljs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126618",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11000200130000,
                                        "label": "体育教师",
                                        "extra": {
                                            "shortPinyin": "tyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126619",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000060000,
                                        "label": "文科教师",
                                        "extra": {
                                            "shortPinyin": "wkjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126620",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000050000,
                                        "label": "理科教师",
                                        "extra": {
                                            "shortPinyin": "lkjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126621",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000070000,
                                        "label": "小学教师",
                                        "extra": {
                                            "shortPinyin": "xxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126622",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000010000,
                                        "label": "初中教师",
                                        "extra": {
                                            "shortPinyin": "czjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126623",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000020000,
                                        "label": "高中教师",
                                        "extra": {
                                            "shortPinyin": "gzjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126624",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000040000,
                                        "label": "兼职教师",
                                        "extra": {
                                            "shortPinyin": "jzjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126625",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000030000,
                                        "label": "家教",
                                        "extra": {
                                            "shortPinyin": "jj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126626",
                                        "parentSerial": "126610"
                                    },
                                    {
                                        "value": 11001000080000,
                                        "label": "托管老师",
                                        "extra": {
                                            "shortPinyin": "tgls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126627",
                                        "parentSerial": "126610"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126610",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000117,
                                "label": "高等教育",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 10000400020000,
                                        "label": "大学教师",
                                        "extra": {
                                            "shortPinyin": "dxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126629",
                                        "parentSerial": "126628"
                                    },
                                    {
                                        "value": 10000400030000,
                                        "label": "大学教授",
                                        "extra": {
                                            "shortPinyin": "dxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126630",
                                        "parentSerial": "126628"
                                    },
                                    {
                                        "value": 11000400010000,
                                        "label": "考研培训讲师",
                                        "extra": {
                                            "shortPinyin": "kypxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126631",
                                        "parentSerial": "126628"
                                    },
                                    {
                                        "value": 11000400020000,
                                        "label": "考研英语教师",
                                        "extra": {
                                            "shortPinyin": "kyyyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126632",
                                        "parentSerial": "126628"
                                    },
                                    {
                                        "value": 11000900090000,
                                        "label": "专升本讲师",
                                        "extra": {
                                            "shortPinyin": "zsbjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126633",
                                        "parentSerial": "126628"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126628",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000118,
                                "label": "才艺特长培训",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 11000200140000,
                                        "label": "舞蹈老师",
                                        "extra": {
                                            "shortPinyin": "wdls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126635",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200070000,
                                        "label": "乐器教师",
                                        "extra": {
                                            "shortPinyin": "lqjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126636",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200180000,
                                        "label": "钢琴教师",
                                        "extra": {
                                            "shortPinyin": "gqjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126637",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200200000,
                                        "label": "吉他教师",
                                        "extra": {
                                            "shortPinyin": "jtjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126638",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200210000,
                                        "label": "古筝教师",
                                        "extra": {
                                            "shortPinyin": "g筝js",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126639",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200110000,
                                        "label": "书法教师",
                                        "extra": {
                                            "shortPinyin": "sfjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126640",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200190000,
                                        "label": "围棋教师",
                                        "extra": {
                                            "shortPinyin": "wqjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126641",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200010000,
                                        "label": "表演教师",
                                        "extra": {
                                            "shortPinyin": "byjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126642",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200150000,
                                        "label": "游泳教练",
                                        "extra": {
                                            "shortPinyin": "yyjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126643",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200170000,
                                        "label": "乐高教师",
                                        "extra": {
                                            "shortPinyin": "lgjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126644",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200040000,
                                        "label": "机器人教师",
                                        "extra": {
                                            "shortPinyin": "jqrjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126645",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200050000,
                                        "label": "驾校教练",
                                        "extra": {
                                            "shortPinyin": "jxjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126646",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200090000,
                                        "label": "球类运动教练",
                                        "extra": {
                                            "shortPinyin": "qlydjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126647",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 6001600050000,
                                        "label": "篮球/羽毛球教练",
                                        "extra": {
                                            "shortPinyin": "lq/ymqjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126648",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200020000,
                                        "label": "高尔夫教练",
                                        "extra": {
                                            "shortPinyin": "gefjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126649",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200160000,
                                        "label": "瑜伽教练",
                                        "extra": {
                                            "shortPinyin": "瑜伽jl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126650",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 11000200120000,
                                        "label": "跆拳道教练",
                                        "extra": {
                                            "shortPinyin": "跆qdjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126651",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 6001600020000,
                                        "label": "武术教练",
                                        "extra": {
                                            "shortPinyin": "wsjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126652",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 6001600030000,
                                        "label": "轮滑教练",
                                        "extra": {
                                            "shortPinyin": "lhjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126653",
                                        "parentSerial": "126634"
                                    },
                                    {
                                        "value": 6001300150000,
                                        "label": "拓展培训",
                                        "extra": {
                                            "shortPinyin": "tzpx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126654",
                                        "parentSerial": "126634"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126634",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000119,
                                "label": "语言培训",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 11000700050000,
                                        "label": "少儿英语教师",
                                        "extra": {
                                            "shortPinyin": "seyyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126656",
                                        "parentSerial": "126655"
                                    },
                                    {
                                        "value": 11000700040000,
                                        "label": "日语教师",
                                        "extra": {
                                            "shortPinyin": "ryjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126657",
                                        "parentSerial": "126655"
                                    },
                                    {
                                        "value": 11000700030000,
                                        "label": "汉语教师",
                                        "extra": {
                                            "shortPinyin": "hyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126658",
                                        "parentSerial": "126655"
                                    },
                                    {
                                        "value": 11000700090000,
                                        "label": "中文教师",
                                        "extra": {
                                            "shortPinyin": "zwjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126659",
                                        "parentSerial": "126655"
                                    },
                                    {
                                        "value": 11000700020000,
                                        "label": "韩语教师",
                                        "extra": {
                                            "shortPinyin": "hyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126660",
                                        "parentSerial": "126655"
                                    },
                                    {
                                        "value": 11000700010000,
                                        "label": "德语教师",
                                        "extra": {
                                            "shortPinyin": "dyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126661",
                                        "parentSerial": "126655"
                                    },
                                    {
                                        "value": 11000700070000,
                                        "label": "外语教师",
                                        "extra": {
                                            "shortPinyin": "wyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126662",
                                        "parentSerial": "126655"
                                    },
                                    {
                                        "value": 11000700060000,
                                        "label": "外籍教师",
                                        "extra": {
                                            "shortPinyin": "wjjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126663",
                                        "parentSerial": "126655"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126655",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000120,
                                "label": "留学辅导",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 11000500050000,
                                        "label": "留学顾问",
                                        "extra": {
                                            "shortPinyin": "lxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126665",
                                        "parentSerial": "126664"
                                    },
                                    {
                                        "value": 11000500040000,
                                        "label": "雅思英语教师",
                                        "extra": {
                                            "shortPinyin": "ysyyjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126666",
                                        "parentSerial": "126664"
                                    },
                                    {
                                        "value": 11000500030000,
                                        "label": "托福教师",
                                        "extra": {
                                            "shortPinyin": "tfjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126667",
                                        "parentSerial": "126664"
                                    },
                                    {
                                        "value": 11000500010000,
                                        "label": "SAT教师",
                                        "extra": {
                                            "shortPinyin": "satjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126668",
                                        "parentSerial": "126664"
                                    },
                                    {
                                        "value": 11000500020000,
                                        "label": "出国留学考试培训讲师",
                                        "extra": {
                                            "shortPinyin": "cglxkspxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126669",
                                        "parentSerial": "126664"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126664",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000121,
                                "label": "IT培训",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 11000100060000,
                                        "label": "JAVA培训讲师",
                                        "extra": {
                                            "shortPinyin": "javapxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126671",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100030000,
                                        "label": "C++培训讲师",
                                        "extra": {
                                            "shortPinyin": "c++pxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126672",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100010000,
                                        "label": ".NET培训讲师",
                                        "extra": {
                                            "shortPinyin": ".netpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126673",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100070000,
                                        "label": "PHP培训讲师",
                                        "extra": {
                                            "shortPinyin": "phppxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126674",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100120000,
                                        "label": "大数据讲师",
                                        "extra": {
                                            "shortPinyin": "dsjjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126675",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100140000,
                                        "label": "人工智能讲师",
                                        "extra": {
                                            "shortPinyin": "rgznjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126676",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100130000,
                                        "label": "机器学习讲师",
                                        "extra": {
                                            "shortPinyin": "jqxxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126677",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100160000,
                                        "label": "云计算讲师",
                                        "extra": {
                                            "shortPinyin": "yjsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126678",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100100000,
                                        "label": "web前端培训讲师",
                                        "extra": {
                                            "shortPinyin": "webqdpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126679",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100090000,
                                        "label": "Unity3D培训讲师",
                                        "extra": {
                                            "shortPinyin": "unity3dpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126680",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100020000,
                                        "label": "Android培训讲师",
                                        "extra": {
                                            "shortPinyin": "androidpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126681",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100040000,
                                        "label": "iOS培训讲师",
                                        "extra": {
                                            "shortPinyin": "iospxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126682",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100110000,
                                        "label": "编程教师",
                                        "extra": {
                                            "shortPinyin": "bcjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126683",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100050000,
                                        "label": "IT培训",
                                        "extra": {
                                            "shortPinyin": "itpx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126684",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100080000,
                                        "label": "UI设计培训讲师",
                                        "extra": {
                                            "shortPinyin": "uisjpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126685",
                                        "parentSerial": "126670"
                                    },
                                    {
                                        "value": 11000100150000,
                                        "label": "软件测试培训讲师",
                                        "extra": {
                                            "shortPinyin": "rjcspxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126686",
                                        "parentSerial": "126670"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126670",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000122,
                                "label": "职业培训",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 12000100070000,
                                        "label": "保险培训师",
                                        "extra": {
                                            "shortPinyin": "bxpxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126688",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900020000,
                                        "label": "财会培训讲师",
                                        "extra": {
                                            "shortPinyin": "chpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126689",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900010000,
                                        "label": "HR培训讲师",
                                        "extra": {
                                            "shortPinyin": "hrpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126690",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900040000,
                                        "label": "法律培训讲师",
                                        "extra": {
                                            "shortPinyin": "flpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126691",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900100000,
                                        "label": "播音主持教师",
                                        "extra": {
                                            "shortPinyin": "byzcjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126692",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900030000,
                                        "label": "动漫培训讲师",
                                        "extra": {
                                            "shortPinyin": "dmpxjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126693",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900060000,
                                        "label": "美容导师",
                                        "extra": {
                                            "shortPinyin": "mrds",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126694",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900050000,
                                        "label": "美发培训师",
                                        "extra": {
                                            "shortPinyin": "mfpxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126695",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900080000,
                                        "label": "职业技术教师",
                                        "extra": {
                                            "shortPinyin": "zyjsjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126696",
                                        "parentSerial": "126687"
                                    },
                                    {
                                        "value": 11000900070000,
                                        "label": "培训师",
                                        "extra": {
                                            "shortPinyin": "pxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126697",
                                        "parentSerial": "126687"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126687",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000123,
                                "label": "教务管理",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 19000200170000,
                                        "label": "课程顾问",
                                        "extra": {
                                            "shortPinyin": "zs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126699",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300160000,
                                        "label": "班主任/辅导员",
                                        "extra": {
                                            "shortPinyin": "bzr/fdy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126700",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300120000,
                                        "label": "学习管理师",
                                        "extra": {
                                            "shortPinyin": "xxgls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126701",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300110000,
                                        "label": "校长/副校长",
                                        "extra": {
                                            "shortPinyin": "xc/fxc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126702",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300100000,
                                        "label": "校区主任",
                                        "extra": {
                                            "shortPinyin": "xqzr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126703",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 14000700070000,
                                        "label": "助教",
                                        "extra": {
                                            "shortPinyin": "zj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126704",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300040000,
                                        "label": "教务管理",
                                        "extra": {
                                            "shortPinyin": "jwgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126705",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300050000,
                                        "label": "教育产品研发",
                                        "extra": {
                                            "shortPinyin": "jycpyf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126706",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300060000,
                                        "label": "课程设计",
                                        "extra": {
                                            "shortPinyin": "kcsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126707",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300140000,
                                        "label": "课程编辑",
                                        "extra": {
                                            "shortPinyin": "kcbj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126708",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300080000,
                                        "label": "培训督导",
                                        "extra": {
                                            "shortPinyin": "pxdd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126709",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300070000,
                                        "label": "培训策划",
                                        "extra": {
                                            "shortPinyin": "pxch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126710",
                                        "parentSerial": "126698"
                                    },
                                    {
                                        "value": 11000300150000,
                                        "label": "就业老师",
                                        "extra": {
                                            "shortPinyin": "jyls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126711",
                                        "parentSerial": "126698"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126698",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000124,
                                "label": "特殊教育",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 11000600030000,
                                        "label": "特教教师",
                                        "extra": {
                                            "shortPinyin": "tjjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126713",
                                        "parentSerial": "126712"
                                    },
                                    {
                                        "value": 11000600020000,
                                        "label": "康复训练教师",
                                        "extra": {
                                            "shortPinyin": "kfxljs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126714",
                                        "parentSerial": "126712"
                                    },
                                    {
                                        "value": 11000600010000,
                                        "label": "感统教师",
                                        "extra": {
                                            "shortPinyin": "gtjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126715",
                                        "parentSerial": "126712"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126712",
                                "parentSerial": "126602"
                            },
                            {
                                "value": 2000125,
                                "label": "科研/学术研究",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 10000400050000,
                                        "label": "实验室技术员",
                                        "extra": {
                                            "shortPinyin": "sysjsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126717",
                                        "parentSerial": "126716"
                                    },
                                    {
                                        "value": 10000300030000,
                                        "label": "实验室负责人",
                                        "extra": {
                                            "shortPinyin": "sysfzr/gcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126718",
                                        "parentSerial": "126716"
                                    },
                                    {
                                        "value": 10000400040000,
                                        "label": "科研人员",
                                        "extra": {
                                            "shortPinyin": "kyry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126719",
                                        "parentSerial": "126716"
                                    },
                                    {
                                        "value": 10000400060000,
                                        "label": "研究助理",
                                        "extra": {
                                            "shortPinyin": "yjzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126720",
                                        "parentSerial": "126716"
                                    },
                                    {
                                        "value": 10000300020000,
                                        "label": "科研管理人员",
                                        "extra": {
                                            "shortPinyin": "kyglry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126721",
                                        "parentSerial": "126716"
                                    },
                                    {
                                        "value": 10000400010000,
                                        "label": "安全研究",
                                        "extra": {
                                            "shortPinyin": "aqyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126722",
                                        "parentSerial": "126716"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126716",
                                "parentSerial": "126602"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126602",
                        "parentSerial": null
                    },
                    {
                        "value": 1000023,
                        "label": "影视/传媒",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000126,
                                "label": "影视制作",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 4000300040000,
                                        "label": "剪辑师",
                                        "extra": {
                                            "shortPinyin": "jjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126725",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300080000,
                                        "label": "视频编辑",
                                        "extra": {
                                            "shortPinyin": "spbj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126726",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300100000,
                                        "label": "音频编辑",
                                        "extra": {
                                            "shortPinyin": "ypbj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126727",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300070000,
                                        "label": "摄影/摄像",
                                        "extra": {
                                            "shortPinyin": "sy/sx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126728",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300010000,
                                        "label": "编导",
                                        "extra": {
                                            "shortPinyin": "bd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126729",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300030000,
                                        "label": "导演",
                                        "extra": {
                                            "shortPinyin": "dy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126730",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300020000,
                                        "label": "编剧",
                                        "extra": {
                                            "shortPinyin": "bj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126731",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300140000,
                                        "label": "制片人",
                                        "extra": {
                                            "shortPinyin": "zpr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126732",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300060000,
                                        "label": "美术指导",
                                        "extra": {
                                            "shortPinyin": "mszd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126733",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300050000,
                                        "label": "录音/音效",
                                        "extra": {
                                            "shortPinyin": "ly/yx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126734",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300120000,
                                        "label": "影视制作",
                                        "extra": {
                                            "shortPinyin": "yszz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126735",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300110000,
                                        "label": "影视策划",
                                        "extra": {
                                            "shortPinyin": "ysch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126736",
                                        "parentSerial": "126724"
                                    },
                                    {
                                        "value": 4000300150000,
                                        "label": "影视发行",
                                        "extra": {
                                            "shortPinyin": "ysfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126737",
                                        "parentSerial": "126724"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126724",
                                "parentSerial": "126723"
                            },
                            {
                                "value": 2000127,
                                "label": "采编/写作/出版",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 1000100010000,
                                        "label": "编辑",
                                        "extra": {
                                            "shortPinyin": "bj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126739",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000100030000,
                                        "label": "美编",
                                        "extra": {
                                            "shortPinyin": "mb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126740",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000100080000,
                                        "label": "文案编辑",
                                        "extra": {
                                            "shortPinyin": "wabj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126741",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000100060000,
                                        "label": "主编/副主编",
                                        "extra": {
                                            "shortPinyin": "zb/fzb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126742",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 3000200230000,
                                        "label": "总编/副总编",
                                        "extra": {
                                            "shortPinyin": "zb/fzb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126743",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000300010000,
                                        "label": "采编",
                                        "extra": {
                                            "shortPinyin": "cb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126744",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000300020000,
                                        "label": "记者",
                                        "extra": {
                                            "shortPinyin": "jz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126745",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000100070000,
                                        "label": "撰稿人",
                                        "extra": {
                                            "shortPinyin": "zgr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126746",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000100050000,
                                        "label": "校对录入",
                                        "extra": {
                                            "shortPinyin": "xdlr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126747",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000100040000,
                                        "label": "排版设计",
                                        "extra": {
                                            "shortPinyin": "pbsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126748",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 15000100480000,
                                        "label": "印刷排版",
                                        "extra": {
                                            "shortPinyin": "yspb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126749",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 1000100020000,
                                        "label": "出版发行",
                                        "extra": {
                                            "shortPinyin": "cbfx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126750",
                                        "parentSerial": "126738"
                                    },
                                    {
                                        "value": 16000200010000,
                                        "label": "发行管理",
                                        "extra": {
                                            "shortPinyin": "fxgl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126751",
                                        "parentSerial": "126738"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126738",
                                "parentSerial": "126723"
                            },
                            {
                                "value": 2000128,
                                "label": "演艺人员/经纪人",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 4000200050000,
                                        "label": "主播",
                                        "extra": {
                                            "shortPinyin": "zb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126753",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200070000,
                                        "label": "带货主播",
                                        "extra": {
                                            "shortPinyin": "dhzb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126754",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200100000,
                                        "label": "主播助理",
                                        "extra": {
                                            "shortPinyin": "zbzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126755",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200040000,
                                        "label": "演员/模特",
                                        "extra": {
                                            "shortPinyin": "yy/mt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126756",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200090000,
                                        "label": "服装/试衣模特",
                                        "extra": {
                                            "shortPinyin": "fz/symt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126757",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 6001300140000,
                                        "label": "水族馆表演演员",
                                        "extra": {
                                            "shortPinyin": "szgbyyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126758",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200010000,
                                        "label": "经纪人",
                                        "extra": {
                                            "shortPinyin": "jjr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126759",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200060000,
                                        "label": "主持人",
                                        "extra": {
                                            "shortPinyin": "zcr/dj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126760",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200020000,
                                        "label": "配音",
                                        "extra": {
                                            "shortPinyin": "py",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126761",
                                        "parentSerial": "126752"
                                    },
                                    {
                                        "value": 4000200080000,
                                        "label": "DJ",
                                        "extra": {
                                            "shortPinyin": "dj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126762",
                                        "parentSerial": "126752"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126752",
                                "parentSerial": "126723"
                            },
                            {
                                "value": 2000059,
                                "label": "广告",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000100020000,
                                        "label": "广告文案",
                                        "extra": {
                                            "shortPinyin": "ggwa",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126764",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 17000500210000,
                                        "label": "广告设计",
                                        "extra": {
                                            "shortPinyin": "ggsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126765",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 16000500040000,
                                        "label": "广告创意设计",
                                        "extra": {
                                            "shortPinyin": "ggcysj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126766",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 16000100010000,
                                        "label": "广告创意/设计总监",
                                        "extra": {
                                            "shortPinyin": "ggcy/sjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126767",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 16000500030000,
                                        "label": "创意总监",
                                        "extra": {
                                            "shortPinyin": "cyzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126768",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 16000500080000,
                                        "label": "广告制作",
                                        "extra": {
                                            "shortPinyin": "ggzz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126769",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 16000500070000,
                                        "label": "广告协调",
                                        "extra": {
                                            "shortPinyin": "ggxd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126770",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 16000500050000,
                                        "label": "广告美术指导",
                                        "extra": {
                                            "shortPinyin": "ggmszd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126771",
                                        "parentSerial": "126763"
                                    },
                                    {
                                        "value": 16000500060000,
                                        "label": "广告审核",
                                        "extra": {
                                            "shortPinyin": "ggsh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126772",
                                        "parentSerial": "126763"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126763",
                                "parentSerial": "126723"
                            },
                            {
                                "value": 2000060,
                                "label": "公关媒介",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000300130000,
                                        "label": "政府关系",
                                        "extra": {
                                            "shortPinyin": "zfgx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126774",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000500100000,
                                        "label": "品牌公关",
                                        "extra": {
                                            "shortPinyin": "ppgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126775",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300120000,
                                        "label": "商务公关",
                                        "extra": {
                                            "shortPinyin": "swgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126776",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300040000,
                                        "label": "活动公关",
                                        "extra": {
                                            "shortPinyin": "hdgg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126777",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300020000,
                                        "label": "公关专员",
                                        "extra": {
                                            "shortPinyin": "ggzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126778",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300010000,
                                        "label": "公关经理/主管",
                                        "extra": {
                                            "shortPinyin": "ggjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126779",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300030000,
                                        "label": "公关总监",
                                        "extra": {
                                            "shortPinyin": "ggzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126780",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300050000,
                                        "label": "媒介策划",
                                        "extra": {
                                            "shortPinyin": "mjch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126781",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300090000,
                                        "label": "媒介投放",
                                        "extra": {
                                            "shortPinyin": "mjtf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126782",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300080000,
                                        "label": "媒介合作",
                                        "extra": {
                                            "shortPinyin": "mjhz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126783",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300060000,
                                        "label": "媒介顾问",
                                        "extra": {
                                            "shortPinyin": "mjgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126784",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300100000,
                                        "label": "媒介专员",
                                        "extra": {
                                            "shortPinyin": "mjzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126785",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300070000,
                                        "label": "媒介经理",
                                        "extra": {
                                            "shortPinyin": "mjjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126786",
                                        "parentSerial": "126773"
                                    },
                                    {
                                        "value": 16000300110000,
                                        "label": "媒介总监",
                                        "extra": {
                                            "shortPinyin": "mjzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126787",
                                        "parentSerial": "126773"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126773",
                                "parentSerial": "126723"
                            },
                            {
                                "value": 2000129,
                                "label": "舞美设计",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 17000600030000,
                                        "label": "化妆师",
                                        "extra": {
                                            "shortPinyin": "hzs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126789",
                                        "parentSerial": "126788"
                                    },
                                    {
                                        "value": 17000600010000,
                                        "label": "灯光师",
                                        "extra": {
                                            "shortPinyin": "dgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126790",
                                        "parentSerial": "126788"
                                    },
                                    {
                                        "value": 17000600060000,
                                        "label": "造型师",
                                        "extra": {
                                            "shortPinyin": "zxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126791",
                                        "parentSerial": "126788"
                                    },
                                    {
                                        "value": 17000600040000,
                                        "label": "舞美设计",
                                        "extra": {
                                            "shortPinyin": "wmsj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126792",
                                        "parentSerial": "126788"
                                    },
                                    {
                                        "value": 17000600050000,
                                        "label": "舞台艺术指导",
                                        "extra": {
                                            "shortPinyin": "wtyszd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126793",
                                        "parentSerial": "126788"
                                    },
                                    {
                                        "value": 17000600020000,
                                        "label": "服装道具",
                                        "extra": {
                                            "shortPinyin": "fzdj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126794",
                                        "parentSerial": "126788"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126788",
                                "parentSerial": "126723"
                            },
                            {
                                "value": 2000130,
                                "label": "场务/剧务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 4000100020000,
                                        "label": "放映员",
                                        "extra": {
                                            "shortPinyin": "fyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126796",
                                        "parentSerial": "126795"
                                    },
                                    {
                                        "value": 4000100050000,
                                        "label": "剧务",
                                        "extra": {
                                            "shortPinyin": "jw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126797",
                                        "parentSerial": "126795"
                                    },
                                    {
                                        "value": 4000100070000,
                                        "label": "摄影助理",
                                        "extra": {
                                            "shortPinyin": "syzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126798",
                                        "parentSerial": "126795"
                                    },
                                    {
                                        "value": 4000100040000,
                                        "label": "化妆助理",
                                        "extra": {
                                            "shortPinyin": "hzzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126799",
                                        "parentSerial": "126795"
                                    },
                                    {
                                        "value": 4000100010000,
                                        "label": "导演助理",
                                        "extra": {
                                            "shortPinyin": "dyzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126800",
                                        "parentSerial": "126795"
                                    },
                                    {
                                        "value": 4000100080000,
                                        "label": "艺人助理",
                                        "extra": {
                                            "shortPinyin": "yrzl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126801",
                                        "parentSerial": "126795"
                                    },
                                    {
                                        "value": 4000100060000,
                                        "label": "群演/跟组演员",
                                        "extra": {
                                            "shortPinyin": "qy/gzyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126802",
                                        "parentSerial": "126795"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126795",
                                "parentSerial": "126723"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126723",
                        "parentSerial": null
                    },
                    {
                        "value": 1000024,
                        "label": "咨询/翻译/法律",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000131,
                                "label": "咨询服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001400100000,
                                        "label": "咨询顾问",
                                        "extra": {
                                            "shortPinyin": "zxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126805",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400120000,
                                        "label": "咨询经理",
                                        "extra": {
                                            "shortPinyin": "zxzg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126806",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400130000,
                                        "label": "咨询总监",
                                        "extra": {
                                            "shortPinyin": "zxzj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126807",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 3000300210000,
                                        "label": "咨询项目经理",
                                        "extra": {
                                            "shortPinyin": "zxxmjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126808",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400030000,
                                        "label": "猎头顾问",
                                        "extra": {
                                            "shortPinyin": "ltgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126809",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 14000600080000,
                                        "label": "人力资源咨询顾问",
                                        "extra": {
                                            "shortPinyin": "rlzyzxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126810",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400040000,
                                        "label": "企业管理咨询",
                                        "extra": {
                                            "shortPinyin": "qyglzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126811",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400070000,
                                        "label": "战略咨询",
                                        "extra": {
                                            "shortPinyin": "zlzx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126812",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400020000,
                                        "label": "财务咨询顾问",
                                        "extra": {
                                            "shortPinyin": "cwzxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126813",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400010000,
                                        "label": "IT咨询顾问",
                                        "extra": {
                                            "shortPinyin": "itzxgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126814",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400090000,
                                        "label": "专利代理",
                                        "extra": {
                                            "shortPinyin": "zldl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126815",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400080000,
                                        "label": "知识产权代理",
                                        "extra": {
                                            "shortPinyin": "zscq/zlgw/dlr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126816",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400150000,
                                        "label": "政策研究",
                                        "extra": {
                                            "shortPinyin": "zcyj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126817",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400160000,
                                        "label": "心理咨询师",
                                        "extra": {
                                            "shortPinyin": "xlzxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126818",
                                        "parentSerial": "126804"
                                    },
                                    {
                                        "value": 6001400140000,
                                        "label": "婚恋咨询师",
                                        "extra": {
                                            "shortPinyin": "hlzxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126819",
                                        "parentSerial": "126804"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126804",
                                "parentSerial": "126803"
                            },
                            {
                                "value": 2000132,
                                "label": "翻译服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 1000200120000,
                                        "label": "英语翻译",
                                        "extra": {
                                            "shortPinyin": "yyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126821",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200080000,
                                        "label": "日语翻译",
                                        "extra": {
                                            "shortPinyin": "ryfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126822",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200060000,
                                        "label": "韩语/朝鲜语翻译",
                                        "extra": {
                                            "shortPinyin": "hy/cxyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126823",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200030000,
                                        "label": "俄语翻译",
                                        "extra": {
                                            "shortPinyin": "eyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126824",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200040000,
                                        "label": "法语翻译",
                                        "extra": {
                                            "shortPinyin": "fyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126825",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200090000,
                                        "label": "西班牙语翻译",
                                        "extra": {
                                            "shortPinyin": "xbyyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126826",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200020000,
                                        "label": "德语翻译",
                                        "extra": {
                                            "shortPinyin": "dyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126827",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200010000,
                                        "label": "阿拉伯语翻译",
                                        "extra": {
                                            "shortPinyin": "albyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126828",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200070000,
                                        "label": "葡萄牙语翻译",
                                        "extra": {
                                            "shortPinyin": "ptyyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126829",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200110000,
                                        "label": "意大利语翻译",
                                        "extra": {
                                            "shortPinyin": "ydlyfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126830",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200100000,
                                        "label": "小语种翻译",
                                        "extra": {
                                            "shortPinyin": "xyzfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126831",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200050000,
                                        "label": "高级翻译",
                                        "extra": {
                                            "shortPinyin": "gjfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126832",
                                        "parentSerial": "126820"
                                    },
                                    {
                                        "value": 1000200130000,
                                        "label": "驻外翻译",
                                        "extra": {
                                            "shortPinyin": "zwfy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126833",
                                        "parentSerial": "126820"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126820",
                                "parentSerial": "126803"
                            },
                            {
                                "value": 2000133,
                                "label": "法律服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 14000500050000,
                                        "label": "律师助理",
                                        "extra": {
                                            "shortPinyin": "lszl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126835",
                                        "parentSerial": "126834"
                                    },
                                    {
                                        "value": 14000500080000,
                                        "label": "事务所律师",
                                        "extra": {
                                            "shortPinyin": "swsls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126836",
                                        "parentSerial": "126834"
                                    },
                                    {
                                        "value": 14000500090000,
                                        "label": "诉讼律师",
                                        "extra": {
                                            "shortPinyin": "ssls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126837",
                                        "parentSerial": "126834"
                                    },
                                    {
                                        "value": 14000500060000,
                                        "label": "民事律师",
                                        "extra": {
                                            "shortPinyin": "msls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126838",
                                        "parentSerial": "126834"
                                    },
                                    {
                                        "value": 14000500100000,
                                        "label": "知识产权律师",
                                        "extra": {
                                            "shortPinyin": "zscqls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126839",
                                        "parentSerial": "126834"
                                    },
                                    {
                                        "value": 14000500110000,
                                        "label": "专利律师",
                                        "extra": {
                                            "shortPinyin": "zlls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126840",
                                        "parentSerial": "126834"
                                    },
                                    {
                                        "value": 14000500010000,
                                        "label": "法律顾问",
                                        "extra": {
                                            "shortPinyin": "flgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126841",
                                        "parentSerial": "126834"
                                    },
                                    {
                                        "value": 14000500070000,
                                        "label": "企业律师/合规顾问",
                                        "extra": {
                                            "shortPinyin": "qyls/hggw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126842",
                                        "parentSerial": "126834"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126834",
                                "parentSerial": "126803"
                            },
                            {
                                "value": 2000134,
                                "label": "其他服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001300200000,
                                        "label": "权证/过户",
                                        "extra": {
                                            "shortPinyin": "qz/gh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126844",
                                        "parentSerial": "126843"
                                    },
                                    {
                                        "value": 14000500130000,
                                        "label": "司法鉴定",
                                        "extra": {
                                            "shortPinyin": "sfjd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126845",
                                        "parentSerial": "126843"
                                    },
                                    {
                                        "value": 14000500140000,
                                        "label": "法医",
                                        "extra": {
                                            "shortPinyin": "fy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126846",
                                        "parentSerial": "126843"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126843",
                                "parentSerial": "126803"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126803",
                        "parentSerial": null
                    },
                    {
                        "value": 1000025,
                        "label": "生活服务",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000135,
                                "label": "餐饮服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6000400270000,
                                        "label": "餐饮店长",
                                        "extra": {
                                            "shortPinyin": "cydc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126849",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000500010000,
                                        "label": "餐厅大堂经理",
                                        "extra": {
                                            "shortPinyin": "ctdtjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126850",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000500070000,
                                        "label": "领班",
                                        "extra": {
                                            "shortPinyin": "lb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126851",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000500040000,
                                        "label": "服务员",
                                        "extra": {
                                            "shortPinyin": "fwy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126852",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000500090000,
                                        "label": "收银",
                                        "extra": {
                                            "shortPinyin": "sy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126853",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000500060000,
                                        "label": "礼仪/迎宾/接待",
                                        "extra": {
                                            "shortPinyin": "ly/yb/jd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126854",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400040000,
                                        "label": "厨师",
                                        "extra": {
                                            "shortPinyin": "cs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126855",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400070000,
                                        "label": "厨师长",
                                        "extra": {
                                            "shortPinyin": "csc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126856",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400290000,
                                        "label": "行政总厨",
                                        "extra": {
                                            "shortPinyin": "xzzc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126857",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400240000,
                                        "label": "中餐厨师",
                                        "extra": {
                                            "shortPinyin": "zccs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126858",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400200000,
                                        "label": "西餐厨师",
                                        "extra": {
                                            "shortPinyin": "xccs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126859",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400140000,
                                        "label": "日料厨师",
                                        "extra": {
                                            "shortPinyin": "rlcs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126860",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400150000,
                                        "label": "烧烤师傅",
                                        "extra": {
                                            "shortPinyin": "sksf",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126861",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400120000,
                                        "label": "配菜打荷",
                                        "extra": {
                                            "shortPinyin": "pcdh",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126862",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400230000,
                                        "label": "洗碗工",
                                        "extra": {
                                            "shortPinyin": "xwg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126863",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000500030000,
                                        "label": "传菜员",
                                        "extra": {
                                            "shortPinyin": "ccy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126864",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400170000,
                                        "label": "食品加工/处理",
                                        "extra": {
                                            "shortPinyin": "spjg/cl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126865",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400160000,
                                        "label": "生鲜食品加工/处理",
                                        "extra": {
                                            "shortPinyin": "sxspjg/cl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126866",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400030000,
                                        "label": "厨工/帮厨",
                                        "extra": {
                                            "shortPinyin": "cg/bc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126867",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400250000,
                                        "label": "中餐厨工",
                                        "extra": {
                                            "shortPinyin": "zcxt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126868",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400210000,
                                        "label": "西餐厨工",
                                        "extra": {
                                            "shortPinyin": "xcxt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126869",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400130000,
                                        "label": "日餐厨工",
                                        "extra": {
                                            "shortPinyin": "rcxt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126870",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400090000,
                                        "label": "烘焙厨工",
                                        "extra": {
                                            "shortPinyin": "hbxt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126871",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400080000,
                                        "label": "烘焙师",
                                        "extra": {
                                            "shortPinyin": "hbs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126872",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400060000,
                                        "label": "蛋糕师",
                                        "extra": {
                                            "shortPinyin": "dgs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126873",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400180000,
                                        "label": "甜品师",
                                        "extra": {
                                            "shortPinyin": "tps",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126874",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400010000,
                                        "label": "裱花师",
                                        "extra": {
                                            "shortPinyin": "裱hs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126875",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400110000,
                                        "label": "面点师",
                                        "extra": {
                                            "shortPinyin": "mds",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126876",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400220000,
                                        "label": "西点师",
                                        "extra": {
                                            "shortPinyin": "xds",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126877",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400190000,
                                        "label": "调酒师",
                                        "extra": {
                                            "shortPinyin": "djs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126878",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6001300120000,
                                        "label": "品酒师",
                                        "extra": {
                                            "shortPinyin": "pjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126879",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400100000,
                                        "label": "咖啡师",
                                        "extra": {
                                            "shortPinyin": "kfs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126880",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400020000,
                                        "label": "茶艺师",
                                        "extra": {
                                            "shortPinyin": "cys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126881",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 6000400280000,
                                        "label": "调饮师",
                                        "extra": {
                                            "shortPinyin": "dys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126882",
                                        "parentSerial": "126848"
                                    },
                                    {
                                        "value": 2000400050000,
                                        "label": "送餐员",
                                        "extra": {
                                            "shortPinyin": "scy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126883",
                                        "parentSerial": "126848"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126848",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000136,
                                "label": "零售百货",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 5000400020000,
                                        "label": "门店店长",
                                        "extra": {
                                            "shortPinyin": "mddc",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126885",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6001500030000,
                                        "label": "店员/营业员",
                                        "extra": {
                                            "shortPinyin": "dy/yyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126886",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 19000200010000,
                                        "label": "促销员",
                                        "extra": {
                                            "shortPinyin": "cxy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126887",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6001500020000,
                                        "label": "陈列员",
                                        "extra": {
                                            "shortPinyin": "cly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126888",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6001500010000,
                                        "label": "理货员",
                                        "extra": {
                                            "shortPinyin": "lhy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126889",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6000100050000,
                                        "label": "防损员",
                                        "extra": {
                                            "shortPinyin": "fsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126890",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6000500100000,
                                        "label": "售票员",
                                        "extra": {
                                            "shortPinyin": "spy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126891",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6000500090000,
                                        "label": "收银",
                                        "extra": {
                                            "shortPinyin": "sy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126892",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 5000400040000,
                                        "label": "督导巡店",
                                        "extra": {
                                            "shortPinyin": "ddxd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126893",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6001500040000,
                                        "label": "卖场经理",
                                        "extra": {
                                            "shortPinyin": "mcjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126894",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6001500050000,
                                        "label": "服装销售",
                                        "extra": {
                                            "shortPinyin": "fzxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126895",
                                        "parentSerial": "126884"
                                    },
                                    {
                                        "value": 6001500060000,
                                        "label": "珠宝销售",
                                        "extra": {
                                            "shortPinyin": "zbxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126896",
                                        "parentSerial": "126884"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126884",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000137,
                                "label": "安保服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6000100030000,
                                        "label": "保安",
                                        "extra": {
                                            "shortPinyin": "ba",
                                            "level": "3",
                                            "type": "SECURITY_GUARD"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126898",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 6000100040000,
                                        "label": "保安经理",
                                        "extra": {
                                            "shortPinyin": "bajl",
                                            "level": "3",
                                            "type": "SECURITY_GUARD"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126899",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 6000100020000,
                                        "label": "安全员",
                                        "extra": {
                                            "shortPinyin": "aqy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126900",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 6000100010000,
                                        "label": "地铁安检",
                                        "extra": {
                                            "shortPinyin": "dtaj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126901",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 6000100060000,
                                        "label": "辅警",
                                        "extra": {
                                            "shortPinyin": "fj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126902",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 6000100140000,
                                        "label": "协警",
                                        "extra": {
                                            "shortPinyin": "xj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126903",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 6000100080000,
                                        "label": "交通管理员",
                                        "extra": {
                                            "shortPinyin": "jtgly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126904",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 6000100150000,
                                        "label": "押运员",
                                        "extra": {
                                            "shortPinyin": "yyy",
                                            "level": "3",
                                            "type": "SECURITY_GUARD"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126905",
                                        "parentSerial": "126897"
                                    },
                                    {
                                        "value": 7000100030000,
                                        "label": "消防中控员",
                                        "extra": {
                                            "shortPinyin": "xfzky",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126906",
                                        "parentSerial": "126897"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126897",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000138,
                                "label": "酒店服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6000800060000,
                                        "label": "酒店前台",
                                        "extra": {
                                            "shortPinyin": "jdqt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126908",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800050000,
                                        "label": "酒店经理",
                                        "extra": {
                                            "shortPinyin": "jdjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126909",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800040000,
                                        "label": "酒店大堂",
                                        "extra": {
                                            "shortPinyin": "jddt",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126910",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800110000,
                                        "label": "前厅经理",
                                        "extra": {
                                            "shortPinyin": "qtjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126911",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800070000,
                                        "label": "客房服务员",
                                        "extra": {
                                            "shortPinyin": "kffwy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126912",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800080000,
                                        "label": "客房经理",
                                        "extra": {
                                            "shortPinyin": "kfjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126913",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800010000,
                                        "label": "公寓管家",
                                        "extra": {
                                            "shortPinyin": "gygj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126914",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800100000,
                                        "label": "民宿运营",
                                        "extra": {
                                            "shortPinyin": "msyy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126915",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800020000,
                                        "label": "贵宾服务主任",
                                        "extra": {
                                            "shortPinyin": "gbfwzr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126916",
                                        "parentSerial": "126907"
                                    },
                                    {
                                        "value": 6000800030000,
                                        "label": "行李员",
                                        "extra": {
                                            "shortPinyin": "xly",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126917",
                                        "parentSerial": "126907"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126907",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000139,
                                "label": "家政/维修",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6000600010000,
                                        "label": "保洁",
                                        "extra": {
                                            "shortPinyin": "bj",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126919",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200090000,
                                        "label": "保洁经理",
                                        "extra": {
                                            "shortPinyin": "bjjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126920",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6000200010000,
                                        "label": "保姆",
                                        "extra": {
                                            "shortPinyin": "bm",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126921",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6000200030000,
                                        "label": "月嫂",
                                        "extra": {
                                            "shortPinyin": "ys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126922",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001000120000,
                                        "label": "产后康复师",
                                        "extra": {
                                            "shortPinyin": "chkfs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126923",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200100000,
                                        "label": "催乳师",
                                        "extra": {
                                            "shortPinyin": "crs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126924",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6000600020000,
                                        "label": "家政",
                                        "extra": {
                                            "shortPinyin": "jz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126925",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6000600030000,
                                        "label": "钟点工",
                                        "extra": {
                                            "shortPinyin": "zdg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126926",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200070000,
                                        "label": "水电维修",
                                        "extra": {
                                            "shortPinyin": "sdwx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126927",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200020000,
                                        "label": "电子/电器维修/保养",
                                        "extra": {
                                            "shortPinyin": "dz/dqwx/by",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126928",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200030000,
                                        "label": "计算机维修",
                                        "extra": {
                                            "shortPinyin": "jsjwx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126929",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200040000,
                                        "label": "家电维修",
                                        "extra": {
                                            "shortPinyin": "jdwx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126930",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200060000,
                                        "label": "手机维修",
                                        "extra": {
                                            "shortPinyin": "sjwx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126931",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200050000,
                                        "label": "纱窗维修",
                                        "extra": {
                                            "shortPinyin": "scwx",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126932",
                                        "parentSerial": "126918"
                                    },
                                    {
                                        "value": 6001200080000,
                                        "label": "维修经理/主管",
                                        "extra": {
                                            "shortPinyin": "wxjl/zg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126933",
                                        "parentSerial": "126918"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126918",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000140,
                                "label": "旅游服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6000900050000,
                                        "label": "旅游顾问",
                                        "extra": {
                                            "shortPinyin": "lygw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126935",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900040000,
                                        "label": "旅游策划师",
                                        "extra": {
                                            "shortPinyin": "lychs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126936",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900110000,
                                        "label": "旅游产品经理",
                                        "extra": {
                                            "shortPinyin": "lycpjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126937",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900020000,
                                        "label": "导游",
                                        "extra": {
                                            "shortPinyin": "dy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126938",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900100000,
                                        "label": "讲解员",
                                        "extra": {
                                            "shortPinyin": "jjy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126939",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900060000,
                                        "label": "计调",
                                        "extra": {
                                            "shortPinyin": "jd",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126940",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900080000,
                                        "label": "签证专员",
                                        "extra": {
                                            "shortPinyin": "qzzy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126941",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900120000,
                                        "label": "预定票务",
                                        "extra": {
                                            "shortPinyin": "ydpw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126942",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900010000,
                                        "label": "出境操作",
                                        "extra": {
                                            "shortPinyin": "cjcz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126943",
                                        "parentSerial": "126934"
                                    },
                                    {
                                        "value": 6000900030000,
                                        "label": "酒店试睡员",
                                        "extra": {
                                            "shortPinyin": "jdssy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126944",
                                        "parentSerial": "126934"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126934",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000141,
                                "label": "美容/保健",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001000070000,
                                        "label": "美容师",
                                        "extra": {
                                            "shortPinyin": "mrs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126946",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 17000600030000,
                                        "label": "化妆师",
                                        "extra": {
                                            "shortPinyin": "hzs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126947",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000060000,
                                        "label": "美甲师",
                                        "extra": {
                                            "shortPinyin": "mjs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126948",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000030000,
                                        "label": "发型师",
                                        "extra": {
                                            "shortPinyin": "fxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126949",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000130000,
                                        "label": "纹绣师",
                                        "extra": {
                                            "shortPinyin": "wxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126950",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000080000,
                                        "label": "美体师",
                                        "extra": {
                                            "shortPinyin": "mts",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126951",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000020000,
                                        "label": "按摩师",
                                        "extra": {
                                            "shortPinyin": "ams",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126952",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000090000,
                                        "label": "足疗师",
                                        "extra": {
                                            "shortPinyin": "zls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126953",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 19000200160000,
                                        "label": "健康顾问",
                                        "extra": {
                                            "shortPinyin": "jkgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126954",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000140000,
                                        "label": "美容顾问",
                                        "extra": {
                                            "shortPinyin": "mrgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126955",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000160000,
                                        "label": "彩妆顾问",
                                        "extra": {
                                            "shortPinyin": "czgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126956",
                                        "parentSerial": "126945"
                                    },
                                    {
                                        "value": 6001000150000,
                                        "label": "会籍顾问",
                                        "extra": {
                                            "shortPinyin": "hjgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126957",
                                        "parentSerial": "126945"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126945",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000142,
                                "label": "运动健身",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001600010000,
                                        "label": "健身顾问",
                                        "extra": {
                                            "shortPinyin": "jsgw",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126959",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 11000200060000,
                                        "label": "健身教练",
                                        "extra": {
                                            "shortPinyin": "jsjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126960",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 11000200160000,
                                        "label": "瑜伽教练",
                                        "extra": {
                                            "shortPinyin": "瑜伽jl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126961",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 6001600040000,
                                        "label": "美体教练",
                                        "extra": {
                                            "shortPinyin": "mtjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126962",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 11000200140000,
                                        "label": "舞蹈老师",
                                        "extra": {
                                            "shortPinyin": "wdls",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126963",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 11000200120000,
                                        "label": "跆拳道教练",
                                        "extra": {
                                            "shortPinyin": "跆qdjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126964",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 6001600020000,
                                        "label": "武术教练",
                                        "extra": {
                                            "shortPinyin": "wsjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126965",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 11000200150000,
                                        "label": "游泳教练",
                                        "extra": {
                                            "shortPinyin": "yyjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126966",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 11000200090000,
                                        "label": "球类运动教练",
                                        "extra": {
                                            "shortPinyin": "qlydjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126967",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 6001600050000,
                                        "label": "篮球/羽毛球教练",
                                        "extra": {
                                            "shortPinyin": "lq/ymqjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126968",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 11000200020000,
                                        "label": "高尔夫教练",
                                        "extra": {
                                            "shortPinyin": "gefjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126969",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 6001300070000,
                                        "label": "户外/游戏教练",
                                        "extra": {
                                            "shortPinyin": "hw/yxjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126970",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 6001600030000,
                                        "label": "轮滑教练",
                                        "extra": {
                                            "shortPinyin": "lhjl",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126971",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 6001300080000,
                                        "label": "救生员",
                                        "extra": {
                                            "shortPinyin": "jsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126972",
                                        "parentSerial": "126958"
                                    },
                                    {
                                        "value": 6001300130000,
                                        "label": "潜水员",
                                        "extra": {
                                            "shortPinyin": "qsy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126973",
                                        "parentSerial": "126958"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126958",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000143,
                                "label": "宠物服务",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 6001300010000,
                                        "label": "宠物美容",
                                        "extra": {
                                            "shortPinyin": "cwmr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126975",
                                        "parentSerial": "126974"
                                    },
                                    {
                                        "value": 6001300210000,
                                        "label": "宠物医生",
                                        "extra": {
                                            "shortPinyin": "cwys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126976",
                                        "parentSerial": "126974"
                                    },
                                    {
                                        "value": 18000200160000,
                                        "label": "兽医",
                                        "extra": {
                                            "shortPinyin": "sy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126977",
                                        "parentSerial": "126974"
                                    },
                                    {
                                        "value": 6001300160000,
                                        "label": "训犬师",
                                        "extra": {
                                            "shortPinyin": "xqs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126978",
                                        "parentSerial": "126974"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126974",
                                "parentSerial": "126847"
                            },
                            {
                                "value": 2000144,
                                "label": "婚礼/花艺",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 16000100030000,
                                        "label": "婚礼策划",
                                        "extra": {
                                            "shortPinyin": "hlch",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126980",
                                        "parentSerial": "126979"
                                    },
                                    {
                                        "value": 17000100020000,
                                        "label": "花艺师",
                                        "extra": {
                                            "shortPinyin": "hys",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": false,
                                        "serial": "126981",
                                        "parentSerial": "126979"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126979",
                                "parentSerial": "126847"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126847",
                        "parentSerial": null
                    },
                    {
                        "value": 1000026,
                        "label": "管培生/非企业从业者",
                        "extra": {
                            "level": "1"
                        },
                        "children": [
                            {
                                "value": 2000145,
                                "label": "管培生/储备干部",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 8000100030000,
                                        "label": "管培生/培训生",
                                        "extra": {
                                            "shortPinyin": "gps/pxs",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126984",
                                        "parentSerial": "126983"
                                    },
                                    {
                                        "value": 8000100010000,
                                        "label": "储备干部",
                                        "extra": {
                                            "shortPinyin": "cbgb",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126985",
                                        "parentSerial": "126983"
                                    },
                                    {
                                        "value": 8000100020000,
                                        "label": "储备经理人",
                                        "extra": {
                                            "shortPinyin": "cbjlr",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126986",
                                        "parentSerial": "126983"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126983",
                                "parentSerial": "126982"
                            },
                            {
                                "value": 2000146,
                                "label": "政府及非盈利机构从业者",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 8000400040000,
                                        "label": "事业单位人员",
                                        "extra": {
                                            "shortPinyin": "sydwry",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126988",
                                        "parentSerial": "126987"
                                    },
                                    {
                                        "value": 8000400020000,
                                        "label": "公务员",
                                        "extra": {
                                            "shortPinyin": "gwy",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126989",
                                        "parentSerial": "126987"
                                    },
                                    {
                                        "value": 8000400010000,
                                        "label": "公共卫生",
                                        "extra": {
                                            "shortPinyin": "ggws",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126990",
                                        "parentSerial": "126987"
                                    },
                                    {
                                        "value": 8000400030000,
                                        "label": "疾病控制",
                                        "extra": {
                                            "shortPinyin": "jbkz",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126991",
                                        "parentSerial": "126987"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126987",
                                "parentSerial": "126982"
                            },
                            {
                                "value": 2000147,
                                "label": "社工",
                                "extra": {
                                    "level": "2"
                                },
                                "children": [
                                    {
                                        "value": 8000200010000,
                                        "label": "社工",
                                        "extra": {
                                            "shortPinyin": "sg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126993",
                                        "parentSerial": "126992"
                                    },
                                    {
                                        "value": 8000200020000,
                                        "label": "志愿者/义工",
                                        "extra": {
                                            "shortPinyin": "zyz/yg",
                                            "level": "3"
                                        },
                                        "children": [],
                                        "mainPath": true,
                                        "serial": "126994",
                                        "parentSerial": "126992"
                                    }
                                ],
                                "mainPath": null,
                                "serial": "126992",
                                "parentSerial": "126982"
                            }
                        ],
                        "mainPath": null,
                        "serial": "126982",
                        "parentSerial": null
                    }
                ]
            }
        ]
    }
]
