// 录入简历/编辑简历 校验以及公共功能
import { message } from "ant-design-vue";
import dayjs from 'dayjs';
import { getDicList,getFieldSet } from "@/composables/base"
import { onMounted,ref} from 'vue';
import { validater } from "@/utils/validater.js"
import {
  fpResumeDetailAPI,
  resumeDetailAPI,//获取简历详情
} from "@/api/resume"
export default function getMixin(data) {
  const validateMail = async (_rule, value) => {
    if (!value) {
      return Promise.reject('请输入邮箱地址');
    }else{ 
      let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      if(!reg.test(value)){
        return Promise.reject('请输入正确的邮箱地址');
      }
    }
  }
  const validatePhone = async (_rule, value) => {
    if (!value) {
      return Promise.reject('请输入7-20位手机号');
    }else{ 
      let reg = /^([0-9])+-?([0-9])$/
      if(!reg.test(value)  || value.length < 7 || value.length > 20){
        return Promise.reject('请输入正确的手机号');
      }
    }
  }
  const currentYearlySalaryVal = async (_rule, value) => {
    if (validater.isEmpty(value)) {
      return Promise.reject('请输入当前年薪');
    } else if (!validater.towDecimal(value)) {
      return Promise.reject("最多输入2位小数");
    } else if (!validater.min(value, 0) || !validater.max(value, 9999.99)) {
      return Promise.reject("0-9999.99之间的数");
    }else{
      return Promise.resolve()
    }
  }
  var genderList = ref([])
  var maritalList = ref([])
  var salaryList = ref([])
  var schoolList = ref([])
  var eduLevelList = ref([])
  var eduLevelList1 = ref([])
  var fieldSetList = ref({
    birthDay:{},
    city:{},
    companyIndustry:{},
    companyIntroduce:{},
    companyName:{},
    currentCompany:{},
    currentPosition:{},
    currentSalary:{},
    department:{},
    educational:{},
    educationalRemark:{},
    employmentTime:{},
    expectCity:{},
    highestEducational:{},
    leaveReason:{},
    mail:{},
    majorName:{},
    maritalStatus:{},
    name:{},
    nationality:{},
    phone:{},
    placeOfDomicile:{},
    positionName:{},
    professionCategory:{},
    projectIntroduce:{},
    projectName:{},
    projectPerformance:{},
    projectResponsibility:{},
    projectTime:{},
    qq:{},
    reporter:{},
    responsibilityDescription:{},
    resume_base:{},
    resume_edu_experience:{},
    resume_personal_characteristic:{},
    resume_project_experience:{},
    resume_work_experience:{},
    school:{},
    schoolName:{},
    schoolType:{},
    selfAppraisal:{},
    sex:{},
    skillDescription:{},
    studyingTime:{},
    tags:{},
    underlingNumber:{},
    workContent:{},
    workPerformance:{},
    workYears:{},
    wx:{},
    companyNameOfWorkExperience:{},
    positionNameOfWorkExperience:{},
    companyNameOfProjectExperience:{},
    positionNameOfProjectExperience:{},
  })

	const rules ={
    resume:{
      // headPhoto:{ required: true, message: "请上传头像", trigger: "change" },
      name:{ required: true, message: "请输入姓名", trigger: "blur" },
      sex: { required: true, message: "请选择性别", trigger: "change" },
      birthDay: { required: true,  message: "请选择出生年月",  trigger: "change",},
      cityId: { required: true, message: "请选择城市", trigger: "change" },
      workYears: { required: true, message: "请选择工作年份", trigger: "change",},
      maritalStatus: { required: true, message: "请选择婚姻状况", trigger: "change" },
      school: { required: true, message: "请填写毕业院校", trigger: "blur" },
      highestEducational: { required: true, message: "请选择最高学历", trigger: "change" },
      placeOfDomicile: { required: true, message: "请选择户口", trigger: "blur" },
      nationality: { required: true, message: "请填写国籍", trigger: "blur" },
      qq:{ required: true, message: "请输入1-15位的QQ", trigger: "blur"},
      wx:{ required: true, message: "请输入1-20位的微信", trigger: "blur"},
      currentCompany: { required: true, message: "请输入当前公司", trigger: "blur" },
      currentPosition: { required: true, message: "请输入目前职位", trigger: "blur" },
      currentYearlySalaryType:{ required: true, message: "请选择年薪类型", trigger: "change" },
      currentYearlySalary:{ validator:currentYearlySalaryVal, trigger: "blur" },
      tags: { required: true, message: "请填写简历标签", trigger: "blur" },
      expectCityIds: { required: true, message: "请选择期望城市", trigger: "blur" },
    },
    phones:{  validator: validatePhone,trigger: "blur",min: 7, max: 20, },
    mails: {  validator: validateMail, trigger: "blur" },
    // 工作经历校验
    companyName:{ required: true, message: "请输入公司名称", trigger: "blur" },
    companyIndustry:{ required: true, message: "请选择行业", trigger: "blur" },
    companyIndustryId:{ required: true, message: "请选择公司行业", trigger: "blur" },
    positionName:{ required: true, message: "请输入职位名称", trigger: "blur" },
    professionCategoryId:{ required: true, message: "请选择职类", trigger: "blur" },
    department:{ required: true, message: "请输入所在部门", trigger: "blur" },
    underlingNumber:{ required: true, message: "请输入下属人数", trigger: "blur" },
    reporter:{ required: true, message: "请输入汇报对象", trigger: "blur" },
    responsibilityDescription:{ required: true, message: "请输入职位描述", trigger: "blur" },
    companyIntroduce:{ required: true, message: "请输入公司介绍", trigger: "blur" },
    workContent:{ required: true, message: "请输入工作内容", trigger: "blur" },
    workPerformance:{ required: true, message: "请输入工作业绩", trigger: "blur" },
    leaveReason:{ required: true, message: "请输入离职原因", trigger: "blur" },
    startDate:{ required: true, message: "需小于结束时间", trigger: "blur" },
    endDate: { required: true, message: "需大于开始时间", trigger: "blur" },
    // 项目经历校验
    projectName:{ required: true, message: "请输入项目名称", trigger: "blur" },
    // companyName:{ required: true, message: "请输入公司名称", trigger: "blur" },
    position:{ required: true, message: "请输入职位名称", trigger: "blur" },
    projectIntroduce:{ required: true, message: "请输入项目介绍", trigger: "blur" },
    projectResponsibility:{ required: true, message: "请输入项目职责", trigger: "blur" },
    projectPerformance:{ required: true, message: "请输入项目业绩", trigger: "blur" },
    // 教育经历校验
    schoolName:{ required: true, message: "请输入学校名称", trigger: "blur" },
    schoolType:{ required: true, message: "请选择学校类型", trigger: "change" },
    majorName:{ required: true, message: "请输入专业名称", trigger: "blur" },
    educational:{ required: true, message: "请选择学历", trigger: "change" },
    remark:{ required: true, message: "请输入教育备注", trigger: "blur" },
    personalFeature:{
      selfAppraisal:{ required: true, message: "请输入人选自我评价", trigger: "blur" },
      skillDescription:{ required: true, message: "请输入技能描述", trigger: "blur" },
    },
    joinProject:{
      remark:{ required: true, message: "请输入加入原因", trigger: "blur" },
    },
    
  }//校验规则
  //回车添加标签tg
  const addLabel = ()=>{
    if(data.label){
      data.ruleForm.resume.tags.push(data.label)
      data.label =''
    }
  }
  //删除标签
  const detLabel=(index)=>{
    data.ruleForm.resume.tags.splice(index,1)
  }
  //保存标签更改
  const saveLabel=()=>{
    
  }
  //删除经历
  const delExperience=(index,key)=>{
    data.ruleForm[key].splice(index,1)
  }
  //添加经历
  const addExperience=(key)=>{
    // let obj = {...data[key]}
    data.ruleForm[key].push({})
  }
  const disabledBase = (current)=>{//出生年月禁用
    return current && current > dayjs().endOf('day')
  };//开始时间限制
  const addContact=(key)=>{
    data.ruleForm.resume[key].push('')
  };//添加手机号码/邮箱地址
  const delContact=(key,index)=>{
    data.ruleForm.resume[key].splice(index,1)
  };//删除手机号码/邮箱地址
  const disabledStartDate =(current)=>{
    let time = dayjs(current).startOf('month').format('YYYY-MM')
    return data.disableEndDate?time > data.disableEndDate:current > dayjs().endOf('month')
  };//禁用经历开始时间
  const disabledEndDate =(current)=>{
    let time = dayjs(current).startOf('month').format('YYYY-MM')
    return data.disableStartDate?time < data.disableStartDate:current > dayjs().endOf('month')
  };//禁用经历结束时间
  const openChange = (item)=>{
    data.disableEndDate = item.endDate
    data.disableStartDate = item.startDate
  };//当前打开的经历
  const changeEndDate = (item)=>{
    item.endDate = ''
  }
  //所在城市选择
  const searchCityhandle = (arr) => {
    data.ruleForm.resume.cityId = arr.length?arr[0].value:''
    data.ruleForm.resume.city = arr;
  }
  //户口城市选择
  const searchCityhandle1 = (arr) => {
    data.ruleForm.resume.placeOfDomicile = arr.length?arr[0].value:''
    data.ruleForm.resume.placeOfDomicileList = arr;
  }
  //期望城市选择
  const searchCityhandle2 = (arr) => {
    data.ruleForm.resume.expectCityIds = []
    if(arr.length){
      arr.forEach(item=>{
        data.ruleForm.resume.expectCityIds.push(item.value)
      })
    }
    data.ruleForm.resume.expectCities = arr
  }
  //获取职位类别选择的值
  const searchTypehandle = (arr,index) => {
    data.ruleForm.works[index].professionCategoryId = arr.length?arr[0].value:''
    data.ruleForm.works[index].professionCategoryList = arr
  }
  //公司行业传值
  const searchIndusthandle= (arr,index) => {
    data.ruleForm.works[index].companyIndustryId = arr.length?arr[0].value:''
    data.ruleForm.works[index].companyIndustryList = arr
  }

  onMounted(async() => {
    fieldSetList.value = await getFieldSet('RESUME')//简历设置字段
    fieldSetList.value.sex.show?genderList.value = await getDicList ('GENDER_DICTIONARY',fieldSetList.value.sex.notNull):''//性别
    fieldSetList.value.maritalStatus.show?maritalList.value = await getDicList ('MARITAL_DICTIONARY',fieldSetList.value.maritalStatus.notNull):''//婚姻
    fieldSetList.value.currentSalary.show?salaryList.value = await getDicList ('SALARY_TYPE_DICTIONARY',fieldSetList.value.currentSalary.notNull):''//薪资类型
    fieldSetList.value.schoolType.show?schoolList.value = await getDicList ('SCHOOL_TYPE_DICTIONARY',fieldSetList.value.schoolType.notNull):''//学校类型
    fieldSetList.value.highestEducational.show?eduLevelList.value = await getDicList ('EDU_LEVEL',fieldSetList.value.highestEducational.notNull):''//学历类型
    fieldSetList.value.educational.show?eduLevelList1.value = await getDicList ('EDU_LEVEL',fieldSetList.value.educational.notNull):''//教育学历类型
  })

  //公共设置ruleForm数据
  const setRuleForm = (dates) => {
    data.ruleForm = JSON.parse(JSON.stringify(dates))
        validater.isEmpty(data.ruleForm.resume.phones) ||data.ruleForm.resume.phones.length == 0?data.ruleForm.resume.phones= [null]:data.ruleForm.resume.phones
        validater.isEmpty(data.ruleForm.resume.mails) ||data.ruleForm.resume.mails.length == 0?data.ruleForm.resume.mails= [null]:data.ruleForm.resume.mails
        //所在城市处理
        data.ruleForm.resume.cityName = data.ruleForm.resume.cityId = ''
        data.ruleForm.resume.city = []
        if(!validater.isEmpty(dates.resume.city)){
          data.ruleForm.resume.cityId = dates.resume.city.id
          data.ruleForm.resume.cityName = dates.resume.city.name
          let cityObj = {
            label: dates.resume.city.name,
            value: dates.resume.city.id
          }
          data.ruleForm.resume.city.push(cityObj)
        }
        //户口处理
        data.ruleForm.resume.placeOfDomicileName = data.ruleForm.resume.placeOfDomicile = ''
        data.ruleForm.resume.placeOfDomicileList = []
        if(!validater.isEmpty(dates.resume.placeOfDomicile)){
          let placeOfDomicileObj = {
            label: dates.resume.placeOfDomicile.name,
            value: dates.resume.placeOfDomicile.id
          }
          data.ruleForm.resume.placeOfDomicileList.push(placeOfDomicileObj)
          data.ruleForm.resume.placeOfDomicileName = dates.resume.placeOfDomicile.name
          data.ruleForm.resume.placeOfDomicile = dates.resume.placeOfDomicile.id
        }
        //期望城市处理
        let expectCityArr = []
        data.ruleForm.resume.expectCityIds = []
        data.ruleForm.resume.expectCityName = []
        if(data.ruleForm.resume.expectCities && data.ruleForm.resume.expectCities.length){
          data.ruleForm.resume.expectCities.forEach(item=>{
            data.ruleForm.resume.expectCityIds.push(item.id)
            data.ruleForm.resume.expectCityName.push(item.name)
            let obj = {
              label: item.areaName,
              value: item.id
            }
            expectCityArr.push(obj)
          })
        }
        data.ruleForm.resume.expectCities = expectCityArr
        //工作经历-职类/行业处理
        if(data.ruleForm.works.length){
          data.ruleForm.works.map((item)=>{
            item.professionCategoryId = item.professionCategoryName = ''
            item.professionCategoryList = []
            if(!validater.isEmpty(item.professionCategory)){
              item.professionCategoryId = item.professionCategory.id
              item.professionCategoryName = item.professionCategory.name
              item.professionCategoryList.push({
                value: item.professionCategory.id,
                label: item.professionCategory.name
              })
            }
            item.companyIndustryId = item.companyIndustryName = ''
            item.companyIndustryList =[]
            if(!validater.isEmpty(item.companyIndustry)){
              item.companyIndustryId = item.companyIndustry.id
              item.companyIndustryName = item.companyIndustry.name
              item.companyIndustryList.push({
                value: item.companyIndustry.id,
                label: item.companyIndustry.name})
            }
          })
        }
  }
  //获取简历详情
  const getDetail = async(id)=>{
   await resumeDetailAPI(id).then(res=>{
      data.loadingEdit = false
      if(res.success){
        setRuleForm(res.data);
      }else{
        message.warning(res.message)
      }
    })
  }
  const getFpResumeDetail = async(md5Value)=>{
    await fpResumeDetailAPI(md5Value).then(res=>{
      data.loadingEdit = false
      if(res.success){
        setRuleForm(res.data);
      }else{
        message.warning(res.message)
      }
    })
  }
  return {
    rules,//校验规则
    addLabel,//回车添加标签
    detLabel,//删除简历标签
    saveLabel,//保存标签修改
    delExperience,//删除经历
    addExperience,//添加经历
    disabledBase,//出生年月限制
    addContact,//添加手机号码/邮箱地址
    delContact,//删除手机号码/邮箱地址
    disabledStartDate,//用经历开始时间
    disabledEndDate,//禁用经历结束时间
    openChange,//当前打开的经历
    changeEndDate,//清除结束时间
    fieldSetList,
    genderList,
    salaryList,
    schoolList,
    maritalList,
    eduLevelList,
    eduLevelList1,
    searchCityhandle,
    searchCityhandle1,
    searchCityhandle2,
    searchTypehandle,
    searchIndusthandle,
    getDetail,
    getFpResumeDetail,
    setRuleForm,
  }
}