export const CITYMAP = {
    to51Job: {
        '北京': '北京|010000',
        '上海': '上海|020000',
        '广州': '广州|030200',
        '深圳': '深圳|040000',
        '杭州': '杭州|080200',
        '南京': '南京|070200',
        '天津': '天津|050000',
        '成都': '成都|090200',
        '武汉': '武汉|180200',

        '重庆': '重庆|060000',
        '大连': '大连|230300',
        '苏州': '苏州|070300',

        '河北': '河北省|160000',
        '石家庄': '石家庄|160200',
        '唐山': '唐山|160500',
        '秦皇岛': '秦皇岛|160600',
        '邯郸': '邯郸|160700',
        //'雄安新区': '雄安新区|160100',

        '山西': '山西省|210000',
        '太原': '太原|210200',
        '大同': '大同|210400',
        '运城': '运城|210300',
        '临汾': '临汾|210500',

        '内蒙古': '内蒙古|280000',
        '呼和浩特': '呼和浩特|280200',
        '包头': '包头|280400',
        '乌海': '乌海|281000',
        '赤峰': '赤峰|280300',

        '辽宁': '辽宁省|230000',
        '沈阳': '沈阳|230200',
        '鞍山': '鞍山|230400',
        '抚顺': '抚顺|230600',
        '本溪': '本溪|231000',

        '吉林': '吉林省|240000',
        '长春': '长春|240200',
        '吉林市': '吉林|240300',
        '四平': '四平|240600',
        '辽源': '辽源|240400',

        '黑龙江': '黑龙江省|220000',
        '哈尔滨': '哈尔滨|220200',
        '齐齐哈尔': '齐齐哈尔|220600',
        '鸡西': '鸡西|220900',
        '鹤岗': '鹤岗|221000',

        '江苏': '江苏省|070000',
        '无锡': '无锡|070400',
        '徐州': '徐州|071100',
        '常州': '常州|070500',
        '南通': '南通|070900',

        '连云港': '060060',
        '淮安': '060140',
        '盐城': '060150',
        '扬州': '060120',
        '镇江': '060130',
        '泰州': '060160',
        '宿迁': '060170',

        '浙江': '浙江省|080000',
        '宁波': '宁波|080300',
        '温州': '温州|080400',
        '嘉兴': '嘉兴|080700',
        '绍兴': '绍兴|080500',

        '湖州': '070080',
        '金华': '070060',
        '衢州': '070100',
        '舟山': '070120',
        '台州': '070070',
        '丽水': '070110',

        '安徽': '安徽省|150000',
        '合肥': '合肥|150200',
        '芜湖': '芜湖|150300',
        '蚌埠': '蚌埠|150600',
        '淮南': '淮南|151100',

        '福建': '福建省|110000',
        '福州': '福州|110200',
        '厦门': '厦门|110300',
        '莆田': '莆田|110600',
        '泉州': '泉州|110400',

        '江西': '江西省|130000',
        '南昌': '南昌|130200',
        '景德镇': '景德镇|130400',
        '萍乡': '萍乡|130500',
        '九江': '九江|130300',

        '山东': '山东省|120000',
        '济南': '济南|120200',
        '青岛': '青岛|120300',
        '烟台': '烟台|120400',
        '淄博': '淄博|120700',

        '河南': '河南省|170000',
        '郑州': '郑州|170200',
        '开封': '开封|170400',
        '洛阳': '洛阳|170300',
        '平顶山': '平顶山|171000',

        '湖北': '湖北省|180000',
        '黄石': '黄石|180400',
        '十堰': '十堰|180600',
        '宜昌': '宜昌|180300',
        '襄阳': '襄阳|180500',

        '湖南': '湖南省|190000',
        '长沙': '长沙|190200',
        '株洲': '株洲|190300',
        '湘潭': '湘潭|190400',
        '衡阳': '衡阳|190500',

        '广东': '广东省|030000',
        '东莞': '东莞|030800',
        '珠海': '珠海|030500',
        '汕头': '汕头|030400',
        '佛山': '佛山|030600',

        '韶关': '050170',
        '江门': '050150',
        '湛江': '050110',
        '茂名': '050180',
        '肇庆': '050120',
        '惠州': '050060',
        '梅州': '050190',
        '汕尾': '050200',
        '河源': '050210',
        '阳江': '050160',
        '清远': '050070',
        '中山': '050130',
        '潮州': '050030',
        '揭阳': '050220',
        '云浮': '050230',

        '广西': '广西|140000',
        '南宁': '南宁|140200',
        '柳州': '柳州|140400',
        '桂林': '桂林|140300',
        '梧州': '梧州|140700',

        '海南': '海南省|100000',
        '海口': '海口|100200',
        '三亚': '三亚|100300',
        '三沙': '三沙|101500',
        '儋州': '儋州|100800',

        '四川': '四川省|090000',
        '自贡': '自贡|090800',
        '攀枝花': '攀枝花|091000',
        '泸州': '泸州|090500',
        '绵阳': '绵阳|090300',

        '贵州': '贵州省|260000',
        '贵阳': '贵阳|260200',
        '六盘水': '六盘水|260400',
        '遵义': '遵义|260300',
        '安顺': '安顺|260500',

        '云南': '云南省|250000',
        '昆明': '昆明|250200',
        '曲靖': '曲靖|250300',
        '玉溪': '玉溪|250400',
        '保山': '保山|251200',

        '西藏': '西藏|300000',
        '拉萨': '拉萨|300200',
        '日喀则': '日喀则|300300',
        '昌都': '昌都|300600',
        '林芝': '林芝|300400',

        '陕西': '陕西省|200000',
        '西安': '西安|200200',
        '咸阳': '咸阳|200300',
        '宝鸡': '宝鸡|200400',
        '铜川': '铜川|200500',

        '甘肃': '甘肃省|270000',
        '兰州': '兰州|270200',
        '嘉峪关': '嘉峪关|270400',
        '金昌': '金昌|270300',
        '白银': '白银|270800',

        '青海': '青海省|320000',
        '西宁': '西宁|320200',
        '海东': '海东|320300',
        '海北': '海北|320500',
        '黄南': '黄南|320600',

        '宁夏': '宁夏|290000',
        '银川': '银川|290200',
        '石嘴山': '石嘴山|290500',
        '吴忠': '吴忠|290300',
        '固原': '固原|290600',

        '新疆': '新疆|310000',
        '乌鲁木齐': '乌鲁木齐|310200',
        '克拉玛依': '克拉玛依|310300',
        '吐鲁番': '吐鲁番|311400',
        '哈密': '哈密|310700'

    },
    liepin: {
        '北京': '010',
        '上海': '020',
        '广州': '050020',
        '深圳': '050090',
        '天津': '030',
        '成都': '280020',
        '杭州': '070020',
        '南京': '060020',
        '武汉': '170020',

        '重庆': '040',
        '大连': '210040',
        '苏州': '060080',

        '河北': '140',
        '石家庄': '140020',
        '唐山': '140080',
        '秦皇岛': '140070',
        '邯郸': '140050',
        //'雄安新区': '140180',

        '山西': '260',
        '太原': '260020',
        '大同': '260030',
        '运城': '260050',
        '临汾': '260040',

        '内蒙古': '220',
        '呼和浩特': '220020',
        '包头': '220030',
        '乌海': '220060',
        '赤峰': '220040',

        '辽宁': '210',
        '沈阳': '210020',
        '鞍山': '210030',
        '抚顺': '210060',
        '本溪': '210070',

        '吉林': '190',
        '长春': '190020',
        '吉林市': '190030',
        '四平': '190040',
        '辽源': '190050',

        '黑龙江': '160',
        '哈尔滨': '160020',
        '齐齐哈尔': '160060',
        '鸡西': '160070',
        '鹤岗': '160080',

        '江苏': '060',
        '无锡': '060100',
        '徐州': '060110',
        '常州': '060040',
        '南通': '060070',
        '连云港': '060060',
        '淮安': '060140',
        '盐城': '060150',
        '扬州': '060120',
        '镇江': '060130',
        '泰州': '060160',
        '宿迁': '060170',

        '浙江': '070',
        '宁波': '070030',
        '温州': '070040',
        '嘉兴': '070090',
        '绍兴': '070050',
        '湖州': '070080',
        '金华': '070060',
        '衢州': '070100',
        '舟山': '070120',
        '台州': '070070',
        '丽水': '070110',


        '安徽': '080',
        '合肥': '080020',
        '芜湖': '080050',
        '蚌埠': '080040',
        '淮南': '080060',

        '福建': '090',
        '福州': '090020',
        '厦门': '090040',
        '莆田': '090060',
        '泉州': '090030',

        '江西': '200',
        '南昌': '200020',
        '景德镇': '200090',
        '萍乡': '200100',
        '九江': '200030',

        '山东': '250',
        '济南': '250020',
        '青岛': '250070',
        '烟台': '250120',
        '淄博': '250130',

        '河南': '150',
        '郑州': '150020',
        '开封': '150030',
        '洛阳': '150040',
        '平顶山': '150070',

        '湖北': '170',
        '黄石': '170090',
        '十堰': '170030',
        '宜昌': '170050',
        '襄阳': '170040',

        '湖南': '180',
        '长沙': '180020',
        '株洲': '180040',
        '湘潭': '180030',
        '衡阳': '180060',

        '广东': '050',
        '东莞': '050040',
        '珠海': '050140',
        '汕头': '050080',
        '佛山': '050050',

        '韶关': '050170',
        '江门': '050150',
        '湛江': '050110',
        '茂名': '050180',
        '肇庆': '050120',
        '惠州': '050060',
        '梅州': '050190',
        '汕尾': '050200',
        '河源': '050210',
        '阳江': '050160',
        '清远': '050070',
        '中山': '050130',
        '潮州': '050030',
        '揭阳': '050220',
        '云浮': '050230',

        '广西': '110',
        '南宁': '110020',
        '柳州': '110050',
        '桂林': '110040',
        '梧州': '110070',

        '海南': '130',
        '海口': '130020',
        '三亚': '130030',
        '三沙': '130040',
        '儋州': '130090',

        '四川': '280',
        '自贡': '280080',
        '攀枝花': '280090',
        '泸州': '280040',
        '绵阳': '280050',

        '贵州': '120',
        '贵阳': '120020',
        '六盘水': '120040',
        '遵义': '120030',
        '安顺': '120050',

        '云南': '310',
        '昆明': '310020',
        '曲靖': '310060',
        '玉溪': '310050',
        '保山': '310070',

        '西藏': '290',
        '拉萨': '290020',
        '日喀则': '290030',
        '昌都': '290060',
        '林芝': '290040',

        '陕西': '270',
        '西安': '270020',
        '铜川': '270050',
        '宝鸡': '270030',
        '咸阳': '270040',

        '甘肃': '100',
        '兰州': '100020',
        '嘉峪关': '100030',
        '金昌': '100050',
        '白银': '100060',

        '青海': '240',
        '西宁': '240020',
        '海东': '240030',
        '海北': '240050',
        '黄南': '240060',

        '宁夏': '230',
        '银川': '230020',
        '石嘴山': '230030',
        '吴忠': '230040',
        '固原': '230050',

        '新疆': '300',
        '乌鲁木齐': '300020',
        '克拉玛依': '300040',
        '吐鲁番': '300140',
        '哈密': '300070'
    },
    //智联
    zhilian: {
        '北京': '530',
        '上海': '538',
        '天津': '531',
        '成都': '801',
        '武汉': '736',
        '杭州': '653',
        '南京': '635',
        '广州': '763',
        '深圳': '765',
        '重庆': '551',
        '大连': '600',
        '苏州': '639',

        '河北': '532',
        '石家庄': '565',
        '唐山': '566',
        '秦皇岛': '567',
        '邯郸': '568',
        '邢台': '569',
        '保定': '570',
        '张家口': '571',
        '承德': '572',
        '沧州': '573',
        '廊坊': '574',
        '衡水': '575',

        '山西': '533',
        '太原': '576',
        '大同': '577',
        '阳泉': '578',
        '长治': '579',
        '晋城': '580',
        '朔州': '581',
        '晋中': '582',
        '运城': '583',
        '忻州': '584',
        '临汾': '585',
        '吕梁': '586',


        '内蒙古': '534',
        '呼和浩特': '587',
        '包头': '588',
        '乌海': '589',
        '赤峰': '590',

        '辽宁': '535',
        '沈阳': '599',
        '鞍山': '601',
        '抚顺': '602',
        '本溪': '603',

        '吉林': '536',
        '长春': '613',
        '吉林市': '614',
        '四平': '615',
        '辽源': '616',

        '黑龙江': '537',
        '哈尔滨': '622',
        '齐齐哈尔': '623',
        '鸡西': '624',
        '鹤岗': '625',

        '江苏': '539',
        '无锡': '636',
        '徐州': '637',
        '常州': '638',
        '南通': '641',
        '连云港': '642',
        '淮安': '643',
        '盐城': '644',
        '扬州': '645',
        '镇江': '646',
        '泰州': '647',
        '宿迁': '648',

        '浙江': '540',
        '宁波': '654',
        '温州': '655',
        '嘉兴': '656',
        '绍兴': '658',
        '湖州': '657',
        '金华': '659',
        '衢州': '660',
        '舟山': '661',
        '台州': '662',
        '丽水': '663',

        '安徽': '541',
        '合肥': '664',
        '芜湖': '665',
        '蚌埠': '666',
        '淮南': '667',

        '福建': '542',
        '福州': '681',
        '厦门': '682',
        '莆田': '683',
        '泉州': '685',

        '江西': '543',
        '南昌': '691',
        '景德镇': '692',
        '萍乡': '693',
        '九江': '694',

        '山东': '544',
        '济南': '702',
        '青岛': '703',
        '烟台': '707',
        '淄博': '704',

        '河南': '545',
        '郑州': '719',
        '开封': '720',
        '洛阳': '721',
        '平顶山': '722',

        '湖北': '546',
        '黄石': '737',
        '十堰': '738',
        '宜昌': '739',
        '襄阳': '740',

        '湖南': '547',
        '长沙': '749',
        '株洲': '750',
        '湘潭': '751',
        '衡阳': '752',

        '广东': '548',
        '东莞': '779',
        '珠海': '766',
        '汕头': '767',
        '佛山': '768',
        '韶关': '764',
        '江门': '769',
        '湛江': '770',
        '茂名': '771',
        '肇庆': '772',
        '惠州': '773',
        '梅州': '774',
        '汕尾': '775',
        '河源': '776',
        '阳江': '777',
        '清远': '778',
        '中山': '780',
        '潮州': '781',
        '揭阳': '782',
        '云浮': '783',

        '广西': '549',
        '南宁': '785',
        '柳州': '786',
        '桂林': '787',
        '梧州': '788',

        '海南': '550',
        '海口': '799',
        '三亚': '800',
        '三沙': '10303',
        '儋州': '10183',

        '四川': '552',
        '自贡': '802',
        '攀枝花': '803',
        '泸州': '804',
        '绵阳': '806',

        '贵州': '553',
        '贵阳': '822',
        '六盘水': '823',
        '遵义': '824',
        '安顺': '825',

        '云南': '554',
        '昆明': '831',
        '曲靖': '832',
        '玉溪': '833',
        '保山': '834',

        '西藏': '555',
        '拉萨': '847',
        '日喀则': '850',
        '昌都': '848',
        '林芝': '853',

        '陕西': '556',
        '西安': '854',
        '铜川': '855',
        '宝鸡': '856',
        '咸阳': '857',

        '甘肃': '557',
        '兰州': '864',
        '嘉峪关': '865',
        '金昌': '866',
        '白银': '867',

        '青海': '558',
        '西宁': '878',
        '海东': '879',
        '海北': '880',
        '黄南': '881',

        '宁夏': '559',
        '银川': '886',
        '石嘴山': '887',
        '吴忠': '888',
        '固原': '889',

        '新疆': '560',
        '乌鲁木齐': '890',
        '克拉玛依': '891',
        '吐鲁番': '892',
        '哈密': '893'
    },
    jingying: {
        '北京': '010000',
        '上海': '020000',
        '天津': '050000',
        '成都': '090200',
        '武汉': '180200',
        '杭州': '080200',
        '南京': '070200',
        '广州': '030200',
        '深圳': '040000',
        '重庆': '060000',
        '大连': '230300',
        '苏州': '070300',

        '河北': '160000',
        '石家庄': '160200',
        '唐山': '160500',
        '秦皇岛': '160600',
        '邯郸': '160700',
        //'雄安新区': '140180',

        '山西': '210000',
        '太原': '210200',
        '大同': '210400',
        '运城': '210300',
        '临汾': '210500',

        '内蒙古': '280000',
        '呼和浩特': '280200',
        '包头': '280400',
        '乌海': '281000',
        '赤峰': '280300',

        '辽宁': '230000',
        '沈阳': '230200',
        '鞍山': '230400',
        '抚顺': '230600',
        '本溪': '231000',

        '吉林': '240000',
        '长春': '240200',
        '吉林市': '240300',
        '四平': '240600',
        '辽源': '240400',

        '黑龙江': '220000',
        '哈尔滨': '220200',
        '齐齐哈尔': '220600',
        '鸡西': '220900',
        '鹤岗': '221000',

        '江苏': '070000',
        '无锡': '070400',
        '徐州': '071100',
        '常州': '070500',
        '南通': '070900',
        '连云港': '071200',
        '淮安': '071900',
        '盐城': '071300',
        '扬州': '070800',
        '镇江': '071000',
        '泰州': '071800',
        '宿迁': '072000',

        '浙江': '080000',
        '宁波': '080300',
        '温州': '080400',
        '嘉兴': '080700',
        '绍兴': '080500',
        '湖州': '080900',
        '金华': '080600',
        '衢州': '081200',
        '舟山': '081100',
        '台州': '080800',
        '丽水': '081000',

        '安徽': '150000',
        '合肥': '150200',
        '芜湖': '150300',
        '蚌埠': '150600',
        '淮南': '151100',

        '福建': '110000',
        '福州': '110200',
        '厦门': '110300',
        '莆田': '110600',
        '泉州': '110400',

        '江西': '130000',
        '南昌': '130200',
        '景德镇': '130400',
        '萍乡': '130300',
        '九江': '130500',

        '山东': '120000',
        '济南': '120200',
        '青岛': '120300',
        '烟台': '120400',
        '淄博': '120700',

        '河南': '170000',
        '郑州': '170200',
        '开封': '170400',
        '洛阳': '170300',
        '平顶山': '171000',

        '湖北': '180000',
        '黄石': '180400',
        '十堰': '180600',
        '宜昌': '180300',
        '襄阳': '180500',

        '湖南': '190000',
        '长沙': '190200',
        '株洲': '190300',
        '湘潭': '190400',
        '衡阳': '190500',

        '广东': '030000',
        '东莞': '030800',
        '珠海': '030500',
        '汕头': '030400',
        '佛山': '030600',
        '韶关': '031400',
        '江门': '031500',
        '湛江': '031700',
        '茂名': '032300',
        '肇庆': '031800',
        '惠州': '030300',
        '梅州': '032600',
        '汕尾': '032400',
        '河源': '032100',
        '阳江': '032800',
        '清远': '031900',
        '中山': '030700',
        '潮州': '032000',
        '揭阳': '032200',
        '云浮': '032900',

        '广西': '140000',
        '南宁': '140200',
        '柳州': '140400',
        '桂林': '140300',
        '梧州': '140700',

        '海南': '100000',
        '海口': '100200',
        '三亚': '100300',
        '三沙': '101500',
        '儋州': '100800',

        '四川': '090000',
        '自贡': '090800',
        '攀枝花': '091000',
        '泸州': '090500',
        '绵阳': '090300',

        '贵州': '260000',
        '贵阳': '260200',
        '六盘水': '260400',
        '遵义': '260300',
        '安顺': '260500',

        '云南': '250000',
        '昆明': '250200',
        '曲靖': '250300',
        '玉溪': '250400',
        '保山': '251200',

        '西藏': '300000',
        '拉萨': '300200',
        '日喀则': '300300',
        '昌都': '300600',
        '林芝': '300400',

        '陕西': '200000',
        '西安': '200200',
        '铜川': '200500',
        '宝鸡': '200400',
        '咸阳': '200300',

        '甘肃': '270000',
        '兰州': '270200',
        '嘉峪关': '270400',
        '金昌': '270300',
        '白银': '270800',

        '青海': '320000',
        '西宁': '320200',
        '海东': '320300',
        '海北': '320500',
        '黄南': '320600',

        '宁夏': '290000',
        '银川': '290200',
        '石嘴山': '290500',
        '吴忠': '290300',
        '固原': '290600',

        '新疆': '310000',
        '乌鲁木齐': '310200',
        '克拉玛依': '310300',
        '吐鲁番': '311400',
        '哈密': '310700'
    },
    linkedin: {
        '北京': '103873152',
        '上海': '102772228',
        '天津': '106736355',
        '成都': '107650347',
        '武汉': '100417443',
        '杭州': '101821877',
        '南京': '107837013',
        '广州': '102046459',
        '深圳': '106750182',

        '重庆': '100743148',
        '大连': '108065819',
        '苏州': '104536353',

        '河北': '100813837',
        '石家庄': '106389157',
        '唐山': '107948009',
        '秦皇岛': '102448480',
        '邯郸': '107742516',
        //'雄安新区': '140180',

        '山西': '100732438',
        '太原': '108054941',
        '大同': '106869825',
        '运城': '107430108',
        '临汾': '107658412',

        '内蒙古': '104964642',
        '呼和浩特': '107683521',
        '包头': '106846923',
        '乌海': '107617869',
        '赤峰': '107277738',

        '辽宁': '105341694',
        '沈阳': '106620453',
        '鞍山': '107413884',
        '抚顺': '100326767',
        '本溪': '102010150',

        '吉林': '101093960',
        '长春': '106179129',
        '吉林市': '107219188',
        '四平': '107515828',
        //'辽源': '240400',

        '黑龙江': '105116373',
        '哈尔滨': '102983080',
        '齐齐哈尔': '107642683',
        '鸡西': '105760661',
        '鹤岗': '107859488',

        '江苏': '105927538',
        '无锡': '101459515',
        '徐州': '107974709',
        '常州': '108033485',
        '南通': '101688265',
        '连云港': '107460959',
        '淮安': '107854379',
        '盐城': '100250867',
        '扬州': '104357119',
        '镇江': '107878588',
        '泰州': '106766815',
        '宿迁': '107704221',

        '浙江': '106834892',
        '宁波': '107930245',
        '温州': '107517622',
        '嘉兴': '108061792',
        '绍兴': '107606206',
        '湖州': '107928237',
        '金华': '102320092',
        '衢州': '108033734',
        '舟山': '107459172',
        '台州': '108047752',
        '丽水': '100340468',

        '安徽': '100639213',
        '合肥': '105892709',
        '芜湖': '108057359',
        '蚌埠': '108053681',
        '淮南': '106968600',

        '福建': '104551092',
        '福州': '102344967',
        '厦门': '106160220',
        '莆田': '102539530',
        '泉州': '107399795',

        '江西': '103922324',
        '南昌': '108074506',
        '景德镇': '100200366',
        '萍乡': '107524827',
        '九江': '101010960',

        '山东': '106062776',
        '济南': '105977216',
        '青岛': '104332104',
        '烟台': '107921895',
        '淄博': '107833439',

        '河南': '100958623',
        '郑州': '104524145',
        '开封': '105349416',
        '洛阳': '101998969',
        '平顶山': '107689058',

        '湖北': '104803765',
        '黄石': '106302567',
        //'十堰': '180600',
        '宜昌': '107874058',
        '襄阳': '100697334',

        '湖南': '103248980',
        '长沙': '100793149',
        '株洲': '105076685',
        '湘潭': '108078419',
        '衡阳': '107328908',

        '广东': '100642566',
        '东莞': '101594724',
        '珠海': '101149451',
        '汕头': '107520485',
        '佛山': '107387656',
        '韶关': '105947458',
        '江门': '107769379',
        '湛江': '108011772',
        '茂名': '108068215',
        '肇庆': '104255598',
        '惠州': '107225077',
        '梅州': '100885144',
        '汕尾': '104131500',
        '河源': '105459808',
        '阳江': '107918030',
        //'清远': '031900',
        '中山': '108024577',
        '潮州': '106518487',
        '揭阳': '107469475',
        '云浮': '107885110',

        '广西': '101540925',
        '南宁': '100313726',
        '柳州': '107494130',
        '桂林': '107296369',
        '梧州': '107373506',

        '海南': '103804124',
        '海口': '102686896',
        '三亚': '107456313',
        //'三沙': '101500',
        '儋州': '107698496',

        '四川': '106583973',
        '自贡': '105249283',
        '攀枝花': '107937383',
        '泸州': '107545943',
        '绵阳': '107674071',

        '贵州': '104953346',
        '贵阳': '105902937',
        '六盘水': '106320713',
        '遵义': '104779104',
        '安顺': '100287060',

        '云南': '106536795',
        '昆明': '107993187',
        '曲靖': '108049471',
        '玉溪': '107373707',
        '保山': '106223507',

        '西藏': '107180804',
        '拉萨': '107836461',
        //'日喀则': '300300',
        //'昌都': '300600',
        //'林芝': '300400',

        '陕西': '102675063',
        '西安': '100275458',
        '铜川': '107809253',
        '宝鸡': '108082677',
        '咸阳': '106704885',

        '甘肃': '101202619',
        '兰州': '102491935',
        //'嘉峪关': '270400',
        //'金昌': '270300',
        //'白银': '270800',

        '青海': '106269816',
        '西宁': '107980030',
        '海东': '108076320',
        //'海北': '320500',
        //'黄南': '320600',

        '宁夏': '105325976',
        '银川': '107455529',
        //'石嘴山': '290500',
        '吴忠': '102810724',
        //'固原': '290600',

        '新疆': '103021424',
        '乌鲁木齐': '107197772',
        '克拉玛依': '106887717'
        //'吐鲁番': '311400',
        //'哈密': '310700'
    },
    zhipin: {
        '北京': '101010100',
        '上海': '101020100',
        '广州': '101280100',
        '深圳': '101280600',
        '天津': '101030100',
        '成都': '101270100',
        '武汉': '101200100',
        '杭州': '101210100',
        '南京': '101190100',
        '苏州': '101190400',
        '重庆': '101040100',
        '大连': '101070200',

        '河北': '101090000',
        '石家庄': '101090100',
        '唐山': '101090500',
        '秦皇岛': '101091100',
        '邯郸': '101091000',
        //'雄安新区': '140180',

        '山西': '101100000',
        '太原': '101100100',
        '大同': '101100200',
        '运城': '101100800',
        '临汾': '101100700',

        '内蒙古': '101080000',
        '呼和浩特': '101080100',
        '包头': '101080200',
        '乌海': '101080300',
        '赤峰': '101080500',

        '辽宁': '101070000',
        '沈阳': '101070100',
        '鞍山': '101070300',
        '抚顺': '101070400',
        '本溪': '101070500',

        '吉林': '101060000',
        '长春': '101060100',
        '吉林市': '101060200',
        '四平': '101060300',
        '辽源': '101060600',

        '黑龙江': '101050000',
        '哈尔滨': '101050100',
        '齐齐哈尔': '101050200',
        '鸡西': '101051000',
        '鹤岗': '101051100',

        '江苏': '101190000',
        '无锡': '101190200',
        '徐州': '101190800',
        '常州': '101191100',
        '南通': '101190500',
        '连云港': '101191000',
        '淮安': '101190900',
        '盐城': '101190700',
        '扬州': '101190600',
        '镇江': '101190300',
        '泰州': '101191200',
        '宿迁': '101191300',

        '浙江': '101210000',
        '宁波': '101210400',
        '温州': '101210700',
        '嘉兴': '101210300',
        '绍兴': '101210500',
        '湖州': '101210200',
        '金华': '101210900',
        '衢州': '101211000',
        '舟山': '101211100',
        '台州': '101210600',
        '丽水': '101210800',

        '安徽': '101220000',
        '合肥': '101220100',
        '芜湖': '101220300',
        '蚌埠': '101220200',
        '淮南': '101220400',

        '福建': '101230000',
        '福州': '101230100',
        '厦门': '101230200',
        '莆田': '101230400',
        '泉州': '101230500',

        '江西': '101240000',
        '南昌': '101240100',
        '景德镇': '101240800',
        '萍乡': '101240900',
        '九江': '101240200',

        '山东': '101120000',
        '济南': '101120100',
        '青岛': '101120200',
        '烟台': '101120500',
        '淄博': '101120300',

        '河南': '101180000',
        '郑州': '101180100',
        '开封': '101180800',
        '洛阳': '101180900',
        '平顶山': '101180500',

        '湖北': '101200000',
        '黄石': '101200600',
        '十堰': '101201000',
        '宜昌': '101200900',
        '襄阳': '101200200',

        '湖南': '101250000',
        '长沙': '101250100',
        '株洲': '101250300',
        '湘潭': '101250200',
        '衡阳': '101250400',

        '广东': '101280000',
        '东莞': '101281600',
        '珠海': '101280700',
        '汕头': '101280500',
        '佛山': '101280800',
        '韶关': '101280200',
        '江门': '101281100',
        '湛江': '101281000',
        '茂名': '101282000',
        '肇庆': '101280900',
        '惠州': '101280300',
        '梅州': '101280400',
        '汕尾': '101282100',
        '河源': '101281200',
        '阳江': '101281800',
        '清远': '101281300',
        '中山': '101281700',
        '潮州': '101281500',
        '揭阳': '101281900',
        '云浮': '101281400',

        '广西': '101300000',
        '南宁': '101300100',
        '柳州': '101300300',
        '桂林': '101300500',
        '梧州': '101300600',

        '海南': '101310000',
        '海口': '101310100',
        '三亚': '101310200',
        '三沙': '101310300',
        '儋州': '101310400',

        '四川': '101270000',
        '自贡': '101270300',
        '攀枝花': '101270200',
        '泸州': '101271000',
        '绵阳': '101270400',

        '贵州': '101260000',
        '贵阳': '101260100',
        '六盘水': '101260600',
        '遵义': '101260200',
        '安顺': '101260300',

        '云南': '101290000',
        '昆明': '101290100',
        '曲靖': '101290200',
        '玉溪': '101290400',
        '保山': '101290300',

        '西藏': '101140000',
        '拉萨': '101140100',
        '日喀则': '101140200',
        '昌都': '101140300',
        '林芝': '101140400',

        '陕西': '101110000',
        '西安': '101110100',
        '铜川': '101111000',
        '宝鸡': '101110900',
        '咸阳': '101110200',

        '甘肃': '101160000',
        '兰州': '101160100',
        '嘉峪关': '101161200',
        '金昌': '101160600',
        '白银': '101161000',

        '青海': '101150000',
        '西宁': '101150100',
        '海东': '101150200',
        '海北': '101150300',
        '黄南': '101150400',

        '宁夏': '101170000',
        '银川': '101170100',
        '石嘴山': '101170200',
        '吴忠': '101170300',
        '固原': '101170400',

        '新疆': '101130000',
        '乌鲁木齐': '101130100',
        '克拉玛依': '101130200',
        '吐鲁番': '101130800',
        '哈密': '101130900'
    },
    zhipinExtension: {
        '101010100': '北京',
        '101020100': '上海',
        '101280100': '广州',
        '101280600': '深圳',
        '101030100': '天津',
        '101270100': '成都',
        '101200100': '武汉',
        '101210100': '杭州',
        '101190100': '南京',
        '101190400': '苏州',
        '101040100': '重庆',
        '101070200': '大连',

        '101090000': '河北',
        '101090100': '石家庄',
        '101090500': '唐山',
        '101091100': '秦皇岛',
        '101091000': '邯郸',

        '101100000': '山西',
        '101100100': '太原',
        '101100200': '大同',
        '101100800': '运城',
        '101100700': '临汾',

        '101080000': '内蒙古',
        '101080100': '呼和浩特',
        '101080200': '包头',
        '101080300': '乌海',
        '101080500': '赤峰',

        '101070000': '辽宁',
        '101070100': '沈阳',
        '101070300': '鞍山',
        '101070400': '抚顺',
        '101070500': '本溪',

        '101060000': '吉林',
        '101060100': '长春',
        '101060200': '吉林市',
        '101060300': '四平',
        '101060600': '辽源',

        '101050000': '黑龙江',
        '101050100': '哈尔滨',
        '101050200': '齐齐哈尔',
        '101051000': '鸡西',
        '101051100': '鹤岗',

        '101190000': '江苏',
        '101190200': '无锡',
        '101190800': '徐州',
        '101191100': '常州',
        '101190500': '南通',
        '101191000': '连云港',
        '101190900': '淮安',
        '101190700': '盐城',
        '101190600': '扬州',
        '101190300': '镇江',
        '101191200': '泰州',
        '101191300': '宿迁',

        '101210000': '浙江',
        '101210400': '宁波',
        '101210700': '温州',
        '101210300': '嘉兴',
        '101210500': '绍兴',
        '101210200': '湖州',
        '101210900': '金华',
        '101211000': '衢州',
        '101211100': '舟山',
        '101210600': '台州',
        '101210800': '丽水',

        '101220000': '安徽',
        '101220100': '合肥',
        '101220300': '芜湖',
        '101220200': '蚌埠',
        '101220400': '淮南',

        '101230000': '福建',
        '101230100': '福州',
        '101230200': '厦门',
        '101230400': '莆田',
        '101230500': '泉州',

        '101240000': '江西',
        '101240100': '南昌',
        '101240800': '景德镇',
        '101240900': '萍乡',
        '101240200': '九江',

        '101120000': '山东',
        '101120100': '济南',
        '101120200': '青岛',
        '101120500': '烟台',
        '101120300': '淄博',

        '101180000': '河南',
        '101180100': '郑州',
        '101180800': '开封',
        '101180900': '洛阳',
        '101180500': '平顶山',

        '101200000': '湖北',
        '101200600': '黄石',
        '101201000': '十堰',
        '101200900': '宜昌',
        '101200200': '襄阳',

        '101250000': '湖南',
        '101250100': '长沙',
        '101250300': '株洲',
        '101250200': '湘潭',
        '101250400': '衡阳',

        '101280000': '广东',
        '101281600': '东莞',
        '101280700': '珠海',
        '101280500': '汕头',
        '101280800': '佛山',
        '101280200': '韶关',
        '101281100': '江门',
        '101281000': '湛江',
        '101282000': '茂名',
        '101280900': '肇庆',
        '101280300': '惠州',
        '101280400': '梅州',
        '101282100': '汕尾',
        '101281200': '河源',
        '101281800': '阳江',
        '101281300': '清远',
        '101281700': '中山',
        '101281500': '潮州',
        '101281900': '揭阳',
        '101281400': '云浮',

        '101300000': '广西',
        '101300100': '南宁',
        '101300300': '柳州',
        '101300500': '桂林',
        '101300600': '梧州',

        '101310000': '海南',
        '101310100': '海口',
        '101310200': '三亚',
        '101310300': '三沙',
        '101310400': '儋州',

        '101270000': '四川',
        '101270300': '自贡',
        '101270200': '攀枝花',
        '101271000': '泸州',
        '101270400': '绵阳',

        '101260000': '贵州',
        '101260100': '贵阳',
        '101260600': '六盘水',
        '101260200': '遵义',
        '101260300': '安顺',

        '101290000': '云南',
        '101290100': '昆明',
        '101290200': '曲靖',
        '101290400': '玉溪',
        '101290300': '保山',

        '101140000': '西藏',
        '101140100': '拉萨',
        '101140200': '日喀则',
        '101140300': '昌都',
        '101140400': '林芝',

        '101110000': '陕西',
        '101110100': '西安',
        '101111000': '铜川',
        '101110900': '宝鸡',
        '101110200': '咸阳',

        '101160000': '甘肃',
        '101160100': '兰州',
        '101161200': '嘉峪关',
        '101160600': '金昌',
        '101161000': '白银',

        '101150000': '青海',
        '101150100': '西宁',
        '101150200': '海东',
        '101150300': '海北',
        '101150400': '黄南',

        '101170000': '宁夏',
        '101170100': '银川',
        '101170200': '石嘴山',
        '101170300': '吴忠',
        '101170400': '固原',

        '101130000': '新疆',
        '101130100': '乌鲁木齐',
        '101130200': '克拉玛依',
        '101130800': '吐鲁番',
        '101130900': '哈密',
    },
    inner: {
        '北京': '11',
        '上海': '31',
        '广州': '4401',
        '深圳': '4402',
        '天津': '12',
        '成都': '5101',
        '武汉': '4201',
        '杭州': '3301',
        '南京': '3202',
        '苏州': '3201',
        '重庆': '20',
        '大连': '600',

        '河北': '13',
        '石家庄': '1301',
        '唐山': '1306',
        '秦皇岛': '1307',
        '邯郸': '1303',
        //'雄安新区': '140180',

        '山西': '14',
        '太原': '1401',
        '大同': '1402',
        '运城': '1404',
        '临汾': '1407',

        '内蒙古': '534',
        '呼和浩特': '587',
        '包头': '588',
        '乌海': '589',
        '赤峰': '590',

        '辽宁': '21',
        '沈阳': '2101',
        '鞍山': '2103',
        '抚顺': '2104',
        //'本溪': '2103',

        '吉林': '22',
        '长春': '2201',
        '吉林市': '2203',
        '四平': '2202',
        '辽源': '2204',

        '黑龙江': '23',
        '哈尔滨': '2301',
        '齐齐哈尔': '2302',
        '鸡西': '2307',
        //'鹤岗': '2301',

        '江苏': '32',
        '无锡': '3203',
        '徐州': '3211',
        '常州': '3204',
        '南通': '3214',
        '连云港': '3210',
        '淮安': '3207',
        '盐城': '3206',
        '扬州': '3209',
        '镇江': '3212',
        '泰州': '3213',
        '宿迁': '3208',

        '浙江': '33',
        '宁波': '3302',
        '温州': '3303',
        '嘉兴': '3306',
        '绍兴': '3307',
        '湖州': '3310',
        '金华': '3304',
        '衢州': '3305',
        //'舟山': '3311',
        '台州': '3308',
        '丽水': '3309',

        '安徽': '34',
        '合肥': '3401',
        '芜湖': '3402',
        '蚌埠': '3403',
        '淮南': '3404',

        '福建': '35',
        '福州': '3501',
        '厦门': '3502',
        '莆田': '3508',
        '泉州': '3503',

        '江西': '36',
        '南昌': '3601',
        '景德镇': '3604',
        '萍乡': '3605',
        '九江': '3603',

        '山东': '37',
        '济南': '3701',
        '青岛': '3702',
        '烟台': '3704',
        '淄博': '3703',

        '河南': '41',
        '郑州': '4101',
        '开封': '4109',
        '洛阳': '4103',
        '平顶山': '4107',

        '湖北': '42',
        '黄石': '4211',
        '十堰': '4202',
        '宜昌': '4207',
        //'襄阳': '4201',

        '湖南': '43',
        '长沙': '4301',
        '株洲': '4302',
        '湘潭': '4303',
        '衡阳': '4304',

        '广东': '44',
        '东莞': '4403',
        '珠海': '4405',
        '汕头': '4406',
        '佛山': '4404',
        '韶关': '4407',
        '江门': '4410',
        '湛江': '4408',
        '茂名': '4411',
        '肇庆': '4409',
        '惠州': '4412',
        '梅州': '4413',
        '汕尾': '4414',
        '河源': '4415',
        '阳江': '4416',
        '清远': '4417',
        '中山': '4418',
        '潮州': '4419',
        '揭阳': '4420',
        '云浮': '4421',

        '广西': '45',
        '南宁': '4501',
        '柳州': '4502',
        '桂林': '4503',
        '梧州': '4504',

        '海南': '16',
        '海口': '1610',
        '三亚': '1611',
        //'三沙': '10303',
        //'儋州': '10183',

        '四川': '51',
        '自贡': '5102',
        '攀枝花': '5103',
        '泸州': '5104',
        '绵阳': '5107',

        '贵州': '52',
        '贵阳': '5201',
        '六盘水': '5202',
        '遵义': '5203',
        '安顺': '5206',

        '云南': '53',
        '昆明': '5301',
        '曲靖': '5303',
        '玉溪': '5304',
        '保山': '5305',

        '西藏': '54',
        //'拉萨': '847',
        //'日喀则': '850',
        //'昌都': '848',
        //'林芝': '853',

        '陕西': '61',
        '西安': '6101',
        '铜川': '6105',
        '宝鸡': '6102',
        '咸阳': '6103',

        '甘肃': '62',
        '兰州': '6201',
        '嘉峪关': '6202',
        '金昌': '6203',
        //'白银': '6207',

        '青海': '63',
        //'西宁': '878',
        //'海东': '879',
        //'海北': '880',
        //'黄南': '881',

        '宁夏': '64',
        '银川': '6401',
        '石嘴山': '6402',
        '吴忠': '6403',
        '固原': '6404',

        '新疆': '65'
        //'乌鲁木齐': '890',
        //'克拉玛依': '891',
        //'吐鲁番': '892',
        //'哈密': '893'
    }


};
// 地区字典
export const DD_JOBAREA = [
    {
        c: '热门城市',
        sub: [
            {
                id: '010000',
                c: '北京',
                l: '北京',
                sub: [
                    {id: '010000',c: '北京',l: '北京'},
                    {id: '010100',c: '东城区',l: '北京-东城区'},
                    {id: '010200',c: '西城区',l: '北京-西城区'},
                    {id: '010500',c: '朝阳区',l: '北京-朝阳区'},
                    {id: '010600',c: '丰台区',l: '北京-丰台区'},
                    {id: '010700',c: '石景山区',l: '北京-石景山区'},
                    {id: '010800',c: '海淀区',l: '北京-海淀区'},
                    {id: '010900',c: '门头沟区',l: '北京-门头沟区'},
                    {id: '011000',c: '房山区',l: '北京-房山区'},
                    {id: '011100',c: '通州区',l: '北京-通州区'},
                    {id: '011200',c: '顺义区',l: '北京-顺义区'},
                    {id: '011300',c: '昌平区',l: '北京-昌平区'},
                    {id: '011400',c: '大兴区',l: '北京-大兴区'},
                    {id: '011500',c: '怀柔区',l: '北京-怀柔区'},
                    {id: '011600',c: '平谷区',l: '北京-平谷区'},
                    {id: '011700',c: '密云区',l: '北京-密云区'},
                    {id: '011800',c: '延庆区',l: '北京-延庆区'}
                ]
            },
            {
                id: '020000',
                c: '上海',
                l: '上海',
                sub: [
                    {id: '020000',c: '上海',l: '上海'},
                    {id: '020100',c: '黄浦区',l: '上海-黄浦区'},
                    {id: '020300',c: '徐汇区',l: '上海-徐汇区'},
                    {id: '020400',c: '长宁区',l: '上海-长宁区'},
                    {id: '020500',c: '静安区',l: '上海-静安区'},
                    {id: '020600',c: '普陀区',l: '上海-普陀区'},
                    {id: '020800',c: '虹口区',l: '上海-虹口区'},
                    {id: '020900',c: '杨浦区',l: '上海-杨浦区'},
                    {id: '021000',c: '浦东新区',l: '上海-浦东新区'},
                    {id: '021100',c: '闵行区',l: '上海-闵行区'},
                    {id: '021200',c: '宝山区',l: '上海-宝山区'},
                    {id: '021300',c: '嘉定区',l: '上海-嘉定区'},
                    {id: '021400',c: '金山区',l: '上海-金山区'},
                    {id: '021500',c: '松江区',l: '上海-松江区'},
                    {id: '021600',c: '青浦区',l: '上海-青浦区'},
                    {id: '021800',c: '奉贤区',l: '上海-奉贤区'},
                    {id: '021900',c: '崇明区',l: '上海-崇明区'}
                ]
            },
            {
                id: '030200',
                c: '广州',
                l: '广州',
                sub: [
                    {id: '030200',c: '广州',l: '广州'},
                    {id: '030201',c: '越秀区',l: '广州-越秀区'},
                    {id: '030202',c: '荔湾区',l: '广州-荔湾区'},
                    {id: '030203',c: '海珠区',l: '广州-海珠区'},
                    {id: '030204',c: '天河区',l: '广州-天河区'},
                    {id: '030205',c: '白云区',l: '广州-白云区'},
                    {id: '030206',c: '黄埔区',l: '广州-黄埔区'},
                    {id: '030207',c: '番禺区',l: '广州-番禺区'},
                    {id: '030208',c: '花都区',l: '广州-花都区'},
                    {id: '030209',c: '南沙区',l: '广州-南沙区'},
                    {id: '030211',c: '增城区',l: '广州-增城区'},
                    {id: '030212',c: '从化区',l: '广州-从化区'}
                ]
            },
            {
                id: '040000',
                c: '深圳',
                l: '深圳',
                sub: [
                    {id: '040000',c: '深圳',l: '深圳'},
                    {id: '040100',c: '福田区',l: '深圳-福田区'},
                    {id: '040200',c: '罗湖区',l: '深圳-罗湖区'},
                    {id: '040300',c: '南山区',l: '深圳-南山区'},
                    {id: '040400',c: '盐田区',l: '深圳-盐田区'},
                    {id: '040500',c: '宝安区',l: '深圳-宝安区'},
                    {id: '040600',c: '龙岗区',l: '深圳-龙岗区'},
                    {id: '040700',c: '光明区',l: '深圳-光明区'},
                    {id: '040800',c: '坪山区',l: '深圳-坪山区'},
                    {id: '040900',c: '大鹏新区',l: '深圳-大鹏新区'},
                    {id: '041000',c: '龙华区',l: '深圳-龙华区'}
                ]
            },
            {
                id: '050000',
                c: '天津',
                l: '天津',
                sub: [
                    {id: '050000',c: '天津',l: '天津'},
                    {id: '050100',c: '和平区',l: '天津-和平区'},
                    {id: '050200',c: '河东区',l: '天津-河东区'},
                    {id: '050300',c: '河西区',l: '天津-河西区'},
                    {id: '050400',c: '南开区',l: '天津-南开区'},
                    {id: '050500',c: '河北区',l: '天津-河北区'},
                    {id: '050600',c: '红桥区',l: '天津-红桥区'},
                    {id: '050700',c: '东丽区',l: '天津-东丽区'},
                    {id: '050800',c: '西青区',l: '天津-西青区'},
                    {id: '050900',c: '津南区',l: '天津-津南区'},
                    {id: '051000',c: '北辰区',l: '天津-北辰区'},
                    {id: '051100',c: '武清区',l: '天津-武清区'},
                    {id: '051200',c: '宝坻区',l: '天津-宝坻区'},
                    {id: '051300',c: '滨海新区',l: '天津-滨海新区'},
                    {id: '051400',c: '宁河区',l: '天津-宁河区'},
                    {id: '051500',c: '静海区',l: '天津-静海区'},
                    {id: '051600',c: '蓟州区',l: '天津-蓟州区'}
                ]
            },
            {
                id: '060000',
                c: '重庆',
                l: '重庆',
                sub: [
                    {id: '060000',c: '重庆',l: '重庆'},
                    {id: '060100',c: '渝中区',l: '重庆-渝中区'},
                    {id: '060200',c: '大渡口区',l: '重庆-大渡口区'},
                    {id: '060300',c: '江北区',l: '重庆-江北区'},
                    {id: '060400',c: '沙坪坝区',l: '重庆-沙坪坝区'},
                    {id: '060600',c: '合川区',l: '重庆-合川区'},
                    {id: '060700',c: '渝北区',l: '重庆-渝北区'},
                    {id: '060800',c: '永川区',l: '重庆-永川区'},
                    {id: '060900',c: '巴南区',l: '重庆-巴南区'},
                    {id: '061000',c: '南川区',l: '重庆-南川区'},
                    {id: '061100',c: '九龙坡区',l: '重庆-九龙坡区'},
                    {id: '061200',c: '万州区',l: '重庆-万州区'},
                    {id: '061300',c: '涪陵区',l: '重庆-涪陵区'},
                    {id: '061400',c: '黔江区',l: '重庆-黔江区'},
                    {id: '061500',c: '南岸区',l: '重庆-南岸区'},
                    {id: '061600',c: '北碚区',l: '重庆-北碚区'},
                    {id: '061700',c: '长寿区',l: '重庆-长寿区'},
                    {id: '061900',c: '江津区',l: '重庆-江津区'},
                    {id: '062000',c: '綦江区',l: '重庆-綦江区'},
                    {id: '062100',c: '潼南区',l: '重庆-潼南区'},
                    {id: '062200',c: '铜梁区',l: '重庆-铜梁区'},
                    {id: '062300',c: '大足区',l: '重庆-大足区'},
                    {id: '062400',c: '荣昌区',l: '重庆-荣昌区'},
                    {id: '062500',c: '璧山区',l: '重庆-璧山区'},
                    {id: '062600',c: '垫江县',l: '重庆-垫江县'},
                    {id: '062700',c: '丰都县',l: '重庆-丰都县'},
                    {id: '062800',c: '忠县',l: '重庆-忠县'},
                    {id: '062900',c: '石柱县',l: '重庆-石柱县'},
                    {id: '063000',c: '城口县',l: '重庆-城口县'},
                    {id: '063100',c: '彭水县',l: '重庆-彭水县'},
                    {id: '063200',c: '梁平区',l: '重庆-梁平区'},
                    {id: '063300',c: '酉阳县',l: '重庆-酉阳县'},
                    {id: '063400',c: '开州区',l: '重庆-开州区'},
                    {id: '063500',c: '秀山县',l: '重庆-秀山县'},
                    {id: '063600',c: '巫溪县',l: '重庆-巫溪县'},
                    {id: '063700',c: '巫山县',l: '重庆-巫山县'},
                    {id: '063800',c: '奉节县',l: '重庆-奉节县'},
                    {id: '063900',c: '武隆区',l: '重庆-武隆区'},
                    {id: '064000',c: '云阳县',l: '重庆-云阳县'}
                ]
            },
            {
                id: '070200',
                c: '南京',
                l: '南京',
                sub: [
                    {id: '070200',c: '南京',l: '南京'},
                    {id: '070201',c: '玄武区',l: '南京-玄武区'},
                    {id: '070203',c: '秦淮区',l: '南京-秦淮区'},
                    {id: '070204',c: '建邺区',l: '南京-建邺区'},
                    {id: '070205',c: '鼓楼区',l: '南京-鼓楼区'},
                    {id: '070207',c: '浦口区',l: '南京-浦口区'},
                    {id: '070208',c: '六合区',l: '南京-六合区'},
                    {id: '070209',c: '栖霞区',l: '南京-栖霞区'},
                    {id: '070210',c: '雨花台区',l: '南京-雨花台区'},
                    {id: '070211',c: '江宁区',l: '南京-江宁区'},
                    {id: '070212',c: '溧水区',l: '南京-溧水区'},
                    {id: '070213',c: '高淳区',l: '南京-高淳区'},
                    {id: '070214',c: '江北新区',l: '南京-江北新区'},
                    {id: '070215',c: '经济技术开发区',l: '南京-经济技术开发区'}
                ]
            },
            {
                id: '180200',
                c: '武汉',
                l: '武汉',
                sub: [
                    {id: '180200',c: '武汉',l: '武汉'},
                    {id: '180201',c: '江岸区',l: '武汉-江岸区'},
                    {id: '180202',c: '江汉区',l: '武汉-江汉区'},
                    {id: '180203',c: '硚口区',l: '武汉-硚口区'},
                    {id: '180204',c: '汉阳区',l: '武汉-汉阳区'},
                    {id: '180205',c: '武昌区',l: '武汉-武昌区'},
                    {id: '180206',c: '青山区',l: '武汉-青山区'},
                    {id: '180207',c: '洪山区',l: '武汉-洪山区'},
                    {id: '180208',c: '东西湖区',l: '武汉-东西湖区'},
                    {id: '180209',c: '汉南区',l: '武汉-汉南区'},
                    {id: '180210',c: '蔡甸区',l: '武汉-蔡甸区'},
                    {id: '180211',c: '江夏区',l: '武汉-江夏区'},
                    {id: '180212',c: '黄陂区',l: '武汉-黄陂区'},
                    {id: '180213',c: '新洲区',l: '武汉-新洲区'},
                    {id: '180214',c: '武汉经济开发区',l: '武汉-武汉经济开发区'},
                    {id: '180215',c: '东湖新技术产业开发区',l: '武汉-东湖新技术产业开发区'}
                ]
            },
            {
                id: '200200',
                c: '西安',
                l: '西安',
                sub: [
                    {id: '200200',c: '西安',l: '西安'},
                    {id: '200201',c: '莲湖区',l: '西安-莲湖区'},
                    {id: '200202',c: '新城区',l: '西安-新城区'},
                    {id: '200203',c: '碑林区',l: '西安-碑林区'},
                    {id: '200204',c: '灞桥区',l: '西安-灞桥区'},
                    {id: '200205',c: '未央区',l: '西安-未央区'},
                    {id: '200206',c: '雁塔区',l: '西安-雁塔区'},
                    {id: '200207',c: '阎良区',l: '西安-阎良区'},
                    {id: '200208',c: '临潼区',l: '西安-临潼区'},
                    {id: '200209',c: '长安区',l: '西安-长安区'},
                    {id: '200210',c: '蓝田县',l: '西安-蓝田县'},
                    {id: '200211',c: '周至县',l: '西安-周至县'},
                    {id: '200212',c: '鄠邑区',l: '西安-鄠邑区'},
                    {id: '200213',c: '高陵区',l: '西安-高陵区'},
                    {id: '200214',c: '高新技术产业开发区',l: '西安-高新技术产业开发区'},
                    {id: '200215',c: '经济技术开发区',l: '西安-经济技术开发区'},
                    {id: '200216',c: '曲江新区',l: '西安-曲江新区'},
                    {id: '200217',c: '浐灞生态区',l: '西安-浐灞生态区'},
                    {id: '200218',c: '国家民用航天产业基地',l: '西安-国家民用航天产业基地'},
                    {id: '200219',c: '西咸新区',l: '西安-西咸新区'},
                    {id: '200220',c: '西安阎良航空基地',l: '西安-西安阎良航空基地'},
                    {id: '200221',c: '西安国际港务区',l: '西安-西安国际港务区'}
                ]
            },
            {
                id: '080200',
                c: '杭州',
                l: '杭州',
                sub: [
                    {id: '080200',c: '杭州',l: '杭州'},
                    {id: '080201',c: '拱墅区',l: '杭州-拱墅区'},
                    {id: '080202',c: '上城区',l: '杭州-上城区'},
                    {id: '080205',c: '西湖区',l: '杭州-西湖区'},
                    {id: '080206',c: '滨江区',l: '杭州-滨江区'},
                    {id: '080207',c: '余杭区',l: '杭州-余杭区'},
                    {id: '080208',c: '萧山区',l: '杭州-萧山区'},
                    {id: '080209',c: '临安区',l: '杭州-临安区'},
                    {id: '080210',c: '富阳区',l: '杭州-富阳区'},
                    {id: '080211',c: '建德市',l: '杭州-建德市'},
                    {id: '080212',c: '桐庐县',l: '杭州-桐庐县'},
                    {id: '080213',c: '淳安县',l: '杭州-淳安县'},
                    {id: '080214',c: '临平区',l: '杭州-临平区'},
                    {id: '080215',c: '钱塘区',l: '杭州-钱塘区'}
                ]
            },
            {
                id: '090200',
                c: '成都',
                l: '成都',
                sub: [
                    {id: '090200',c: '成都',l: '成都'},
                    {id: '090201',c: '青羊区',l: '成都-青羊区'},
                    {id: '090202',c: '锦江区',l: '成都-锦江区'},
                    {id: '090203',c: '金牛区',l: '成都-金牛区'},
                    {id: '090204',c: '武侯区',l: '成都-武侯区'},
                    {id: '090205',c: '成华区',l: '成都-成华区'},
                    {id: '090206',c: '龙泉驿区',l: '成都-龙泉驿区'},
                    {id: '090207',c: '青白江区',l: '成都-青白江区'},
                    {id: '090208',c: '新都区',l: '成都-新都区'},
                    {id: '090209',c: '温江区',l: '成都-温江区'},
                    {id: '090210',c: '都江堰市',l: '成都-都江堰市'},
                    {id: '090211',c: '彭州市',l: '成都-彭州市'},
                    {id: '090212',c: '邛崃市',l: '成都-邛崃市'},
                    {id: '090213',c: '崇州市',l: '成都-崇州市'},
                    {id: '090214',c: '金堂县',l: '成都-金堂县'},
                    {id: '090215',c: '双流区',l: '成都-双流区'},
                    {id: '090216',c: '郫都区',l: '成都-郫都区'},
                    {id: '090217',c: '大邑县',l: '成都-大邑县'},
                    {id: '090218',c: '蒲江县',l: '成都-蒲江县'},
                    {id: '090219',c: '新津县',l: '成都-新津县'},
                    {id: '090220',c: '高新区',l: '成都-高新区'},
                    {id: '090221',c: '简阳市',l: '成都-简阳市'},
                    {id: '090222',c: '天府新区',l: '成都-天府新区'},
                    {id: '090223',c: '经济技术开发区',l: '成都-经济技术开发区'}
                ]
            },
            {
                id: '070300',
                c: '苏州',
                l: '苏州',
                sub: [
                    {id: '070300',c: '苏州',l: '苏州'},
                    {id: '070301',c: '姑苏区',l: '苏州-姑苏区'},
                    {id: '070303',c: '吴中区',l: '苏州-吴中区'},
                    {id: '070304',c: '相城区',l: '苏州-相城区'},
                    {id: '070305',c: '吴江区',l: '苏州-吴江区'},
                    {id: '070306',c: '苏州工业园区',l: '苏州-苏州工业园区'},
                    {id: '070307',c: '高新区',l: '苏州-高新区'},
                    {id: '070308',c: '虎丘区',l: '苏州-虎丘区'}
                ]
            },
            {
                id: '080300',
                c: '宁波',
                l: '宁波',
                sub: [
                    {id: '080300',c: '宁波',l: '宁波'},
                    {id: '080301',c: '海曙区',l: '宁波-海曙区'},
                    {id: '080303',c: '江北区',l: '宁波-江北区'},
                    {id: '080304',c: '北仑区',l: '宁波-北仑区'},
                    {id: '080305',c: '镇海区',l: '宁波-镇海区'},
                    {id: '080306',c: '鄞州区',l: '宁波-鄞州区'},
                    {id: '080307',c: '慈溪市',l: '宁波-慈溪市'},
                    {id: '080308',c: '余姚市',l: '宁波-余姚市'},
                    {id: '080309',c: '奉化区',l: '宁波-奉化区'},
                    {id: '080310',c: '宁海县',l: '宁波-宁海县'},
                    {id: '080311',c: '象山县',l: '宁波-象山县'},
                    {id: '080312',c: '高新区',l: '宁波-高新区'}
                ]
            },
            {
                id: '120300',
                c: '青岛',
                l: '青岛',
                sub: [
                    {id: '120300',c: '青岛',l: '青岛'},
                    {id: '120301',c: '市南区',l: '青岛-市南区'},
                    {id: '120302',c: '市北区',l: '青岛-市北区'},
                    {id: '120303',c: '黄岛区',l: '青岛-黄岛区'},
                    {id: '120304',c: '崂山区',l: '青岛-崂山区'},
                    {id: '120305',c: '城阳区',l: '青岛-城阳区'},
                    {id: '120306',c: '李沧区',l: '青岛-李沧区'},
                    {id: '120307',c: '胶州市',l: '青岛-胶州市'},
                    {id: '120308',c: '即墨区',l: '青岛-即墨区'},
                    {id: '120309',c: '平度市',l: '青岛-平度市'},
                    {id: '120310',c: '莱西市',l: '青岛-莱西市'},
                    {id: '120311',c: '高新区',l: '青岛-高新区'}
                ]
            },
            {
                id: '230200',
                c: '沈阳',
                l: '沈阳',
                sub: [
                    {id: '230200',c: '沈阳',l: '沈阳'},
                    {id: '230201',c: '大东区',l: '沈阳-大东区'},
                    {id: '230202',c: '浑南区',l: '沈阳-浑南区'},
                    {id: '230203',c: '康平县',l: '沈阳-康平县'},
                    {id: '230204',c: '和平区',l: '沈阳-和平区'},
                    {id: '230205',c: '皇姑区',l: '沈阳-皇姑区'},
                    {id: '230206',c: '沈北新区',l: '沈阳-沈北新区'},
                    {id: '230207',c: '沈河区',l: '沈阳-沈河区'},
                    {id: '230208',c: '苏家屯区',l: '沈阳-苏家屯区'},
                    {id: '230209',c: '铁西区',l: '沈阳-铁西区'},
                    {id: '230210',c: '于洪区',l: '沈阳-于洪区'},
                    {id: '230211',c: '法库县',l: '沈阳-法库县'},
                    {id: '230212',c: '辽中区',l: '沈阳-辽中区'},
                    {id: '230213',c: '新民市',l: '沈阳-新民市'}
                ]
            },
            {
                id: '230300',
                c: '大连',
                l: '大连',
                sub: [
                    {id: '230300',c: '大连',l: '大连'},
                    {id: '230301',c: '西岗区',l: '大连-西岗区'},
                    {id: '230302',c: '中山区',l: '大连-中山区'},
                    {id: '230303',c: '沙河口区',l: '大连-沙河口区'},
                    {id: '230304',c: '甘井子区',l: '大连-甘井子区'},
                    {id: '230305',c: '旅顺口区',l: '大连-旅顺口区'},
                    {id: '230306',c: '金州区',l: '大连-金州区'},
                    {id: '230307',c: '瓦房店市',l: '大连-瓦房店市'},
                    {id: '230308',c: '普兰店区',l: '大连-普兰店区'},
                    {id: '230309',c: '庄河市',l: '大连-庄河市'},
                    {id: '230310',c: '长海县',l: '大连-长海县'},
                    {id: '230312',c: '高新园区',l: '大连-高新园区'},
                    {id: '230313',c: '长兴岛',l: '大连-长兴岛'},
                    {id: '230314',c: '大连保税区',l: '大连-大连保税区'}
                ]
            },
            {
                id: '030800',
                c: '东莞',
                l: '东莞',
                sub: [
                    {id: '030800',c: '东莞',l: '东莞'},
                    {id: '030801',c: '莞城区',l: '东莞-莞城区'},
                    {id: '030802',c: '南城区',l: '东莞-南城区'},
                    {id: '030803',c: '东城区',l: '东莞-东城区'},
                    {id: '030804',c: '万江区',l: '东莞-万江区'},
                    {id: '030805',c: '石碣镇',l: '东莞-石碣镇'},
                    {id: '030806',c: '石龙镇',l: '东莞-石龙镇'},
                    {id: '030807',c: '茶山镇',l: '东莞-茶山镇'},
                    {id: '030808',c: '石排镇',l: '东莞-石排镇'},
                    {id: '030809',c: '企石镇',l: '东莞-企石镇'},
                    {id: '030810',c: '横沥镇',l: '东莞-横沥镇'},
                    {id: '030811',c: '桥头镇',l: '东莞-桥头镇'},
                    {id: '030812',c: '谢岗镇',l: '东莞-谢岗镇'},
                    {id: '030813',c: '东坑镇',l: '东莞-东坑镇'},
                    {id: '030814',c: '常平镇',l: '东莞-常平镇'},
                    {id: '030815',c: '寮步镇',l: '东莞-寮步镇'},
                    {id: '030816',c: '大朗镇',l: '东莞-大朗镇'},
                    {id: '030817',c: '麻涌镇',l: '东莞-麻涌镇'},
                    {id: '030818',c: '中堂镇',l: '东莞-中堂镇'},
                    {id: '030819',c: '高埗镇',l: '东莞-高埗镇'},
                    {id: '030820',c: '樟木头镇',l: '东莞-樟木头镇'},
                    {id: '030821',c: '大岭山镇',l: '东莞-大岭山镇'},
                    {id: '030822',c: '望牛墩镇',l: '东莞-望牛墩镇'},
                    {id: '030823',c: '黄江镇',l: '东莞-黄江镇'},
                    {id: '030824',c: '洪梅镇',l: '东莞-洪梅镇'},
                    {id: '030825',c: '清溪镇',l: '东莞-清溪镇'},
                    {id: '030826',c: '沙田镇',l: '东莞-沙田镇'},
                    {id: '030827',c: '道滘镇',l: '东莞-道滘镇'},
                    {id: '030828',c: '塘厦镇',l: '东莞-塘厦镇'},
                    {id: '030829',c: '虎门镇',l: '东莞-虎门镇'},
                    {id: '030830',c: '厚街镇',l: '东莞-厚街镇'},
                    {id: '030831',c: '凤岗镇',l: '东莞-凤岗镇'},
                    {id: '030832',c: '长安镇',l: '东莞-长安镇'},
                    {id: '030833',c: '松山湖区',l: '东莞-松山湖区'}
                ]
            },
            {
                id: '110200',
                c: '福州',
                l: '福州',
                sub: [
                    {id: '110200',c: '福州',l: '福州'},
                    {id: '110201',c: '鼓楼区',l: '福州-鼓楼区'},
                    {id: '110202',c: '台江区',l: '福州-台江区'},
                    {id: '110203',c: '仓山区',l: '福州-仓山区'},
                    {id: '110204',c: '马尾区',l: '福州-马尾区'},
                    {id: '110205',c: '晋安区',l: '福州-晋安区'},
                    {id: '110206',c: '闽侯县',l: '福州-闽侯县'},
                    {id: '110207',c: '连江县',l: '福州-连江县'},
                    {id: '110208',c: '罗源县',l: '福州-罗源县'},
                    {id: '110209',c: '闽清县',l: '福州-闽清县'},
                    {id: '110210',c: '永泰县',l: '福州-永泰县'},
                    {id: '110211',c: '平潭县',l: '福州-平潭县'},
                    {id: '110212',c: '福清市',l: '福州-福清市'},
                    {id: '110213',c: '长乐区',l: '福州-长乐区'}
                ]
            },
            {
                id: '120200',
                c: '济南',
                l: '济南',
                sub: [
                    {id: '120200',c: '济南',l: '济南'},
                    {id: '120201',c: '历下区',l: '济南-历下区'},
                    {id: '120202',c: '市中区',l: '济南-市中区'},
                    {id: '120203',c: '槐荫区',l: '济南-槐荫区'},
                    {id: '120204',c: '天桥区',l: '济南-天桥区'},
                    {id: '120205',c: '历城区',l: '济南-历城区'},
                    {id: '120206',c: '长清区',l: '济南-长清区'},
                    {id: '120207',c: '平阴县',l: '济南-平阴县'},
                    {id: '120208',c: '济阳区',l: '济南-济阳区'},
                    {id: '120209',c: '商河县',l: '济南-商河县'},
                    {id: '120210',c: '章丘区',l: '济南-章丘区'},
                    {id: '120211',c: '高新区',l: '济南-高新区'},
                    {id: '120212',c: '莱芜区',l: '济南-莱芜区'},
                    {id: '120213',c: '钢城区',l: '济南-钢城区'}
                ]
            },
            {
                id: '150200',
                c: '合肥',
                l: '合肥',
                sub: [
                    {id: '150200',c: '合肥',l: '合肥'},
                    {id: '150201',c: '瑶海区',l: '合肥-瑶海区'},
                    {id: '150202',c: '庐阳区',l: '合肥-庐阳区'},
                    {id: '150203',c: '蜀山区',l: '合肥-蜀山区'},
                    {id: '150204',c: '包河区',l: '合肥-包河区'},
                    {id: '150205',c: '经开区',l: '合肥-经开区'},
                    {id: '150206',c: '滨湖新区',l: '合肥-滨湖新区'},
                    {id: '150207',c: '新站区',l: '合肥-新站区'},
                    {id: '150208',c: '高新区',l: '合肥-高新区'},
                    {id: '150209',c: '政务区',l: '合肥-政务区'},
                    {id: '150210',c: '北城新区',l: '合肥-北城新区'},
                    {id: '150211',c: '巢湖市',l: '合肥-巢湖市'},
                    {id: '150212',c: '长丰县',l: '合肥-长丰县'},
                    {id: '150213',c: '肥东县',l: '合肥-肥东县'},
                    {id: '150214',c: '肥西县',l: '合肥-肥西县'},
                    {id: '150215',c: '庐江县',l: '合肥-庐江县'}
                ]
            },
            {
                id: '170200',
                c: '郑州',
                l: '郑州',
                sub: [
                    {id: '170200',c: '郑州',l: '郑州'},
                    {id: '170201',c: '中原区',l: '郑州-中原区'},
                    {id: '170202',c: '二七区',l: '郑州-二七区'},
                    {id: '170203',c: '管城回族区',l: '郑州-管城回族区'},
                    {id: '170204',c: '金水区',l: '郑州-金水区'},
                    {id: '170205',c: '上街区',l: '郑州-上街区'},
                    {id: '170206',c: '惠济区',l: '郑州-惠济区'},
                    {id: '170207',c: '中牟县',l: '郑州-中牟县'},
                    {id: '170208',c: '巩义市',l: '郑州-巩义市'},
                    {id: '170209',c: '荥阳市',l: '郑州-荥阳市'},
                    {id: '170210',c: '新密市',l: '郑州-新密市'},
                    {id: '170211',c: '新郑市',l: '郑州-新郑市'},
                    {id: '170212',c: '登封市',l: '郑州-登封市'},
                    {id: '170213',c: '郑东新区',l: '郑州-郑东新区'},
                    {id: '170214',c: '高新区',l: '郑州-高新区'},
                    {id: '170215',c: '经开区',l: '郑州-经开区'},
                    {id: '170216',c: '郑州航空港区',l: '郑州-郑州航空港区'}
                ]
            },
            {
                id: '190200',
                c: '长沙',
                l: '长沙',
                sub: [
                    {id: '190200',c: '长沙',l: '长沙'},
                    {id: '190201',c: '芙蓉区',l: '长沙-芙蓉区'},
                    {id: '190202',c: '天心区',l: '长沙-天心区'},
                    {id: '190203',c: '岳麓区',l: '长沙-岳麓区'},
                    {id: '190204',c: '开福区',l: '长沙-开福区'},
                    {id: '190205',c: '雨花区',l: '长沙-雨花区'},
                    {id: '190206',c: '望城区',l: '长沙-望城区'},
                    {id: '190207',c: '长沙县',l: '长沙-长沙县'},
                    {id: '190208',c: '宁乡市',l: '长沙-宁乡市'},
                    {id: '190209',c: '浏阳市',l: '长沙-浏阳市'}
                ]
            },
            {
                id: '220200',
                c: '哈尔滨',
                l: '哈尔滨',
                sub: [
                    {id: '220200',c: '哈尔滨',l: '哈尔滨'},
                    {id: '220201',c: '道里区',l: '哈尔滨-道里区'},
                    {id: '220202',c: '南岗区',l: '哈尔滨-南岗区'},
                    {id: '220203',c: '道外区',l: '哈尔滨-道外区'},
                    {id: '220204',c: '平房区',l: '哈尔滨-平房区'},
                    {id: '220205',c: '松北区',l: '哈尔滨-松北区'},
                    {id: '220206',c: '香坊区',l: '哈尔滨-香坊区'},
                    {id: '220207',c: '呼兰区',l: '哈尔滨-呼兰区'},
                    {id: '220208',c: '阿城区',l: '哈尔滨-阿城区'},
                    {id: '220209',c: '依兰县',l: '哈尔滨-依兰县'},
                    {id: '220210',c: '方正县',l: '哈尔滨-方正县'},
                    {id: '220211',c: '宾县',l: '哈尔滨-宾县'},
                    {id: '220212',c: '巴彦县',l: '哈尔滨-巴彦县'},
                    {id: '220213',c: '木兰县',l: '哈尔滨-木兰县'},
                    {id: '220214',c: '通河县',l: '哈尔滨-通河县'},
                    {id: '220215',c: '延寿县',l: '哈尔滨-延寿县'},
                    {id: '220216',c: '双城区',l: '哈尔滨-双城区'},
                    {id: '220217',c: '尚志市',l: '哈尔滨-尚志市'},
                    {id: '220218',c: '五常市',l: '哈尔滨-五常市'}
                ]
            },
            {
                id: '240200',
                c: '长春',
                l: '长春',
                sub: [
                    {id: '240200',c: '长春',l: '长春'},
                    {id: '240201',c: '朝阳区',l: '长春-朝阳区'},
                    {id: '240202',c: '南关区',l: '长春-南关区'},
                    {id: '240203',c: '宽城区',l: '长春-宽城区'},
                    {id: '240204',c: '二道区',l: '长春-二道区'},
                    {id: '240205',c: '绿园区',l: '长春-绿园区'},
                    {id: '240206',c: '双阳区',l: '长春-双阳区'},
                    {id: '240207',c: '经济技术开发区',l: '长春-经济技术开发区'},
                    {id: '240208',c: '高新技术产业开发区',l: '长春-高新技术产业开发区'},
                    {id: '240209',c: '净月高新技术产业开发区',l: '长春-净月高新技术产业开发区'},
                    {id: '240210',c: '汽车经济技术开发区',l: '长春-汽车经济技术开发区'},
                    {id: '240211',c: '榆树市',l: '长春-榆树市'},
                    {id: '240212',c: '九台区',l: '长春-九台区'},
                    {id: '240213',c: '德惠市',l: '长春-德惠市'},
                    {id: '240214',c: '农安县',l: '长春-农安县'},
                    {id: '240215',c: '公主岭市',l: '长春-公主岭市'}
                ]
            },
            {
                id: '250200',
                c: '昆明',
                l: '昆明',
                sub: [
                    {id: '250200',c: '昆明',l: '昆明'},
                    {id: '250201',c: '五华区',l: '昆明-五华区'},
                    {id: '250202',c: '盘龙区',l: '昆明-盘龙区'},
                    {id: '250203',c: '官渡区',l: '昆明-官渡区'},
                    {id: '250204',c: '西山区',l: '昆明-西山区'},
                    {id: '250205',c: '东川区',l: '昆明-东川区'},
                    {id: '250206',c: '呈贡区',l: '昆明-呈贡区'},
                    {id: '250207',c: '晋宁区',l: '昆明-晋宁区'},
                    {id: '250208',c: '富民县',l: '昆明-富民县'},
                    {id: '250209',c: '宜良县',l: '昆明-宜良县'},
                    {id: '250210',c: '石林彝族自治县',l: '昆明-石林彝族自治县'},
                    {id: '250211',c: '嵩明县',l: '昆明-嵩明县'},
                    {id: '250212',c: '禄劝县',l: '昆明-禄劝县'},
                    {id: '250213',c: '寻甸县',l: '昆明-寻甸县'},
                    {id: '250214',c: '安宁市',l: '昆明-安宁市'}
                ]
            },
            {
                id: '070400',
                c: '无锡',
                l: '无锡',
                sub: [
                    {id: '070400',c: '无锡',l: '无锡'},
                    {id: '070401',c: '梁溪区',l: '无锡-梁溪区'},
                    {id: '070404',c: '滨湖区',l: '无锡-滨湖区'},
                    {id: '070405',c: '新吴区',l: '无锡-新吴区'},
                    {id: '070406',c: '惠山区',l: '无锡-惠山区'},
                    {id: '070407',c: '锡山区',l: '无锡-锡山区'},
                    {id: '070408',c: '宜兴市',l: '无锡-宜兴市'},
                    {id: '070409',c: '江阴市',l: '无锡-江阴市'}
                ]
            },
            {
                id: '070500',
                c: '常州',
                l: '常州',
                sub: [
                    {id: '070500',c: '常州',l: '常州'},
                    {id: '070501',c: '天宁区',l: '常州-天宁区'},
                    {id: '070502',c: '钟楼区',l: '常州-钟楼区'},
                    {id: '070504',c: '新北区',l: '常州-新北区'},
                    {id: '070505',c: '武进区',l: '常州-武进区'},
                    {id: '070506',c: '金坛区',l: '常州-金坛区'},
                    {id: '070507',c: '溧阳市',l: '常州-溧阳市'}
                ]
            },
            {
                id: '030500',
                c: '珠海',
                l: '珠海',
                sub: [
                    {id: '030500',c: '珠海',l: '珠海'},
                    {id: '030501',c: '香洲区',l: '珠海-香洲区'},
                    {id: '030502',c: '斗门区',l: '珠海-斗门区'},
                    {id: '030503',c: '金湾区',l: '珠海-金湾区'},
                    {id: '030504',c: '横琴新区',l: '珠海-横琴新区'},
                    {id: '030505',c: '高栏港经济区',l: '珠海-高栏港经济区'},
                    {id: '030506',c: '珠海高新区',l: '珠海-珠海高新区'},
                    {id: '030507',c: '珠海保税区',l: '珠海-珠海保税区'},
                    {id: '030508',c: '万山海洋开发试验区',l: '珠海-万山海洋开发试验区'}
                ]
            },
            {
                id: '030600',
                c: '佛山',
                l: '佛山',
                sub: [
                    {id: '030600',c: '佛山',l: '佛山'},
                    {id: '030601',c: '禅城区',l: '佛山-禅城区'},
                    {id: '030602',c: '顺德区',l: '佛山-顺德区'},
                    {id: '030603',c: '南海区',l: '佛山-南海区'},
                    {id: '030604',c: '三水区',l: '佛山-三水区'},
                    {id: '030605',c: '高明区',l: '佛山-高明区'}
                ]
            },
            {
                id: '130200',
                c: '南昌',
                l: '南昌',
                sub: [
                    {id: '130200',c: '南昌',l: '南昌'},
                    {id: '130201',c: '东湖区',l: '南昌-东湖区'},
                    {id: '130202',c: '西湖区',l: '南昌-西湖区'},
                    {id: '130203',c: '青云谱区',l: '南昌-青云谱区'},
                    {id: '130205',c: '青山湖区',l: '南昌-青山湖区'},
                    {id: '130206',c: '南昌县',l: '南昌-南昌县'},
                    {id: '130207',c: '新建区',l: '南昌-新建区'},
                    {id: '130208',c: '安义县',l: '南昌-安义县'},
                    {id: '130209',c: '进贤县',l: '南昌-进贤县'},
                    {id: '130210',c: '红谷滩新区',l: '南昌-红谷滩新区'}
                ]
            }
        ]
    },
    {
        id: '030000',
        c: '广东省',
        l: '广东省',
        sub: [
            {
                id: '030000',
                c: '广东省',
                l: '广东省',
                sub: [{ id: '030000',c: '广东省',l: '广东省'}]
            },
            {
                id: '030200',
                c: '广州',
                l: '广州',
                sub: [
                    {id: '030200',c: '广州',l: '广州'},
                    {id: '030201',c: '越秀区',l: '广州-越秀区'},
                    {id: '030202',c: '荔湾区',l: '广州-荔湾区'},
                    {id: '030203',c: '海珠区',l: '广州-海珠区'},
                    {id: '030204',c: '天河区',l: '广州-天河区'},
                    {id: '030205',c: '白云区',l: '广州-白云区'},
                    {id: '030206',c: '黄埔区',l: '广州-黄埔区'},
                    {id: '030207',c: '番禺区',l: '广州-番禺区'},
                    {id: '030208',c: '花都区',l: '广州-花都区'},
                    {id: '030209',c: '南沙区',l: '广州-南沙区'},
                    {id: '030211',c: '增城区',l: '广州-增城区'},
                    {id: '030212',c: '从化区',l: '广州-从化区'}
                ]
            },
            {
                id: '030300',
                c: '惠州',
                l: '惠州',
                sub: [
                    {id: '030300',c: '惠州',l: '惠州'},
                    {id: '030301',c: '惠城区',l: '惠州-惠城区'},
                    {id: '030302',c: '惠阳区',l: '惠州-惠阳区'},
                    {id: '030303',c: '大亚湾区',l: '惠州-大亚湾区'},
                    {id: '030304',c: '仲恺区',l: '惠州-仲恺区'},
                    {id: '030305',c: '惠东县',l: '惠州-惠东县'},
                    {id: '030306',c: '博罗县',l: '惠州-博罗县'},
                    {id: '030307',c: '龙门县',l: '惠州-龙门县'}
                ]
            },
            {
                id: '030400',
                c: '汕头',
                l: '汕头',
                sub: [
                    {id: '030400',c: '汕头',l: '汕头'},
                    {id: '030401',c: '金平区',l: '汕头-金平区'},
                    {id: '030402',c: '龙湖区',l: '汕头-龙湖区'},
                    {id: '030403',c: '澄海区',l: '汕头-澄海区'},
                    {id: '030404',c: '濠江区',l: '汕头-濠江区'},
                    {id: '030405',c: '潮阳区',l: '汕头-潮阳区'},
                    {id: '030406',c: '潮南区',l: '汕头-潮南区'},
                    {id: '030407',c: '南澳县',l: '汕头-南澳县'}
                ]
            },
            {
                id: '030500',
                c: '珠海',
                l: '珠海',
                sub: [
                    {id: '030500',c: '珠海',l: '珠海'},
                    {id: '030501',c: '香洲区',l: '珠海-香洲区'},
                    {id: '030502',c: '斗门区',l: '珠海-斗门区'},
                    {id: '030503',c: '金湾区',l: '珠海-金湾区'},
                    {id: '030504',c: '横琴新区',l: '珠海-横琴新区'},
                    {id: '030505',c: '高栏港经济区',l: '珠海-高栏港经济区'},
                    {id: '030506',c: '珠海高新区',l: '珠海-珠海高新区'},
                    {id: '030507',c: '珠海保税区',l: '珠海-珠海保税区'},
                    {id: '030508',c: '万山海洋开发试验区',l: '珠海-万山海洋开发试验区'}
                ]
            },
            {
                id: '030600',
                c: '佛山',
                l: '佛山',
                sub: [
                    {id: '030600',c: '佛山',l: '佛山'},
                    {id: '030601',c: '禅城区',l: '佛山-禅城区'},
                    {id: '030602',c: '顺德区',l: '佛山-顺德区'},
                    {id: '030603',c: '南海区',l: '佛山-南海区'},
                    {id: '030604',c: '三水区',l: '佛山-三水区'},
                    {id: '030605',c: '高明区',l: '佛山-高明区'}
                ]
            },
            {
                id: '030700',
                c: '中山',
                l: '中山',
                sub: [
                    {id: '030700',c: '中山',l: '中山'},
                    {id: '030701',c: '石岐区',l: '中山-石岐区'},
                    {id: '030702',c: '东区',l: '中山-东区'},
                    {id: '030703',c: '西区',l: '中山-西区'},
                    {id: '030704',c: '南区',l: '中山-南区'},
                    {id: '030705',c: '五桂山区',l: '中山-五桂山区'},
                    {id: '030706',c: '火炬开发区',l: '中山-火炬开发区'},
                    {id: '030707',c: '黄圃镇',l: '中山-黄圃镇'},
                    {id: '030708',c: '南头镇',l: '中山-南头镇'},
                    {id: '030709',c: '东凤镇',l: '中山-东凤镇'},
                    {id: '030710',c: '阜沙镇',l: '中山-阜沙镇'},
                    {id: '030711',c: '小榄镇',l: '中山-小榄镇'},
                    {id: '030712',c: '东升镇',l: '中山-东升镇'},
                    {id: '030713',c: '古镇镇',l: '中山-古镇镇'},
                    {id: '030714',c: '横栏镇',l: '中山-横栏镇'},
                    {id: '030715',c: '三角镇',l: '中山-三角镇'},
                    {id: '030716',c: '民众镇',l: '中山-民众镇'},
                    {id: '030717',c: '南朗镇',l: '中山-南朗镇'},
                    {id: '030718',c: '港口镇',l: '中山-港口镇'},
                    {id: '030719',c: '大涌镇',l: '中山-大涌镇'},
                    {id: '030720',c: '沙溪镇',l: '中山-沙溪镇'},
                    {id: '030721',c: '三乡镇',l: '中山-三乡镇'},
                    {id: '030722',c: '板芙镇',l: '中山-板芙镇'},
                    {id: '030723',c: '神湾镇',l: '中山-神湾镇'},
                    {id: '030724',c: '坦洲镇',l: '中山-坦洲镇'}
                ]
            },
            {
                id: '030800',
                c: '东莞',
                l: '东莞',
                sub: [
                    {id: '030800',c: '东莞',l: '东莞'},
                    {id: '030801',c: '莞城区',l: '东莞-莞城区'},
                    {id: '030802',c: '南城区',l: '东莞-南城区'},
                    {id: '030803',c: '东城区',l: '东莞-东城区'},
                    {id: '030804',c: '万江区',l: '东莞-万江区'},
                    {id: '030805',c: '石碣镇',l: '东莞-石碣镇'},
                    {id: '030806',c: '石龙镇',l: '东莞-石龙镇'},
                    {id: '030807',c: '茶山镇',l: '东莞-茶山镇'},
                    {id: '030808',c: '石排镇',l: '东莞-石排镇'},
                    {id: '030809',c: '企石镇',l: '东莞-企石镇'},
                    {id: '030810',c: '横沥镇',l: '东莞-横沥镇'},
                    {id: '030811',c: '桥头镇',l: '东莞-桥头镇'},
                    {id: '030812',c: '谢岗镇',l: '东莞-谢岗镇'},
                    {id: '030813',c: '东坑镇',l: '东莞-东坑镇'},
                    {id: '030814',c: '常平镇',l: '东莞-常平镇'},
                    {id: '030815',c: '寮步镇',l: '东莞-寮步镇'},
                    {id: '030816',c: '大朗镇',l: '东莞-大朗镇'},
                    {id: '030817',c: '麻涌镇',l: '东莞-麻涌镇'},
                    {id: '030818',c: '中堂镇',l: '东莞-中堂镇'},
                    {id: '030819',c: '高埗镇',l: '东莞-高埗镇'},
                    {id: '030820',c: '樟木头镇',l: '东莞-樟木头镇'},
                    {id: '030821',c: '大岭山镇',l: '东莞-大岭山镇'},
                    {id: '030822',c: '望牛墩镇',l: '东莞-望牛墩镇'},
                    {id: '030823',c: '黄江镇',l: '东莞-黄江镇'},
                    {id: '030824',c: '洪梅镇',l: '东莞-洪梅镇'},
                    {id: '030825',c: '清溪镇',l: '东莞-清溪镇'},
                    {id: '030826',c: '沙田镇',l: '东莞-沙田镇'},
                    {id: '030827',c: '道滘镇',l: '东莞-道滘镇'},
                    {id: '030828',c: '塘厦镇',l: '东莞-塘厦镇'},
                    {id: '030829',c: '虎门镇',l: '东莞-虎门镇'},
                    {id: '030830',c: '厚街镇',l: '东莞-厚街镇'},
                    {id: '030831',c: '凤岗镇',l: '东莞-凤岗镇'},
                    {id: '030832',c: '长安镇',l: '东莞-长安镇'},
                    {id: '030833',c: '松山湖区',l: '东莞-松山湖区'}
                ]
            },
            {
                id: '031400',
                c: '韶关',
                l: '韶关',
                sub: [
                    {id: '031400',c: '韶关',l: '韶关'}
                ]
            },
            {
                id: '031500',
                c: '江门',
                l: '江门',
                sub: [
                    {id: '031500',c: '江门',l: '江门'},
                    {id: '031501',c: '蓬江区',l: '江门-蓬江区'},
                    {id: '031502',c: '江海区',l: '江门-江海区'},
                    {id: '031503',c: '新会区',l: '江门-新会区'},
                    {id: '031504',c: '台山市',l: '江门-台山市'},
                    {id: '031505',c: '开平市',l: '江门-开平市'},
                    {id: '031506',c: '鹤山市',l: '江门-鹤山市'},
                    {id: '031507',c: '恩平市',l: '江门-恩平市'}
                ]
            },
            {
                id: '031700',
                c: '湛江',
                l: '湛江',
                sub: [
                    {id: '031700',c: '湛江',l: '湛江'},
                    {id: '031701',c: '赤坎区',l: '湛江-赤坎区'},
                    {id: '031702',c: '霞山区',l: '湛江-霞山区'},
                    {id: '031703',c: '坡头区',l: '湛江-坡头区'},
                    {id: '031704',c: '麻章区',l: '湛江-麻章区'},
                    {id: '031705',c: '廉江市',l: '湛江-廉江市'},
                    {id: '031706',c: '雷州市',l: '湛江-雷州市'},
                    {id: '031707',c: '吴川市',l: '湛江-吴川市'},
                    {id: '031708',c: '遂溪县',l: '湛江-遂溪县'},
                    {id: '031709',c: '徐闻县',l: '湛江-徐闻县'}
                ]
            },
            {
                id: '031800',
                c: '肇庆',
                l: '肇庆',
                sub: [
                    {id: '031800',c: '肇庆',l: '肇庆'},
                    {id: '031801',c: '端州区',l: '肇庆-端州区'},
                    {id: '031802',c: '鼎湖区',l: '肇庆-鼎湖区'},
                    {id: '031803',c: '高要区',l: '肇庆-高要区'},
                    {id: '031804',c: '广宁县',l: '肇庆-广宁县'},
                    {id: '031805',c: '德庆县',l: '肇庆-德庆县'},
                    {id: '031806',c: '封开县',l: '肇庆-封开县'},
                    {id: '031807',c: '怀集县',l: '肇庆-怀集县'},
                    {id: '031808',c: '四会市',l: '肇庆-四会市'}
                ]
            },
            {
                id: '031900',
                c: '清远',
                l: '清远',
                sub: [
                    {id: '031900',c: '清远',l: '清远'},
                    {id: '031901',c: '清城区',l: '清远-清城区'},
                    {id: '031902',c: '清新区',l: '清远-清新区'},
                    {id: '031903',c: '英德市',l: '清远-英德市'},
                    {id: '031904',c: '连州市',l: '清远-连州市'},
                    {id: '031905',c: '佛冈县',l: '清远-佛冈县'},
                    {id: '031906',c: '阳山县',l: '清远-阳山县'},
                    {id: '031907',c: '连南瑶族自治县',l: '清远-连南瑶族自治县'},
                    {id: '031908',c: '连山壮族瑶族自治县',l: '清远-连山壮族瑶族自治县'}
                ]
            },
            {
                id: '032000',
                c: '潮州',
                l: '潮州',
                sub: [
                    {id: '032000',c: '潮州',l: '潮州'}
                ]
            },
            {
                id: '032100',
                c: '河源',
                l: '河源',
                sub: [
                    {id: '032100',c: '河源',l: '河源'}
                ]
            },
            {
                id: '032200',
                c: '揭阳',
                l: '揭阳',
                sub: [
                    {id: '032200',c: '揭阳',l: '揭阳'}
                ]
            },
            {
                id: '032300',
                c: '茂名',
                l: '茂名',
                sub: [
                    {id: '032300',c: '茂名',l: '茂名'}
                ]
            },
            {
                id: '032400',
                c: '汕尾',
                l: '汕尾',
                sub: [
                    {id: '032400',c: '汕尾',l: '汕尾'}
                ]
            },
            {
                id: '032600',
                c: '梅州',
                l: '梅州',
                sub: [
                    {id: '032600',c: '梅州',l: '梅州'}
                ]
            },
            {
                id: '032700',
                c: '开平',
                l: '开平',
                sub: [
                    {id: '032700',c: '开平',l: '开平'}
                ]
            },
            {
                id: '032800',
                c: '阳江',
                l: '阳江',
                sub: [
                    {id: '032800',c: '阳江',l: '阳江'}
                ]
            },
            {
                id: '032900',
                c: '云浮',
                l: '云浮',
                sub: [
                    {id: '032900',c: '云浮',l: '云浮'}
                ]
            },
            {
                id: '040000',
                c: '深圳',
                l: '深圳',
                sub: [
                    {id: '040000',c: '深圳',l: '深圳'},
                    {id: '040100',c: '福田区',l: '深圳-福田区'},
                    {id: '040200',c: '罗湖区',l: '深圳-罗湖区'},
                    {id: '040300',c: '南山区',l: '深圳-南山区'},
                    {id: '040400',c: '盐田区',l: '深圳-盐田区'},
                    {id: '040500',c: '宝安区',l: '深圳-宝安区'},
                    {id: '040600',c: '龙岗区',l: '深圳-龙岗区'},
                    {id: '040700',c: '光明区',l: '深圳-光明区'},
                    {id: '040800',c: '坪山区',l: '深圳-坪山区'},
                    {id: '040900',c: '大鹏新区',l: '深圳-大鹏新区'},
                    {id: '041000',c: '龙华区',l: '深圳-龙华区'}
                ]
            }
        ]
    },
    {
        id: '070000',
        c: '江苏省',
        l: '江苏省',
        sub: [
            {
                id: '070000',
                c: '江苏省',
                l: '江苏省',
                sub: [{ id: '070000',c: '江苏省',l: '江苏省'}]
            },
            {
                id: '070200',
                c: '南京',
                l: '南京',
                sub: [
                    {id: '070200',c: '南京',l: '南京'},
                    {id: '070201',c: '玄武区',l: '南京-玄武区'},
                    {id: '070203',c: '秦淮区',l: '南京-秦淮区'},
                    {id: '070204',c: '建邺区',l: '南京-建邺区'},
                    {id: '070205',c: '鼓楼区',l: '南京-鼓楼区'},
                    {id: '070207',c: '浦口区',l: '南京-浦口区'},
                    {id: '070208',c: '六合区',l: '南京-六合区'},
                    {id: '070209',c: '栖霞区',l: '南京-栖霞区'},
                    {id: '070210',c: '雨花台区',l: '南京-雨花台区'},
                    {id: '070211',c: '江宁区',l: '南京-江宁区'},
                    {id: '070212',c: '溧水区',l: '南京-溧水区'},
                    {id: '070213',c: '高淳区',l: '南京-高淳区'},
                    {id: '070214',c: '江北新区',l: '南京-江北新区'},
                    {id: '070215',c: '经济技术开发区',l: '南京-经济技术开发区'}
                ]
            },
            {
                id: '070300',
                c: '苏州',
                l: '苏州',
                sub: [
                    {id: '070300',c: '苏州',l: '苏州'},
                    {id: '070301',c: '姑苏区',l: '苏州-姑苏区'},
                    {id: '070303',c: '吴中区',l: '苏州-吴中区'},
                    {id: '070304',c: '相城区',l: '苏州-相城区'},
                    {id: '070305',c: '吴江区',l: '苏州-吴江区'},
                    {id: '070306',c: '苏州工业园区',l: '苏州-苏州工业园区'},
                    {id: '070307',c: '高新区',l: '苏州-高新区'},
                    {id: '070308',c: '虎丘区',l: '苏州-虎丘区'}
                ]
            },
            {
                id: '070400',
                c: '无锡',
                l: '无锡',
                sub: [
                    {id: '070400',c: '无锡',l: '无锡'},
                    {id: '070401',c: '梁溪区',l: '无锡-梁溪区'},
                    {id: '070404',c: '滨湖区',l: '无锡-滨湖区'},
                    {id: '070405',c: '新吴区',l: '无锡-新吴区'},
                    {id: '070406',c: '惠山区',l: '无锡-惠山区'},
                    {id: '070407',c: '锡山区',l: '无锡-锡山区'},
                    {id: '070408',c: '宜兴市',l: '无锡-宜兴市'},
                    {id: '070409',c: '江阴市',l: '无锡-江阴市'}
                ]
            },
            {
                id: '070500',
                c: '常州',
                l: '常州',
                sub: [
                    {id: '070500',c: '常州',l: '常州'},
                    {id: '070501',c: '天宁区',l: '常州-天宁区'},
                    {id: '070502',c: '钟楼区',l: '常州-钟楼区'},
                    {id: '070504',c: '新北区',l: '常州-新北区'},
                    {id: '070505',c: '武进区',l: '常州-武进区'},
                    {id: '070506',c: '金坛区',l: '常州-金坛区'},
                    {id: '070507',c: '溧阳市',l: '常州-溧阳市'}
                ]
            },
            {
                id: '070600',
                c: '昆山',
                l: '昆山',
                sub: [
                    {id: '070600',c: '昆山',l: '昆山'}
                ]
            },
            {
                id: '070700',
                c: '常熟',
                l: '常熟',
                sub: [
                    {id: '070700',c: '常熟',l: '常熟'}
                ]
            },
            {
                id: '070800',
                c: '扬州',
                l: '扬州',
                sub: [
                    {id: '070800',c: '扬州',l: '扬州'},
                    {id: '070801',c: '邗江区',l: '扬州-邗江区'},
                    {id: '070802',c: '广陵区',l: '扬州-广陵区'},
                    {id: '070803',c: '江都区',l: '扬州-江都区'},
                    {id: '070804',c: '仪征市',l: '扬州-仪征市'},
                    {id: '070805',c: '高邮市',l: '扬州-高邮市'},
                    {id: '070806',c: '宝应县',l: '扬州-宝应县'},
                    {id: '070807',c: '开发区',l: '扬州-开发区'}
                ]
            },
            {
                id: '070900',
                c: '南通',
                l: '南通',
                sub: [
                    {id: '070900',c: '南通',l: '南通'},
                    {id: '070901',c: '崇川区',l: '南通-崇川区'},
                    {id: '070903',c: '通州区',l: '南通-通州区'},
                    {id: '070904',c: '如东县',l: '南通-如东县'},
                    {id: '070905',c: '如皋市',l: '南通-如皋市'},
                    {id: '070906',c: '海门市',l: '南通-海门市'},
                    {id: '070907',c: '启东市',l: '南通-启东市'},
                    {id: '070908',c: '海安市',l: '南通-海安市'}
                ]
            },
            {
                id: '071000',
                c: '镇江',
                l: '镇江',
                sub: [
                    {id: '071000',c: '镇江',l: '镇江'},
                    {id: '071001',c: '京口区',l: '镇江-京口区'},
                    {id: '071002',c: '润州区',l: '镇江-润州区'},
                    {id: '071003',c: '丹徒区',l: '镇江-丹徒区'},
                    {id: '071005',c: '扬中市',l: '镇江-扬中市'},
                    {id: '071006',c: '句容市',l: '镇江-句容市'},
                    {id: '071007',c: '丹阳市',l: '镇江-丹阳市'},
                    {id: '071008',c: '镇江新区',l: '镇江-镇江新区'},
                    {id: '071009',c: '镇江高新区',l: '镇江-镇江高新区'}
                ]
            },
            {
                id: '071100',
                c: '徐州',
                l: '徐州',
                sub: [
                    {id: '071100',c: '徐州',l: '徐州'},
                    {id: '071101',c: '泉山区',l: '徐州-泉山区'},
                    {id: '071102',c: '鼓楼区',l: '徐州-鼓楼区'},
                    {id: '071103',c: '云龙区',l: '徐州-云龙区'},
                    {id: '071104',c: '贾汪区',l: '徐州-贾汪区'},
                    {id: '071105',c: '铜山区',l: '徐州-铜山区'},
                    {id: '071106',c: '睢宁县',l: '徐州-睢宁县'},
                    {id: '071107',c: '沛县',l: '徐州-沛县'},
                    {id: '071108',c: '丰县',l: '徐州-丰县'},
                    {id: '071109',c: '邳州市',l: '徐州-邳州市'},
                    {id: '071110',c: '新沂市',l: '徐州-新沂市'}
                ]
            },
            {
                id: '071200',
                c: '连云港',
                l: '连云港',
                sub: [
                    {id: '071200',c: '连云港',l: '连云港'},
                    {id: '071201',c: '海州区',l: '连云港-海州区'},
                    {id: '071202',c: '连云区',l: '连云港-连云区'},
                    {id: '071203',c: '赣榆区',l: '连云港-赣榆区'},
                    {id: '071204',c: '灌云县',l: '连云港-灌云县'},
                    {id: '071205',c: '东海县',l: '连云港-东海县'},
                    {id: '071206',c: '灌南县',l: '连云港-灌南县'}
                ]
            },
            {
                id: '071300',
                c: '盐城',
                l: '盐城',
                sub: [
                    {id: '071300',c: '盐城',l: '盐城'},
                    {id: '071301',c: '亭湖区',l: '盐城-亭湖区'},
                    {id: '071302',c: '盐都区',l: '盐城-盐都区'},
                    {id: '071303',c: '大丰区',l: '盐城-大丰区'},
                    {id: '071304',c: '建湖县',l: '盐城-建湖县'},
                    {id: '071305',c: '射阳县',l: '盐城-射阳县'},
                    {id: '071306',c: '阜宁县',l: '盐城-阜宁县'},
                    {id: '071307',c: '滨海县',l: '盐城-滨海县'},
                    {id: '071308',c: '响水县',l: '盐城-响水县'},
                    {id: '071309',c: '东台市',l: '盐城-东台市'}
                ]
            },
            {
                id: '071400',
                c: '张家港',
                l: '张家港',
                sub: [
                    {id: '071400',c: '张家港',l: '张家港'}
                ]
            },
            {
                id: '071600',
                c: '太仓',
                l: '太仓',
                sub: [
                    {id: '071600',c: '太仓',l: '太仓'}
                ]
            },
            {
                id: '071800',
                c: '泰州',
                l: '泰州',
                sub: [
                    {id: '071800',c: '泰州',l: '泰州'},
                    {id: '071801',c: '海陵区',l: '泰州-海陵区'},
                    {id: '071802',c: '高港区',l: '泰州-高港区'},
                    {id: '071803',c: '姜堰区',l: '泰州-姜堰区'},
                    {id: '071804',c: '兴化市',l: '泰州-兴化市'},
                    {id: '071805',c: '泰兴市',l: '泰州-泰兴市'},
                    {id: '071806',c: '靖江市',l: '泰州-靖江市'}
                ]
            },
            {
                id: '071900',
                c: '淮安',
                l: '淮安',
                sub: [
                    {id: '071900',c: '淮安',l: '淮安'},
                    {id: '071901',c: '清江浦区',l: '淮安-清江浦区'},
                    {id: '071902',c: '淮阴区',l: '淮安-淮阴区'},
                    {id: '071903',c: '淮安区',l: '淮安-淮安区'},
                    {id: '071904',c: '洪泽区',l: '淮安-洪泽区'},
                    {id: '071905',c: '涟水县',l: '淮安-涟水县'},
                    {id: '071906',c: '盱眙县',l: '淮安-盱眙县'},
                    {id: '071907',c: '金湖县',l: '淮安-金湖县'}
                ]
            },
            {
                id: '072000',
                c: '宿迁',
                l: '宿迁',
                sub: [
                    {id: '072000',c: '宿迁',l: '宿迁'}
                ]
            }
        ]
    },
    {
        id: '080000',
        c: '浙江省',
        l: '浙江省',
        sub: [
            {
                id: '080000',
                c: '浙江省',
                l: '浙江省',
                sub: [{ id: '080000',c: '浙江省',l: '浙江省'}]
            },
            {
                id: '080200',
                c: '杭州',
                l: '杭州',
                sub: [
                    {id: '080200',c: '杭州',l: '杭州'},
                    {id: '080201',c: '拱墅区',l: '杭州-拱墅区'},
                    {id: '080202',c: '上城区',l: '杭州-上城区'},
                    {id: '080205',c: '西湖区',l: '杭州-西湖区'},
                    {id: '080206',c: '滨江区',l: '杭州-滨江区'},
                    {id: '080207',c: '余杭区',l: '杭州-余杭区'},
                    {id: '080208',c: '萧山区',l: '杭州-萧山区'},
                    {id: '080209',c: '临安区',l: '杭州-临安区'},
                    {id: '080210',c: '富阳区',l: '杭州-富阳区'},
                    {id: '080211',c: '建德市',l: '杭州-建德市'},
                    {id: '080212',c: '桐庐县',l: '杭州-桐庐县'},
                    {id: '080213',c: '淳安县',l: '杭州-淳安县'},
                    {id: '080214',c: '临平区',l: '杭州-临平区'},
                    {id: '080215',c: '钱塘区',l: '杭州-钱塘区'}
                ]
            },
            {
                id: '080300',
                c: '宁波',
                l: '宁波',
                sub: [
                    {id: '080300',c: '宁波',l: '宁波'},
                    {id: '080301',c: '海曙区',l: '宁波-海曙区'},
                    {id: '080303',c: '江北区',l: '宁波-江北区'},
                    {id: '080304',c: '北仑区',l: '宁波-北仑区'},
                    {id: '080305',c: '镇海区',l: '宁波-镇海区'},
                    {id: '080306',c: '鄞州区',l: '宁波-鄞州区'},
                    {id: '080307',c: '慈溪市',l: '宁波-慈溪市'},
                    {id: '080308',c: '余姚市',l: '宁波-余姚市'},
                    {id: '080309',c: '奉化区',l: '宁波-奉化区'},
                    {id: '080310',c: '宁海县',l: '宁波-宁海县'},
                    {id: '080311',c: '象山县',l: '宁波-象山县'},
                    {id: '080312',c: '高新区',l: '宁波-高新区'}
                ]
            },
            {
                id: '080400',
                c: '温州',
                l: '温州',
                sub: [
                    {id: '080400',c: '温州',l: '温州'},
                    {id: '080401',c: '鹿城区',l: '温州-鹿城区'},
                    {id: '080402',c: '龙湾区',l: '温州-龙湾区'},
                    {id: '080403',c: '瓯海区',l: '温州-瓯海区'},
                    {id: '080404',c: '洞头区',l: '温州-洞头区'},
                    {id: '080405',c: '瑞安市',l: '温州-瑞安市'},
                    {id: '080406',c: '乐清市',l: '温州-乐清市'},
                    {id: '080407',c: '龙港市',l: '温州-龙港市'},
                    {id: '080408',c: '永嘉县',l: '温州-永嘉县'},
                    {id: '080409',c: '平阳县',l: '温州-平阳县'},
                    {id: '080410',c: '苍南县',l: '温州-苍南县'},
                    {id: '080411',c: '文成县',l: '温州-文成县'},
                    {id: '080412',c: '泰顺县',l: '温州-泰顺县'}
                ]
            },
            {
                id: '080500',
                c: '绍兴',
                l: '绍兴',
                sub: [
                    {id: '080500',c: '绍兴',l: '绍兴'},
                    {id: '080501',c: '越城区',l: '绍兴-越城区'},
                    {id: '080502',c: '柯桥区',l: '绍兴-柯桥区'},
                    {id: '080503',c: '上虞区',l: '绍兴-上虞区'},
                    {id: '080504',c: '新昌县',l: '绍兴-新昌县'},
                    {id: '080505',c: '嵊州市',l: '绍兴-嵊州市'},
                    {id: '080506',c: '诸暨市',l: '绍兴-诸暨市'}
                ]
            },
            {
                id: '080600',
                c: '金华',
                l: '金华',
                sub: [
                    {id: '080600',c: '金华',l: '金华'},
                    {id: '080601',c: '婺城区',l: '金华-婺城区'},
                    {id: '080602',c: '金东区',l: '金华-金东区'},
                    {id: '080603',c: '兰溪市',l: '金华-兰溪市'},
                    {id: '080605',c: '东阳市',l: '金华-东阳市'},
                    {id: '080606',c: '永康市',l: '金华-永康市'},
                    {id: '080607',c: '浦江县',l: '金华-浦江县'},
                    {id: '080608',c: '武义县',l: '金华-武义县'},
                    {id: '080609',c: '磐安县',l: '金华-磐安县'}
                ]
            },
            {
                id: '080700',
                c: '嘉兴',
                l: '嘉兴',
                sub: [
                    {id: '080700',c: '嘉兴',l: '嘉兴'},
                    {id: '080701',c: '南湖区',l: '嘉兴-南湖区'},
                    {id: '080702',c: '秀洲区',l: '嘉兴-秀洲区'},
                    {id: '080703',c: '嘉善县',l: '嘉兴-嘉善县'},
                    {id: '080704',c: '海盐县',l: '嘉兴-海盐县'},
                    {id: '080706',c: '平湖市',l: '嘉兴-平湖市'},
                    {id: '080707',c: '桐乡市',l: '嘉兴-桐乡市'}
                ]
            },
            {
                id: '080800',
                c: '台州',
                l: '台州',
                sub: [
                    {id: '080800',c: '台州',l: '台州'},
                    {id: '080801',c: '椒江区',l: '台州-椒江区'},
                    {id: '080802',c: '黄岩区',l: '台州-黄岩区'},
                    {id: '080803',c: '路桥区',l: '台州-路桥区'},
                    {id: '080804',c: '三门县',l: '台州-三门县'},
                    {id: '080805',c: '天台县',l: '台州-天台县'},
                    {id: '080806',c: '仙居县',l: '台州-仙居县'},
                    {id: '080807',c: '温岭市',l: '台州-温岭市'},
                    {id: '080808',c: '临海市',l: '台州-临海市'},
                    {id: '080809',c: '玉环市',l: '台州-玉环市'}
                ]
            },
            {
                id: '080900',
                c: '湖州',
                l: '湖州',
                sub: [
                    {id: '080900',c: '湖州',l: '湖州'},
                    {id: '080901',c: '吴兴区',l: '湖州-吴兴区'},
                    {id: '080902',c: '南浔区',l: '湖州-南浔区'},
                    {id: '080903',c: '德清县',l: '湖州-德清县'},
                    {id: '080904',c: '长兴县',l: '湖州-长兴县'},
                    {id: '080905',c: '安吉县',l: '湖州-安吉县'}
                ]
            },
            {
                id: '081000',
                c: '丽水',
                l: '丽水',
                sub: [
                    {id: '081000',c: '丽水',l: '丽水'}
                ]
            },
            {
                id: '081100',
                c: '舟山',
                l: '舟山',
                sub: [
                    {id: '081100',c: '舟山',l: '舟山'}
                ]
            },
            {
                id: '081200',
                c: '衢州',
                l: '衢州',
                sub: [
                    {id: '081200',c: '衢州',l: '衢州'}
                ]
            },
            {
                id: '081400',
                c: '义乌',
                l: '义乌',
                sub: [
                    {id: '081400',c: '义乌',l: '义乌'}
                ]
            },
            {
                id: '081600',
                c: '海宁',
                l: '海宁',
                sub: [
                    {id: '081600',c: '海宁',l: '海宁'}
                ]
            }
        ]
    },
    {
        id: '090000',
        c: '四川省',
        l: '四川省',
        sub: [
            {
                id: '090000',
                c: '四川省',
                l: '四川省',
                sub: [{ id: '090000',c: '四川省',l: '四川省'}]
            },
            {
                id: '090200',
                c: '成都',
                l: '成都',
                sub: [
                    {id: '090200',c: '成都',l: '成都'},
                    {id: '090201',c: '青羊区',l: '成都-青羊区'},
                    {id: '090202',c: '锦江区',l: '成都-锦江区'},
                    {id: '090203',c: '金牛区',l: '成都-金牛区'},
                    {id: '090204',c: '武侯区',l: '成都-武侯区'},
                    {id: '090205',c: '成华区',l: '成都-成华区'},
                    {id: '090206',c: '龙泉驿区',l: '成都-龙泉驿区'},
                    {id: '090207',c: '青白江区',l: '成都-青白江区'},
                    {id: '090208',c: '新都区',l: '成都-新都区'},
                    {id: '090209',c: '温江区',l: '成都-温江区'},
                    {id: '090210',c: '都江堰市',l: '成都-都江堰市'},
                    {id: '090211',c: '彭州市',l: '成都-彭州市'},
                    {id: '090212',c: '邛崃市',l: '成都-邛崃市'},
                    {id: '090213',c: '崇州市',l: '成都-崇州市'},
                    {id: '090214',c: '金堂县',l: '成都-金堂县'},
                    {id: '090215',c: '双流区',l: '成都-双流区'},
                    {id: '090216',c: '郫都区',l: '成都-郫都区'},
                    {id: '090217',c: '大邑县',l: '成都-大邑县'},
                    {id: '090218',c: '蒲江县',l: '成都-蒲江县'},
                    {id: '090219',c: '新津县',l: '成都-新津县'},
                    {id: '090220',c: '高新区',l: '成都-高新区'},
                    {id: '090221',c: '简阳市',l: '成都-简阳市'},
                    {id: '090222',c: '天府新区',l: '成都-天府新区'},
                    {id: '090223',c: '经济技术开发区',l: '成都-经济技术开发区'}
                ]
            },
            {
                id: '090300',
                c: '绵阳',
                l: '绵阳',
                sub: [
                    {id: '090300',c: '绵阳',l: '绵阳'},
                    {id: '090301',c: '涪城区',l: '绵阳-涪城区'},
                    {id: '090302',c: '游仙区',l: '绵阳-游仙区'},
                    {id: '090303',c: '安州区',l: '绵阳-安州区'},
                    {id: '090304',c: '江油市',l: '绵阳-江油市'},
                    {id: '090305',c: '三台县',l: '绵阳-三台县'},
                    {id: '090306',c: '梓潼县',l: '绵阳-梓潼县'},
                    {id: '090307',c: '盐亭县',l: '绵阳-盐亭县'},
                    {id: '090308',c: '平武县',l: '绵阳-平武县'},
                    {id: '090309',c: '北川羌族自治县',l: '绵阳-北川羌族自治县'}
                ]
            },
            {
                id: '090400',
                c: '乐山',
                l: '乐山',
                sub: [
                    {id: '090400',c: '乐山',l: '乐山'}
                ]
            },
            {
                id: '090500',
                c: '泸州',
                l: '泸州',
                sub: [
                    {id: '090500',c: '泸州',l: '泸州'}
                ]
            },
            {
                id: '090600',
                c: '德阳',
                l: '德阳',
                sub: [
                    {id: '090600',c: '德阳',l: '德阳'}
                ]
            },
            {
                id: '090700',
                c: '宜宾',
                l: '宜宾',
                sub: [
                    {id: '090700',c: '宜宾',l: '宜宾'}
                ]
            },
            {
                id: '090800',
                c: '自贡',
                l: '自贡',
                sub: [
                    {id: '090800',c: '自贡',l: '自贡'}
                ]
            },
            {
                id: '090900',
                c: '内江',
                l: '内江',
                sub: [
                    {id: '090900',c: '内江',l: '内江'}
                ]
            },
            {
                id: '091000',
                c: '攀枝花',
                l: '攀枝花',
                sub: [
                    {id: '091000',c: '攀枝花',l: '攀枝花'}
                ]
            },
            {
                id: '091100',
                c: '南充',
                l: '南充',
                sub: [
                    {id: '091100',c: '南充',l: '南充'}
                ]
            },
            {
                id: '091200',
                c: '眉山',
                l: '眉山',
                sub: [
                    {id: '091200',c: '眉山',l: '眉山'}
                ]
            },
            {
                id: '091300',
                c: '广安',
                l: '广安',
                sub: [
                    {id: '091300',c: '广安',l: '广安'}
                ]
            },
            {
                id: '091400',
                c: '资阳',
                l: '资阳',
                sub: [
                    {id: '091400',c: '资阳',l: '资阳'}
                ]
            },
            {
                id: '091500',
                c: '遂宁',
                l: '遂宁',
                sub: [
                    {id: '091500',c: '遂宁',l: '遂宁'}
                ]
            },
            {
                id: '091600',
                c: '广元',
                l: '广元',
                sub: [
                    {id: '091600',c: '广元',l: '广元'}
                ]
            },
            {
                id: '091700',
                c: '达州',
                l: '达州',
                sub: [
                    {id: '091700',c: '达州',l: '达州'}
                ]
            },
            {
                id: '091800',
                c: '雅安',
                l: '雅安',
                sub: [
                    {id: '091800',c: '雅安',l: '雅安'}
                ]
            },
            {
                id: '091900',
                c: '西昌',
                l: '西昌',
                sub: [
                    {id: '091900',c: '西昌',l: '西昌'}
                ]
            },
            {
                id: '092000',
                c: '巴中',
                l: '巴中',
                sub: [
                    {id: '092000',c: '巴中',l: '巴中'}
                ]
            },
            {
                id: '092100',
                c: '甘孜',
                l: '甘孜',
                sub: [
                    {id: '092100',c: '甘孜',l: '甘孜'}
                ]
            },
            {
                id: '092200',
                c: '阿坝',
                l: '阿坝',
                sub: [
                    {id: '092200',c: '阿坝',l: '阿坝'}
                ]
            },
            {
                id: '092300',
                c: '凉山',
                l: '凉山',
                sub: [
                    {id: '092300',c: '凉山',l: '凉山'}
                ]
            }
        ]
    },
    {
        id: '100000',
        c: '海南省',
        l: '海南省',
        sub: [
            {
                id: '100000',
                c: '海南省',
                l: '海南省',
                sub: [{ id: '100000',c: '海南省',l: '海南省'}]
            },
            {
                id: '100200',
                c: '海口',
                l: '海口',
                sub: [
                    {id: '100200',c: '海口',l: '海口'},
                    {id: '100201',c: '龙华区',l: '海口-龙华区'},
                    {id: '100202',c: '秀英区',l: '海口-秀英区'},
                    {id: '100203',c: '琼山区',l: '海口-琼山区'},
                    {id: '100204',c: '美兰区',l: '海口-美兰区'}
                ]
            },
            {
                id: '100300',
                c: '三亚',
                l: '三亚',
                sub: [
                    {id: '100300',c: '三亚',l: '三亚'}
                ]
            },
            {
                id: '100400',
                c: '洋浦经济开发区',
                l: '洋浦经济开发区',
                sub: [
                    {id: '100400',c: '洋浦经济开发区',l: '洋浦经济开发区'}
                ]
            },
            {
                id: '100500',
                c: '文昌',
                l: '文昌',
                sub: [
                    {id: '100500',c: '文昌',l: '文昌'}
                ]
            },
            {
                id: '100600',
                c: '琼海',
                l: '琼海',
                sub: [
                    {id: '100600',c: '琼海',l: '琼海'}
                ]
            },
            {
                id: '100700',
                c: '万宁',
                l: '万宁',
                sub: [
                    {id: '100700',c: '万宁',l: '万宁'}
                ]
            },
            {
                id: '100800',
                c: '儋州',
                l: '儋州',
                sub: [
                    {id: '100800',c: '儋州',l: '儋州'}
                ]
            },
            {
                id: '100900',
                c: '东方',
                l: '东方',
                sub: [
                    {id: '100900',c: '东方',l: '东方'}
                ]
            },
            {
                id: '101000',
                c: '五指山',
                l: '五指山',
                sub: [
                    {id: '101000',c: '五指山',l: '五指山'}
                ]
            },
            {
                id: '101100',
                c: '定安',
                l: '定安',
                sub: [
                    {id: '101100',c: '定安',l: '定安'}
                ]
            },
            {
                id: '101200',
                c: '屯昌',
                l: '屯昌',
                sub: [
                    {id: '101200',c: '屯昌',l: '屯昌'}
                ]
            },
            {
                id: '101300',
                c: '澄迈',
                l: '澄迈',
                sub: [
                    {id: '101300',c: '澄迈',l: '澄迈'}
                ]
            },
            {
                id: '101400',
                c: '临高',
                l: '临高',
                sub: [
                    {id: '101400',c: '临高',l: '临高'}
                ]
            },
            {
                id: '101500',
                c: '三沙',
                l: '三沙',
                sub: [
                    {id: '101500',c: '三沙',l: '三沙'}
                ]
            },
            {
                id: '101600',
                c: '琼中',
                l: '琼中',
                sub: [
                    {id: '101600',c: '琼中',l: '琼中'}
                ]
            },
            {
                id: '101700',
                c: '保亭',
                l: '保亭',
                sub: [
                    {id: '101700',c: '保亭',l: '保亭'}
                ]
            },
            {
                id: '101800',
                c: '白沙',
                l: '白沙',
                sub: [
                    {id: '101800',c: '白沙',l: '白沙'}
                ]
            },
            {
                id: '101900',
                c: '昌江',
                l: '昌江',
                sub: [
                    {id: '101900',c: '昌江',l: '昌江'}
                ]
            },
            {
                id: '102000',
                c: '乐东',
                l: '乐东',
                sub: [
                    {id: '102000',c: '乐东',l: '乐东'}
                ]
            },
            {
                id: '102100',
                c: '陵水',
                l: '陵水',
                sub: [
                    {id: '102100',c: '陵水',l: '陵水'}
                ]
            }
        ]
    },
    {
        id: '110000',
        c: '福建省',
        l: '福建省',
        sub: [
            {
                id: '110000',
                c: '福建省',
                l: '福建省',
                sub: [{ id: '110000',c: '福建省',l: '福建省'}]
            },
            {
                id: '110200',
                c: '福州',
                l: '福州',
                sub: [
                    {id: '110200',c: '福州',l: '福州'},
                    {id: '110201',c: '鼓楼区',l: '福州-鼓楼区'},
                    {id: '110202',c: '台江区',l: '福州-台江区'},
                    {id: '110203',c: '仓山区',l: '福州-仓山区'},
                    {id: '110204',c: '马尾区',l: '福州-马尾区'},
                    {id: '110205',c: '晋安区',l: '福州-晋安区'},
                    {id: '110206',c: '闽侯县',l: '福州-闽侯县'},
                    {id: '110207',c: '连江县',l: '福州-连江县'},
                    {id: '110208',c: '罗源县',l: '福州-罗源县'},
                    {id: '110209',c: '闽清县',l: '福州-闽清县'},
                    {id: '110210',c: '永泰县',l: '福州-永泰县'},
                    {id: '110211',c: '平潭县',l: '福州-平潭县'},
                    {id: '110212',c: '福清市',l: '福州-福清市'},
                    {id: '110213',c: '长乐区',l: '福州-长乐区'}
                ]
            },
            {
                id: '110300',
                c: '厦门',
                l: '厦门',
                sub: [
                    {id: '110300',c: '厦门',l: '厦门'},
                    {id: '110301',c: '思明区',l: '厦门-思明区'},
                    {id: '110302',c: '海沧区',l: '厦门-海沧区'},
                    {id: '110303',c: '湖里区',l: '厦门-湖里区'},
                    {id: '110304',c: '集美区',l: '厦门-集美区'},
                    {id: '110305',c: '同安区',l: '厦门-同安区'},
                    {id: '110306',c: '翔安区',l: '厦门-翔安区'}
                ]
            },
            {
                id: '110400',
                c: '泉州',
                l: '泉州',
                sub: [
                    {id: '110400',c: '泉州',l: '泉州'},
                    {id: '110401',c: '鲤城区',l: '泉州-鲤城区'},
                    {id: '110402',c: '丰泽区',l: '泉州-丰泽区'},
                    {id: '110403',c: '洛江区',l: '泉州-洛江区'},
                    {id: '110404',c: '泉港区',l: '泉州-泉港区'},
                    {id: '110405',c: '惠安县',l: '泉州-惠安县'},
                    {id: '110406',c: '安溪县',l: '泉州-安溪县'},
                    {id: '110407',c: '永春县',l: '泉州-永春县'},
                    {id: '110408',c: '德化县',l: '泉州-德化县'},
                    {id: '110409',c: '金门县',l: '泉州-金门县'},
                    {id: '110410',c: '石狮市',l: '泉州-石狮市'},
                    {id: '110411',c: '晋江市',l: '泉州-晋江市'},
                    {id: '110412',c: '南安市',l: '泉州-南安市'}
                ]
            },
            {
                id: '110500',
                c: '漳州',
                l: '漳州',
                sub: [
                    {id: '110500',c: '漳州',l: '漳州'}
                ]
            },
            {
                id: '110600',
                c: '莆田',
                l: '莆田',
                sub: [
                    {id: '110600',c: '莆田',l: '莆田'}
                ]
            },
            {
                id: '110700',
                c: '三明',
                l: '三明',
                sub: [
                    {id: '110700',c: '三明',l: '三明'}
                ]
            },
            {
                id: '110800',
                c: '南平',
                l: '南平',
                sub: [
                    {id: '110800',c: '南平',l: '南平'}
                ]
            },
            {
                id: '110900',
                c: '宁德',
                l: '宁德',
                sub: [
                    {id: '110900',c: '宁德',l: '宁德'}
                ]
            },
            {
                id: '111000',
                c: '龙岩',
                l: '龙岩',
                sub: [
                    {id: '111000',c: '龙岩',l: '龙岩'}
                ]
            }
        ]
    },
    {
        id: '120000',
        c: '山东省',
        l: '山东省',
        sub: [
            {
                id: '120000',
                c: '山东省',
                l: '山东省',
                sub: [{ id: '120000',c: '山东省',l: '山东省'}]
            },
            {
                id: '120200',
                c: '济南',
                l: '济南',
                sub: [
                    {id: '120200',c: '济南',l: '济南'},
                    {id: '120201',c: '历下区',l: '济南-历下区'},
                    {id: '120202',c: '市中区',l: '济南-市中区'},
                    {id: '120203',c: '槐荫区',l: '济南-槐荫区'},
                    {id: '120204',c: '天桥区',l: '济南-天桥区'},
                    {id: '120205',c: '历城区',l: '济南-历城区'},
                    {id: '120206',c: '长清区',l: '济南-长清区'},
                    {id: '120207',c: '平阴县',l: '济南-平阴县'},
                    {id: '120208',c: '济阳区',l: '济南-济阳区'},
                    {id: '120209',c: '商河县',l: '济南-商河县'},
                    {id: '120210',c: '章丘区',l: '济南-章丘区'},
                    {id: '120211',c: '高新区',l: '济南-高新区'},
                    {id: '120212',c: '莱芜区',l: '济南-莱芜区'},
                    {id: '120213',c: '钢城区',l: '济南-钢城区'}
                ]
            },
            {
                id: '120300',
                c: '青岛',
                l: '青岛',
                sub: [
                    {id: '120300',c: '青岛',l: '青岛'},
                    {id: '120301',c: '市南区',l: '青岛-市南区'},
                    {id: '120302',c: '市北区',l: '青岛-市北区'},
                    {id: '120303',c: '黄岛区',l: '青岛-黄岛区'},
                    {id: '120304',c: '崂山区',l: '青岛-崂山区'},
                    {id: '120305',c: '城阳区',l: '青岛-城阳区'},
                    {id: '120306',c: '李沧区',l: '青岛-李沧区'},
                    {id: '120307',c: '胶州市',l: '青岛-胶州市'},
                    {id: '120308',c: '即墨区',l: '青岛-即墨区'},
                    {id: '120309',c: '平度市',l: '青岛-平度市'},
                    {id: '120310',c: '莱西市',l: '青岛-莱西市'},
                    {id: '120311',c: '高新区',l: '青岛-高新区'}
                ]
            },
            {
                id: '120400',
                c: '烟台',
                l: '烟台',
                sub: [
                    {id: '120400',c: '烟台',l: '烟台'},
                    {id: '120401',c: '芝罘区',l: '烟台-芝罘区'},
                    {id: '120402',c: '福山区',l: '烟台-福山区'},
                    {id: '120403',c: '牟平区',l: '烟台-牟平区'},
                    {id: '120404',c: '莱山区',l: '烟台-莱山区'},
                    {id: '120405',c: '长岛县',l: '烟台-长岛县'},
                    {id: '120406',c: '龙口市',l: '烟台-龙口市'},
                    {id: '120407',c: '莱阳市',l: '烟台-莱阳市'},
                    {id: '120408',c: '莱州市',l: '烟台-莱州市'},
                    {id: '120409',c: '蓬莱市',l: '烟台-蓬莱市'},
                    {id: '120410',c: '招远市',l: '烟台-招远市'},
                    {id: '120411',c: '栖霞市',l: '烟台-栖霞市'},
                    {id: '120412',c: '海阳市',l: '烟台-海阳市'},
                    {id: '120413',c: '高新区',l: '烟台-高新区'},
                    {id: '120414',c: '开发区',l: '烟台-开发区'}
                ]
            },
            {
                id: '120500',
                c: '潍坊',
                l: '潍坊',
                sub: [
                    {id: '120500',c: '潍坊',l: '潍坊'},
                    {id: '120501',c: '潍城区',l: '潍坊-潍城区'},
                    {id: '120502',c: '寒亭区',l: '潍坊-寒亭区'},
                    {id: '120503',c: '坊子区',l: '潍坊-坊子区'},
                    {id: '120504',c: '奎文区',l: '潍坊-奎文区'},
                    {id: '120505',c: '临朐县',l: '潍坊-临朐县'},
                    {id: '120506',c: '昌乐县',l: '潍坊-昌乐县'},
                    {id: '120507',c: '青州市',l: '潍坊-青州市'},
                    {id: '120508',c: '诸城市',l: '潍坊-诸城市'},
                    {id: '120509',c: '寿光市',l: '潍坊-寿光市'},
                    {id: '120510',c: '安丘市',l: '潍坊-安丘市'},
                    {id: '120511',c: '高密市',l: '潍坊-高密市'},
                    {id: '120512',c: '昌邑市',l: '潍坊-昌邑市'},
                    {id: '120513',c: '高新技术开发区',l: '潍坊-高新技术开发区'},
                    {id: '120514',c: '滨海经济开发区',l: '潍坊-滨海经济开发区'},
                    {id: '120515',c: '峡山生态经济发展区',l: '潍坊-峡山生态经济发展区'},
                    {id: '120516',c: '潍坊综合保税区',l: '潍坊-潍坊综合保税区'}
                ]
            },
            {
                id: '120600',
                c: '威海',
                l: '威海',
                sub: [
                    {id: '120600',c: '威海',l: '威海'}
                ]
            },
            {
                id: '120700',
                c: '淄博',
                l: '淄博',
                sub: [
                    {id: '120700',c: '淄博',l: '淄博'}
                ]
            },
            {
                id: '120800',
                c: '临沂',
                l: '临沂',
                sub: [
                    {id: '120800',c: '临沂',l: '临沂'},
                    {id: '120801',c: '兰山区',l: '临沂-兰山区'},
                    {id: '120802',c: '罗庄区',l: '临沂-罗庄区'},
                    {id: '120803',c: '河东区',l: '临沂-河东区'},
                    {id: '120804',c: '沂南县',l: '临沂-沂南县'},
                    {id: '120805',c: '郯城县',l: '临沂-郯城县'},
                    {id: '120806',c: '沂水县',l: '临沂-沂水县'},
                    {id: '120807',c: '兰陵县',l: '临沂-兰陵县'},
                    {id: '120808',c: '费县',l: '临沂-费县'},
                    {id: '120809',c: '平邑县',l: '临沂-平邑县'},
                    {id: '120810',c: '莒南县',l: '临沂-莒南县'},
                    {id: '120811',c: '蒙阴县',l: '临沂-蒙阴县'},
                    {id: '120812',c: '临沭县',l: '临沂-临沭县'}
                ]
            },
            {
                id: '120900',
                c: '济宁',
                l: '济宁',
                sub: [
                    {id: '120900',c: '济宁',l: '济宁'}
                ]
            },
            {
                id: '121000',
                c: '东营',
                l: '东营',
                sub: [
                    {id: '121000',c: '东营',l: '东营'}
                ]
            },
            {
                id: '121100',
                c: '泰安',
                l: '泰安',
                sub: [
                    {id: '121100',c: '泰安',l: '泰安'}
                ]
            },
            {
                id: '121200',
                c: '日照',
                l: '日照',
                sub: [
                    {id: '121200',c: '日照',l: '日照'}
                ]
            },
            {
                id: '121300',
                c: '德州',
                l: '德州',
                sub: [
                    {id: '121300',c: '德州',l: '德州'}
                ]
            },
            {
                id: '121400',
                c: '菏泽',
                l: '菏泽',
                sub: [
                    {id: '121400',c: '菏泽',l: '菏泽'}
                ]
            },
            {
                id: '121500',
                c: '滨州',
                l: '滨州',
                sub: [
                    {id: '121500',c: '滨州',l: '滨州'}
                ]
            },
            {
                id: '121600',
                c: '枣庄',
                l: '枣庄',
                sub: [
                    {id: '121600',c: '枣庄',l: '枣庄'}
                ]
            },
            {
                id: '121700',
                c: '聊城',
                l: '聊城',
                sub: [
                    {id: '121700',c: '聊城',l: '聊城'}
                ]
            }
        ]
    },
    {
        id: '130000',
        c: '江西省',
        l: '江西省',
        sub: [
            {
                id: '130000',
                c: '江西省',
                l: '江西省',
                sub: [{ id: '130000',c: '江西省',l: '江西省'}]
            },
            {
                id: '130200',
                c: '南昌',
                l: '南昌',
                sub: [
                    {id: '130200',c: '南昌',l: '南昌'},
                    {id: '130201',c: '东湖区',l: '南昌-东湖区'},
                    {id: '130202',c: '西湖区',l: '南昌-西湖区'},
                    {id: '130203',c: '青云谱区',l: '南昌-青云谱区'},
                    {id: '130205',c: '青山湖区',l: '南昌-青山湖区'},
                    {id: '130206',c: '南昌县',l: '南昌-南昌县'},
                    {id: '130207',c: '新建区',l: '南昌-新建区'},
                    {id: '130208',c: '安义县',l: '南昌-安义县'},
                    {id: '130209',c: '进贤县',l: '南昌-进贤县'},
                    {id: '130210',c: '红谷滩新区',l: '南昌-红谷滩新区'}
                ]
            },
            {
                id: '130300',
                c: '九江',
                l: '九江',
                sub: [
                    {id: '130300',c: '九江',l: '九江'}
                ]
            },
            {
                id: '130400',
                c: '景德镇',
                l: '景德镇',
                sub: [
                    {id: '130400',c: '景德镇',l: '景德镇'}
                ]
            },
            {
                id: '130500',
                c: '萍乡',
                l: '萍乡',
                sub: [
                    {id: '130500',c: '萍乡',l: '萍乡'}
                ]
            },
            {
                id: '130600',
                c: '新余',
                l: '新余',
                sub: [
                    {id: '130600',c: '新余',l: '新余'}
                ]
            },
            {
                id: '130700',
                c: '鹰潭',
                l: '鹰潭',
                sub: [
                    {id: '130700',c: '鹰潭',l: '鹰潭'}
                ]
            },
            {
                id: '130800',
                c: '赣州',
                l: '赣州',
                sub: [
                    {id: '130800',c: '赣州',l: '赣州'},
                    {id: '130801',c: '章贡区',l: '赣州-章贡区'},
                    {id: '130802',c: '赣县区',l: '赣州-赣县区'},
                    {id: '130803',c: '南康区',l: '赣州-南康区'},
                    {id: '130804',c: '信丰县',l: '赣州-信丰县'},
                    {id: '130805',c: '大余县',l: '赣州-大余县'},
                    {id: '130806',c: '上犹县',l: '赣州-上犹县'},
                    {id: '130807',c: '崇义县',l: '赣州-崇义县'},
                    {id: '130808',c: '安远县',l: '赣州-安远县'},
                    {id: '130809',c: '龙南县',l: '赣州-龙南县'},
                    {id: '130810',c: '定南县',l: '赣州-定南县'},
                    {id: '130811',c: '全南县',l: '赣州-全南县'},
                    {id: '130812',c: '宁都县',l: '赣州-宁都县'},
                    {id: '130813',c: '于都县',l: '赣州-于都县'},
                    {id: '130814',c: '兴国县',l: '赣州-兴国县'},
                    {id: '130815',c: '会昌县',l: '赣州-会昌县'},
                    {id: '130816',c: '寻乌县',l: '赣州-寻乌县'},
                    {id: '130817',c: '石城县',l: '赣州-石城县'},
                    {id: '130818',c: '瑞金市',l: '赣州-瑞金市'}
                ]
            },
            {
                id: '130900',
                c: '吉安',
                l: '吉安',
                sub: [
                    {id: '130900',c: '吉安',l: '吉安'}
                ]
            },
            {
                id: '131000',
                c: '宜春',
                l: '宜春',
                sub: [
                    {id: '131000',c: '宜春',l: '宜春'}
                ]
            },
            {
                id: '131100',
                c: '抚州',
                l: '抚州',
                sub: [
                    {id: '131100',c: '抚州',l: '抚州'}
                ]
            },
            {
                id: '131200',
                c: '上饶',
                l: '上饶',
                sub: [
                    {id: '131200',c: '上饶',l: '上饶'}
                ]
            }
        ]
    },
    {
        id: '140000',
        c: '广西',
        l: '广西',
        sub: [
            {
                id: '140000',
                c: '广西',
                l: '广西',
                sub: [{ id: '140000',c: '广西',l: '广西'}]
            },
            {
                id: '140200',
                c: '南宁',
                l: '南宁',
                sub: [
                    {id: '140200',c: '南宁',l: '南宁'},
                    {id: '140201',c: '兴宁区',l: '南宁-兴宁区'},
                    {id: '140202',c: '青秀区',l: '南宁-青秀区'},
                    {id: '140203',c: '西乡塘区',l: '南宁-西乡塘区'},
                    {id: '140204',c: '江南区',l: '南宁-江南区'},
                    {id: '140205',c: '良庆区',l: '南宁-良庆区'},
                    {id: '140206',c: '邕宁区',l: '南宁-邕宁区'},
                    {id: '140207',c: '武鸣区',l: '南宁-武鸣区'},
                    {id: '140208',c: '隆安县',l: '南宁-隆安县'},
                    {id: '140209',c: '马山县',l: '南宁-马山县'},
                    {id: '140210',c: '上林县',l: '南宁-上林县'},
                    {id: '140211',c: '宾阳县',l: '南宁-宾阳县'},
                    {id: '140212',c: '横县',l: '南宁-横县'}
                ]
            },
            {
                id: '140300',
                c: '桂林',
                l: '桂林',
                sub: [
                    {id: '140300',c: '桂林',l: '桂林'}
                ]
            },
            {
                id: '140400',
                c: '柳州',
                l: '柳州',
                sub: [
                    {id: '140400',c: '柳州',l: '柳州'},
                    {id: '140401',c: '城中区',l: '柳州-城中区'},
                    {id: '140402',c: '鱼峰区',l: '柳州-鱼峰区'},
                    {id: '140403',c: '柳南区',l: '柳州-柳南区'},
                    {id: '140404',c: '柳北区',l: '柳州-柳北区'},
                    {id: '140405',c: '柳江区',l: '柳州-柳江区'},
                    {id: '140406',c: '柳城县',l: '柳州-柳城县'},
                    {id: '140407',c: '鹿寨县',l: '柳州-鹿寨县'},
                    {id: '140408',c: '融安县',l: '柳州-融安县'},
                    {id: '140409',c: '融水苗族自治县',l: '柳州-融水苗族自治县'},
                    {id: '140410',c: '三江侗族自治县',l: '柳州-三江侗族自治县'}
                ]
            },
            {
                id: '140500',
                c: '北海',
                l: '北海',
                sub: [
                    {id: '140500',c: '北海',l: '北海'}
                ]
            },
            {
                id: '140600',
                c: '玉林',
                l: '玉林',
                sub: [
                    {id: '140600',c: '玉林',l: '玉林'}
                ]
            },
            {
                id: '140700',
                c: '梧州',
                l: '梧州',
                sub: [
                    {id: '140700',c: '梧州',l: '梧州'}
                ]
            },
            {
                id: '140800',
                c: '防城港',
                l: '防城港',
                sub: [
                    {id: '140800',c: '防城港',l: '防城港'}
                ]
            },
            {
                id: '140900',
                c: '钦州',
                l: '钦州',
                sub: [
                    {id: '140900',c: '钦州',l: '钦州'}
                ]
            },
            {
                id: '141000',
                c: '贵港',
                l: '贵港',
                sub: [
                    {id: '141000',c: '贵港',l: '贵港'}
                ]
            },
            {
                id: '141100',
                c: '百色',
                l: '百色',
                sub: [
                    {id: '141100',c: '百色',l: '百色'}
                ]
            },
            {
                id: '141200',
                c: '河池',
                l: '河池',
                sub: [
                    {id: '141200',c: '河池',l: '河池'}
                ]
            },
            {
                id: '141300',
                c: '来宾',
                l: '来宾',
                sub: [
                    {id: '141300',c: '来宾',l: '来宾'}
                ]
            },
            {
                id: '141400',
                c: '崇左',
                l: '崇左',
                sub: [
                    {id: '141400',c: '崇左',l: '崇左'}
                ]
            },
            {
                id: '141500',
                c: '贺州',
                l: '贺州',
                sub: [
                    {id: '141500',c: '贺州',l: '贺州'}
                ]
            }
        ]
    },
    {
        id: '150000',
        c: '安徽省',
        l: '安徽省',
        sub: [
            {
                id: '150000',
                c: '安徽省',
                l: '安徽省',
                sub: [{ id: '150000',c: '安徽省',l: '安徽省'}]
            },
            {
                id: '150200',
                c: '合肥',
                l: '合肥',
                sub: [
                    {id: '150200',c: '合肥',l: '合肥'},
                    {id: '150201',c: '瑶海区',l: '合肥-瑶海区'},
                    {id: '150202',c: '庐阳区',l: '合肥-庐阳区'},
                    {id: '150203',c: '蜀山区',l: '合肥-蜀山区'},
                    {id: '150204',c: '包河区',l: '合肥-包河区'},
                    {id: '150205',c: '经开区',l: '合肥-经开区'},
                    {id: '150206',c: '滨湖新区',l: '合肥-滨湖新区'},
                    {id: '150207',c: '新站区',l: '合肥-新站区'},
                    {id: '150208',c: '高新区',l: '合肥-高新区'},
                    {id: '150209',c: '政务区',l: '合肥-政务区'},
                    {id: '150210',c: '北城新区',l: '合肥-北城新区'},
                    {id: '150211',c: '巢湖市',l: '合肥-巢湖市'},
                    {id: '150212',c: '长丰县',l: '合肥-长丰县'},
                    {id: '150213',c: '肥东县',l: '合肥-肥东县'},
                    {id: '150214',c: '肥西县',l: '合肥-肥西县'},
                    {id: '150215',c: '庐江县',l: '合肥-庐江县'}
                ]
            },
            {
                id: '150300',
                c: '芜湖',
                l: '芜湖',
                sub: [
                    {id: '150300',c: '芜湖',l: '芜湖'},
                    {id: '150301',c: '镜湖区',l: '芜湖-镜湖区'},
                    {id: '150302',c: '弋江区',l: '芜湖-弋江区'},
                    {id: '150303',c: '三山区',l: '芜湖-三山区'},
                    {id: '150304',c: '鸠江区',l: '芜湖-鸠江区'},
                    {id: '150305',c: '芜湖县',l: '芜湖-芜湖县'},
                    {id: '150306',c: '繁昌县',l: '芜湖-繁昌县'},
                    {id: '150307',c: '南陵县',l: '芜湖-南陵县'},
                    {id: '150308',c: '无为市',l: '芜湖-无为市'}
                ]
            },
            {
                id: '150400',
                c: '安庆',
                l: '安庆',
                sub: [
                    {id: '150400',c: '安庆',l: '安庆'}
                ]
            },
            {
                id: '150500',
                c: '马鞍山',
                l: '马鞍山',
                sub: [
                    {id: '150500',c: '马鞍山',l: '马鞍山'}
                ]
            },
            {
                id: '150600',
                c: '蚌埠',
                l: '蚌埠',
                sub: [
                    {id: '150600',c: '蚌埠',l: '蚌埠'}
                ]
            },
            {
                id: '150700',
                c: '阜阳',
                l: '阜阳',
                sub: [
                    {id: '150700',c: '阜阳',l: '阜阳'}
                ]
            },
            {
                id: '150800',
                c: '铜陵',
                l: '铜陵',
                sub: [
                    {id: '150800',c: '铜陵',l: '铜陵'}
                ]
            },
            {
                id: '150900',
                c: '滁州',
                l: '滁州',
                sub: [
                    {id: '150900',c: '滁州',l: '滁州'}
                ]
            },
            {
                id: '151000',
                c: '黄山',
                l: '黄山',
                sub: [
                    {id: '151000',c: '黄山',l: '黄山'}
                ]
            },
            {
                id: '151100',
                c: '淮南',
                l: '淮南',
                sub: [
                    {id: '151100',c: '淮南',l: '淮南'}
                ]
            },
            {
                id: '151200',
                c: '六安',
                l: '六安',
                sub: [
                    {id: '151200',c: '六安',l: '六安'}
                ]
            },
            {
                id: '151400',
                c: '宣城',
                l: '宣城',
                sub: [
                    {id: '151400',c: '宣城',l: '宣城'}
                ]
            },
            {
                id: '151500',
                c: '池州',
                l: '池州',
                sub: [
                    {id: '151500',c: '池州',l: '池州'}
                ]
            },
            {
                id: '151600',
                c: '宿州',
                l: '宿州',
                sub: [
                    {id: '151600',c: '宿州',l: '宿州'}
                ]
            },
            {
                id: '151700',
                c: '淮北',
                l: '淮北',
                sub: [
                    {id: '151700',c: '淮北',l: '淮北'}
                ]
            },
            {
                id: '151800',
                c: '亳州',
                l: '亳州',
                sub: [
                    {id: '151800',c: '亳州',l: '亳州'}
                ]
            }
        ]
    },
    {
        id: '160000',
        c: '河北省',
        l: '河北省',
        sub: [
            {
                id: '160000',
                c: '河北省',
                l: '河北省',
                sub: [{ id: '160000',c: '河北省',l: '河北省'}]
            },
            {
                id: '160100',
                c: '雄安新区',
                l: '雄安新区',
                sub: [
                    {id: '160100',c: '雄安新区',l: '雄安新区'}
                ]
            },
            {
                id: '160200',
                c: '石家庄',
                l: '石家庄',
                sub: [
                    {id: '160200',c: '石家庄',l: '石家庄'},
                    {id: '160201',c: '长安区',l: '石家庄-长安区'},
                    {id: '160202',c: '裕华区',l: '石家庄-裕华区'},
                    {id: '160203',c: '桥西区',l: '石家庄-桥西区'},
                    {id: '160204',c: '新华区',l: '石家庄-新华区'},
                    {id: '160205',c: '藁城区',l: '石家庄-藁城区'},
                    {id: '160206',c: '鹿泉区',l: '石家庄-鹿泉区'},
                    {id: '160207',c: '栾城区',l: '石家庄-栾城区'},
                    {id: '160208',c: '东开发区',l: '石家庄-东开发区'},
                    {id: '160209',c: '井陉矿区',l: '石家庄-井陉矿区'},
                    {id: '160210',c: '井陉县',l: '石家庄-井陉县'},
                    {id: '160211',c: '正定县',l: '石家庄-正定县'},
                    {id: '160212',c: '行唐县',l: '石家庄-行唐县'},
                    {id: '160213',c: '灵寿县',l: '石家庄-灵寿县'},
                    {id: '160214',c: '高邑县',l: '石家庄-高邑县'},
                    {id: '160215',c: '深泽县',l: '石家庄-深泽县'},
                    {id: '160216',c: '赞皇县',l: '石家庄-赞皇县'},
                    {id: '160217',c: '无极县',l: '石家庄-无极县'},
                    {id: '160218',c: '平山县',l: '石家庄-平山县'},
                    {id: '160219',c: '元氏县',l: '石家庄-元氏县'},
                    {id: '160220',c: '赵县',l: '石家庄-赵县'},
                    {id: '160221',c: '辛集市',l: '石家庄-辛集市'},
                    {id: '160222',c: '晋州市',l: '石家庄-晋州市'},
                    {id: '160223',c: '新乐市',l: '石家庄-新乐市'}
                ]
            },
            {
                id: '160300',
                c: '廊坊',
                l: '廊坊',
                sub: [
                    {id: '160300',c: '廊坊',l: '廊坊'}
                ]
            },
            {
                id: '160400',
                c: '保定',
                l: '保定',
                sub: [
                    {id: '160400',c: '保定',l: '保定'}
                ]
            },
            {
                id: '160500',
                c: '唐山',
                l: '唐山',
                sub: [
                    {id: '160500',c: '唐山',l: '唐山'}
                ]
            },
            {
                id: '160600',
                c: '秦皇岛',
                l: '秦皇岛',
                sub: [
                    {id: '160600',c: '秦皇岛',l: '秦皇岛'}
                ]
            },
            {
                id: '160700',
                c: '邯郸',
                l: '邯郸',
                sub: [
                    {id: '160700',c: '邯郸',l: '邯郸'}
                ]
            },
            {
                id: '160800',
                c: '沧州',
                l: '沧州',
                sub: [
                    {id: '160800',c: '沧州',l: '沧州'}
                ]
            },
            {
                id: '160900',
                c: '张家口',
                l: '张家口',
                sub: [
                    {id: '160900',c: '张家口',l: '张家口'}
                ]
            },
            {
                id: '161000',
                c: '承德',
                l: '承德',
                sub: [
                    {id: '161000',c: '承德',l: '承德'}
                ]
            },
            {
                id: '161100',
                c: '邢台',
                l: '邢台',
                sub: [
                    {id: '161100',c: '邢台',l: '邢台'}
                ]
            },
            {
                id: '161200',
                c: '衡水',
                l: '衡水',
                sub: [
                    {id: '161200',c: '衡水',l: '衡水'}
                ]
            },
            {
                id: '161300',
                c: '燕郊开发区',
                l: '燕郊开发区',
                sub: [
                    {id: '161300',c: '燕郊开发区',l: '燕郊开发区'}
                ]
            }
        ]
    },
    {
        id: '170000',
        c: '河南省',
        l: '河南省',
        sub: [
            {
                id: '170000',
                c: '河南省',
                l: '河南省',
                sub: [{ id: '170000',c: '河南省',l: '河南省'}]
            },
            {
                id: '170200',
                c: '郑州',
                l: '郑州',
                sub: [
                    {id: '170200',c: '郑州',l: '郑州'},
                    {id: '170201',c: '中原区',l: '郑州-中原区'},
                    {id: '170202',c: '二七区',l: '郑州-二七区'},
                    {id: '170203',c: '管城回族区',l: '郑州-管城回族区'},
                    {id: '170204',c: '金水区',l: '郑州-金水区'},
                    {id: '170205',c: '上街区',l: '郑州-上街区'},
                    {id: '170206',c: '惠济区',l: '郑州-惠济区'},
                    {id: '170207',c: '中牟县',l: '郑州-中牟县'},
                    {id: '170208',c: '巩义市',l: '郑州-巩义市'},
                    {id: '170209',c: '荥阳市',l: '郑州-荥阳市'},
                    {id: '170210',c: '新密市',l: '郑州-新密市'},
                    {id: '170211',c: '新郑市',l: '郑州-新郑市'},
                    {id: '170212',c: '登封市',l: '郑州-登封市'},
                    {id: '170213',c: '郑东新区',l: '郑州-郑东新区'},
                    {id: '170214',c: '高新区',l: '郑州-高新区'},
                    {id: '170215',c: '经开区',l: '郑州-经开区'},
                    {id: '170216',c: '郑州航空港区',l: '郑州-郑州航空港区'}
                ]
            },
            {
                id: '170300',
                c: '洛阳',
                l: '洛阳',
                sub: [
                    {id: '170300',c: '洛阳',l: '洛阳'},
                    {id: '170301',c: '老城区',l: '洛阳-老城区'},
                    {id: '170302',c: '西工区',l: '洛阳-西工区'},
                    {id: '170303',c: '廛河回族区',l: '洛阳-廛河回族区'},
                    {id: '170304',c: '涧西区',l: '洛阳-涧西区'},
                    {id: '170305',c: '吉利区',l: '洛阳-吉利区'},
                    {id: '170306',c: '洛龙区',l: '洛阳-洛龙区'},
                    {id: '170307',c: '伊滨区',l: '洛阳-伊滨区'},
                    {id: '170308',c: '高新区',l: '洛阳-高新区'},
                    {id: '170309',c: '孟津县',l: '洛阳-孟津县'},
                    {id: '170310',c: '新安县',l: '洛阳-新安县'},
                    {id: '170311',c: '栾川县',l: '洛阳-栾川县'},
                    {id: '170312',c: '嵩县',l: '洛阳-嵩县'},
                    {id: '170313',c: '汝阳县',l: '洛阳-汝阳县'},
                    {id: '170314',c: '宜阳县',l: '洛阳-宜阳县'},
                    {id: '170315',c: '洛宁县',l: '洛阳-洛宁县'},
                    {id: '170316',c: '伊川县',l: '洛阳-伊川县'},
                    {id: '170317',c: '偃师市',l: '洛阳-偃师市'}
                ]
            },
            {
                id: '170400',
                c: '开封',
                l: '开封',
                sub: [
                    {id: '170400',c: '开封',l: '开封'}
                ]
            },
            {
                id: '170500',
                c: '焦作',
                l: '焦作',
                sub: [
                    {id: '170500',c: '焦作',l: '焦作'}
                ]
            },
            {
                id: '170600',
                c: '南阳',
                l: '南阳',
                sub: [
                    {id: '170600',c: '南阳',l: '南阳'}
                ]
            },
            {
                id: '170700',
                c: '新乡',
                l: '新乡',
                sub: [
                    {id: '170700',c: '新乡',l: '新乡'}
                ]
            },
            {
                id: '170800',
                c: '周口',
                l: '周口',
                sub: [
                    {id: '170800',c: '周口',l: '周口'}
                ]
            },
            {
                id: '170900',
                c: '安阳',
                l: '安阳',
                sub: [
                    {id: '170900',c: '安阳',l: '安阳'}
                ]
            },
            {
                id: '171000',
                c: '平顶山',
                l: '平顶山',
                sub: [
                    {id: '171000',c: '平顶山',l: '平顶山'}
                ]
            },
            {
                id: '171100',
                c: '许昌',
                l: '许昌',
                sub: [
                    {id: '171100',c: '许昌',l: '许昌'}
                ]
            },
            {
                id: '171200',
                c: '信阳',
                l: '信阳',
                sub: [
                    {id: '171200',c: '信阳',l: '信阳'}
                ]
            },
            {
                id: '171300',
                c: '商丘',
                l: '商丘',
                sub: [
                    {id: '171300',c: '商丘',l: '商丘'}
                ]
            },
            {
                id: '171400',
                c: '驻马店',
                l: '驻马店',
                sub: [
                    {id: '171400',c: '驻马店',l: '驻马店'}
                ]
            },
            {
                id: '171500',
                c: '漯河',
                l: '漯河',
                sub: [
                    {id: '171500',c: '漯河',l: '漯河'}
                ]
            },
            {
                id: '171600',
                c: '濮阳',
                l: '濮阳',
                sub: [
                    {id: '171600',c: '濮阳',l: '濮阳'}
                ]
            },
            {
                id: '171700',
                c: '鹤壁',
                l: '鹤壁',
                sub: [
                    {id: '171700',c: '鹤壁',l: '鹤壁'}
                ]
            },
            {
                id: '171800',
                c: '三门峡',
                l: '三门峡',
                sub: [
                    {id: '171800',c: '三门峡',l: '三门峡'}
                ]
            },
            {
                id: '171900',
                c: '济源',
                l: '济源',
                sub: [
                    {id: '171900',c: '济源',l: '济源'}
                ]
            },
            {
                id: '172000',
                c: '邓州',
                l: '邓州',
                sub: [
                    {id: '172000',c: '邓州',l: '邓州'}
                ]
            }
        ]
    },
    {
        id: '180000',
        c: '湖北省',
        l: '湖北省',
        sub: [
            {
                id: '180000',
                c: '湖北省',
                l: '湖北省',
                sub: [{ id: '180000',c: '湖北省',l: '湖北省'}]
            },
            {
                id: '180200',
                c: '武汉',
                l: '武汉',
                sub: [
                    {id: '180200',c: '武汉',l: '武汉'},
                    {id: '180201',c: '江岸区',l: '武汉-江岸区'},
                    {id: '180202',c: '江汉区',l: '武汉-江汉区'},
                    {id: '180203',c: '硚口区',l: '武汉-硚口区'},
                    {id: '180204',c: '汉阳区',l: '武汉-汉阳区'},
                    {id: '180205',c: '武昌区',l: '武汉-武昌区'},
                    {id: '180206',c: '青山区',l: '武汉-青山区'},
                    {id: '180207',c: '洪山区',l: '武汉-洪山区'},
                    {id: '180208',c: '东西湖区',l: '武汉-东西湖区'},
                    {id: '180209',c: '汉南区',l: '武汉-汉南区'},
                    {id: '180210',c: '蔡甸区',l: '武汉-蔡甸区'},
                    {id: '180211',c: '江夏区',l: '武汉-江夏区'},
                    {id: '180212',c: '黄陂区',l: '武汉-黄陂区'},
                    {id: '180213',c: '新洲区',l: '武汉-新洲区'},
                    {id: '180214',c: '武汉经济开发区',l: '武汉-武汉经济开发区'},
                    {id: '180215',c: '东湖新技术产业开发区',l: '武汉-东湖新技术产业开发区'}
                ]
            },
            {
                id: '180300',
                c: '宜昌',
                l: '宜昌',
                sub: [
                    {id: '180300',c: '宜昌',l: '宜昌'},
                    {id: '180301',c: '西陵区',l: '宜昌-西陵区'},
                    {id: '180302',c: '伍家岗区',l: '宜昌-伍家岗区'},
                    {id: '180303',c: '点军区',l: '宜昌-点军区'},
                    {id: '180304',c: '猇亭区',l: '宜昌-猇亭区'},
                    {id: '180305',c: '夷陵区',l: '宜昌-夷陵区'},
                    {id: '180306',c: '远安县',l: '宜昌-远安县'},
                    {id: '180307',c: '兴山县',l: '宜昌-兴山县'},
                    {id: '180308',c: '秭归县',l: '宜昌-秭归县'},
                    {id: '180309',c: '长阳土家族自治县',l: '宜昌-长阳土家族自治县'},
                    {id: '180310',c: '五峰土家族自治县',l: '宜昌-五峰土家族自治县'},
                    {id: '180311',c: '宜都市',l: '宜昌-宜都市'},
                    {id: '180312',c: '当阳市',l: '宜昌-当阳市'},
                    {id: '180313',c: '枝江市',l: '宜昌-枝江市'}
                ]
            },
            {
                id: '180400',
                c: '黄石',
                l: '黄石',
                sub: [
                    {id: '180400',c: '黄石',l: '黄石'}
                ]
            },
            {
                id: '180500',
                c: '襄阳',
                l: '襄阳',
                sub: [
                    {id: '180500',c: '襄阳',l: '襄阳'},
                    {id: '180501',c: '襄城区',l: '襄阳-襄城区'},
                    {id: '180502',c: '樊城区',l: '襄阳-樊城区'},
                    {id: '180503',c: '襄州区',l: '襄阳-襄州区'},
                    {id: '180504',c: '南漳县',l: '襄阳-南漳县'},
                    {id: '180505',c: '谷城县',l: '襄阳-谷城县'},
                    {id: '180506',c: '保康县',l: '襄阳-保康县'},
                    {id: '180507',c: '老河口市',l: '襄阳-老河口市'},
                    {id: '180508',c: '枣阳市',l: '襄阳-枣阳市'},
                    {id: '180509',c: '宜城市',l: '襄阳-宜城市'}
                ]
            },
            {
                id: '180600',
                c: '十堰',
                l: '十堰',
                sub: [
                    {id: '180600',c: '十堰',l: '十堰'}
                ]
            },
            {
                id: '180700',
                c: '荆州',
                l: '荆州',
                sub: [
                    {id: '180700',c: '荆州',l: '荆州'},
                    {id: '180701',c: '沙市区',l: '荆州-沙市区'},
                    {id: '180702',c: '荆州区',l: '荆州-荆州区'},
                    {id: '180703',c: '公安县',l: '荆州-公安县'},
                    {id: '180704',c: '监利县',l: '荆州-监利县'},
                    {id: '180705',c: '江陵县',l: '荆州-江陵县'},
                    {id: '180706',c: '石首市',l: '荆州-石首市'},
                    {id: '180707',c: '洪湖市',l: '荆州-洪湖市'},
                    {id: '180708',c: '松滋市',l: '荆州-松滋市'}
                ]
            },
            {
                id: '180800',
                c: '荆门',
                l: '荆门',
                sub: [
                    {id: '180800',c: '荆门',l: '荆门'}
                ]
            },
            {
                id: '180900',
                c: '孝感',
                l: '孝感',
                sub: [
                    {id: '180900',c: '孝感',l: '孝感'}
                ]
            },
            {
                id: '181000',
                c: '鄂州',
                l: '鄂州',
                sub: [
                    {id: '181000',c: '鄂州',l: '鄂州'}
                ]
            },
            {
                id: '181100',
                c: '黄冈',
                l: '黄冈',
                sub: [
                    {id: '181100',c: '黄冈',l: '黄冈'}
                ]
            },
            {
                id: '181200',
                c: '随州',
                l: '随州',
                sub: [
                    {id: '181200',c: '随州',l: '随州'}
                ]
            },
            {
                id: '181300',
                c: '咸宁',
                l: '咸宁',
                sub: [
                    {id: '181300',c: '咸宁',l: '咸宁'}
                ]
            },
            {
                id: '181400',
                c: '仙桃',
                l: '仙桃',
                sub: [
                    {id: '181400',c: '仙桃',l: '仙桃'}
                ]
            },
            {
                id: '181500',
                c: '潜江',
                l: '潜江',
                sub: [
                    {id: '181500',c: '潜江',l: '潜江'}
                ]
            },
            {
                id: '181600',
                c: '天门',
                l: '天门',
                sub: [
                    {id: '181600',c: '天门',l: '天门'}
                ]
            },
            {
                id: '181700',
                c: '神农架',
                l: '神农架',
                sub: [
                    {id: '181700',c: '神农架',l: '神农架'}
                ]
            },
            {
                id: '181800',
                c: '恩施',
                l: '恩施',
                sub: [
                    {id: '181800',c: '恩施',l: '恩施'}
                ]
            }
        ]
    },
    {
        id: '190000',
        c: '湖南省',
        l: '湖南省',
        sub: [
            {
                id: '190000',
                c: '湖南省',
                l: '湖南省',
                sub: [{ id: '190000',c: '湖南省',l: '湖南省'}]
            },
            {
                id: '190200',
                c: '长沙',
                l: '长沙',
                sub: [
                    {id: '190200',c: '长沙',l: '长沙'},
                    {id: '190201',c: '芙蓉区',l: '长沙-芙蓉区'},
                    {id: '190202',c: '天心区',l: '长沙-天心区'},
                    {id: '190203',c: '岳麓区',l: '长沙-岳麓区'},
                    {id: '190204',c: '开福区',l: '长沙-开福区'},
                    {id: '190205',c: '雨花区',l: '长沙-雨花区'},
                    {id: '190206',c: '望城区',l: '长沙-望城区'},
                    {id: '190207',c: '长沙县',l: '长沙-长沙县'},
                    {id: '190208',c: '宁乡市',l: '长沙-宁乡市'},
                    {id: '190209',c: '浏阳市',l: '长沙-浏阳市'}
                ]
            },
            {
                id: '190300',
                c: '株洲',
                l: '株洲',
                sub: [
                    {id: '190300',c: '株洲',l: '株洲'},
                    {id: '190301',c: '荷塘区',l: '株洲-荷塘区'},
                    {id: '190302',c: '芦淞区',l: '株洲-芦淞区'},
                    {id: '190303',c: '石峰区',l: '株洲-石峰区'},
                    {id: '190304',c: '天元区',l: '株洲-天元区'},
                    {id: '190305',c: '渌口区',l: '株洲-渌口区'},
                    {id: '190306',c: '攸县',l: '株洲-攸县'},
                    {id: '190307',c: '茶陵县',l: '株洲-茶陵县'},
                    {id: '190308',c: '炎陵县',l: '株洲-炎陵县'},
                    {id: '190309',c: '醴陵市',l: '株洲-醴陵市'},
                    {id: '190310',c: '云龙示范区',l: '株洲-云龙示范区'}
                ]
            },
            {
                id: '190400',
                c: '湘潭',
                l: '湘潭',
                sub: [
                    {id: '190400',c: '湘潭',l: '湘潭'}
                ]
            },
            {
                id: '190500',
                c: '衡阳',
                l: '衡阳',
                sub: [
                    {id: '190500',c: '衡阳',l: '衡阳'}
                ]
            },
            {
                id: '190600',
                c: '岳阳',
                l: '岳阳',
                sub: [
                    {id: '190600',c: '岳阳',l: '岳阳'}
                ]
            },
            {
                id: '190700',
                c: '常德',
                l: '常德',
                sub: [
                    {id: '190700',c: '常德',l: '常德'}
                ]
            },
            {
                id: '190800',
                c: '益阳',
                l: '益阳',
                sub: [
                    {id: '190800',c: '益阳',l: '益阳'}
                ]
            },
            {
                id: '190900',
                c: '郴州',
                l: '郴州',
                sub: [
                    {id: '190900',c: '郴州',l: '郴州'}
                ]
            },
            {
                id: '191000',
                c: '邵阳',
                l: '邵阳',
                sub: [
                    {id: '191000',c: '邵阳',l: '邵阳'}
                ]
            },
            {
                id: '191100',
                c: '怀化',
                l: '怀化',
                sub: [
                    {id: '191100',c: '怀化',l: '怀化'}
                ]
            },
            {
                id: '191200',
                c: '娄底',
                l: '娄底',
                sub: [
                    {id: '191200',c: '娄底',l: '娄底'}
                ]
            },
            {
                id: '191300',
                c: '永州',
                l: '永州',
                sub: [
                    {id: '191300',c: '永州',l: '永州'}
                ]
            },
            {
                id: '191400',
                c: '张家界',
                l: '张家界',
                sub: [
                    {id: '191400',c: '张家界',l: '张家界'}
                ]
            },
            {
                id: '191500',
                c: '湘西',
                l: '湘西',
                sub: [
                    {id: '191500',c: '湘西',l: '湘西'}
                ]
            }
        ]
    },
    {
        id: '200000',
        c: '陕西省',
        l: '陕西省',
        sub: [
            {
                id: '200000',
                c: '陕西省',
                l: '陕西省',
                sub: [{ id: '200000',c: '陕西省',l: '陕西省'}]
            },
            {
                id: '200200',
                c: '西安',
                l: '西安',
                sub: [
                    {id: '200200',c: '西安',l: '西安'},
                    {id: '200201',c: '莲湖区',l: '西安-莲湖区'},
                    {id: '200202',c: '新城区',l: '西安-新城区'},
                    {id: '200203',c: '碑林区',l: '西安-碑林区'},
                    {id: '200204',c: '灞桥区',l: '西安-灞桥区'},
                    {id: '200205',c: '未央区',l: '西安-未央区'},
                    {id: '200206',c: '雁塔区',l: '西安-雁塔区'},
                    {id: '200207',c: '阎良区',l: '西安-阎良区'},
                    {id: '200208',c: '临潼区',l: '西安-临潼区'},
                    {id: '200209',c: '长安区',l: '西安-长安区'},
                    {id: '200210',c: '蓝田县',l: '西安-蓝田县'},
                    {id: '200211',c: '周至县',l: '西安-周至县'},
                    {id: '200212',c: '鄠邑区',l: '西安-鄠邑区'},
                    {id: '200213',c: '高陵区',l: '西安-高陵区'},
                    {id: '200214',c: '高新技术产业开发区',l: '西安-高新技术产业开发区'},
                    {id: '200215',c: '经济技术开发区',l: '西安-经济技术开发区'},
                    {id: '200216',c: '曲江新区',l: '西安-曲江新区'},
                    {id: '200217',c: '浐灞生态区',l: '西安-浐灞生态区'},
                    {id: '200218',c: '国家民用航天产业基地',l: '西安-国家民用航天产业基地'},
                    {id: '200219',c: '西咸新区',l: '西安-西咸新区'},
                    {id: '200220',c: '西安阎良航空基地',l: '西安-西安阎良航空基地'},
                    {id: '200221',c: '西安国际港务区',l: '西安-西安国际港务区'}
                ]
            },
            {
                id: '200300',
                c: '咸阳',
                l: '咸阳',
                sub: [
                    {id: '200300',c: '咸阳',l: '咸阳'},
                    {id: '200301',c: '秦都区',l: '咸阳-秦都区'},
                    {id: '200302',c: '杨陵区',l: '咸阳-杨陵区'},
                    {id: '200303',c: '渭城区',l: '咸阳-渭城区'},
                    {id: '200304',c: '三原县',l: '咸阳-三原县'},
                    {id: '200305',c: '泾阳县',l: '咸阳-泾阳县'},
                    {id: '200306',c: '乾县',l: '咸阳-乾县'},
                    {id: '200307',c: '礼泉县',l: '咸阳-礼泉县'},
                    {id: '200308',c: '永寿县',l: '咸阳-永寿县'},
                    {id: '200309',c: '长武县',l: '咸阳-长武县'},
                    {id: '200310',c: '旬邑县',l: '咸阳-旬邑县'},
                    {id: '200311',c: '淳化县',l: '咸阳-淳化县'},
                    {id: '200312',c: '武功县',l: '咸阳-武功县'},
                    {id: '200313',c: '彬州市',l: '咸阳-彬州市'},
                    {id: '200314',c: '兴平市',l: '咸阳-兴平市'}
                ]
            },
            {
                id: '200400',
                c: '宝鸡',
                l: '宝鸡',
                sub: [
                    {id: '200400',c: '宝鸡',l: '宝鸡'}
                ]
            },
            {
                id: '200500',
                c: '铜川',
                l: '铜川',
                sub: [
                    {id: '200500',c: '铜川',l: '铜川'}
                ]
            },
            {
                id: '200600',
                c: '延安',
                l: '延安',
                sub: [
                    {id: '200600',c: '延安',l: '延安'}
                ]
            },
            {
                id: '200700',
                c: '渭南',
                l: '渭南',
                sub: [
                    {id: '200700',c: '渭南',l: '渭南'}
                ]
            },
            {
                id: '200800',
                c: '榆林',
                l: '榆林',
                sub: [
                    {id: '200800',c: '榆林',l: '榆林'}
                ]
            },
            {
                id: '200900',
                c: '汉中',
                l: '汉中',
                sub: [
                    {id: '200900',c: '汉中',l: '汉中'}
                ]
            },
            {
                id: '201000',
                c: '安康',
                l: '安康',
                sub: [
                    {id: '201000',c: '安康',l: '安康'}
                ]
            },
            {
                id: '201100',
                c: '商洛',
                l: '商洛',
                sub: [
                    {id: '201100',c: '商洛',l: '商洛'}
                ]
            },
            {
                id: '201200',
                c: '杨凌',
                l: '杨凌',
                sub: [
                    {id: '201200',c: '杨凌',l: '杨凌'}
                ]
            }
        ]
    },
    {
        id: '210000',
        c: '山西省',
        l: '山西省',
        sub: [
            {
                id: '210000',
                c: '山西省',
                l: '山西省',
                sub: [{ id: '210000',c: '山西省',l: '山西省'}]
            },
            {
                id: '210200',
                c: '太原',
                l: '太原',
                sub: [
                    {id: '210200',c: '太原',l: '太原'},
                    {id: '210201',c: '小店区',l: '太原-小店区'},
                    {id: '210202',c: '迎泽区',l: '太原-迎泽区'},
                    {id: '210203',c: '杏花岭区',l: '太原-杏花岭区'},
                    {id: '210204',c: '尖草坪区',l: '太原-尖草坪区'},
                    {id: '210205',c: '万柏林区',l: '太原-万柏林区'},
                    {id: '210206',c: '晋源区',l: '太原-晋源区'},
                    {id: '210207',c: '清徐县',l: '太原-清徐县'},
                    {id: '210208',c: '阳曲县',l: '太原-阳曲县'},
                    {id: '210209',c: '娄烦县',l: '太原-娄烦县'},
                    {id: '210210',c: '古交市',l: '太原-古交市'}
                ]
            },
            {
                id: '210300',
                c: '运城',
                l: '运城',
                sub: [
                    {id: '210300',c: '运城',l: '运城'}
                ]
            },
            {
                id: '210400',
                c: '大同',
                l: '大同',
                sub: [
                    {id: '210400',c: '大同',l: '大同'}
                ]
            },
            {
                id: '210500',
                c: '临汾',
                l: '临汾',
                sub: [
                    {id: '210500',c: '临汾',l: '临汾'}
                ]
            },
            {
                id: '210600',
                c: '长治',
                l: '长治',
                sub: [
                    {id: '210600',c: '长治',l: '长治'}
                ]
            },
            {
                id: '210700',
                c: '晋城',
                l: '晋城',
                sub: [
                    {id: '210700',c: '晋城',l: '晋城'}
                ]
            },
            {
                id: '210800',
                c: '阳泉',
                l: '阳泉',
                sub: [
                    {id: '210800',c: '阳泉',l: '阳泉'}
                ]
            },
            {
                id: '210900',
                c: '朔州',
                l: '朔州',
                sub: [
                    {id: '210900',c: '朔州',l: '朔州'}
                ]
            },
            {
                id: '211000',
                c: '晋中',
                l: '晋中',
                sub: [
                    {id: '211000',c: '晋中',l: '晋中'}
                ]
            },
            {
                id: '211100',
                c: '忻州',
                l: '忻州',
                sub: [
                    {id: '211100',c: '忻州',l: '忻州'}
                ]
            },
            {
                id: '211200',
                c: '吕梁',
                l: '吕梁',
                sub: [
                    {id: '211200',c: '吕梁',l: '吕梁'}
                ]
            }
        ]
    },
    {
        id: '220000',
        c: '黑龙江省',
        l: '黑龙江省',
        sub: [
            {
                id: '220000',
                c: '黑龙江省',
                l: '黑龙江省',
                sub: [{ id: '220000',c: '黑龙江省',l: '黑龙江省'}]
            },
            {
                id: '220200',
                c: '哈尔滨',
                l: '哈尔滨',
                sub: [
                    {id: '220200',c: '哈尔滨',l: '哈尔滨'},
                    {id: '220201',c: '道里区',l: '哈尔滨-道里区'},
                    {id: '220202',c: '南岗区',l: '哈尔滨-南岗区'},
                    {id: '220203',c: '道外区',l: '哈尔滨-道外区'},
                    {id: '220204',c: '平房区',l: '哈尔滨-平房区'},
                    {id: '220205',c: '松北区',l: '哈尔滨-松北区'},
                    {id: '220206',c: '香坊区',l: '哈尔滨-香坊区'},
                    {id: '220207',c: '呼兰区',l: '哈尔滨-呼兰区'},
                    {id: '220208',c: '阿城区',l: '哈尔滨-阿城区'},
                    {id: '220209',c: '依兰县',l: '哈尔滨-依兰县'},
                    {id: '220210',c: '方正县',l: '哈尔滨-方正县'},
                    {id: '220211',c: '宾县',l: '哈尔滨-宾县'},
                    {id: '220212',c: '巴彦县',l: '哈尔滨-巴彦县'},
                    {id: '220213',c: '木兰县',l: '哈尔滨-木兰县'},
                    {id: '220214',c: '通河县',l: '哈尔滨-通河县'},
                    {id: '220215',c: '延寿县',l: '哈尔滨-延寿县'},
                    {id: '220216',c: '双城区',l: '哈尔滨-双城区'},
                    {id: '220217',c: '尚志市',l: '哈尔滨-尚志市'},
                    {id: '220218',c: '五常市',l: '哈尔滨-五常市'}
                ]
            },
            {
                id: '220300',
                c: '伊春',
                l: '伊春',
                sub: [
                    {id: '220300',c: '伊春',l: '伊春'}
                ]
            },
            {
                id: '220400',
                c: '绥化',
                l: '绥化',
                sub: [
                    {id: '220400',c: '绥化',l: '绥化'}
                ]
            },
            {
                id: '220500',
                c: '大庆',
                l: '大庆',
                sub: [
                    {id: '220500',c: '大庆',l: '大庆'}
                ]
            },
            {
                id: '220600',
                c: '齐齐哈尔',
                l: '齐齐哈尔',
                sub: [
                    {id: '220600',c: '齐齐哈尔',l: '齐齐哈尔'}
                ]
            },
            {
                id: '220700',
                c: '牡丹江',
                l: '牡丹江',
                sub: [
                    {id: '220700',c: '牡丹江',l: '牡丹江'}
                ]
            },
            {
                id: '220800',
                c: '佳木斯',
                l: '佳木斯',
                sub: [
                    {id: '220800',c: '佳木斯',l: '佳木斯'}
                ]
            },
            {
                id: '220900',
                c: '鸡西',
                l: '鸡西',
                sub: [
                    {id: '220900',c: '鸡西',l: '鸡西'}
                ]
            },
            {
                id: '221000',
                c: '鹤岗',
                l: '鹤岗',
                sub: [
                    {id: '221000',c: '鹤岗',l: '鹤岗'}
                ]
            },
            {
                id: '221100',
                c: '双鸭山',
                l: '双鸭山',
                sub: [
                    {id: '221100',c: '双鸭山',l: '双鸭山'}
                ]
            },
            {
                id: '221200',
                c: '黑河',
                l: '黑河',
                sub: [
                    {id: '221200',c: '黑河',l: '黑河'}
                ]
            },
            {
                id: '221300',
                c: '七台河',
                l: '七台河',
                sub: [
                    {id: '221300',c: '七台河',l: '七台河'}
                ]
            },
            {
                id: '221400',
                c: '大兴安岭',
                l: '大兴安岭',
                sub: [
                    {id: '221400',c: '大兴安岭',l: '大兴安岭'}
                ]
            }
        ]
    },
    {
        id: '230000',
        c: '辽宁省',
        l: '辽宁省',
        sub: [
            {
                id: '230000',
                c: '辽宁省',
                l: '辽宁省',
                sub: [{ id: '230000',c: '辽宁省',l: '辽宁省'}]
            },
            {
                id: '230200',
                c: '沈阳',
                l: '沈阳',
                sub: [
                    {id: '230200',c: '沈阳',l: '沈阳'},
                    {id: '230201',c: '大东区',l: '沈阳-大东区'},
                    {id: '230202',c: '浑南区',l: '沈阳-浑南区'},
                    {id: '230203',c: '康平县',l: '沈阳-康平县'},
                    {id: '230204',c: '和平区',l: '沈阳-和平区'},
                    {id: '230205',c: '皇姑区',l: '沈阳-皇姑区'},
                    {id: '230206',c: '沈北新区',l: '沈阳-沈北新区'},
                    {id: '230207',c: '沈河区',l: '沈阳-沈河区'},
                    {id: '230208',c: '苏家屯区',l: '沈阳-苏家屯区'},
                    {id: '230209',c: '铁西区',l: '沈阳-铁西区'},
                    {id: '230210',c: '于洪区',l: '沈阳-于洪区'},
                    {id: '230211',c: '法库县',l: '沈阳-法库县'},
                    {id: '230212',c: '辽中区',l: '沈阳-辽中区'},
                    {id: '230213',c: '新民市',l: '沈阳-新民市'}
                ]
            },
            {
                id: '230300',
                c: '大连',
                l: '大连',
                sub: [
                    {id: '230300',c: '大连',l: '大连'},
                    {id: '230301',c: '西岗区',l: '大连-西岗区'},
                    {id: '230302',c: '中山区',l: '大连-中山区'},
                    {id: '230303',c: '沙河口区',l: '大连-沙河口区'},
                    {id: '230304',c: '甘井子区',l: '大连-甘井子区'},
                    {id: '230305',c: '旅顺口区',l: '大连-旅顺口区'},
                    {id: '230306',c: '金州区',l: '大连-金州区'},
                    {id: '230307',c: '瓦房店市',l: '大连-瓦房店市'},
                    {id: '230308',c: '普兰店区',l: '大连-普兰店区'},
                    {id: '230309',c: '庄河市',l: '大连-庄河市'},
                    {id: '230310',c: '长海县',l: '大连-长海县'},
                    {id: '230312',c: '高新园区',l: '大连-高新园区'},
                    {id: '230313',c: '长兴岛',l: '大连-长兴岛'},
                    {id: '230314',c: '大连保税区',l: '大连-大连保税区'}
                ]
            },
            {
                id: '230400',
                c: '鞍山',
                l: '鞍山',
                sub: [
                    {id: '230400',c: '鞍山',l: '鞍山'}
                ]
            },
            {
                id: '230500',
                c: '营口',
                l: '营口',
                sub: [
                    {id: '230500',c: '营口',l: '营口'}
                ]
            },
            {
                id: '230600',
                c: '抚顺',
                l: '抚顺',
                sub: [
                    {id: '230600',c: '抚顺',l: '抚顺'}
                ]
            },
            {
                id: '230700',
                c: '锦州',
                l: '锦州',
                sub: [
                    {id: '230700',c: '锦州',l: '锦州'}
                ]
            },
            {
                id: '230800',
                c: '丹东',
                l: '丹东',
                sub: [
                    {id: '230800',c: '丹东',l: '丹东'}
                ]
            },
            {
                id: '230900',
                c: '葫芦岛',
                l: '葫芦岛',
                sub: [
                    {id: '230900',c: '葫芦岛',l: '葫芦岛'}
                ]
            },
            {
                id: '231000',
                c: '本溪',
                l: '本溪',
                sub: [
                    {id: '231000',c: '本溪',l: '本溪'}
                ]
            },
            {
                id: '231100',
                c: '辽阳',
                l: '辽阳',
                sub: [
                    {id: '231100',c: '辽阳',l: '辽阳'}
                ]
            },
            {
                id: '231200',
                c: '铁岭',
                l: '铁岭',
                sub: [
                    {id: '231200',c: '铁岭',l: '铁岭'}
                ]
            },
            {
                id: '231300',
                c: '盘锦',
                l: '盘锦',
                sub: [
                    {id: '231300',c: '盘锦',l: '盘锦'}
                ]
            },
            {
                id: '231400',
                c: '朝阳',
                l: '朝阳',
                sub: [
                    {id: '231400',c: '朝阳',l: '朝阳'}
                ]
            },
            {
                id: '231500',
                c: '阜新',
                l: '阜新',
                sub: [
                    {id: '231500',c: '阜新',l: '阜新'}
                ]
            }
        ]
    },
    {
        id: '240000',
        c: '吉林省',
        l: '吉林省',
        sub: [
            {
                id: '240000',
                c: '吉林省',
                l: '吉林省',
                sub: [{ id: '240000',c: '吉林省',l: '吉林省'}]
            },
            {
                id: '240200',
                c: '长春',
                l: '长春',
                sub: [
                    {id: '240200',c: '长春',l: '长春'},
                    {id: '240201',c: '朝阳区',l: '长春-朝阳区'},
                    {id: '240202',c: '南关区',l: '长春-南关区'},
                    {id: '240203',c: '宽城区',l: '长春-宽城区'},
                    {id: '240204',c: '二道区',l: '长春-二道区'},
                    {id: '240205',c: '绿园区',l: '长春-绿园区'},
                    {id: '240206',c: '双阳区',l: '长春-双阳区'},
                    {id: '240207',c: '经济技术开发区',l: '长春-经济技术开发区'},
                    {id: '240208',c: '高新技术产业开发区',l: '长春-高新技术产业开发区'},
                    {id: '240209',c: '净月高新技术产业开发区',l: '长春-净月高新技术产业开发区'},
                    {id: '240210',c: '汽车经济技术开发区',l: '长春-汽车经济技术开发区'},
                    {id: '240211',c: '榆树市',l: '长春-榆树市'},
                    {id: '240212',c: '九台区',l: '长春-九台区'},
                    {id: '240213',c: '德惠市',l: '长春-德惠市'},
                    {id: '240214',c: '农安县',l: '长春-农安县'},
                    {id: '240215',c: '公主岭市',l: '长春-公主岭市'}
                ]
            },
            {
                id: '240300',
                c: '吉林',
                l: '吉林',
                sub: [
                    {id: '240300',c: '吉林',l: '吉林'}
                ]
            },
            {
                id: '240400',
                c: '辽源',
                l: '辽源',
                sub: [
                    {id: '240400',c: '辽源',l: '辽源'}
                ]
            },
            {
                id: '240500',
                c: '通化',
                l: '通化',
                sub: [
                    {id: '240500',c: '通化',l: '通化'}
                ]
            },
            {
                id: '240600',
                c: '四平',
                l: '四平',
                sub: [
                    {id: '240600',c: '四平',l: '四平'}
                ]
            },
            {
                id: '240700',
                c: '松原',
                l: '松原',
                sub: [
                    {id: '240700',c: '松原',l: '松原'}
                ]
            },
            {
                id: '240800',
                c: '延吉',
                l: '延吉',
                sub: [
                    {id: '240800',c: '延吉',l: '延吉'}
                ]
            },
            {
                id: '240900',
                c: '白山',
                l: '白山',
                sub: [
                    {id: '240900',c: '白山',l: '白山'}
                ]
            },
            {
                id: '241000',
                c: '白城',
                l: '白城',
                sub: [
                    {id: '241000',c: '白城',l: '白城'}
                ]
            },
            {
                id: '241100',
                c: '延边',
                l: '延边',
                sub: [
                    {id: '241100',c: '延边',l: '延边'}
                ]
            }
        ]
    },
    {
        id: '250000',
        c: '云南省',
        l: '云南省',
        sub: [
            {
                id: '250000',
                c: '云南省',
                l: '云南省',
                sub: [{ id: '250000',c: '云南省',l: '云南省'}]
            },
            {
                id: '250200',
                c: '昆明',
                l: '昆明',
                sub: [
                    {id: '250200',c: '昆明',l: '昆明'},
                    {id: '250201',c: '五华区',l: '昆明-五华区'},
                    {id: '250202',c: '盘龙区',l: '昆明-盘龙区'},
                    {id: '250203',c: '官渡区',l: '昆明-官渡区'},
                    {id: '250204',c: '西山区',l: '昆明-西山区'},
                    {id: '250205',c: '东川区',l: '昆明-东川区'},
                    {id: '250206',c: '呈贡区',l: '昆明-呈贡区'},
                    {id: '250207',c: '晋宁区',l: '昆明-晋宁区'},
                    {id: '250208',c: '富民县',l: '昆明-富民县'},
                    {id: '250209',c: '宜良县',l: '昆明-宜良县'},
                    {id: '250210',c: '石林彝族自治县',l: '昆明-石林彝族自治县'},
                    {id: '250211',c: '嵩明县',l: '昆明-嵩明县'},
                    {id: '250212',c: '禄劝县',l: '昆明-禄劝县'},
                    {id: '250213',c: '寻甸县',l: '昆明-寻甸县'},
                    {id: '250214',c: '安宁市',l: '昆明-安宁市'}
                ]
            },
            {
                id: '250300',
                c: '曲靖',
                l: '曲靖',
                sub: [
                    {id: '250300',c: '曲靖',l: '曲靖'}
                ]
            },
            {
                id: '250400',
                c: '玉溪',
                l: '玉溪',
                sub: [
                    {id: '250400',c: '玉溪',l: '玉溪'}
                ]
            },
            {
                id: '250500',
                c: '大理',
                l: '大理',
                sub: [
                    {id: '250500',c: '大理',l: '大理'}
                ]
            },
            {
                id: '250600',
                c: '丽江',
                l: '丽江',
                sub: [
                    {id: '250600',c: '丽江',l: '丽江'}
                ]
            },
            {
                id: '251000',
                c: '红河州',
                l: '红河州',
                sub: [
                    {id: '251000',c: '红河州',l: '红河州'}
                ]
            },
            {
                id: '251100',
                c: '普洱',
                l: '普洱',
                sub: [
                    {id: '251100',c: '普洱',l: '普洱'}
                ]
            },
            {
                id: '251200',
                c: '保山',
                l: '保山',
                sub: [
                    {id: '251200',c: '保山',l: '保山'}
                ]
            },
            {
                id: '251300',
                c: '昭通',
                l: '昭通',
                sub: [
                    {id: '251300',c: '昭通',l: '昭通'}
                ]
            },
            {
                id: '251400',
                c: '文山',
                l: '文山',
                sub: [
                    {id: '251400',c: '文山',l: '文山'}
                ]
            },
            {
                id: '251500',
                c: '西双版纳',
                l: '西双版纳',
                sub: [
                    {id: '251500',c: '西双版纳',l: '西双版纳'}
                ]
            },
            {
                id: '251600',
                c: '德宏',
                l: '德宏',
                sub: [
                    {id: '251600',c: '德宏',l: '德宏'}
                ]
            },
            {
                id: '251700',
                c: '楚雄',
                l: '楚雄',
                sub: [
                    {id: '251700',c: '楚雄',l: '楚雄'}
                ]
            },
            {
                id: '251800',
                c: '临沧',
                l: '临沧',
                sub: [
                    {id: '251800',c: '临沧',l: '临沧'}
                ]
            },
            {
                id: '251900',
                c: '怒江',
                l: '怒江',
                sub: [
                    {id: '251900',c: '怒江',l: '怒江'}
                ]
            },
            {
                id: '252000',
                c: '迪庆',
                l: '迪庆',
                sub: [
                    {id: '252000',c: '迪庆',l: '迪庆'}
                ]
            }
        ]
    },
    {
        id: '260000',
        c: '贵州省',
        l: '贵州省',
        sub: [
            {
                id: '260000',
                c: '贵州省',
                l: '贵州省',
                sub: [{ id: '260000',c: '贵州省',l: '贵州省'}]
            },
            {
                id: '260200',
                c: '贵阳',
                l: '贵阳',
                sub: [
                    {id: '260200',c: '贵阳',l: '贵阳'},
                    {id: '260201',c: '南明区',l: '贵阳-南明区'},
                    {id: '260202',c: '云岩区',l: '贵阳-云岩区'},
                    {id: '260203',c: '花溪区',l: '贵阳-花溪区'},
                    {id: '260204',c: '观山湖区',l: '贵阳-观山湖区'},
                    {id: '260205',c: '乌当区',l: '贵阳-乌当区'},
                    {id: '260206',c: '白云区',l: '贵阳-白云区'},
                    {id: '260207',c: '开阳县',l: '贵阳-开阳县'},
                    {id: '260208',c: '息烽县',l: '贵阳-息烽县'},
                    {id: '260209',c: '修文县',l: '贵阳-修文县'},
                    {id: '260210',c: '清镇市',l: '贵阳-清镇市'}
                ]
            },
            {
                id: '260300',
                c: '遵义',
                l: '遵义',
                sub: [
                    {id: '260300',c: '遵义',l: '遵义'}
                ]
            },
            {
                id: '260400',
                c: '六盘水',
                l: '六盘水',
                sub: [
                    {id: '260400',c: '六盘水',l: '六盘水'}
                ]
            },
            {
                id: '260500',
                c: '安顺',
                l: '安顺',
                sub: [
                    {id: '260500',c: '安顺',l: '安顺'}
                ]
            },
            {
                id: '260600',
                c: '铜仁',
                l: '铜仁',
                sub: [
                    {id: '260600',c: '铜仁',l: '铜仁'}
                ]
            },
            {
                id: '260700',
                c: '毕节',
                l: '毕节',
                sub: [
                    {id: '260700',c: '毕节',l: '毕节'}
                ]
            },
            {
                id: '260800',
                c: '黔西南',
                l: '黔西南',
                sub: [
                    {id: '260800',c: '黔西南',l: '黔西南'}
                ]
            },
            {
                id: '260900',
                c: '黔东南',
                l: '黔东南',
                sub: [
                    {id: '260900',c: '黔东南',l: '黔东南'}
                ]
            },
            {
                id: '261000',
                c: '黔南',
                l: '黔南',
                sub: [
                    {id: '261000',c: '黔南',l: '黔南'}
                ]
            }
        ]
    },
    {
        id: '270000',
        c: '甘肃省',
        l: '甘肃省',
        sub: [
            {
                id: '270000',
                c: '甘肃省',
                l: '甘肃省',
                sub: [{ id: '270000',c: '甘肃省',l: '甘肃省'}]
            },
            {
                id: '270200',
                c: '兰州',
                l: '兰州',
                sub: [
                    {id: '270200',c: '兰州',l: '兰州'},
                    {id: '270201',c: '城关区',l: '兰州-城关区'},
                    {id: '270202',c: '七里河区',l: '兰州-七里河区'},
                    {id: '270203',c: '西固区',l: '兰州-西固区'},
                    {id: '270204',c: '安宁区',l: '兰州-安宁区'},
                    {id: '270205',c: '红古区',l: '兰州-红古区'},
                    {id: '270206',c: '兰州新区',l: '兰州-兰州新区'},
                    {id: '270207',c: '永登县',l: '兰州-永登县'},
                    {id: '270208',c: '皋兰县',l: '兰州-皋兰县'},
                    {id: '270209',c: '榆中县',l: '兰州-榆中县'}
                ]
            },
            {
                id: '270300',
                c: '金昌',
                l: '金昌',
                sub: [
                    {id: '270300',c: '金昌',l: '金昌'}
                ]
            },
            {
                id: '270400',
                c: '嘉峪关',
                l: '嘉峪关',
                sub: [
                    {id: '270400',c: '嘉峪关',l: '嘉峪关'}
                ]
            },
            {
                id: '270500',
                c: '酒泉',
                l: '酒泉',
                sub: [
                    {id: '270500',c: '酒泉',l: '酒泉'}
                ]
            },
            {
                id: '270600',
                c: '天水',
                l: '天水',
                sub: [
                    {id: '270600',c: '天水',l: '天水'}
                ]
            },
            {
                id: '270700',
                c: '武威',
                l: '武威',
                sub: [
                    {id: '270700',c: '武威',l: '武威'}
                ]
            },
            {
                id: '270800',
                c: '白银',
                l: '白银',
                sub: [
                    {id: '270800',c: '白银',l: '白银'}
                ]
            },
            {
                id: '270900',
                c: '张掖',
                l: '张掖',
                sub: [
                    {id: '270900',c: '张掖',l: '张掖'}
                ]
            },
            {
                id: '271000',
                c: '平凉',
                l: '平凉',
                sub: [
                    {id: '271000',c: '平凉',l: '平凉'}
                ]
            },
            {
                id: '271100',
                c: '定西',
                l: '定西',
                sub: [
                    {id: '271100',c: '定西',l: '定西'}
                ]
            },
            {
                id: '271200',
                c: '陇南',
                l: '陇南',
                sub: [
                    {id: '271200',c: '陇南',l: '陇南'}
                ]
            },
            {
                id: '271300',
                c: '庆阳',
                l: '庆阳',
                sub: [
                    {id: '271300',c: '庆阳',l: '庆阳'}
                ]
            },
            {
                id: '271400',
                c: '临夏',
                l: '临夏',
                sub: [
                    {id: '271400',c: '临夏',l: '临夏'}
                ]
            },
            {
                id: '271500',
                c: '甘南',
                l: '甘南',
                sub: [
                    {id: '271500',c: '甘南',l: '甘南'}
                ]
            }
        ]
    },
    {
        id: '280000',
        c: '内蒙古',
        l: '内蒙古',
        sub: [
            {
                id: '280000',
                c: '内蒙古',
                l: '内蒙古',
                sub: [{ id: '280000',c: '内蒙古',l: '内蒙古'}]
            },
            {
                id: '280200',
                c: '呼和浩特',
                l: '呼和浩特',
                sub: [
                    {id: '280200',c: '呼和浩特',l: '呼和浩特'},
                    {id: '280201',c: '新城区',l: '呼和浩特-新城区'},
                    {id: '280202',c: '回民区',l: '呼和浩特-回民区'},
                    {id: '280203',c: '玉泉区',l: '呼和浩特-玉泉区'},
                    {id: '280204',c: '赛罕区',l: '呼和浩特-赛罕区'},
                    {id: '280205',c: '土默特左旗',l: '呼和浩特-土默特左旗'},
                    {id: '280206',c: '托克托县',l: '呼和浩特-托克托县'},
                    {id: '280207',c: '和林格尔县',l: '呼和浩特-和林格尔县'},
                    {id: '280208',c: '清水河县',l: '呼和浩特-清水河县'},
                    {id: '280209',c: '武川县',l: '呼和浩特-武川县'}
                ]
            },
            {
                id: '280300',
                c: '赤峰',
                l: '赤峰',
                sub: [
                    {id: '280300',c: '赤峰',l: '赤峰'}
                ]
            },
            {
                id: '280400',
                c: '包头',
                l: '包头',
                sub: [
                    {id: '280400',c: '包头',l: '包头'}
                ]
            },
            {
                id: '280700',
                c: '通辽',
                l: '通辽',
                sub: [
                    {id: '280700',c: '通辽',l: '通辽'}
                ]
            },
            {
                id: '280800',
                c: '鄂尔多斯',
                l: '鄂尔多斯',
                sub: [
                    {id: '280800',c: '鄂尔多斯',l: '鄂尔多斯'}
                ]
            },
            {
                id: '280900',
                c: '巴彦淖尔',
                l: '巴彦淖尔',
                sub: [
                    {id: '280900',c: '巴彦淖尔',l: '巴彦淖尔'}
                ]
            },
            {
                id: '281000',
                c: '乌海',
                l: '乌海',
                sub: [
                    {id: '281000',c: '乌海',l: '乌海'}
                ]
            },
            {
                id: '281100',
                c: '呼伦贝尔',
                l: '呼伦贝尔',
                sub: [
                    {id: '281100',c: '呼伦贝尔',l: '呼伦贝尔'}
                ]
            },
            {
                id: '281200',
                c: '乌兰察布',
                l: '乌兰察布',
                sub: [
                    {id: '281200',c: '乌兰察布',l: '乌兰察布'}
                ]
            },
            {
                id: '281300',
                c: '兴安盟',
                l: '兴安盟',
                sub: [
                    {id: '281300',c: '兴安盟',l: '兴安盟'}
                ]
            },
            {
                id: '281400',
                c: '锡林郭勒盟',
                l: '锡林郭勒盟',
                sub: [
                    {id: '281400',c: '锡林郭勒盟',l: '锡林郭勒盟'}
                ]
            },
            {
                id: '281500',
                c: '阿拉善盟',
                l: '阿拉善盟',
                sub: [
                    {id: '281500',c: '阿拉善盟',l: '阿拉善盟'}
                ]
            }
        ]
    },
    {
        id: '290000',
        c: '宁夏',
        l: '宁夏',
        sub: [
            {
                id: '290000',
                c: '宁夏',
                l: '宁夏',
                sub: [{ id: '290000',c: '宁夏',l: '宁夏'}]
            },
            {
                id: '290200',
                c: '银川',
                l: '银川',
                sub: [
                    {id: '290200',c: '银川',l: '银川'}
                ]
            },
            {
                id: '290300',
                c: '吴忠',
                l: '吴忠',
                sub: [
                    {id: '290300',c: '吴忠',l: '吴忠'}
                ]
            },
            {
                id: '290400',
                c: '中卫',
                l: '中卫',
                sub: [
                    {id: '290400',c: '中卫',l: '中卫'}
                ]
            },
            {
                id: '290500',
                c: '石嘴山',
                l: '石嘴山',
                sub: [
                    {id: '290500',c: '石嘴山',l: '石嘴山'}
                ]
            },
            {
                id: '290600',
                c: '固原',
                l: '固原',
                sub: [
                    {id: '290600',c: '固原',l: '固原'}
                ]
            }
        ]
    },
    {
        id: '300000',
        c: '西藏',
        l: '西藏',
        sub: [
            {
                id: '300000',
                c: '西藏',
                l: '西藏',
                sub: [{ id: '300000',c: '西藏',l: '西藏'}]
            },
            {
                id: '300200',
                c: '拉萨',
                l: '拉萨',
                sub: [
                    {id: '300200',c: '拉萨',l: '拉萨'}
                ]
            },
            {
                id: '300300',
                c: '日喀则',
                l: '日喀则',
                sub: [
                    {id: '300300',c: '日喀则',l: '日喀则'}
                ]
            },
            {
                id: '300400',
                c: '林芝',
                l: '林芝',
                sub: [
                    {id: '300400',c: '林芝',l: '林芝'}
                ]
            },
            {
                id: '300500',
                c: '山南',
                l: '山南',
                sub: [
                    {id: '300500',c: '山南',l: '山南'}
                ]
            },
            {
                id: '300600',
                c: '昌都',
                l: '昌都',
                sub: [
                    {id: '300600',c: '昌都',l: '昌都'}
                ]
            },
            {
                id: '300700',
                c: '那曲',
                l: '那曲',
                sub: [
                    {id: '300700',c: '那曲',l: '那曲'}
                ]
            },
            {
                id: '300800',
                c: '阿里',
                l: '阿里',
                sub: [
                    {id: '300800',c: '阿里',l: '阿里'}
                ]
            }
        ]
    },
    {
        id: '310000',
        c: '新疆',
        l: '新疆',
        sub: [
            {
                id: '310000',
                c: '新疆',
                l: '新疆',
                sub: [{ id: '310000',c: '新疆',l: '新疆'}]
            },
            {
                id: '310200',
                c: '乌鲁木齐',
                l: '乌鲁木齐',
                sub: [
                    {id: '310200',c: '乌鲁木齐',l: '乌鲁木齐'},
                    {id: '310201',c: '天山区',l: '乌鲁木齐-天山区'},
                    {id: '310202',c: '沙依巴克区',l: '乌鲁木齐-沙依巴克区'},
                    {id: '310203',c: '新市区',l: '乌鲁木齐-新市区'},
                    {id: '310204',c: '水磨沟区',l: '乌鲁木齐-水磨沟区'},
                    {id: '310205',c: '头屯河区',l: '乌鲁木齐-头屯河区'},
                    {id: '310206',c: '达坂城区',l: '乌鲁木齐-达坂城区'},
                    {id: '310207',c: '米东区',l: '乌鲁木齐-米东区'},
                    {id: '310208',c: '乌鲁木齐县',l: '乌鲁木齐-乌鲁木齐县'}
                ]
            },
            {
                id: '310300',
                c: '克拉玛依',
                l: '克拉玛依',
                sub: [
                    {id: '310300',c: '克拉玛依',l: '克拉玛依'}
                ]
            },
            {
                id: '310400',
                c: '喀什地区',
                l: '喀什地区',
                sub: [
                    {id: '310400',c: '喀什地区',l: '喀什地区'}
                ]
            },
            {
                id: '310500',
                c: '伊犁',
                l: '伊犁',
                sub: [
                    {id: '310500',c: '伊犁',l: '伊犁'}
                ]
            },
            {
                id: '310600',
                c: '阿克苏',
                l: '阿克苏',
                sub: [
                    {id: '310600',c: '阿克苏',l: '阿克苏'}
                ]
            },
            {
                id: '310700',
                c: '哈密',
                l: '哈密',
                sub: [
                    {id: '310700',c: '哈密',l: '哈密'}
                ]
            },
            {
                id: '310800',
                c: '石河子',
                l: '石河子',
                sub: [
                    {id: '310800',c: '石河子',l: '石河子'}
                ]
            },
            {
                id: '310900',
                c: '阿拉尔',
                l: '阿拉尔',
                sub: [
                    {id: '310900',c: '阿拉尔',l: '阿拉尔'}
                ]
            },
            {
                id: '311000',
                c: '五家渠',
                l: '五家渠',
                sub: [
                    {id: '311000',c: '五家渠',l: '五家渠'}
                ]
            },
            {
                id: '311100',
                c: '图木舒克',
                l: '图木舒克',
                sub: [
                    {id: '311100',c: '图木舒克',l: '图木舒克'}
                ]
            },
            {
                id: '311200',
                c: '昌吉',
                l: '昌吉',
                sub: [
                    {id: '311200',c: '昌吉',l: '昌吉'}
                ]
            },
            {
                id: '311300',
                c: '阿勒泰',
                l: '阿勒泰',
                sub: [
                    {id: '311300',c: '阿勒泰',l: '阿勒泰'}
                ]
            },
            {
                id: '311400',
                c: '吐鲁番',
                l: '吐鲁番',
                sub: [
                    {id: '311400',c: '吐鲁番',l: '吐鲁番'}
                ]
            },
            {
                id: '311500',
                c: '塔城',
                l: '塔城',
                sub: [
                    {id: '311500',c: '塔城',l: '塔城'}
                ]
            },
            {
                id: '311600',
                c: '和田',
                l: '和田',
                sub: [
                    {id: '311600',c: '和田',l: '和田'}
                ]
            },
            {
                id: '311700',
                c: '克孜勒苏柯尔克孜',
                l: '克孜勒苏柯尔克孜',
                sub: [
                    {id: '311700',c: '克孜勒苏柯尔克孜',l: '克孜勒苏柯尔克孜'}
                ]
            },
            {
                id: '311800',
                c: '巴音郭楞',
                l: '巴音郭楞',
                sub: [
                    {id: '311800',c: '巴音郭楞',l: '巴音郭楞'}
                ]
            },
            {
                id: '311900',
                c: '博尔塔拉',
                l: '博尔塔拉',
                sub: [
                    {id: '311900',c: '博尔塔拉',l: '博尔塔拉'}
                ]
            }
        ]
    },
    {
        id: '320000',
        c: '青海省',
        l: '青海省',
        sub: [
            {
                id: '320000',
                c: '青海省',
                l: '青海省',
                sub: [{ id: '320000',c: '青海省',l: '青海省'}]
            },
            {
                id: '320200',
                c: '西宁',
                l: '西宁',
                sub: [
                    {id: '320200',c: '西宁',l: '西宁'}
                ]
            },
            {
                id: '320300',
                c: '海东',
                l: '海东',
                sub: [
                    {id: '320300',c: '海东',l: '海东'}
                ]
            },
            {
                id: '320400',
                c: '海西',
                l: '海西',
                sub: [
                    {id: '320400',c: '海西',l: '海西'}
                ]
            },
            {
                id: '320500',
                c: '海北',
                l: '海北',
                sub: [
                    {id: '320500',c: '海北',l: '海北'}
                ]
            },
            {
                id: '320600',
                c: '黄南',
                l: '黄南',
                sub: [
                    {id: '320600',c: '黄南',l: '黄南'}
                ]
            },
            {
                id: '320700',
                c: '海南州',
                l: '海南州',
                sub: [
                    {id: '320700',c: '海南州',l: '海南州'}
                ]
            },
            {
                id: '320800',
                c: '果洛',
                l: '果洛',
                sub: [
                    {id: '320800',c: '果洛',l: '果洛'}
                ]
            },
            {
                id: '320900',
                c: '玉树',
                l: '玉树',
                sub: [
                    {id: '320900',c: '玉树',l: '玉树'}
                ]
            }
        ]
    },
    {
        id: '330000',
        c: '香港',
        l: '香港',
        sub: [{ id: '330000',c: '香港',l: '香港'}]
    },
    {
        id: '340000',
        c: '澳门',
        l: '澳门',
        sub: [{ id: '340000',c: '澳门',l: '澳门'}]
    },
    {
        id: '350000',
        c: '台湾',
        l: '台湾',
        sub: [{ id: '350000',c: '台湾',l: '台湾'}]
    },
    {
        id: '360000',
        c: '国外',
        l: '国外',
        sub: [{ id: '360000',c: '国外',l: '国外'}]
    }
]