<!--* perMoadl 添加客户联系人弹窗-->
<template>
  <div ref="addModel" class="add-model">
    <a-modal
      :getContainer="() => $refs.addModel"
      :visible="isShowMoadl"
      :title="`${id?'编辑':'添加'}客户联系人`"
      :maskClosable="false"
      :keyboard="false"
      width="520px"
      centered
      wrap-class-name="perModel" 
    >
      <template #closeIcon>
        <close-outlined @click.stop="closeMoadl"/>
      </template>
      <a-form
        :model="ruleForm"
        :colon="false"
        :rules="rules"
        ref="modalFormRef"
        autocomplete="off"
      >
        <a-form-item label="是否有效" name="valid" :rules="fieldSetList.valid.notNull?rules.valid:[{ required: false }]" v-if="fieldSetList.valid.show">
          <a-select  v-model:value="ruleForm.valid"  placeholder="请选择是否有效"  :options="sfyxSelect">
            <template #suffixIcon><caret-down-outlined class="ant-select-suffix"/></template>
          </a-select>
        </a-form-item>
        <a-form-item label="重要程度" name="importantLevel" :rules="fieldSetList.importantLevel.notNull?rules.importantLevel:[{ required: false }]" v-if="fieldSetList.importantLevel.show">
          <a-select v-model:value="ruleForm.importantLevel" placeholder="请选择重要程度"  :options="importList" :field-names="{ label: 'dicValue', value: 'dicKey' }">
            <template #suffixIcon><caret-down-outlined class="ant-select-suffix" /></template>
          </a-select>
        </a-form-item>
        <a-form-item name="name" :rules="fieldSetList.name.notNull?rules.name:[{ required: false }]" v-if="fieldSetList.name.show">
          <template #label>姓<i class="space-font" />名</template>
          <a-input v-model:value="ruleForm.name" placeholder="请填写姓名" maxlength="15"/>
        </a-form-item>
        <a-form-item name="position" :rules="fieldSetList.position.notNull?rules.position:[{ required: false }]" v-if="fieldSetList.position.show">
          <template #label>职<i class="space-font" />位</template>
          <a-input v-model:value="ruleForm.position" placeholder="请填写职位" maxlength="30"/>
        </a-form-item>
        <a-form-item name="phone" :rules="fieldSetList.phone.notNull || ruleForm.phone?rules.phone:[{ required: false }]" v-if="fieldSetList.phone.show">
          <template #label>手<i class="space-font" />机</template>
          <a-input v-model:value.trim="ruleForm.phone"  placeholder="请填写手机" oninput ="value=value.replace(/[^0-9+-]/g,'')" maxlength="20"/>
        </a-form-item>
        <a-form-item name="mail" :required='fieldSetList.mail.notNull'  v-if="fieldSetList.mail.show">
          <template #label>邮<i class="space-font" />箱</template>
          <a-input v-model:value="ruleForm.mail" placeholder="请填写邮箱" maxlength="50"/>
        </a-form-item>
        <a-form-item name="qq" :rules="fieldSetList.qq.notNull?rules.qq:[{ required: false }]" v-if="fieldSetList.qq.show">
          <template #label>Q<i class="space-font2" />Q</template>
          <a-input v-model:value.trim="ruleForm.qq" placeholder="请填写qq" maxlength="15" onkeyup="value=value.replace(/\D/g,'')" />
        </a-form-item>
        <a-form-item name="wx" :rules="fieldSetList.wx.notNull?rules.wx:[{ required: false }]" v-if="fieldSetList.wx.show">
          <template #label>微<i class="space-font" />信</template>
          <a-input v-model:value.trim="ruleForm.wx" placeholder="请填写微信" maxlength="20" oninput ="value=value.replace(/[^a-zA-Z\d_-]/g,'')"/>
        </a-form-item>
        <a-form-item name="sex" :rules="fieldSetList.sex.notNull?rules.sex:[{ required: false }]" v-if="fieldSetList.sex.show">
          <template #label>性<i class="space-font" />别</template>
          <a-select v-model:value="ruleForm.sex" placeholder="请选择性别" :options="sexGender">
            <template #suffixIcon><caret-down-outlined class="ant-select-suffix"/></template>
          </a-select>
        </a-form-item>
        <a-form-item name='projectIds' label="关联职位" class="flexItem">
          <a-select v-model:value="ruleForm.projectIds" @click="getProject" placeholder="请选择关联职位" :getPopupContainer=" triggerNode => {return triggerNode.parentNode }"  mode="multiple">
            <a-select-option
              v-for="item in projectList"
              :key="item.id"
              :value="item.id"
              :label="item.title"
            >{{ item.title }}</a-select-option>
            <template #suffixIcon><caret-down-outlined class="ant-select-suffix"/></template>
          </a-select>
          <span @click="toPositon" class="clickable-txt" v-if="isShow">创建职位</span>
        </a-form-item>
        <a-form-item name="remark" :rules="fieldSetList.remark.notNull?rules.remark:[{ required: false }]" v-if="fieldSetList.remark.show">
          <template #label>备<i class="space-font" />注</template>
          <a-textarea v-model:value="ruleForm.remark"  placeholder="请填写备注" :auto-size="{ minRows: 3, maxRows: 5 }" maxlength="500" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button class="cancelBtn" @click="closeMoadl">取消</a-button>
        <a-button type="primary" @click="handleOk" :loading="loading" class="mainBtn">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
import { message } from "ant-design-vue";
import { defineComponent, reactive, toRefs,onMounted ,getCurrentInstance} from "vue";
import {
  CaretDownOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import { getFieldSet,getDicList } from "@/composables/base"
import {
  addContactAPI,
  updateContactAPI,
  contactDetailAPI,
  relatedPositionsAddAPI,//职位下拉框(添加客户联系人时)
  relatedPositionsUpdateAPI,//职位下拉框(编辑客户联系人时)
} from "@/api/customer"
export default defineComponent({
  emits: ['closeMoadl'],
  props:['isShowMoadl','customerId','id','isShow'],
  components: {
    CaretDownOutlined,
    CloseOutlined
  },
  setup(props,{ emit }) {
    var data = reactive({
      modalFormRef:{},//组件ref
      loading:false,//保存loading
      ruleForm: {
        valid:undefined,
        importantLevel:undefined,
        name:'',
        position:'',
        phone:'',
        mail:'',
        remark:'',
      },
      fieldSetList:{
        valid:{},
        importantLevel:{},
        name:{},
        position:{},
        phone:{},
        qq:{},
        wx:{},
        sex:{},
        mail:{},
        remark:{},
      },
      importList:[],//重要性
      sfyxSelect: [
        {
          label: "有效",
          value: 1,
        },
        {
          label: "失效",
          value: 0,
        },
      ], //是否有效
      sexGender: [
        {
          label: "男",
          value: 1,
        },
        {
          label: "女",
          value: 0,
        },
      ],
      projectList:[],//关联职位
    });
    const validateMail = async (_rule, value) => {
      if (!value) {
        if(data.fieldSetList.mail.notNull){
          return Promise.reject('请输入邮箱地址');
        }
      }else{ 
        let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
        if(!reg.test(value)){
          return Promise.reject('请输入正确的邮箱地址');
        }
      }
    }
    //手机号校验
    const valrPhone = async (_rule, value) => {
      if (value) {
        let reg = /^([0-9])+-?([0-9]+)$/
        if(!reg.test(value) || value.length < 7 || value.length > 20){
          return Promise.reject('请输入正确的手机号');
        }
      }else{
        return Promise.reject('请输入7-20位的手机号');
      }
    }
    //校验规则
    const rules ={
      valid:[{required: true, message: "请选择是否有效", trigger: 'change',}],
      importantLevel:[{required: true, message: "请选择重要程度", trigger: 'change',}],
      name:[{required: true, message: "请填写姓名", trigger: 'blur',}],
      position:[{required: true, message: "请填写职位", trigger: 'blur',}],
      phone:{required: true, validator: valrPhone, trigger: 'blur'},
      remark:[{required: true, message: "请填写备注", trigger: 'blur',}],
      qq:[{ required: true, message: "请输入1-15位的QQ", trigger: "blur"}],
      wx:[{ required: true, message: "请输入1-20位的微信", trigger: "blur"}],
      sex:[{required: true, message: "请选择性别", trigger: 'change',}],
      mail: [{  validator: validateMail, trigger: "blur" }],
    }
    //保存
    const $bus = getCurrentInstance().appContext.config.globalProperties.$bus
    const handleOk = () => {
      data.modalFormRef.validateFields().then((res)=>{
        data.loading = true
        res.customerId = props.customerId
        res.id = props.id
        let url = props.id?updateContactAPI:addContactAPI
        url(res).then(res=>{
          data.loading = false
          if(res.success){
            message.success('保存成功');
            emit('saveCantactHandle', true)
            closeMoadl(1)
            $bus.emit('updateDetailBase')//更新tab数值
          }else{
            message.warning(res.message);
          }
        })
      }).catch((rep)=>{
        data.modalFormRef.scrollToField(rep.errorFields[0].name)//滚动到对应字段位置
      })
    };
    //关闭弹窗
    const closeMoadl = (isUpdata)=>{
      // emit('closeMoadl',false,isUpdata)//联系人列表
      emit('closeMoadl','isShowPer',false,isUpdata==1?isUpdata:'')//客户列表
    };
    //详情
    const getDetail=()=>{
      let param={
        id:props.id
      }
      contactDetailAPI(param).then(res=>{
        if(res.success){
          data.ruleForm =res.data
          data.ruleForm.projectIds = []
          data.ruleForm.projects.forEach(item =>{
            data.ruleForm.projectIds.push(item.id)
          })
        }
      })
    }
    //获取关联职位下拉框
    const getProject = ()=>{
      let param = {
        customerId:props.customerId,
        customerContactId:props.id,
      }
      let url = props.id?relatedPositionsUpdateAPI:relatedPositionsAddAPI
      url(param).then(res=>{
        if(res.success){
          data.projectList = res.data

        }
      })
    }
    //跳转职位
    const router = useRouter();
    const toPositon = ()=>{
      const url = router.resolve({
        path: '/addPosition',
        query:{
          customerId:props.customerId
        }
      });
      window.open(url.href);
    }
    onMounted(async() => {
      getProject()
      if(props.id){
        getDetail()
      }
      
      data.fieldSetList = await getFieldSet('CUSTOMER_CONTACTS')//客户联系人设置字段
      if(data.fieldSetList.name.show){
        data.importList = await getDicList ('IMPORTANT_LEVEL',data.fieldSetList.name.notNull)//重要性
      }
      // if(data.fieldSetList.valid.show && data.fieldSetList.valid.notNull===0){
      //   data.sfyxSelect.unshift({ value: '',label: "请选择"})
      // }
      if(data.fieldSetList.sex.show && data.fieldSetList.sex.notNull===0){
        data.sexGender.unshift({ value: '',label: "请选择"})
      }
    })
    return {
      ...toRefs(data),
      rules,
      handleOk,
      closeMoadl,
      getDetail,
      getProject,
      toPositon,
    };
  },
});
</script>
<style scoped>
::v-deep .perModel .ant-modal-body{
  padding-bottom: 8px;
  max-height: calc(100vh - 170px);
  overflow: hidden;
  overflow-y: auto;
}
.space-font2{
  margin-left: 34px;
}
::v-deep .ant-form-item-label{
  width: 70px;
  margin-right: 24px;
}

::v-deep .flexItem .ant-form-item-control-input-content{
  display: flex;
  align-items: center;
}
.clickable-txt{
  white-space: nowrap;
  margin-left: 8px;
}
</style>
